<template lang="pug">
  v-app-bar(app text height="90" :color="!$vuetify.theme.dark? 'white' : 'grey darken-4'" elevate-on-scroll)
    v-app-bar-nav-icon(v-if="$vuetify.breakpoint.mobile" @click="$emit('drawer')")
    Search(v-if="signedIn && $route.name ==='Home' && !$vuetify.breakpoint.xs")
      v-badge(
          v-if="signedIn && $route.name ==='Home'"
          :content="filterCounter"
          :value="filterCounter !== 0"
          overlap
          color="transparent")
        v-icon(medium) mdi-magnify
        Search
    v-btn(v-if="signedIn && $route.name ==='Home' && $vuetify.breakpoint.xs"
           @click="toggleSearch"
           rounded
           color="primary")
      v-icon mdi-magnify
      | Search
    v-spacer
    v-menu(
        v-if="signedIn && $route.name ==='Home' && !$vuetify.breakpoint.mobile"
        bottom
        left
        offset-y)
      v-divider
      v-list(
          dense
          min-width="150"
          color="transparent"
          class="justify-start text-start")
        v-list-item-group(
            v-model="currentSortItem"
            color="primary"
        )
          v-list-item(
              v-for="(item, i) in filteredSortStrategies"
              :key="i"
              @click="SwitchSortStrategy(item)")
            v-list-item-content(content="transparent")
              v-list-item-title {{ $vuetify.lang.t('$vuetify.' + item.type) }}
            v-list-item-icon(
                v-if="item.isEnabled"
                right
            )
              v-icon(
                  v-if="item.isDescending") mdi-arrow-down
              v-icon(v-else) mdi-arrow-up
    v-btn(
        v-if="signedIn && $route.name ==='Home'"
        @click="openDialog"
        class="mr-6"
        rounded
        color="primary"
    )
      v-icon mdi-plus
      | {{ $t('$vuetify.addNewCard') }}
    v-menu(v-if="!$vuetify.breakpoint.xs" offset-y min-width="300" rounded="lg")
      template(v-slot:activator="{ on, attrs }")
        v-btn(icon v-bind="attrs" v-on="on" class="mr-6")
          v-avatar(:color="userAvatarColor" class="justify-space-around" size="32")
              span(class="white--text headline") {{ userInitials ? userInitials : '' }}
          v-icon(size="32" color="blue-grey lighten-1") mdi-chevron-down
      v-list(class="text-start")
        v-list-item
          v-list-item-avatar(:color="userAvatarColor" class="justify-space-around" size="28")
            span(class="white--text headline") {{ userInitials ? userInitials : '' }}
          v-list-item-title {{ userName }}
        v-list-item(to="/profile")
          v-list-item-avatar(class="justify-space-around" size="28")
            v-icon(size="28") mdi-cog
          v-list-item-title {{ $vuetify.lang.t('$vuetify.navBar.editProfile') }}
        v-divider
        v-list-item(@click="logoutClicked")
          v-list-item-avatar(class="justify-space-around" size="28")
            v-icon(size="28") mdi-logout
          v-list-item-title {{ $vuetify.lang.t('$vuetify.navBar.logOut') }}
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import CreateTenantDialog from "@/components/tenant/creation/CreateTenantDialog";
import Search from "@/components/search/Search";
import JoinTenantDialog from "@/components/tenant/joining/JoinTenantDialog";
import {fireAnalytics} from "../../main";

export default {
  name: "Navbar",
  components: {JoinTenantDialog, Search, CreateTenantDialog},
  props: {},
  data() {
    return {
      // for some reason the getter showFilter is not working in this case
      // because of the time limit I used this hack instead 29.12.2020
      search: false,
      mini: false,
      /**
       * Is nav menu visible
       * (name taken from Vue)
       * @type {boolean}
       */
      drawer: false,
      /**
       * The index of the active item in the Nav item group
       * Used to hide nav menu by clicking outside of it
       * @type {boolean}
       */
      currentNavItem: 0,
      /**
       * The display name of the tenant
       * @type {string}
       */
      tenantDisplayName: "",
      /**
       * The index of the current sort strategy in the dropdown
       * @type {number}
       */
      currentSortItem: 0,
      /**
       * The List of navigation items
       * @typedef navItem
       * @type {Object}
       * @property {string} icon
       * @property {string} title
       * @property {string} route
       */
      allNavItems: [
        {
          icon: 'mdi-view-dashboard-outline',
          title: this.$t('navBar.dashboard'),
          route: '/',
          private: true
        },
        {
          icon: 'mdi-account-group-outline',
          title: this.$t('navBar.editTenant'),
          route: '/tenant',
          private: false
        },
      ],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "signedIn",
      "filter",
      "currentTenant",
      "currentProfile",
      "isPrivateMode",
      "sortStrategies",
      "currentTenant",
      "currentProfile",
    ]),
    userName() {
      return !this.currentProfile ? '' : this.currentProfile.alias ? this.currentProfile.alias : this.currentProfile.email;
    },
    userInitials() {
      const segments = this.userName.split(" ").filter(Boolean);
      const letters = []

      for (let i = 0; i < segments.length; i++) {
        letters.push(segments[i].charAt(0))
      }

      const initials = letters.join('');

      return initials? initials : ''
    },
    userAvatarColor() {
      return (this.currentProfile && this.currentProfile.avatarColor)? this.currentProfile.avatarColor : '#ffb84a'
    },
    navItems() {
      if (this.isPrivateMode) {
        return this.allNavItems.filter((item) => {
          return item.private === true
        })
      }

      return this.allNavItems
    },
    /**
     * Returns the number of applied filters
     * @returns {number}
     */
    filterCounter() {
      return this.filter.numberOfAppliedFilters();
    },
    /**
     * Generates a filtered list of all sorting strategies
     * excludes author and likes strategies in private mode
     * @returns {SortStrategy[]}
     */
    filteredSortStrategies() {
      return this.sortStrategies.filter(strategy => {
        return !((this.isPrivateMode && strategy.type === "creator") || (this.isPrivateMode && strategy.type === "likes"))
      })
    },
    filteredTenants() {
      return (this.currentProfile && this.currentProfile.tenants) ? this.currentProfile.tenants.filter((item) => {
        return item.companyName !== this.currentTenant.companyName
      }) : []
    }
  },
  methods: {
    ...mapActions([
      "openFilter",
      "closeFilter",
      "newLink",
      "appLoggedOut",
      "readProfile",
      "SwitchToPrivateMode",
      "SetActiveTenant",
      "searchCards",
      "createTenant",
      "toggleFilter",
    ]),
    onHelpClicked() {
      fireAnalytics.logEvent('help_clicked');
      this.$emit('help-clicked')
    },
    /**
     * Toggles the filter and search panel
     */
    toggleSearch() {
      // for some reason the getter showFilter is not working in this case
      // because of the time limit I used this hack instead 29.12.2020
      this.search = !this.search;

      if (this.search) {
        this.$vuetify.goTo(0, {duration: 1, offset: 1, easing: "linear"});
        this.openFilter()
        return
      }

      this.closeFilter();
    },

    /**
     * Opens the new Link dialog
     */
    openDialog() {
      this.newLink();
    },
    /**
     * Redirects to the Dashboard
     */
    gotoDashboard() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    /**
     * Logs out the current user
     */
    logoutClicked() {
      try {
        this.appLoggedOut();
        if (this.$route.path !== "/login") {
          this.$router.push("login");
        }
      } catch (err) {
        console.log("Logout not possible", err);
      }
    },
    /**
     * Switches to private mode and goes to dashboard
     */
    SwitchToPrivateModeClicked() {
      this.SwitchToPrivateMode();
      this.gotoDashboard();
    },
    /**
     * Switches to selected tenant and goes to dashboard
     * @param {string} tenantId Tenant's ID
     */
    SwitchToTenantClicked(tenantId) {
      this.SetActiveTenant(tenantId);
      this.gotoDashboard();
    },
    /**
     * Sets the current sorting strategy and reloads the cards
     * @param {SortStrategy} strategy
     */
    SwitchSortStrategy(strategy) {
      this.sortStrategies.forEach(element => {
        if (element === strategy) {
          element.isEnabled ? element.isDescending = !element.isDescending : element.isEnabled = true;
        } else {
          element.isEnabled = false;
        }
      });
      this.searchCards(this.filter.tagFilters);
    }
  },
  watch: {
    /**
     * Watches if the current nav item is changed and then closes navigation drawer
     */
    currentNavItem() {
      this.drawer = false;
    },
    /**
     * Watches if the current tenant has changed and changes the tenant display name
     * @param {Tenant}
     */
    currentTenant(val) {
      if (val) {
        this.tenantDisplayName = val.displayName;
      } else {
        this.tenantDisplayName = "";
      }
    },
  },
};
</script>

<style scoped lang="css">
.v-btn--active.no-active::before {
  background-color: transparent !important;
}

#navbar {
}

.linkAsText {
  color: var(--secondary--text);
  text-decoration: none;
}

.v-btn::before {
  display: none;
}

.v-btn--active {
  font-weight: bold;
}
</style>
