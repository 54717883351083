<template lang="pug">
  v-container(class="justify-center align-center" fluid)
    v-row(justify="center" align="center")
      v-col(cols="12" class="ml-md-8 mb-0 pb-0")
        h2.text-h4 {{ $t('$vuetify.profile.title') }}
      v-col(cols="12" class="ml-xs-0 mr-xs-0 pl-md-8")
        UserSettings
</template>
<script>


import UserSettings from "@/components/user/UserSettings";
export default {
  name: "User",
  components: {UserSettings},
};
</script>