<template lang="pug">
  v-container(class="justify-center align-top" fluid)
    v-row(justify="start" align="center")
      v-col(v-if="showFilter" cols="12")
        FilterDialog
      v-col(cols="12" class="ml-8 mb-0 pb-0")
        h2.text-h4 Dashboard
      v-col(v-if="!loadingCards" cols="12" class="mt-0 pt-0")
        RecordList
      v-col(v-else v-for="(item, i) in numOfSkeletonCards" :key="i" cols="12" md="6" lg="4" xl="3")
        v-container(fluid ma-0)
          v-row
            v-col(cols="12")
              v-skeleton-loader(type="card" loading)
</template>

<script>
import RecordList from '../components/RecordList.vue';
import FilterDialog from "@/components/search/FilterDialog";
import {mapGetters} from "vuex";

export default {
  components: {
    FilterDialog,
    RecordList
  },
  computed: {
    ...mapGetters([
      'showFilter',
      "loadingCards",
      "cardPagination"
    ]),
    numOfSkeletonCards() {
      // On mobile return 2 cards, on tablet 4 and on desktop 8
      const numRequestedCards = (this.cardPagination.nextRequestCount - this.cardPagination.firstRequestCount);
      return numRequestedCards > 0 ? numRequestedCards : this.$vuetify.breakpoint.sm ? 2 : this.$vuetify.breakpoint.mobile ? 4 : 8;
    }
  }
}
</script>

<style>


</style>
