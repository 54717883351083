<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12")
        v-card(shaped)
          v-card-text
            v-data-table(:items="allCustomLabels" :headers="headers" :itemsPerPage="itemsPerPage" sort-by="label" :search="search")
              template(v-slot:top)
                v-row(justify="start" align="center" class="text-start")
                  v-col(cols="12" sm="2" md="1")
                    v-toolbar-title {{$vuetify.lang.t('$vuetify.labels')}}
                  v-col(cols="12" sm="6" md="5" class="pl-0 pr-0 justify-start text-start")
                    v-text-field(
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('tenant.labels.search')"
                      single-line
                      hide-details
                      rounded
                      outlined
                      dense
                    )
                  v-col(cols="12" sm="4" md="6" class="text-end justify-end")
                    v-dialog( v-model="dialog" max-width="500px")
                      template(v-slot:activator="{ on, attrs }")
                        v-tooltip(bottom :disabled="!isAdmin || !isUnderFeatureLimit('t-custom-labels', allCustomLabels.length)")
                          template(v-slot:activator="{ on, attrs }")
                            div(v-on="on" class="d-inline-block")
                              v-btn(
                                color="primary"
                                dark
                                rounded
                                class="mb-2"
                                :disabled="!isAdmin || !isUnderFeatureLimit('t-custom-labels', allCustomLabels.length)"
                                v-bind="attrs"
                                :block="$vuetify.breakpoint.mobile"
                                @click="dialog=true"
                                v-on="on")
                                v-icon mdi-plus
                                | {{ $t('tenant.labels.buttonCreateLabel') }}
                          span(v-if="!isAdmin || !isUnderFeatureLimit('t-custom-labels', allCustomLabels.length)") {{ $t('tenant.subscription.limits.overLabelLimit') }}
                      v-card
                        v-card-title
                          span(class="headline") {{ formTitle }}
                        v-card-text
                          v-container
                            v-form(ref="addnewlabelform" v-model="valid")
                              v-row
                                v-col(cols="12")
                                  v-text-field(
                                    v-model="editedItem.label"
                                    :label="$t('tenant.labels.fields.title')"
                                    :error-messages="titleErrors"
                                    @input="$v.editedItem.label.$touch()"
                                    @blur="$v.editedItem.label.$touch()"
                                    )
                                v-col(cols="12")
                                  v-text-field(
                                    v-model="editedItem.description"
                                    :label="$t('tenant.labels.fields.description')"
                                    :error-messages="descriptionErrors"
                                    @input="$v.editedItem.description.$touch()"
                                    @blur="$v.editedItem.description.$touch()"
                                    )
                                v-col(cols="12")
                                  v-menu(v-model="menuEditColor" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" min-width="200px")
                                    template(v-slot:activator="{ on }")
                                      v-row(v-on="on")
                                        v-col(cols="1").mt-3
                                          v-avatar(:color="editedItem.color" size="30")
                                        v-col(cols="11")
                                          v-text-field(v-model="editedItem.color" :label="$t('tenant.labels.fields.color')"                                            readonly)
                                    v-color-picker(v-model="editedItem.color" hide-mode-switch mode="hexa" )
                        v-card-actions
                          v-spacer
                          v-btn(color="primary" text @click="close") {{ $vuetify.lang.t('$vuetify.close') }}
                          v-btn(color="primary" text @click="save" :disabled="$v.$invalid") {{ $vuetify.lang.t('$vuetify.save') }}
              template(v-slot:item.actions="{ item }")
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    div(v-on="on" class="d-inline-block")
                      v-icon(
                        :disabled="!isAdmin"
                        small
                        class="mr-2"
                        @click="editItem(item)") mdi-pencil
                  span {{ $t('tenant.labels.buttonEditLabel') }}
                v-tooltip(bottom)
                  template(v-slot:activator="{ on, attrs }")
                    div(v-on="on" class="d-inline-block")
                      v-icon(
                        :disabled="!isAdmin"
                        small
                        class="mr-2"
                        @click="deleteItem(item)"
                        ) mdi-delete
                  span {{ $t('tenant.labels.buttonDeleteLabel') }}
              template(v-slot:item.color="{ item }")
                v-row
                  v-col(cols="2" class="text-start")
                    v-avatar(:color="item.color" size="32")
                  v-col(cols="10" class="text-start")
                    p {{ item.color }}
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import TenantMembers from "@/components/tenant/TenantMembers";
import {mapActions, mapGetters} from "vuex";
import LinkLabel from "@/store/classes/LinkLabel";

export default {
  name: "TenantLabels",
  components: {TenantMembers},
  mixins: [validationMixin],
  validations: {
    editedItem: {
      label: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      description: {
        maxLength: maxLength(256),
      },
    }
  },
  data() {
    return {
      valid: null,
      dialog: false,
      menuEditColor: false,
      search: '',
      borderColor: '#000000',
      itemsPerPage: 10,
      editedIndex: -1,
      editedItem: {
        label: '',
        description: '',
        color: '#2196F3'
      },
      defaultItem: {
        label: '',
        description: '',
        color: '#2196F3'
      },
      headers: [
        {
          text: this.$t('tenant.labels.fields.title'),
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: this.$t('tenant.labels.fields.description'),
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t('tenant.labels.fields.color'),
          align: "start",
          sortable: true,
          value: "color",
        },
        {
          text: this.$t('tenant.labels.fields.actions'),
          value: 'actions',
          align: 'end',
          sortable: false
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'allCustomLabels',
      'getCustomClaimRole',
      'isAdmin',
      'isUnderFeatureLimit']),
    formTitle() {
      return this.editedIndex === -1 ? this.$t('tenant.labels.toolbarCreateLabel')  : this.$t('tenant.labels.toolbarEditLabel')
    },
    titleErrors() {
      const errors = []
      if (!this.$v.editedItem.label.$dirty) return errors
      !this.$v.editedItem.label.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.editedItem.label.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.editedItem.description.$dirty) return errors
      !this.$v.editedItem.description.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
  },
  
  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  methods: {
    ...mapActions([
      'addLabel',
      'deleteLabel'
    ]),

    editItem(item) {
      this.editedIndex = this.allCustomLabels.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleDeleteLabel'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), 
        { color: 'red' })
        .then((response) => {
          if (response.confirmed){
            const index = this.allCustomLabels.indexOf(item)
            const selectedLabel = this.allCustomLabels[index]
            this.deleteLabel(selectedLabel)                  
          }
        })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const copyOfOriginalLabel = this.allCustomLabels[this.editedIndex]
        Object.assign(copyOfOriginalLabel, this.editedItem)
        this.addLabel(copyOfOriginalLabel)
      } else {
        this.addLabel(new LinkLabel(this.editedItem.label, this.editedItem.description, this.editedItem.color))
      }
      this.close()
    },
  },
}
</script>