export default class LinkDateFormatter {
  constructor(){

  }

  static format(created){
      const toDay = new Date();

      const Difference_In_Time = toDay.getTime() - created.getTime();
      const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

      const year = toDay.getYear() - created.getYear();
      const month = toDay.getMonth() - created.getMonth();

      if(Difference_In_Days < 1){
          return "today"
      }
      else if(Difference_In_Days < 2){
        return "yesterday"
      }
      else if(Difference_In_Days < 15){
        return Difference_In_Days + " days ago"
      }
      else if(year === 0 && month === 0){
        return Difference_In_Days + " days ago"
      }
      else if(year === 0 && month === 1){
        return "last month"
      }
      else if(year === 0 && month > 1){
        return month + " months ago"
      }
      else if(year === 1 && Math.abs(month) < 9){
        return month + " months ago"
      }
      else if(year === 1){
        return "last year"
      }
      else{
        return year + "years ago";
      }

    return "";
  }
}
