/**
  * Stores the Link type properties and methods
*/
export default class LinkType {
  /**
  * the Type's displayed name
  * @type {string}
  */
  label = "";
  /**
  * the Type's icon
  * @type {string}
  */
  icon = "";
  /**
  * the Type's inner name
  * @type {string}
  */
  name = "";
  /**
  * the regex used to detect Type
  * @type {RegExp}
  */
  regexToDetect = null;
  /**
  * the regex used to validate Link's URL
  * @type {RegExp}
  */
  regexToComplete =  null;
  /**
  * the Type's regex priority when autodetect type
  * @type {number}
  */
  regexPriority = 0;

/**
  * @constructs LinkType
  * @param {string} name - the Type's inside name
  * @param {string} label - the Type's displayed name  * 
  * @param {string} icon - the Type's icon
  * @param {string} regexToDetect - the Type's regex
  * @param {string} regexToComplete - the Type's regexToComplete
  * @param {number} regexPriority - the Type's regex priority
*/
  constructor(name, label, icon, regexToDetect, regexToComplete, regexPriority){
    this.name = name;
    this.label = label;
    this.icon = icon;
    this.regexToDetect = new RegExp(regexToDetect);
    this.regexToComplete = new RegExp(regexToComplete);
    this.regexPriority = regexPriority;
  }
  /**
   * Reformat the url based on the LinkType
   *
   * @param {string}  url  - the Link Type's name
   * @param {LinkType[]} allLabels - an array of existing types
   * 
   * @returns {string}
  */
  reformatURL(url){
    if ((this.name !== "undefined") && !this.regexToComplete.test(url)) {
      if (this.name === "mailto"){
        if (!url.startsWith("mailto:")){
          if (url.startsWith("http://") || url.startsWith("https://")){
            url = url.slice(url.search(":")+3);
          }
          return "mailto:".concat(url);
        }
      } else {
        if ((this.name !== "phone") && !url.startsWith("http://") && !url.startsWith("https://")){
          if (url.startsWith("mailto:")){
            url = url.slice(url.search(":")+1);
          }
          return "https://".concat(url);
        }
      }
    }
    return url;
  }
  /**
   * Detects the type based on regex of the url
   * @static
   * @param {string}  url  - the url
   * @param {LinkType[]} allLabels - an array of existing types
   * 
   * @returns {LinkType} 
  */
  static fromRegex(url, allTypes){
    let detectedType = null;
    allTypes.forEach((item) => {
      if (item.regexToDetect.test(url) && 
        (!detectedType || (detectedType && (item.regexPriority > detectedType.regexPriority)))){
        detectedType = item;
      } 
    });
    if(detectedType){
      return detectedType;
    } else {
      return LinkType.undefined();
    }
  }
  /**
   * Retrieves a new Link Type object based on its name
   * @static
   * @param {string}  name  - the Link Type's name
   * @param {LinkType[]} allLabels - an array of existing types
   * 
   * @returns {LinkType} 
  */
  static fromName(name, allTypes){
    if(name && allTypes && allTypes.length > 0){
      let tmpType = allTypes.find(item => item.name === name);
      if(tmpType){
        return tmpType;
      }        
    }
    return LinkType.undefined();
  }

  /**
   * Generates a new undefined Link Type object 
   * @static   * 
   * @returns {LinkType} 
  */
  static undefined(){
    return new LinkType('undefined', 'Undefined', 'mdi-help-circle-outline', "^\\S+$", "^\\S+$", 0);
  }

  toJSON(){
    return {
      label: this.label,
      icon: this.icon ,
      name: this.name,
      regexToDetect: this.regexToDetect,
      regexToComplete: this.regexToComplete,
    }
  }
}
