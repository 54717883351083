<template lang="pug">
  v-container(fluid)
    v-row(align="center" justify="center")
      v-col(v-if="verified && loaded" cols="12")
        ConfirmationFeedback(:description="$vuetify.lang.t('$vuetify.emailVerification.confirmation')" :continue-url="continueUrl")
      v-col(v-else-if="!verified && loaded" cols="12")
        RejectionFeedback(:description="$vuetify.lang.t('$vuetify.emailVerification.rejection')" :continue-url="continueUrl")
      v-col(v-else class="justify-center align-center text-center")
        v-progress-circular(indeterminate color="primary" :width="3")
</template>

<script>
import FirebaseAuth from "../../../firebase/auth/FirebaseAuth";
import RejectionFeedback from "@/components/feedback/RejectionFeedback";
import ConfirmationFeedback from "@/components/feedback/ConfirmationFeedback";
import {fireAnalytics} from "../../main";

export default {
  name: "EmailVerification",
  components: { ConfirmationFeedback, RejectionFeedback},
  props: {
    actionCode: {
      type: String,
      default: "",
      required: true,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    },
    lang: {
      type: String,
      default: "en",
      required: false,
    }
  },
  data() {
    return {
      verified: false,
      loaded: false,
    }
  },
  mounted() {
    FirebaseAuth.methods.checkActionCode(this.actionCode)
        .then(() => {
          FirebaseAuth.methods.handleVerifyEmail(this.actionCode, this.continueUrl, this.lang)
        })
        .then(() => {
          fireAnalytics.logEvent('sign_up_email_verified_successfully',
              {
                continueUrl: this.continueUrl,
                lang: this.lang
              });
          this.verified = true;
          this.loaded = true;
        })
        .catch(error => {
          fireAnalytics.logEvent('sign_up_email_verified_error', {
            error: error,
            continueUrl: this.continueUrl,
            lang: this.lang
          });
          this.verified = false;
          this.loaded = true;
        })
  }
}
</script>

<style scoped>

</style>