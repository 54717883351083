<template lang="pug">
  v-hover
    template(v-slot:default="{ hover }")
      v-card(hover shaped ripple :href="paymentPlan.link" class="text-decoration-none" :elevation="(hover || active)? 24 : elevationDefault")
        v-row(class="text-center justify-center align-center")
          v-col(cols="8" class="text-center align-center pb-0 mb-0 mt-6")
            h2.text-h4.font-weight-bold {{ paymentPlan.name }}
            p.text-subtitle-2.font-weight-light {{ paymentPlan.description }}
            span(v-if="monthlyPrice && monthlyPrice.unit_amount > 0")
              span.text-h5.font-weight-light {{ paymentPlan.currency }}
              span.text-h5.font-weight-bold {{ (monthlyPrice.unit_amount / 100) }}
              span.text-subtitle-2 /month
            span(v-else)
              span.text-h5.font-weight-bold FREE
          v-col(cols="12" class="pl-6 pr-6")
            v-divider
          v-col(cols="12" class="justify-center align-center")
            v-row
              v-col(cols="12" class="text-sm-center text-md-start pt-0 pb-0 pl-md-12 mt-0 mb-0")
                p.subtitle-2.font-weight-bold Highlights:
              v-col(cols="12" v-for="(highlight,i) in paymentPlan.highlights" :key="i" class="text-sm-center text-md-start pt-0 pb-0 pl-md-12 mt-0 mb-0")
                p {{ highlight }}
          v-col(cols="12")
            v-spacer(vertical)
          v-col(cols="10" class="text-center justify-center align-center pb-6" large)
            v-btn(rounded :outlined="!hover && !active" :color="(hover || active)? 'secondary' : 'primary'" class="text-decoration-none" :href="paymentPlan.link") {{ paymentPlan.active? 'Selected' : paymentPlan.btn.text }}
</template>

<script>
export default {
  name: "PricingCard",
  props: {
    active: {
      type: Boolean,
      default: false,
      required: false,
    },
    elevationDefault: {
      type: Number,
      required: false,
      default: 2,
    },
    paymentPlan: {
      type: Object,
      required: true,
    },
  },
  computed: {
    monthlyPrice() {
      return this.paymentPlan.prices.find((item) => { return item.interval === 'month'})
    }
  }
};
</script>

<style scoped></style>
