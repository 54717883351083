import typesJson from '../data/initial-types.json';
import LinkType from '../classes/LinkType';

const  state = {
  /**
   * A list of all Link types
   * @type {LinkType[]}
  */
  types: typesJson
};


const getters = {
  /**
   * Retrives all stored Link types
   * @returns {LinkType[]}
  */
  allTypes(){
    return state.types
  }
};

const actions = {
  /**
   * Recasts all json link types as LinkType class objects and stores them in the vuex
  */
  fetchTypes(context) {
    let tmpTypes = [];
    state.types.forEach((item, i) => {
      tmpTypes.push(new LinkType(item.name, item.label, item.icon, item.regexToDetect, item.regexToComplete, item.regexPriority))
    });
    tmpTypes.push(LinkType.undefined())
    context.commit('setTypes', tmpTypes);
  },
  /**
   * Detects the type of link based on the URL
   * @param {Object}  payload  payload.
   * @param {String}  payload.url  The Link's URL
   * 
   * @returns {LinkType}
  */
  detectTypeFromURL(context, payload) {
    return new Promise(function (resolve, reject){
      const aDetectedType = LinkType.fromRegex(payload.url, state.types);
      resolve(aDetectedType);
    });
  }
};

const mutations = {
  setTypes(state, types){
    state.types = types;
    console.log("Type list updated: ", state.types);
  },
  clearTypes(state){
    state.types = [];
  }
};

export default{
  state,
  getters,
  actions,
  mutations
};
