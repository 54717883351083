<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card shaped>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titleSelectLanguage') }}</v-card-title>
          <v-card-text>
            <v-btn class="px-0" text :color="this.$i18n.locale === 'en'? 'primary' : 'grey'" @click="changeLocaleEn">
              en
            </v-btn>
            <v-btn class="px-0" text :color="this.$i18n.locale === 'de'? 'primary' : 'grey'" @click="changeLocaleDe">
              de
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card shaped>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titleSelectTheme') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="isDark"
                :hint="$vuetify.lang.t('$vuetify.profile.themeDarkModeHint')"
                inset
                :label="$vuetify.lang.t('$vuetify.profile.themeDarkMode')"
                persistent-hint
            ></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import firebase from 'firebase/app'

export default {
  name: "UserPreferences",
  computed: {
    ...mapGetters({
      profile: "currentProfile",
    }),
    isDark: {
      get() {
        return localStorage.getItem("isDark") ? this.$vuetify.theme.isDark : false
      },
      set() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        const theme = this.$vuetify.theme.dark? 'dark' : 'light';
        localStorage.setItem("theme", theme);
      }
    },
  },
  methods: {
    ...mapActions([
      "updateProfile",
      "StoreNotification",
    ]),
    SaveChangesClicked: function () {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdateProfile'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), {color: 'blue'})
          .then((response) => {
            if (response.confirmed) {
              if (this.$refs.profileform.validate()) {
                if (this.filledAlias) {
                  this.isUserAliasTaken(this.alias)
                      .then(isTaken => {
                        if (isTaken) {
                          this.StoreNotification({code: "user/alias-taken", type: "info"});
                          this.alias = this.profile.alias;
                        } else {
                          this.updateUserAlias(this.alias)
                        }
                      })
                }
                if (this.filledName || this.filledSurname || this.filledYear || this.filledMonth || this.filledDay) {
                  this.updateProfile(this.$data)
                }
                this.cleanUpProfile();
              }
            } else {
              //Revert changes
              this.username = this.profile.username;
              this.surname = this.profile.surname;
              this.alias = this.profile.alias;
              this.birthyear = this.profile.birthyear;
              this.birthmonth = this.profile.birthmonth;
              this.birthday = this.profile.birthday;
              this.cleanUpProfile();
            }
          })
    },
    changeLocaleEn() {
      this.$i18n.locale = "en"
      this.$vuetify.lang.current = "en";
      localStorage.setItem("lang", "en");
      firebase.auth().languageCode = "en";
    },
    changeLocaleDe() {
      this.$i18n.locale = "de"
      this.$vuetify.lang.current = "de";
      localStorage.setItem("lang", "de");
      firebase.auth().languageCode = "de";
    },
    setFieldValues() {
      if (this.profile) {
        this.username = this.profile.username;
        this.surname = this.profile.surname;
        this.email = this.profile.email;
        this.alias = this.profile.alias;
        this.birthyear = this.profile.birthyear;
        this.birthmonth = this.profile.birthmonth;
        this.birthday = this.profile.birthday;
      }
    }
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    profile(value) {
      if (value) {
        this.setFieldValues();
      }
    },
  },
};
</script>
