<template lang="pug">
  v-footer(color="transparent" app absolute)
    v-col(cols="12" class="text-center")
      a(href="https://yndooo.com/" class="text-decoration-none mr-4 fontColorLight--text") © {{ $vuetify.lang.t('$vuetify.productName') }}
      a(href="https://yndooo.com/contact" class="text-decoration-none mr-4 fontColorLight--text") Contact
      a(href="https://yndooo.com/privacy" class="text-decoration-none mr-4 fontColorLight--text") Privacy & Terms
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>

</style>