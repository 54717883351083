<template>
  <v-combobox
      v-model="model"
      :search-input.sync="search"
      :autofocus="autofocus"
      hide-selected
      :hint="customHint"
      :label="$t('$vuetify.search')"
      prepend-inner-icon="mdi-magnify"
      multiple
      persistent-hint
      deletable-chips
      hide-no-data
      disable-lookup
      hide-details
      :disabled="lockstatus"
      clearable
      rounded
      outlined
      dense
      small-chips
      @click:append="toogleFilter"
      >
      <v-icon slot="append" 
        :color="isVisible ? 'primary' : undefined" 
        @click="toogleFilter" 
        v-text="isVisible ? 'mdi-filter-variant' : 'mdi-filter-variant'"
      ></v-icon>
    <template v-slot:no-data >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('$vuetify.filter.noResults') }}"<strong>{{ search }}</strong>".
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: {
    customLabel: {
      required: false,
    },
    customHint: {
      required: false,

    },
    selectedTags: {
      required: false,

    },
    lockstatus: {
      required: false,
    },
    autofocus: {
      required: false,
      default: false
    }
  },
  data: () => ({
    items: [],
    model: [],
    search: null,
    isVisible: false,
  }),
  methods: {
    ...mapActions([
      'fetchTags',
      'filteredTags',
      "openFilter",
      "closeFilter",
      'searchCards',
      'setTagsFilters',
    ]),
    hide() {
      this.closeFilter();
    },
    show() {
      this.$vuetify.goTo(0, {duration: 2, offset: 1, easing: "linear"});
      this.openFilter();
    },
    toogleFilter(){
      this.isVisible = !this.isVisible;
      this.isVisible ? this.show() : this.hide();
    }
  },
  computed: {
    ...mapGetters([
      "allTags",
      "filter",
      "showFilter",
      "currentTenant",
      "isPrivateMode",
    ]),
  },
  watch: {
    selectedTags(val) {
      if (val) {
        this.model = val;
      } else {
        this.model = [];
      }
    },
    model(val) {
      if (!val) {
        val = [];
        this.search = null;
      }

      if (val.length > 10) {
        this.$nextTick(() => this.model.pop())
      }

      this.searchCards(val);
      this.setTagsFilters(val);
    },
    currentTenant(before, after) {
      if (before != after) {
        this.model = [];
      }
    },
    isPrivateMode(before, after) {
      if (before != after) {
        this.model = [];
      }
    }
  },
  created() {
    this.fetchTags();
    this.items = this.allTags;
    this.model = (this.selectedTags) ? this.selectedTags : [];
  }
}
</script>