import Vue from 'vue';
import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import minifyTheme from "minify-css-string";
import LRU from "lru-cache";

Vue.use(Vuetify)
Vue.use(VueI18n)

//i18n custom languages
import en from './i18n/vuetify/en.ts'
import de from './i18n/vuetify/de.ts'

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages: { en,de }, // set locale messages
})

const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
});

const opts = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.purple.darken4,
        secondary: colors.indigo,
        accent: colors.pink,
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        fontColorLight: "#3c4257", // light grey
      },
      dark: {
        primary: colors.purple.darken2,
        secondary: colors.indigo,
        accent: colors.pink,
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
        fontColorLight: "#ffffff", // white
      },
    },
    options: {
      customProperties: true,
      minifyTheme,
      themeCache,
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
};

export default new Vuetify(opts);
