<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card shaped>
      <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titleProfile')}}</v-card-title>
      <v-card-text>
        <v-form
            ref="profileform"
            v-model="validProfile"
            class="px-3"
        >
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12>
              <v-row>
                <v-col cols="6" sm="6">
                  <v-text-field
                      v-model="firstname"
                      :label="$vuetify.lang.t('$vuetify.profile.labelName')"
                      :filled="filledName"
                      @input="filledName = true; changedProfileFields = true; $v.firstname.$touch()"
                      :error-messages="firstnameErrors"
                      @blur="$v.firstname.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field
                      v-model="surname"
                      v-bind:label="$vuetify.lang.t('$vuetify.profile.labelSurname')"
                      :filled="filledSurname"
                      @input="filledSurname = true; changedProfileFields = true; $v.surname.$touch()"
                      :error-messages="surnameErrors"
                      @blur="$v.surname.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                      v-model="alias"
                      :label="$vuetify.lang.t('$vuetify.profile.labelAlias')"
                      :filled="filledAlias"
                      @input="filledAlias = true; changedProfileFields = true; $v.alias.$touch()"
                      :error-messages="aliasErrors"
                      @blur="$v.alias.$touch()"
                  ></v-text-field>
                  <v-btn
                      color="primary"
                      @click="generateRandomAliasClicked"
                  >
                    Generate Random Alias
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12">
                  {{ $vuetify.lang.t('$vuetify.profile.birthdate')}}
                  <v-row>
                    <v-col cols="4" sm="4">
                      <v-text-field
                          v-model="birthyear"
                          :label="$vuetify.lang.t('$vuetify.profile.labelYear')"
                          maxlength="4"
                          type="number"
                          :filled="filledYear"
                          @input="filledYear = true; changedProfileFields = true; $v.birthyear.$touch()"
                          :error-messages="birthyearErrors"
                          @blur="$v.birthyear.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-select
                          v-model="birthmonth"
                          :label="$vuetify.lang.t('$vuetify.profile.labelMonth')"
                          :items="months"
                          :filled="filledMonth"
                          @change="filledMonth = true; changedProfileFields = true"
                      ></v-select>
                    </v-col>
                    <v-col cols="4" sm="4">
                      <v-select
                          v-model="birthday"
                          :label="$vuetify.lang.t('$vuetify.profile.labelDay')"
                          :items="days"
                          :filled="filledDay"
                          @change="filledDay = true; changedProfileFields = true"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                      color="primary"
                      :disabled="!validProfile || !changedProfileFields"
                      @click="SaveChangesClicked"
                  >
                    {{ $vuetify.lang.t('$vuetify.save')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, between } from 'vuelidate/lib/validators'

export default {
  name: "UserProfile",
  mixins: [validationMixin],
  validations: {
    firstname: {
      maxLength: maxLength(256),
    },
    surname: {
      maxLength: maxLength(256),
    },
    alias: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(256),
    },
    birthyear: {
      between: between(1900, 2020),
    },
    email: { required, email },
    oldpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
    newpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
    repeatnewpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
  },
  data() {
    return {
      validProfile: true,
      validEmail: true,
      validPassword: true,
      filledName: false,
      filledSurname: false,
      filledAlias: false,
      filledYear: false,
      filledMonth: false,
      filledDay: false,
      filledEmail: false,
      changedProfileFields: false,
      changedEmailFields: false,
      changedPasswordFields: false,
      firstname: "",
      surname: "",
      email: "",
      alias: "",
      birthyear: "",
      birthmonth: "",
      birthday: "",
      months: [
        "-",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      days: [
        "-",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      oldpassword: "",
      newpassword: "",
      repeatnewpassword: "",
      show1: false,
      show2: false,
      show3: false,
      /* Tenant table headers */
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.displayName'),
          value: "displayName",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t('$vuetify.joined'),
          value: "joinedAt",
          align: "center",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions'),
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      profile: "currentProfile",
    }),
    isDark: {
      get() {
        return localStorage.getItem("isDark") ? this.$vuetify.theme.isDark : false
      },
      set() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        localStorage.setItem("isDark", this.$vuetify.theme.isDark.toString());
      }
    },
    usernameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.username.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.username.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    firstnameErrors() {
      const errors = []
      if (!this.$v.firstname.$dirty) return errors
      !this.$v.firstname.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    surnameErrors() {
      const errors = []
      if (!this.$v.surname.$dirty) return errors
      !this.$v.surname.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    aliasErrors() {
      const errors = []
      if (!this.$v.alias.$dirty) return errors
      !this.$v.alias.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.alias.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.alias.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    birthyearErrors() {
      const errors = []
      if (!this.$v.birthyear.$dirty) return errors
      !this.$v.birthyear.between && errors.push(this.$vuetify.lang.t('$vuetify.rules.between', 1900, 2020))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.email.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
    oldpasswordErrors() {
      const errors = []
      if (!this.$v.oldpassword.$dirty) return errors
      !this.$v.oldpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.oldpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.oldpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      return errors
    },
    newpasswordErrors() {
      const errors = []
      if (!this.$v.oldpassword.$dirty) return errors
      !this.$v.oldpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.oldpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.oldpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      !(this.newpassword != this.oldpassword) && errors.push(this.$vuetify.lang.t('$vuetify.rules.oldnewpassnotsame'))
      return errors
    },
    repeatnewpasswordErrors() {
      const errors = []
      if (!this.$v.oldpassword.$dirty) return errors
      !this.$v.oldpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.oldpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.oldpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      !(this.newpassword === this.repeatnewpassword) && errors.push(this.$vuetify.lang.t('$vuetify.rules.passnotmatch'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "updateProfile",
      "updateUserAlias",
      "updateEmail",
      "updatePassword",
      "StoreNotification",
      "removeTenantMember",
      "isUserAliasTaken",
      "generateRandomAlias",
    ]),
    cleanUpProfile: function() {
      this.changedProfileFields = false;
      this.filledName = false;
      this.filledSurname = false;
      this.filledAlias = false;
      this.filledYear = false;
      this.filledMonth = false;
      this.filledDay = false;
    },
    SaveChangesClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdateProfile'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.profileform.validate()){
                if (this.filledAlias){
                  this.isUserAliasTaken(this.alias)
                      .then( isTaken => {
                        if (isTaken) {
                          this.StoreNotification({code: "user/alias-taken", type: "info"});
                          this.alias = this.profile.alias;
                        } else {
                          this.updateUserAlias(this.alias)
                        }
                      })
                }
                if (this.filledName || this.filledSurname || this.filledYear || this.filledMonth || this.filledDay) {
                  this.updateProfile(this.$data)
                }
                this.cleanUpProfile();
              }
            } else {
              //Revert changes
              this.username = this.profile.username;
              this.surname = this.profile.surname;
              this.alias = this.profile.alias;
              this.birthyear = this.profile.birthyear;
              this.birthmonth = this.profile.birthmonth;
              this.birthday = this.profile.birthday;
              this.cleanUpProfile();
            }
          })
    },
    cleanUpEmail: function() {
      this.changedEmailFields = false;
      this.filledEmail = false;
    },
    UpdateEmailClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdateEmail'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageUpdateEmail'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.updateemailform.validate()) {
                this.updateEmail({ email: this.email })
                    .then (() => {
                      this.cleanUpEmail();
                    })
              }
            } else {
              //Revert changes
              this.email = this.profile.email;
              this.cleanUpEmail();
            }
          })
    },
    cleanUpPassword: function() {
      this.changedPasswordFields = false;
      this.$refs.updatepasswordform.reset();
    },
    ChangePasswordClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleChangePassword'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.updatepasswordform.validate()) {
                this.updatePassword({
                  email: this.email,
                  oldpassword: this.oldpassword,
                  newpassword: this.newpassword,
                })
                    .then (() => {
                      this.cleanUpPassword();
                    })
              }
            } else {
              this.cleanUpPassword();
            }
          })
    },
    changeLocaleEn() {
      this.$i18n.locale= "en"
      this.$vuetify.lang.current = "en";
      localStorage.setItem("lang", "en");
    },
    changeLocaleDe() {
      this.$i18n.locale= "de"
      this.$vuetify.lang.current = "de";
      localStorage.setItem("lang", "de");
    },
    leaveTenantClicked(tenant) {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleLeaveTenant'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'red' })
          .then((response) => {
            if (response.confirmed){
              this.removeTenantMember( {userId: this.profile.id, tenantId: tenant.id})
            }
          })
    },
    setFieldValues() {
      if (this.profile) {
        this.firstname = this.profile.firstname;
        this.surname = this.profile.surname;
        this.email = this.profile.email;
        this.alias = this.profile.alias;
        this.birthyear = this.profile.birthyear;
        this.birthmonth = this.profile.birthmonth;
        this.birthday = this.profile.birthday;
      }
    },
    generateRandomAliasClicked(){
      this.generateRandomAlias()
        .then(response => {
          this.alias = response;
          this.filledAlias = true;
          this.changedProfileFields = true
        })
    }
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    profile(value) {
      if (value) {
        this.setFieldValues();
      }
    },
  },
};
</script>
