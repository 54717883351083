<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    v-row(v-if="!completed" class="fill-height" justify="center")
      v-col(cols="12")
        PasswordResetForm(@password-reset-completed="onPasswortResetCompleted")
    EmailSendFeedback(v-else :description="$t('reset.confirmation.description')" :title="$t('reset.confirmation.title')" :restart-animation="restartAnimation" @resend-link="onResendVerificationEmail")
</template>

<script>
import EmailSendFeedback from "../components/feedback/EmailSendFeedback";
import PasswordResetForm from "../components/recovery/PasswordResetForm";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {PasswordResetForm, EmailSendFeedback},
  data() {
    return {
      completed: false,
      email: "",
      restartAnimation: false,
    };
  },
  methods: {
    ...mapActions(['sendPasswordResetEmail']),
    onPasswortResetCompleted(event) {
      this.email = event
      this.completed= true
    },
    onResendVerificationEmail(event) {
      this.sendPasswordResetEmail({ email: this.email });
      this.restartAnimation = !this.restartAnimation;
    }
  }
};
</script>

<style scoped>
</style>