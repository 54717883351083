import FirebaseAuth from "../../../firebase/auth/FirebaseAuth";
import FirestoreUser from "../../../firebase/firestore/FirestoreUser";
import FirestoreTenant from "../../../firebase/firestore/FirestoreTenants";
import FirestoreCards from "../../../firebase/firestore/FirestoreCards";
import Tenant from "../classes/Tenant"
import Profile from "../classes/Profile"
import router from '../../router/index'
import {createAvatarColor} from "@/store/classes/Colors";
import {fireAnalytics} from "../../main";
import { uniqueNamesGenerator, adjectives, colors, animals, NumberDictionary  } from 'unique-names-generator';

const state = {
    /**
     * User credentials
     * @type {firebase.auth.UserCredential}
     */
    user: null,
    /**
     * User profile
     * @type {Profile}
     */
    profile: null,
    /**
     * Active tenant
     * @type {Tenant}
     */
    tenant: null,
    /**
     * Is private mode enabeled
     * @type {boolean}
     */
    privateMode: false,
    /**
     * current user's status inside Tenant (owner, admin, member)
     * @type {string}
     */
    tenantStatus: null
};

const getters = {
    /**
     * Checks if user is signed in
     * @returns {boolean}
     */
    signedIn: () => {
        return state.user !== null
    },
    /**
     * Retrieves currently signed in user credentials
     * @returns {firebase.auth.UserCredential}
     */
    currentUser: () => {
        return state.user
    },
    /**
     * Retrieves currently signed in user profile
     * @returns {firebase.firestore.DocumentData}
     */
    currentProfile: () => {
        return state.profile
    },
    /**
     * Converts a userId to a displayName
     * @returns {String}
     */
    userIdToDisplayName: state => userid => {
        let aMember = state.tenant ? state.tenant.members.find(item => item.id === userid) : null;
        return aMember ? aMember.displayName : null;
    },
    /**
     * Retrieves the current tenant
     * @returns {Tenant}
     */
    currentTenant: () => state.tenant,
    /**
     * Checks if the Private Mode is turned on
     * @returns {boolean}
     */
    isPrivateMode: () => state.privateMode,
    /**
     * returns current User's status inside tenant
     * @returns {boolean}
     */
    currentStatus: () => state.tenantStatus,
    /**
     * Checks if user is an admin
     * @returns {boolean}
     */
    isAdmin: () => {
        return (state.tenantStatus === "admin" || state.tenantStatus === "owner")
    },
    /**
     * Checks if user is an owner
     * @returns {boolean}
     */
    isOwner: () => {
        return state.tenantStatus === "owner"
    },
};

const actions = {
    /**
     * Registers a new user
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.email  a user's email
     * @param {String}  payload.password  a user's password
     */
    signUpAction({dispatch, commit}, payload) {
        FirebaseAuth.methods.signUp(payload.email, payload.password)
            .then(response => {
                console.log("Sucessfully signed up")
                const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
                payload.alias = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals, numberDictionary] })
                FirestoreUser.methods.createProfile(response.user.uid, payload)
                    // .then(() => {
                    //     console.log("Profile sucessfully created");
                    // })
                    .then(() => {
                        fireAnalytics.setUserId(response.user.uid)
                        fireAnalytics.logEvent('sign_up');
                    })
                    .catch(error => {
                        dispatch("StoreError", {error: error, showNotification: true})
                    });
                FirebaseAuth.methods.sendVerificationEmail(response.user)
                    // .then(() => {
                    //     dispatch("StoreNotification", {code: "user/created", type: "success"})
                    // })
                    .catch(error => {
                        dispatch("StoreError", {error: error, showNotification: true})
                    });
                commit('setUser', response.user);
            })
            // .catch(error => {
            //     dispatch("StoreError", {error: error, showNotification: true})
            // });
    },
    /**
     * Signs in a user
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.email  a user's email
     * @param {String}  payload.password  a user's password
     */
    async signInAction({commit, dispatch}, payload) {
        await FirebaseAuth.methods.signIn(payload.email, payload.password)
            .then(response => {
                if (!response.user.emailVerified) {
                    dispatch("StoreNotification", {
                        code: "user/email-verify", type: "info",
                        action: "Resend E-mail"
                    });
                    commit('setUser', response.user);
                } else {
                    dispatch('appLoggedIn', response.user)
                    fireAnalytics.setUserId(response.user.uid)
                    fireAnalytics.logEvent('login');
                    console.log("Sucessfully signed in", response.user);
                }
            })
    },
    /**
     * Resends a verification email to the currently signed in user
     */
    resendVerificationEmail({ state, dispatch }, payload) {
        if(!state.user) {
            dispatch("StoreError", {error: new Error("No user data available."), showNotification: true})
        }

        state.user.sendEmailVerification()
            .then(() => {
                fireAnalytics.logEvent('resend_email_verification');
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * Sends a password reset email to the user
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.email  a user's email
     */
    async sendPasswordResetEmail({dispatch}, payload) {
        // TODO evaluate if we would like the user to know if we suspect a DDOS
        FirebaseAuth.methods.sendEmailPasswordReset(payload.email)
            .then(() => {
                fireAnalytics.logEvent('send_email_password_reset');
            })
    },
    /**
     * Updates currently signed in user's email
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.email  a user's email
     */
    updateEmail({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            FirebaseAuth.methods.updateEmail(state.user, payload.email)
                .then(() => {
                    FirestoreUser.methods.updateProfileEmail(state.user.uid, payload.email)
                        .then(() => {
                            state.profile.email = payload.email;
                            dispatch("StoreNotification", {code: "user/email-changed", type: "success"})
                            resolve();
                        })
                        .catch(error => {
                            dispatch("StoreError", {error: error, showNotification: true})
                            reject();
                        });
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true})
                    reject();
                });
        });
    },
    /**
     * Updates a user's password
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.email  a user's email
     * @param {String}  payload.oldpassword  a user's old password
     * @param {String}  payload.newpassword  a user's new password
     */
    updatePassword({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            FirebaseAuth.methods.updatePassword(payload.email, payload.oldpassword, payload.newpassword)
                .then(() => {
                    dispatch("StoreNotification", {code: "user/password-changed", type: "success"})
                    resolve();
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true})
                    reject();
                });
        });
    },
    /**
     * Retrieves and stores in vuex currently signed in user's profile
     */
    readProfile({commit, dispatch}) {
        if (!state.user) {
            dispatch("StoreNotification", {code: "user/not-signed-in", type: "info"})
        } else {
            FirestoreUser.methods.readProfile(state.user.uid)
                .then(response => {
                    // update user email if it was restored
                    if (state.user.email != response.email) {
                        response.email = state.user.email;
                        FirestoreUser.methods.updateProfileEmail(state.user.uid, state.user.email)
                    }
                    commit("setProfile", response);
                    FirestoreUser.methods.cleanUpLikedCard(response.likedCards, state.user.uid)
                        .catch(error => {
                            dispatch("StoreError", {error: error, showNotification: true})
                        });
                    FirestoreUser.methods.readUserTenants(state.user.uid)
                        .then(docs => {
                            if (docs.length > 0) {
                                state.profile.tenants = docs;
                            }
                        })
                        .catch(error => {
                            dispatch("StoreError", {error: error, showNotification: true})
                        });
                    if (response.activeTenant) {
                        dispatch("readTenant", response.activeTenant);
                    } else {
                        commit("setPrivateMode", true)
                        //retrieve private cards
                        dispatch("retrieveCards", {id: state.user.uid, personal: true})
                    }
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true})
                });
        }
    },
    /**
     * Updates currently signed in user's profile
     *
     * @param {object}  payload  profile payload
     * @param {string}  payload.firstname a user's name
     * @param {string}  payload.surname a user's surname
     * @param {string}  payload.alias a user's alias
     * @param {string}  payload.birthyear a user's birthyear
     * @param {string}  payload.birthmonth a user's birthmonth
     * @param {string}  payload.birthday a user's birthday
     * @param {string}  payload.activeTenant a user's birthday
     */
    updateProfile({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            FirestoreUser.methods.updateProfile(state.user.uid, payload)
                .then(() => {
                    state.profile.firstname = payload.firstname ? payload.firstname : state.profile.firstname;
                    state.profile.surname = payload.surname ? payload.surname : state.profile.surname;
                    state.profile.birthyear = payload.birthyear ? payload.birthyear : state.profile.birthyear;
                    state.profile.birthmonth = payload.birthmonth ? payload.birthmonth : state.profile.birthmonth;
                    state.profile.birthday = payload.birthday ? payload.birthday : state.profile.birthday;
                    state.profile.activeTenant = payload.activeTenant ? payload.activeTenant : state.profile.activeTenant;
                    if (payload.firstname || payload.surname ) {
                        dispatch("updateTenantMemberDisplayName", payload.firstname + " " + payload.surname);
                    }
                    dispatch("StoreNotification", {code: "user/profile-updated", type: "success"})
                    resolve();
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true});
                    reject();
                });
        })
    },
    /**
     * Updates currently signed in user's profile
     *
     * @param {object}  payload  profile payload
     * @param {string}  payload.hideTutorial true if the user would like to see the tutorial
     */
    updateHideTutorial({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            FirestoreUser.methods.updateProfile(state.user.uid, payload)
                .then(() => {
                    state.profile.hideTutorial = payload.hideTutorial ? payload.hideTutorial : false;
                    dispatch("StoreNotification", {code: "user/profile-updated", type: "success"})
                    resolve();
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true});
                    reject();
                });
        })
    },
    /**
     * Updates profile public information in Firestore
     *
     * @param {object}  payload  public profile payload
     * @param {string}  payload.publicEmail a user's name
     * @param {string}  payload.phone a user's surname
     * @param {string}  payload.description a user's alias
     */
    updatePublicProfile({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            FirestoreUser.methods.updatePublicProfile(state.user.uid, payload)
                .then(() => {
                    state.profile.public.email = payload.publicEmail ? payload.publicEmail : state.profile.public.email;
                    state.profile.public.phone = payload.phone ? payload.phone : state.profile.public.phone;
                    state.profile.public.description = payload.description ? payload.description : state.profile.public.description;
                    dispatch("StoreNotification", {code: "user/public-profile-updated", type: "success"})
                    resolve();
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true});
                    reject();
                });
        })
    },
    /**
     * Generates a random Alias
     * @param {*} context 
     * @returns {string}
     */
    generateRandomAlias(context){
        return new Promise(function (resolve, reject) {
            const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 });
            const alias = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals, numberDictionary] })
            FirestoreUser.methods.isUserAliasTaken(alias)
            .then(taken => {
                if (!taken) {resolve(alias)}
                else {actions.generateRandomAlias()}
            })
            .catch(error => {
                context.dispatch("StoreError", {error: error, showNotification: true})
                reject(error);
            });
        })
    },
    /**
     * Updates currently signed in user's alias
     *
     * @param {string}  alias a user's alias
     */
    updateUserAlias({dispatch}, alias) {
        return new Promise(function (resolve, reject) {
            FirestoreUser.methods.updateAlias(state.user.uid, alias)
                .then(() => {
                    state.profile.alias = alias;
                    dispatch("updateTenantMemberDisplayName", alias)
                    dispatch("StoreNotification", {code: "user/alias-updated", type: "success"})
                    resolve();
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true});
                    reject();
                });
        })
    },
    /**
     * checks if user alias is already taken
     * @param {string}  alias an alias to check
     * @returns {boolean} returns true if alias is taken
     */
    isUserAliasTaken({dispatch}, alias) {
        return new Promise(function (resolve, reject) {
            FirestoreUser.methods.isUserAliasTaken(alias)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true})
                    reject(error);
                });
        });
    },
    /**
     *  Sets the current user into private mode
     */
    SwitchToPrivateMode({commit, dispatch}) {
        dispatch("startLoadingSpinner");
        dispatch("resetFilter");
        commit("clearCustomLabels");
        commit("clearTags");
        commit("setPrivateMode", true);
        dispatch("retrieveCards", {id: state.user.uid, personal: true})
    },
    /**
     * Deletes currently signed in user's profile
     */
    deleteProfile({dispatch}) {
        FirestoreUser.methods.deleteProfile(state.user.uid)
            .then(() => {
                dispatch("StoreNotification", {code: "user/profile-deleted", type: "success"})
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * Logs in a user
     *
     * @param {object}  user  user's credentials
     */
    appLoggedIn({commit, dispatch}, user) {
        commit('setUser', user);
        dispatch('readProfile');
        dispatch('fetchDefaultLabels');
    },
    /**
     * Logs out current user
     */
    appLoggedOut({commit, dispatch}) {
        FirebaseAuth.methods.signOut()
            .then(() => {
                commit('setUser', null);
                commit('setProfile', null);
                commit('setCurrentTenant', null);
                commit('setPrivateMode', false);
                commit('setTenantStatus', null);
                commit('clearLinks');
                commit('clearCustomLabels');
                commit('clearAllCodes');
                commit('clearPaymentInfo');
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
                fireAnalytics.logEvent('logout_error', {
                    error: error
                });
            });
    },
    /**
     * Creates a new tenant and stores it in Firestore
     *
     * @param {Tenant}  tenant  payload.
     */
    createTenant({commit, dispatch, state}, tenant) {
        if (state.user) {
            let aDisplayName = state.profile.alias ?
                state.profile.alias :
                state.profile.firstname + " " + state.profile.surname;
            tenant.initials = Tenant.createInitials(tenant.displayName? tenant.displayName : tenant.companyName);

            // Do not use the user avatar color and avoid duplicate colors if possible.
            const usedAvatarColors = (state.profile && state.profile.tenants && Array.isArray(state.profile.tenants)) ? state.profile.tenants.map(tenant => tenant.avatarColor) : []
            usedAvatarColors.push(state.profile.avatarColor)
            const randomAvatarColor = createAvatarColor(usedAvatarColors);
            tenant.avatarColor = randomAvatarColor? randomAvatarColor : createAvatarColor()

            FirestoreTenant.methods.createTenant(tenant, state.user.uid, aDisplayName)
                .then(response => {
                    dispatch('copyDefaultLabels', response.id);
                    FirestoreUser.methods.addTenantToUser(state.user.uid, response.id, response.displayName, tenant.avatarColor)
                        .then(() => {
                            // modify local store
                            state.profile.tenants.push({
                                id: response.id,
                                joinedAt: new Date(),
                                displayName: response.displayName,
                                initials: response.initials,
                                avatarColor: response.avatarColor
                            })                            
                            dispatch("SetActiveTenant", response.id);
                            fireAnalytics.logEvent('tenant_created');
                        })
                        .catch((error) => {
                            dispatch("StoreError", {error: error, showNotification: true})
                        });
                })
                .catch(error => {
                    dispatch("StoreError", {error: error, showNotification: true})
                });
        } else {
            dispatch("StoreNotification", {code: "user/not-signed-in", type: "info"})
        }
    },
    /**
     * Sets active tenant in User profile
     *
     * @param {string}  tenantid  tenant id.
     */
    SetActiveTenant({commit, dispatch}, tenantid) {
        dispatch("startLoadingSpinner");
        FirestoreUser.methods.updateActiveTenant(state.user.uid, tenantid)
            .then(() => {
                commit("clearTags");
                commit('clearPaymentInfo');
                dispatch("resetFilter");
                dispatch("readTenant", tenantid);
                commit("setPrivateMode", false);
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * clears the active tenant in User profile
     *
     * @param {string}  tenantid  tenant id.
     */
    clearActiveTenant({dispatch}) {
        FirestoreUser.methods.updateActiveTenant(state.user.uid, "")
            .then(() => {
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * Reads a tenant from Firestore
     *
     * @param {string}  tenantid  tenant id.
     */
    readTenant({commit, dispatch}, tenantid) {
        // Retrieve tenant infor from db
        FirestoreTenant.methods.readTenant(tenantid)
            .then(tenantResponse => {
                // This is for the old tenants, that wre created before we added the initials attribute
                tenantResponse.initials = tenantResponse.initials? tenantResponse.initials : Tenant.createInitials(tenantResponse.displayName? tenantResponse.displayName : tenantResponse.companyName);

                commit("setCurrentTenant", tenantResponse);
                //Retrieve custom labels from db
                FirestoreCards.methods.ReadAllabels(tenantid)
                    .then((labelsResponse) => {
                        commit('setCustomLabels', labelsResponse);
                        commit('updateAllLabels');
                        dispatch('cleanupDefaultLabelCopies',tenantid);
                        dispatch('retrieveCurrentFeatureList');
                        dispatch('retrieveActiveTenantSubscription', tenantResponse);
                        dispatch("setUserTenantStatus", {userid: state.user.uid, tenant: tenantResponse});
                        dispatch("retrieveCards", {id: tenantResponse.id, personal: false});
                        // init a custom label counter if it does not exist
                        if (!tenantResponse.customLabelCount) {
                            FirestoreCards.methods.SetLabelCount(tenantid, labelsResponse.length);
                        }
                        let aDisplayName = state.profile.alias ?
                            state.profile.alias :
                            state.profile.firstname + " " + state.profile.surname;
                        dispatch("updateTenantMemberDisplayName", aDisplayName)
                        let aProfileTenant = state.profile.tenants.find(item => item.id === tenantResponse.id)
                        if (!aProfileTenant || !aProfileTenant.displayName || aProfileTenant.displayName != tenantResponse.displayName) {
                            FirestoreUser.methods.updateTenantDisplayName(tenantid, state.user.uid, tenantResponse.displayName)
                                .then(() => {
                                    commit("updateTenantDisplayName", {
                                        id: tenantResponse.id,
                                        joinedAt: new Date,
                                        displayName: tenantResponse.displayName
                                    });
                                })
                                .catch(error => {
                                    dispatch("StoreError", {error: error, showNotification: true})
                                });
                        }
                    })
                    .catch(error => {
                        dispatch("StoreError", {error: error, showNotification: true})
                    });
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * updates tenant info
     *
     * @param {Tenant}  tenant  Tenant object
     */
    updateCurrentTenant({commit, dispatch}, tenant) {
        tenant.initials = Tenant.createInitials(tenant.displayName? tenant.displayName : tenant.companyName);

        // Retrieve tenant info from db
        FirestoreTenant.methods.updateTenant(tenant)
            .then(() => {
                commit("setCurrentTenant", tenant);
                dispatch("StoreNotification", {code: "tenant/updated", type: "success"})
                fireAnalytics.logEvent('tenant_updated');
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * checks user status inside Tenant
     *
     * @param {object}  payload  payload
     * @param {string}  payload.userid a user's name
     * @param {Tenant}  payload.tenant a tenant
     *
     * @returns {string} users status
     */
    setUserTenantStatus({commit, dispatch}, payload) {
        try {
            if (payload.tenant.members && (payload.tenant.members.length > 0)) {
                let status = "guest";
                payload.tenant.members.forEach(element => {
                    if ((status === "guest") && (element.id === payload.userid)) {
                        if (element.owner) {
                            status = "owner";
                        } else if (element.admin) {
                            status = "admin";
                        } else {
                            status = "member";
                        }
                    }
                });
                console.log(status);
                commit('setTenantStatus', status);
            } else {
                dispatch("StoreNotification", {code: "yndooo/tenant-members-empty", type: "error"})
            }
        } catch (error) {
            dispatch("StoreError", {error: error, showNotification: true})
        }
    },
    /**
     * Updates the status of a member
     *
     * @param {object}  payload  payload
     * @param {String}  payload.tenandId  the Tenants's id
     * @param {object}  payload.newStatus new status object
     * @param {String}  payload.newStatus.id  the User's id
     * @param {boolean} payload.newStatus.owner - the new owner status
     * @param {boolean} payload.newStatus.admin - the new admin status
     */
    updateTenantMemberStatus({dispatch}, payload) {
        return new Promise(function (resolve, reject) {
            if (state.user.uid != payload.newStatus.id) {
                FirestoreTenant.methods.updateMemberStatus(payload.tenandId, payload.newStatus)
                    .then(() => {
                        dispatch("StoreNotification", {code: "tenant/member-status-updated", type: "success"})
                        resolve();
                    })
                    .catch(error => {
                        dispatch("StoreError", {error: error, showNotification: true})
                        reject(error);
                    });
            } else {
                dispatch("StoreNotification", {code: "tenant/member-status-update-self", type: "info"})
                reject("selfEdit");
            }
        })
    },
    /**
     * Updates a display name that is stored inside a tenant
     *
     * @param {String}  displayName  a new display name
     */
    updateTenantMemberDisplayName({commit, dispatch}, displayName) {
        if (state.user && state.tenant) {
            let aMember = state.tenant.members.find(item => item.id === state.user.uid);
            if (aMember) {
                if (displayName != aMember.displayName) {
                    let aDisplayName = state.profile.alias ?
                        state.profile.alias :
                        state.profile.firstname + " " + state.profile.surname;
                    FirestoreTenant.methods.updateMemberDisplayName(state.tenant.id, state.user.uid, aDisplayName)
                        .then(() => {
                            commit('updateMemberDisplayName', {userId: state.user.uid, displayName: aDisplayName});
                            dispatch("StoreNotification", {code: "tenant/displayname-updated", type: "success"})
                        })
                        .catch(error => {
                            dispatch("StoreError", {error: error, showNotification: true})
                        });
                }
            } else {
                dispatch("StoreNotification", {code: "tenant/not-member", type: "error"})
            }
        }
    },
    /**
     * removes a member from tenant
     *
     * @param {Object}  payload  payload.
     * @param {String}  payload.userId  a user's ID
     * @param {String}  payload.tenantId  a tenant's ID
     */
    async removeTenantMember({ commit, dispatch, getters }, payload) {
        const aDisplayName = getters.userIdToDisplayName(payload.userId) + " (no longer a member)";
        if (state.user.uid === payload.userId && state.tenant && state.tenant.id === payload.tenantId) {
            dispatch("SwitchToPrivateMode");
            dispatch("clearActiveTenant");
            commit("setCurrentTenant", null);
            commit("removeTenant", payload.tenantId);
            await router.push('/')
        }
        FirestoreCards.methods.StoreAuthorDisplayName(payload.tenantId, payload.userId, aDisplayName)
            .then(() => {
                FirestoreTenant.methods.removeMember(payload.tenantId, payload.userId)
                    .then(() => {
                        FirestoreUser.methods.removeTenantFromUser(payload.userId, payload.tenantId)
                            .then(() => {
                                dispatch("StoreNotification", {code: "tenant/left", type: "success"})
                            })
                            .catch(error => {
                                dispatch("StoreError", {error: error, showNotification: true})
                            });
                    })
                    .catch(error => {
                        dispatch("StoreError", {error: error, showNotification: true})
                    });
            })
            .catch(error => {
                dispatch("StoreError", {error: error, showNotification: true})
            });
    },
    /**
     * Retrieves the active tenant member count
     * @param {*} context Vue context
     * @param {boolean} [localOnly=false] get member count from local instance
     * @returns {Promise<number>} member count
     */
    getTenantMemberCount(context, localOnly) {
        return new Promise(function (resolve, reject) {
            if (state.tenant && state.user) {
                if (localOnly) {
                    resolve(state.tenant.members.length)
                } else {
                    FirestoreTenant.methods.getMemberCount(state.tenant.id)
                        .then((response) => {
                            resolve(response);
                        })
                        .catch(error => {
                            context.dispatch("StoreError", {error: error, showNotification: true})
                            reject();
                        });

                }
            } else {
                if (!state.tenant) {
                    context.dispatch("StoreNotification", {code: 'tenant/no-active-tenant', type: "info"})
                }
                if (!state.user) {
                    context.dispatch("StoreNotification", {code: "user/not-signed-in", type: "info"})
                }
                reject();
            }
        })
    },
    /**
     * Retrieves the active tenant custom label count
     * @param {*} context Vue context
     * @param {boolean} [localOnly=false] get custom label count from local instance
     * @returns {Promise<number>} member count
     */
    getCustomLabelCount(context, localOnly) {
        return new Promise(function (resolve, reject) {
            if (state.tenant && state.user) {
                if (localOnly) {
                    resolve(context.rootState.labels.customLabels.length)
                } else {
                    FirestoreTenant.methods.getCustomLabelCount(state.tenant.id)
                        .then((response) => {
                            resolve(response);
                        })
                        .catch(error => {
                            context.dispatch("StoreError", {error: error, showNotification: true})
                            reject();
                        });

                }
            } else {
                if (!state.tenant) {
                    context.dispatch("StoreNotification", {code: 'tenant/no-active-tenant', type: "info"})
                }
                if (!state.user) {
                    context.dispatch("StoreNotification", {code: "user/not-signed-in", type: "info"})
                }
                reject();
            }
        })
    },
};

const mutations = {
    setUser: (state, payload) => {
        state.user = payload;
    },
    setProfile: (state, payload) => {
        state.profile = payload;
    },
    setCurrentTenant: (state, tenantToSet) => {
        state.tenant = tenantToSet;
        // prevents saving cards to wrong tenant
        if (state.profile && tenantToSet) {
            state.profile.activeTenant = tenantToSet.id;
        }
    },
    addTenant: (state, tenant) => {
        state.profile.tenants.push(tenant)
    },
    addTenantMember: (state, member) => {
        state.tenant.members.push(member);
    },
    removeTenant: (state, tenantid) => {
        let indexOfElement = state.profile.tenants.findIndex(item => item.id === tenantid);
        state.profile.tenants.splice(indexOfElement, 1);
    },
    updateMemberDisplayName: (state, payload) => {
        const aMember = state.tenant.members.find(item => item.id === payload.userId);
        aMember.displayName = payload.displayName
    },
    updateTenantDisplayName: (state, payload) => {
        const aTenant = state.profile.tenants.find(item => item.id === payload.id);
        aTenant ? aTenant.displayName = payload.displayName : state.profile.tenants.push(payload);
    },
    setPrivateMode(state, status) {
        state.privateMode = status
    },
    setTenantStatus(state, status) {
        state.tenantStatus = status
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
