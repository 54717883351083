import { db } from '../../src/main'
import firebase from 'firebase/app'
import Tenant from '../../src/store/classes/Tenant'
import { i18n } from '../../src/plugins/vuetify' 

const tenantConverter = {
  /**
   * Converts A Tenant class object into JSON object
   *
   * @param {Tenant}  tenant  a Link class object
  */
  toFirestore: function(tenant) {
    return {
      displayName: tenant.displayName,
      companyName: tenant.companyName,
      initials: tenant.initials,
      avatarColor: tenant.avatarColor,
      address: tenant.address,
      contactName: tenant.contactName,
      contactPosition: tenant.contactPosition,
      contactEmail: tenant.contactEmail,
      contactPhone: tenant.contactPhone ? tenant.contactPhone : "",
    }
  },
  /**
   * Converts Firestore document data into a Tenant class object
   *
   * @param {firebase.firestore.QuerySnapshot}  snapshot  retrieved Document data
   * @param {object}  snapshot  query options
   * 
   * @returns {Tenant}
  */
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    const aTenant = new Tenant(data.displayName, data.companyName, data.avatarColor, data.address, data.contactName, data.contactPosition, data.contactEmail, data.contactPhone, data.members)
    aTenant.memberCount = data.memberCount ? data.memberCount : null;
    aTenant.customLabelCount = data.customLabelCount ? data.customLabelCount : null;
    return aTenant;
  }
}

/**
 * asynchronosly get all documents in a collection
 *
 * @param {firebase.firestore.collection}  docRef  Firebase collection reference
 * @param {string}  cardid  the card ID
*/
async function getdocs(collRef) {
  const snapshot = await collRef.get();
  let docs = [];
  snapshot.forEach(doc => {
    let aDoc = doc.data();
    aDoc.id = doc.id;
    docs.push(aDoc);
    console.log(doc.id, '=>', doc.data());
  });
  return docs;
}
/**
 * Asynchronously update atomic counter field counter by 1
 * @param {firebase.firestore.DocumentReference}  docRef  Firebase document reference
 * @param {string} fieldName Field name
 * @param {boolean} [isIncrement=false] will increment field if true, subtract if false 
 */
async function updateCounter(docRef,fieldName,isIncrement) {
  const value = isIncrement ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(-1);
  const aResult = await docRef.update({ [fieldName]: value });
  return aResult;
}

export default {
  methods: {
    /**
     * Creates a tenant document in Firestore
     *
     * @param {Tenant} tenant - the Tenant object
     * @param {string} ownerid - the user id of the Owner
     * @param {string} displayName - the Owners display name
     *
     * @returns {Tenant} returns an updated tenant with id
    */
    createTenant(tenant, ownerid, displayName) {
      return new Promise(function (resolve, reject){
        const createdAt = new Date()
        const tenantsRef = db.collection('tenants');
        tenantsRef
          .withConverter(tenantConverter)
          .add(tenant)
          .then((newDoc) => {
            tenant.id = newDoc.id;
            tenantsRef
              .doc(tenant.id)
              .collection('members')
              .doc(ownerid)
              .set({
                owner: true,
                admin: false,
                displayName: displayName,
              })
              .then (() => {
                newDoc.update({
                  memberCount: 1,
                  customLabelCount: 0,
                })
                .then(() => {
                  tenant.members.push({id: ownerid, owner: true, admin: false, displayName: displayName});
                  resolve(tenant);                 
                })
              .catch((error) => {
                reject(error);
              });
            })
            .catch((error) => {
              reject(error);
            });            
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    /**
     * Reads a tenant document from Firestore
     *
     * @param {string} id - the Tenants's id
     *
     * @returns {Tenant} returns Tenant class object
    */
    readTenant(id) {
      return new Promise(function (resolve, reject){
        const tenantsRef = db.collection('tenants').doc(id);
        tenantsRef
          .withConverter(tenantConverter)
          .get()
          .then(doc => {
            if (!doc.exists) {
              reject('tenant/does-not-exist');
            } else {
              let aReadTenant = doc.data();
              aReadTenant.id = doc.id;  
              const memberRef = tenantsRef.collection('members');
              getdocs(memberRef)
              .then( allMembers => {
                aReadTenant.members = allMembers;
                // update older tenants that do not have a label count
                if (!aReadTenant.memberCount) {
                  tenantsRef
                    .update({
                      memberCount: allMembers.length
                    })
                }
                resolve(aReadTenant);
              }) 
              .catch( (error) => {
                reject(error);
              });
            }
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates tenant company info in firestore
     *
     * @param {Tenant} tenant - the Tenant object
     *
    */
    updateTenant(tenant) {
      return new Promise(function (resolve, reject){
        const tenantsRef = db.collection('tenants')
          .doc(tenant.id)
          .update(tenantConverter.toFirestore(tenant))
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Add a new member to the tenant in Firestore
     *
     * @param {string} tenantid - the Tenants's id
     * @param {string} userid - the User's id
     * @param {string} displayName - the Tenants's Display name
     * @param {string} [inviteCode] - the Tenants's invitation code
     */
    addMember(tenantid, userid, displayName, inviteCode) {
      return new Promise(function (resolve, reject){
        const docRef = db.collection('tenants').doc(tenantid).collection('members').doc(userid);
        docRef
          .set({
            owner: false,
            admin: false,
            displayName: displayName,
            codeId: inviteCode ? inviteCode : null,
          })
          .then(() => {
            const tenantRef = db.collection('tenants').doc(tenantid);
            updateCounter(tenantRef,'memberCount', true)
              .then(() => {
                resolve();
              }).catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Removes a member from a tenant in Firestore
     *
     * @param {string} tenantid - the Tenants's id
     * @param {string} userid - the User's id
    */
    removeMember(tenantid, userid) {
      return new Promise(function (resolve, reject){
        const tenantRef = db.collection('tenants').doc(tenantid);
        updateCounter(tenantRef,'memberCount', false)
          .then(() => {
            const docRef = db.collection('tenants')
              .doc(tenantid)
              .collection('members')
              .doc(userid)
              .delete()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          }).catch((error) => {
            reject(error);
          });
    })},
    /**
     * updates tenant member status in Firestore
     *
     * @param {string} tenantid - the Tenants's id
     * @param {object} status - the new status object
     * @param {string} status.id - the User's id
     * @param {boolean} status.owner - the new owner status
     * @param {boolean} status.admin - the new admin status
    */
    updateMemberStatus(tenantid, status) {
      return new Promise(function (resolve, reject){
        const docRef = db.collection('tenants')
          .doc(tenantid)
          .collection('members')
          .doc(status.id)
          .update({
            owner: status.owner,
            admin: status.admin,
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates a member's display Name
     *
     * @param {string} tenantid - the Tenants's id
     * @param {string} userid - the User's id
     * @param {string} displayName - the member's Display name
    */
    updateMemberDisplayName(tenantid, userid, displayName) {
      return new Promise(function (resolve, reject){
        const docRef = db.collection('tenants').doc(tenantid).collection('members').doc(userid);
        docRef
          .update({
            displayName: displayName,
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * retrieve the member count of the tenant
     * @param {string} tenantid - the Tenants's id
     * @returns {Promise<number>} member count
     */
    getMemberCount(tenantid) {
      return new Promise(function (resolve, reject){
        const tenantRef = db.collection('tenants').doc(tenantid);
        tenantRef
          .get()
          .then((doc) => {
            if (!doc.exists) {
              reject('tenant/does-not-exist');
            } else {
              if (doc.data().memberCount) {
                resolve(doc.data().memberCount);
              } else {
                reject('tenant/no-member-count');
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * retrieve the custom label count of the tenant
     * @param {string} tenantid - the Tenants's id
     * @returns {Promise<number>} custom label count
     */
    getCustomLabelCount(tenantid) {
      return new Promise(function (resolve, reject){
        const tenantRef = db.collection('tenants').doc(tenantid);
        tenantRef
          .get()
          .then((doc) => {
            if (!doc.exists) {
              reject('tenant/does-not-exist');
            } else {
              if (doc.data().customLabelCount) {
                resolve(doc.data().customLabelCount);
              } else {
                reject('tenant/no-label-count');
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Creates a firestore document which triggers a firebase send Email extension
     * @param {string} tenantid ID of the tennant
     * @param {string} to Invite recepient email address
     * @param {string} inviteCode Team invite code
     * @param {string} displayName Invite sender display name
     * @param {string} tenantName Team name that the user is being invited into
     */
    sendEmailInvite(tenantid, to, inviteCode, displayName, tenantName){
      return new Promise(function (resolve, reject){
        const joinLink = process.env.VUE_APP_FIREBASE_PROJECT_ID === "organizer-92584"
        ? 'https://linkr.bde.lv/usermgmt?mode=joinTeam&oobCode=' + inviteCode
        : 'https://app.yndooo.com/usermgmt?mode=joinTeam&oobCode=' + inviteCode;
        const registerLink = process.env.VUE_APP_FIREBASE_PROJECT_ID === "organizer-92584"
        ? 'https://linkr.bde.lv/register'
        : 'https://app.yndooo.com/register';
        db.collection('tenants').doc(tenantid).collection('inviteMail').add({
          to: to,
          template: {
            name: 'teamInvite',
            data: {
              invite_sender_name: displayName,
              invite_sender_organization_name: tenantName,
              joinLink: joinLink,
              registerLink: registerLink,
              inviteCode: inviteCode
            }
          },
          /*
          message: {
            subject: i18n.t('$vuetify.mail.teamInvite.subject'),
            html: i18n.t('$vuetify.mail.teamInvite.body1') + tenantName + i18n.t('$vuetify.mail.teamInvite.body2') + joinLink + i18n.t('$vuetify.mail.teamInvite.body3') + registerLink + i18n.t('$vuetify.mail.teamInvite.body4') + inviteCode + i18n.t('$vuetify.mail.teamInvite.body5'),
          },
          */
        }).then(() =>{
          resolve();
        }).catch((error) => {
          reject(error);
        });
      });
    }
  }
};