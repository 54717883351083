import linksJson from '../data/initial-links.json';
import sortTypesJson from '../data/sort-types.json'
import Filter from '../classes/Filter';
import Link from '../classes/Link';
import LinkType from '../classes/LinkType';
import LinkLabel from '../classes/LinkLabel';
import Pagination from '../classes/Pagination';
import SortStrategy from '../classes/SortStrategy'
import FirestoreCards from '../../../firebase/firestore/FirestoreCards';
import FirestoreUser from "../../../firebase/firestore/FirestoreUser";

const  state = {
  /**
   * Current filters
   * @type {Filter}
  */
  filter: new Filter(),
  /**
   * All sort strategies
   * @type {SortStrategy[]}
  */
  sortStrategies: sortTypesJson,
  /**
   * An array of Links
   * @type {Link[]}
  */
  links: [],
  /**
   * Raw array of predefined links
   * @type {object}
  */
  rawLinks: linksJson,
  /**
   * The parameters used for pagination
   * @type {Pagination}
  */
  cardPagination: new Pagination(),

  /**
   * If cards are loading this is true, else false
   */
  loadingCards: true,
  loadingMoreCards: false
};


const getters = {
  /**
   * Retrieves all available links
   * @returns {Link[]}
  */
  allLinks: state => state.links,
  /**
   * Retrieves all currently filtered links
   * @returns {Link[]}
  */
  filteredLinks: (state) => {
    let filteredLinksResult =  state.links.filter(item => state.filter.test(item));
    return filteredLinksResult;
  },
  /**
   * Retrieves the current filter
   * @returns {Filter}
  */
  filter: state => state.filter,
  /**
   * Retrieves all Sorting strategies
   * @returns {SortStrategy[]}
   */
  sortStrategies: state => state.sortStrategies,
  /**
   * Retrieves current Sorting strategy
   * @returns {SortStrategy}
   */
  currentSortStrategy: (state) => {
    let aStrategy = state.sortStrategies.find(item => item.isEnabled === true)
    return aStrategy ? aStrategy : SortStrategy.default();
  },
  /**
   * Retrieves all card Pagination parameters
   * @returns {Pagination}
  */
  cardPagination: state => state.cardPagination,
  /**
   * checks if there is more cards
   * @returns {boolean}
  */
  isThereMoreCards: (state) => {
    let aResult = false;
    if (state.cardPagination) {
      state.cardPagination.currentQueries.forEach(element => {
        if (!element.reachedEnd) {
          aResult = true;
        }
      });
    }
    return aResult;
  },

  /**
   * True if cards are loading, false if they are loaded
   */
  loadingCards: (state) => {
    return state.loadingCards
  },
  /**
   * True if additional cards are fetched.
   * On the first load of the cards this will be false. Only used for pagination.
   */
  loadingMoreCards: (state) => {
    return state.loadingMoreCards
  }
};

const actions = {
  /**
   * Adds or updates a Link
   *
   * @param {Link}  link  payload.
   * @param {String}  link.id  a Link is updated if this exists
   * @param {String}  link.author  the user id of the link's creator
   * @param {String}  [link.tenant]  the tenant id that tells where to store the link, if it is null then the link is stored in user profile
  */
  addLink(context, link)  {
    return new Promise(function (resolve, reject){
      link.generateKeywords();
      if(link.id){
        link.modifiedDate = new Date();
        FirestoreCards.methods.updateCard(link)
          .then(() => {
            context.commit('updateLink', link);
            resolve();
          })
          .catch(error =>{
            context.dispatch("StoreError" , {error: error, showNotification: true})
            reject(error);
          });
      }
      else{
        link.created = new Date();
        FirestoreCards.methods.createCard(link, link.author, link.tenant)
          .then(response => {
            context.commit('addLinkToFirstPosition', response);
            if (!link.tenant) {
              context.dispatch("StoreNotification" , {code: "card/created-private", type: "success"}) ;
            }
            resolve();
          })
          .catch(error => {
            context.dispatch("StoreError" , {error: error, showNotification: true})
            reject(error);
          });
      }
    });
  },
  /**
   * Deletes a Link
   *
   * @param {Link}  link  Link object
  */
  deleteLink(context, link)  {
    FirestoreCards.methods.DeleteCard(link)
      .then(() => {
        context.commit('deleteLink', link);
      })
      .catch(error => {
        if (error === "Document does not exist in Firestore"){
          context.commit('deleteLink', link);
          context.dispatch("StoreError" , {error: error, showNotification: true, customCode: "yndoo/missing-card"})
        } else {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        }
      });
    
  },
  /**
   * Assigns a tenant to a link and creates a copy of it there, and deletes it from the personal collection
   *
   * @param {object}  payload  payload
   * @param {Link}  payload.link  link object
   * @param {string}  payload.tenant  tenant ID
  */
  assignTenant(context, payload) {
    return new Promise(function (resolve, reject){
      FirestoreCards.methods.DeleteCard(payload.link)
        .then(() => {
          context.commit('deleteLink', payload.link);
          FirestoreCards.methods.createCard(payload.link, payload.link.author, payload.tenant)
          .then(() => {
            context.dispatch("StoreNotification" , {code: "card/moved-tenant", type: "success"});     
          })
          .catch(error => {
            context.dispatch("StoreError" , {error: error, showNotification: true})
          });
        })
        .catch(error => {
          if (error === "Document does not exist in Firestore"){
            context.commit('deleteLink', payload.link);
            context.dispatch("StoreError" , {error: error, showNotification: true, customCode: "yndoo/missing-card"})
          } else {
            context.dispatch("StoreError" , {error: error, showNotification: true})
          }
        });
    });
  },
  /**
   * Increases the Link coutnter
   *
   * @param {Link}  link  Link object
  */
  likeLink(context, link)  {
    if (context.rootState.user.profile.isCardAlreadyLiked(link.id)) {
      context.dispatch("StoreNotification" , {code: "card/already-liked", type: "success"});             
    } else {
      FirestoreCards.methods.IncrementLikeCounter(link)
        .then( () => {
          FirestoreUser.methods.addLikedCard(context.rootState.user.user.uid, link.tenant, link.id, )
            .then( (response) => {
              context.rootState.user.profile.likedCards.push(response);
            })
            .catch(error => {
              context.dispatch("StoreError" , {error: error, showNotification: true})
            });
          context.commit('likeLink', link);
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        }); 
    }
  },
  /**
   * Toggles the card lock status
   *
   * @param {Link}  link  Link object
  */
  toggleCardLock(context, link)  {
    FirestoreCards.methods.SetCardLock(link, !link.locked)
      .then( (response) => {
        if (response){
          context.commit('lockLink', link);
        } else {
          context.commit('unlockLink', link);
        }        
      })
      .catch(error => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      }); 
  },
  /**
   * Retrieves all the links from the RAW array and stores them in vuex links state, replacing the old state
  */
  fetchLinks(context) {
      context.dispatch('fetchDefaultLabels');
      context.dispatch('fetchTypes');
      const tmpLinks = []
      state.rawLinks.forEach((item, i) => {
        item.created = new Date(item.created);
        const linkType = LinkType.fromName(item.type, context.getters.allTypes);
        const linkLabels = LinkLabel.multiFromID(item.labels, context.getters.allLabels);
        const tmpLink = new Link(item.href, item.title, item.description, linkType, linkLabels, item.tags, item.created, item.author,null, item.likes);
        tmpLink.id = item.id;
        tmpLinks.push(tmpLink);
      });
      state.links = tmpLinks;
      console.log('Fetch items');
  },
  /**
   * Recasts all json Sort stragies as SortStrategy class objects and stores them in the vuex
  */
  fetchSortingStrategies(context) {
    let tmpSort = [];
    state.sortStrategies.forEach((item, i) => {
      tmpSort.push(new SortStrategy(item.type, item.fieldName, item.isEnabled.toLowerCase() == 'true' ? true : false, item.isDescending.toLowerCase() == 'true' ? true : false))
    });
    context.commit('setSortStrategies', tmpSort);
  },
  /**
   * Retrieves the first batch of cards from Firestore
   *
   * @param context
   * @param {object}  payload payload
   * @param {object}  payload.id  user or tenant id
   * @param {object}  [payload.personal=false]  user or tenant id
   */
  retrieveCards(context, payload) {
    if (payload.id) {
      context.commit('setLoadingCards', true);
      return FirestoreCards.methods.ReadAllCards(payload.id, state.cardPagination, getters.currentSortStrategy(state), payload.personal)
        .then(response => {
          if (response && response.length > 0) {
            //check if card is missing keywords or modified date and update it if necessary
            response.forEach( link => {
              if (link.noKeywords || !link.modifiedDate) {
                link.generateKeywords();
                link.modifiedDate = link.created;     
                FirestoreCards.methods.updateCard(link);
              }
            });
            context.commit('replaceLinks', response);
            context.commit('checkLikedLinks', context.rootState.user.profile);
            context.dispatch('fetchTags');
          } else {
            context.commit('clearLinks');
          }
          context.commit('setLoadingCards', false);
          // context.dispatch("stopLoadingSpinner");
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
          context.commit('setLoadingCards', false);
        });
    }
  },
  /**
   * Retrieves next batch of cards from Firestore
  */
  retrieveNextCards(context){
    // context.dispatch("startLoadingSpinner");
    context.commit('setLoadingMoreCards', true);
    return state.cardPagination.loadNext()
      .then( (response) => {
        if (response && response.length > 0) {
          //check if card is missing keywords or modified date and update it if necessary
          response.forEach( link => {
            if (link.noKeywords || !link.modifiedDate) {
              link.generateKeywords();
              link.modifiedDate = link.created;     
              FirestoreCards.methods.updateCard(link);
            }
          });
          context.commit('insertLinks', response);
          context.commit('checkLikedLinks', context.rootState.user.profile);
          context.dispatch('fetchTags');
          context.commit('setLoadingMoreCards', false);
        }
        /// context.dispatch("stopLoadingSpinner");
      })
      .catch(error => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
        context.commit('setLoadingMoreCards', false);
        // context.dispatch("stopLoadingSpinner");
      });
  },
  /**
   * Searches for cards in Firestore
   *
   * @param {string[]} searchTerms Search terms
   * 
  */
  searchCards(context, searchTerms){
    if(!context.rootState.user || !context.rootState.user.profile) return

    if (searchTerms && searchTerms.length > 0 ){
      // let lowerSearchTerms = searchTerms.map(v => v.toLowerCase());
      let lowerSearchTerms = [searchTerms[0].toLowerCase()]
      FirestoreCards.methods.SearchAllCards(
        context.rootState.user.profile.id, 
        state.cardPagination, 
        getters.currentSortStrategy(state), 
        lowerSearchTerms, 
        context.rootState.user.profile.activeTenant,
        context.rootState.user.privateMode)
        .then(response => {
          if (response && response.length > 0) {
            //check if card is missing keywords or modified date and update it if necessary
            response.forEach( link => {
              if (link.noKeywords || !link.modifiedDate) {
                link.generateKeywords();
                link.modifiedDate = link.created;     
                FirestoreCards.methods.updateCard(link);
              }
            });
            context.commit('replaceLinks', response);
            context.commit('checkLikedLinks', context.rootState.user.profile);
            context.dispatch('fetchTags');
          }else {
            context.commit('clearLinks');
          }
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
    } else {
      let aId = context.rootState.user.privateMode ? context.rootState.user.profile.id : context.rootState.user.profile.activeTenant;
      context.dispatch("retrieveCards" , {id: aId, personal: context.rootState.user.privateMode}) ;
    }
  },
  /**
   * Moves cards between current user profile and tenant in Firestore
   *
   * @param {object}  payload payload
   * @param {Link}   payload.link link object
  */
  moveCard(context, payload){
    let toPrivate = payload.link.tenant ? true : false;
    let tenantID = context.rootState.user.profile.activeTenant;
    let userID = context.rootState.user.profile.id
    if (tenantID){
      FirestoreCards.methods.MoveCard(payload.link, tenantID, userID, toPrivate)
        .then(response => {
          let linkToDelete = state.links.find(item => item.id === payload.link.id)
          context.commit('deleteLink', linkToDelete);
          context.dispatch("StoreNotification" , {code: "card/moved", type: "success"}) ;
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
    } else {
      context.dispatch("StoreNotification" , {code: "user/no-tenants", type: "info"}) ;
    }
  },
  /**
   * Adds a label filter to current filters
   *
   * @param {object}  payload  Filter object
  */
  addLabelFilter(context, payload){
    console.log('Adding label filter: ', payload);
    state.filter.addLabelFilter(payload);
  },
  /**
   * Removes a label filter from current filters
   *
   * @param {object}  payload  Filter object
  */
  removeLabelFilter(context, payload){
    console.log('Removing label filter: ', payload);
    state.filter.removeLabelFilter(payload);
  },
  /**
   * adds a type filter to current filters
   *
   * @param {object}  payload  Filter object
  */
  addTypeFilter(context, payload){
    console.log('Adding type filter: ', payload);
    state.filter.addTypeFilter(payload);
  },
  /**
   * removes a type filter from current filters
   *
   * @param {object}  payload  Filter object
  */
  removeTypeFilter(context, payload){
    console.log('Removing type filter: ', payload);
    state.filter.removeTypeFilter(payload);
  },
  setTagsFilters(context, payload){
    state.filter.setTagsFilters(payload)
  },
  resetFilter(context){
    state.filter.reset();
  }
};

const mutations = {
  likeLink: (state, link) =>  {
    link.like();
    console.log("Link liked: ", link);
  },
  lockLink: (state, link) =>  {
    link.locked = true;
    console.log("Link locked: ", link);
  },
  unlockLink: (state, link) =>  {
    link.locked = false;
    console.log("Link unlocked: ", link);
  },
  updateLink: (state, link) =>  {
    let linkToUpdate = state.links.find(item => item.id === link.id)
    linkToUpdate.update(link);
    console.log("Link updated: ", link);
  },
  deleteLink: (state, link) =>  {
    let indexOfElement = state.links.indexOf(link);
    state.links.splice(indexOfElement, 1);
    console.log("Link deleted: ", link);
  },
  replaceLinks: (state, newlinks) =>  {
    state.links = newlinks;
    console.log("Links replaced: ", newlinks);
  },
  insertLinks: (state, newlinks) =>  {
    newlinks.forEach( (item, i) => {
      state.links.push(item);
    });
    console.log("Links inserted: ", newlinks);
  },
  clearLinks: (state) =>  {
    state.links = [];
    state.filter.reset();
  },
  checkLikedLinks: (state, profile) =>  {
    state.links.forEach( (item, i) => {
      item.isLiked = profile.isCardAlreadyLiked(item.id);
    });
  },
  addLinkToFirstPosition: (state, link) =>  {
    state.links.unshift(link);
    console.log("Link created: ", link);
  },
  setSortStrategies: (state, sortStrategies) =>  {
    state.sortStrategies = sortStrategies;
    console.log("Sort Strategies loaded: ", sortStrategies);
  },
  setLoadingCards: (state, loading) => {
    state.loadingCards = loading;
  },
  setLoadingMoreCards: (state, loading) => {
    state.loadingMoreCards = loading;
  }
};

export default{
  state,
  getters,
  actions,
  mutations
};
