<template lang="pug">
  v-container(class="fill-height ma-0 pa-0" fluid)
    v-row(v-if="!completed" class="fill-height" justify="center")
      v-col(md="6" lg="6" xl="6" class="d-none d-md-block justify-center align-center" id="leftHalf" ref="leftHalf")
        RegisterLeftHalf(:style="{ background: $vuetify.theme.themes[theme].background, 'border-right': `${theme === 'light'? '1px' : '0px'} solid #dbe0e3` }")
      v-col(cols="12" sm="8" md="6" lg="6" xl="6")
        RegisterForm(@registration-completed="onRegistrationCompleted")
    EmailSendFeedback(v-else @resend-link="onResendVerificationEmail" :restart-animation="restartAnimation")
</template>

<script>
import RegisterLeftHalf from "@/components/register/RegisterLeftHalf";
import RegisterForm from "@/components/register/RegisterForm";
import EmailSendFeedback from "../components/feedback/EmailSendFeedback";
import {mapActions} from "vuex";
export default {
  components: {EmailSendFeedback, RegisterForm, RegisterLeftHalf},
  data() {
    return {
      completed: false,
      eventData: null,
      restartAnimation: false,
    }
  },
  computed: {
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
  },
  methods: {
    ...mapActions(['resendVerificationEmail']),
    onRegistrationCompleted(event) {
      this.completed = true;
    },
    onResendVerificationEmail(event) {
      this.resendVerificationEmail(null);
      this.restartAnimation = !this.restartAnimation;
    }
  }
};
</script>