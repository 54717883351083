<template lang="pug">
  v-container(fluid)
    v-row
      v-col(v-if="activeSubscription" cols="12")
        ActiveSubscription
      v-col(cols="12")
        v-card(shaped)
          v-card-title.text-h4 {{ $t('tenant.subscription.change.title') }}
          v-card-text
            p.title {{ $t('tenant.subscription.change.description') }}
          v-card-text
            PlanList
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Plan from "@/components/subscription/Plan"
import PlanList from "@/components/subscription/PlanList";
import ActiveSubscription from "@/components/subscription/ActiveSubscription";

export default {
  name: "SubscriptionOverview",
  components: {ActiveSubscription, PlanList, Plan},
  data() {
    return {
      loadingSwitchAnnualPlan: false,
      loadingCancelSubscription: false,
      selectedPrice: null,
    }
  },
  computed: {
    ...mapGetters({
      paymentPlans: "getActivePaymentPlans",
      activeSubscription: "getActiveSubscription",
    }),
    activePlan() {
      if(!this.activeSubscription) return { name: "", description: "" }

      const plan = this.paymentPlans.find((item) => {
        return item.role === this.activeSubscription.role
      })

      return plan ? plan : {name: "", description: ""}
    },
  },
  methods: {
    ...mapActions([
      'retrivePaymentPlans',
      'retrieveActiveTenantSubscription',
      'goToBillingPortal',
    ]),
    fetch() {
      if (!this.paymentPlans || this.paymentPlans.length <= 0) {
        this.retrivePaymentPlans()
      }
      if (!this.activeSubscription) {
        this.retrieveActiveTenantSubscription()
      }
    },
    formatDate(date) {
      return `${date.toLocaleString('default', {day: '2-digit'})} ${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`;
    },
    switchToAnnualPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    switchToMonthlyPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    cancelPlan() {
      this.loadingCancelSubscription = true
      this.goToBillingPortal()
    }
  },
  created() {
    this.fetch()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetch'
  },
}
</script>

<style>

</style>