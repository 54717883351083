<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
          height="210px"
          class="text-center"
          shaped
          @click="newLinkClicked"
          hover
      >
        <v-card-actions>
          <v-row justify="center" align="center" fill-height>
            <v-col
                cols="12" class="pb-0 mb-0 justify-center align-center text-center"
            >
              <v-icon
                  size="100px"
                  @click="newLinkClicked"
                  :color="hover? 'secondary' : 'primary'"
              >mdi-plus
              </v-icon>
            </v-col>
            <v-col cols="12" class="pt-0 mt-0">
              {{ $t('$vuetify.addNewCard') }}
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NewCard",
  methods: {
    ...mapActions([
      "newLink",
    ]),
    newLinkClicked() {
      this.newLink();
    },
  }
}
</script>

<style scoped>

</style>