import Vue from 'vue';
import Vuex from 'vuex';
import links from './modules/links'
import user from './modules/user'
import ui from './modules/ui'
import tags from './modules/tags'
import labels from './modules/labels'
import types from './modules/types'
import ErrorHandler from './modules/ErrorHandler'
import codes from './modules/codes'
import payment from './modules/payment'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules:{
    links,
    user,
    ui,
    tags,
    labels,
    types,
    ErrorHandler,
    codes,
    payment,
  }
})

export default store;
