import FirestoreCards from "../../../firebase/firestore/FirestoreCards";

/**
  * Contains all pagination properties and methods
*/
export default class Pagination {

  /**
  * the retrieval limit for the first request
  * @type {number}
  */
  firstRequestCount = 0;
  /**
  * the retrieval limit for the all following requests
  * @type {number}
  */
  nextRequestCount = 0;
  /**
   * An array of currently running pagination queries
   * @type {Object[]}
   */
  currentQueries = []

  /**
   * @constructs Pagination
  */ 
  constructor(){
    this.firstRequestCount = 5;
    this.nextRequestCount = 2;
    this.reset();
  }

  /**
   * Resets pagination
  */ 
  reset() {
    this.currentQueries.length = 0;
  }

  /**
   *  Defines how many pages are requested
   * 
   * @param {number} firstRequestCount the retrieval limit for the first request
   * @param {number} nextRequestCount the retrieval limit for the all following requests
   */
  setRequestCount(firstRequestCount, nextRequestCount){
    this.firstRequestCount = firstRequestCount;
    this.nextRequestCount = nextRequestCount;
  }


  /**
   * adds a pagination query
   * 
   * @param {firebase.firestore.Query} query The firebase query
   * @param {number} retrievedDocCount the amount of retrieved documents
   * @param {firebase.firestore.DocumentSnapshot} lastDoc the last doc retrieved from the first batch
  */ 
  addQuery(query, retrievedDocCount, lastDoc) {
    let aNewQuery = {
      query: query,
      reachedEnd: (retrievedDocCount < this.firstRequestCount) ? true : false,
      lastDoc: lastDoc
    };
    this.currentQueries.push(aNewQuery);
  }

  /**
   * updates a pagination query
   * 
   * @param {Object} prevQuery the query to update
   * @param {number} retrievedDocCount the amount of retrieved documents
   * @param {firebase.firestore.DocumentSnapshot} lastDoc the last doc retrieved from the last batch
  */ 
  updateQuery(prevQuery, retrievedDocCount, lastDoc) {
    prevQuery.reachedEnd = (retrievedDocCount < this.nextRequestCount) ? true : false;
    prevQuery.lastDoc = lastDoc;
  }
  /**
   * loads next documents from the query
   *
   * @param {firebase.firestore.Query} query The firebase query
   * @param {number} retrievedDocCount the amount of retrieved documents
  */
  loadNext() {
    let that = this;
    return new Promise(function (resolve, reject){
    let promises = [];
    let aCombinedArray = [];
      try {      
        that.currentQueries.forEach(element => {
          if (!element.reachedEnd){
            promises.push(FirestoreCards.methods.LoadNextPage(element.query, element.lastDoc, that.nextRequestCount)
              .then((response) => {
                that.updateQuery(element, response.docs.length, response.lastDoc);
                aCombinedArray = aCombinedArray.concat(response.docs);
              }))        
          }
        })
        Promise.all(promises)
          .then( () => {
            resolve(aCombinedArray);
          })
      } catch (error) {
        reject(error);
      }
    })
  }
}