<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card shaped>
      <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titlePublic')}}</v-card-title>
      <v-card-text>
        <v-form
            ref="publicform"
            v-model="validPublic"
            class="px-3"
        >
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                      v-model="publicEmail"
                      :label="$vuetify.lang.t('$vuetify.profile.public.labelPublicEmail')"
                      :filled="filledEmail"
                      @input="filledEmail = true; changedPublicFields = true; $v.publicEmail.$touch()"
                      :error-messages="emailErrors"
                      @blur="$v.publicEmail.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field
                      v-model="phone"
                      :label="$vuetify.lang.t('$vuetify.profile.public.labelPublicPhone')"
                      :filled="filledPhone"
                      @input="filledPhone = true; changedPublicFields = true; $v.phone.$touch()"
                      :error-messages="phoneErrors"
                      @blur="$v.phone.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                      v-model="description"
                      :label="$vuetify.lang.t('$vuetify.profile.public.labelDescription')"
                      :filled="filledDescription"
                      @input="filledDescription = true; changedPublicFields = true; $v.description.$touch()"
                      :error-messages="descriptionErrors"
                      @blur="$v.description.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                      color="primary"
                      :disabled="!validPublic || !changedPublicFields"
                      @click="SaveChangesClicked"
                  >
                    {{ $vuetify.lang.t('$vuetify.profile.public.save')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from 'vuelidate'
import { minLength, maxLength, email, } from 'vuelidate/lib/validators'

export default {
  name: "UserProfile",
  mixins: [validationMixin],
  validations: {
    publicEmail: { email },
    phone: {
      minLength: minLength(3),
      maxLength: maxLength(256),
    },
    description: {
      minLength: minLength(3),
      maxLength: maxLength(256),
    },
  },
  data() {
    return {
      validPublic: true,
      filledEmail: false,
      filledPhone: false,
      filledDescription: false,
      publicEmail: "",
      phone: "",
      description: "",
    };
  },
  computed: {
    ...mapGetters({
      profile: "currentProfile",
    }),
    changedPublicFields() {
      return ((this.publicEmail && this.publicEmail !== this.profile.public.email) || this.phone || this.description)
    },
    emailErrors() {
      const errors = []
      if (!this.$v.publicEmail.$dirty) return errors
      !this.$v.publicEmail.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.phone.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.description.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "updatePublicProfile",
    ]),
    SaveChangesClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdatePublicProfile'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.publicform.validate()){
                if (this.filledEmail || this.filledPhone || this.filledDescription) {
                  this.updatePublicProfile(this.$data)
                }
              }
            } else {
              //Revert changes
              this.setFieldValues();
            }
          })
    },
    setFieldValues() {
      if (this.profile) {
        this.publicEmail = this.profile.public.email ? this.profile.public.email : this.profile.email;
        this.alias = this.profile.public.phone ? this.profile.public.phone : '';
        this.birthyear = this.profile.public.description ? this.profile.public.description : '';
      }
    }
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    profile(value) {
      if (value) {
        this.setFieldValues();
      }
    },
  },
};
</script>
