<template lang="pug">
  v-container(class="justify-center align-center" fluid)
    v-row(justify="start" align="center")
      v-col(cols="12" class="ml-md-8 mb-0 pb-0")
        h2.text-h4 Team
      v-col(cols="12" class="ml-xs-0 mr-xs-0 pl-md-8")
        TenantNavigation
      v-col(cols="12")
        TenantOverview
</template>
<script>


import TenantNavigation from "@/components/tenant/TenantNavigation";
import TenantOverview from "@/components/tenant/TenantOverview";
export default {
  name: "Tenant",
  components: {TenantOverview, TenantNavigation },
};
</script>