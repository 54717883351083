export default {
  $vuetify: {
    productName: "Yndooo",
    paidFeatureHint: 'Upgrade dein Team, um dieses Feature nutzen zu können',
    menu: "Menü",
    search: "Suche nach ...",
    badge: "Abzeichen",
    close: "Schließen",
    save: "speichern",
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    locked: 'Gesperrt',
    lock: 'Sperren',
    unlock: 'Freischalten',
    delete: 'Löschen',
    share: 'Aktie',
    comment: 'Kommentar',
    status: 'Status',
    created: 'Erstellt',
    actions: 'Aktionen',
    edit: 'Bearbeiten',
    move: 'Zum Mieter wechseln',
    join: 'Beitreten',
    requiredFieldIndication: "*notwendige Angaben",
    generate: 'Generieren',
    addNewCard: "Neue Karte",
    dataIterator: {
      noResultsText: "Keine Elemente gefunden",
      loadingText: "Lade Elemente...",
    },
    dataTable: {
      itemsPerPageText: "Zeilen pro Seite:",
      ariaLabel: {
        sortDescending: "Absteigend sortiert.",
        sortAscending: "Aufsteigend sortiert.",
        sortNone: "Nicht sortiert.",
        activateNone: "Aktivieren um Sortierung zu entfernen.",
        activateDescending: "Aktivieren um absteigend zu sortieren.",
        activateAscending: "Aktivieren um aufsteigend zu sortieren.",
      },
      sortBy: "Sortiere nach",
    },
    dataFooter: {
      itemsPerPageText: "Elemente pro Seite:",
      itemsPerPageAll: "Alle",
      nextPage: "Nächste Seite",
      prevPage: "Vorherige Seite",
      firstPage: "Erste Seite",
      lastPage: "Letzte Seite",
      pageText: "{0}-{1} von {2}",
    },
    datePicker: {
      itemsSelected: "{0} ausgewählt",
      nextMonthAriaLabel: "Nächsten Monat",
      nextYearAriaLabel: "Nächstes Jahr",
      prevMonthAriaLabel: "Vorheriger Monat",
      prevYearAriaLabel: "Vorheriges Jahr",
    },
    noDataText: "Keine Daten vorhanden",
    carousel: {
      prev: "Vorheriges Bild",
      next: "Nächstes Bild",
      ariaLabel: {
        delimiter: "Element {0} von {1}",
      },
    },
    calendar: {
      moreEvents: "{0} mehr",
    },
    fileInput: {
      counter: "{0} Dateien",
      counterSize: "{0} Dateien ({1} gesamt)",
    },
    timePicker: {
      am: "AM",
      pm: "PM",
    },
    pagination: {
      ariaLabel: {
        wrapper: "Seitennavigation",
        next: "Nächste Seite",
        previous: "Vorherige Seite",
        page: "Gehe zu Seite {0}",
        currentPage: "Aktuelle Seite, Seite {0}",
      },
    },
    login: {
      title: "Logge Dich in Deinen Account ein",
      email: "E-Mail",
      emailError: {
        valid: "Muss eine valide E-Mail sein",
        required: "E-mail wird benötigt"
      },
      passwordError: {
        minimum: "Muss mindestens 6 Zeichen enthalten",
        maximum: "Darf maximal 50 Zeichen enthalten",
        required: "Passwort wird benötigt"
      },
      password: "Passwort",
      forgotPassword: "Passwort vergessen?",
      login: "Weiter",
      register: {
        question: "Du hast noch keinen Account?",
        linkText: "registrieren"
      },
      staySignedIn: "Bleib für eine Woche eingeloggt"
    },
    register: {
      title: "Erstelle deinen Yndooo account",
      email: "E-Mail",
      emailError: {
        valid: "Muss eine valide E-Mail sein",
        required: "E-Mail wird benötigt"
      },
      passwordError: {
        minimum: "Mindestens 6 Zeichen",
        maximum: "Maximal 50 Zeichen",
        required: "Passwort wird benötigt"
      },
      password: "Passwort",
      name: "Nachname",
      nameErrors: {
        minimum: "Mindestens 1 Zeichen",
        maximum: "Maximal 1500 Zeichen",
        alpha: "Ein name kann nur aus Buchstaben bestehen",
        required: "Nachname wird benötigt"
      },
      username: "Vorname",
      usernameErrors: {
        minimum: "Mindestens 4 Zeichen",
        maximum: "Maximal 150 Zeichen",
        alpha: "Ein name kann nur aus Buchstaben bestehen",
        required: "Vorname wird benötigt"
      },
      register: "Erstelle einen Account",
      login: {
        question: "Du hast bereits einen Account?",
        linkText: "Logge Dich ein"
      },
      productUpdates: {
        description: "Sende mir keine Updates über das Produkt. Wenn diese Box ausgelassen wird, sendet dir Yndoo gelegentlich hilfreiche E-Mails über Produktupdates. Du kannst dies jederzeit im Profil ändern.",
        linkText: "Privacy Policy"
      },
      numberOfSellingPoints: 3,
      sellingPoints: [
        {
          title: "Schnelle und kostenlose Registrierung",
          description: "Du brauchst nichts als eine E-Mail und ein Password."
        },
        {
          title: "Beginne noch heute damit deine Firma zu strukturieren.",
          description: "Verwende Yndooos Dashboard, um Links firmenweit zu speichern, sortieren, teilen und mehr."
        },
        {
          title: "DSGVO",
          description: "Der Schutz deiner Daten ist uns wichtig."
        },
      ],
      feedback: {
        title: "E-Mail-Bestätigung",
        description: "Wir haben dir eine E-Mail zugesendet um dein Account zu verifizieren.",
        resend: {
          question: "Email nicht erhalten?",
          link: "Link erneut senden."
        },
        return: "Zurück zum login",
      }
    },
    reset: {
      title: "Passwort zurücksetzen",
      description: "Gib die E-Mail Addresse, die mit deinem Account verbunden ist ein und wir senden Dir einen Link, um dein Passwort zurückzusetzen.",
      email: "E-Mail",
      reset: "Weiter",
      return: "Zurück zum login",
      confirmation: {
        title: "Wiederherstellungs-E-Mail",
        description: "Du wirst innerhalb weniger Minuten einen Passwort-Wiederherstellungs-Link von uns erhalten."
      }
    },
    navBar: {
      switchTo: "Stellen auf",
      privateMode: "Privatmodus",
      editProfile: "Einstellungen",
      editTenant: "Team",
      logOut: "Abmelden",
      dashboard: "Dashboard",
      billing: "Abonnement",
      userDocumentation: "Hilfe",
    },
    profile: {
      title: "Benutzerprofil",
      tabs: {
        account: "Account",
        profile: "Profil",
        preferences: "Präferenzen",
        public: "Öffentliches Profil",
      },
      dangerZone: {
        title: "Account löschen",
        disclaimer: "Das löschen des Accounts ist unwiderruflich. Dadurch werden all deine angelegten Teams und Links gelöscht.",
        contactSupport: "Support kontaktieren",
      },
      titleSelectTheme: "Farbschema auswählen",
      themeDarkModeHint: "Dieser Modus lässt deine Augen entspannen",
      themeDarkMode: "Dunkel Modus",
      titleSelectLanguage: "Sprache auswählen",
      titleProfile: "Profilinformation",
      titlePublic: "Öffentliches Profil",
      titleChangePassword: "Passwort ändern",
      titleCurrentEmail: "Aktuelle E-Mail",
      labelOldpassword: "Altes Passwort",
      labelNewpassword: "Neues Passwort",
      labelRepeatnewpassword: "Neues Passwort wiederholen",
      labelName: "Name",
      labelSurname: "Nachname",
      labelEmail: "E-Mail",
      labelAlias: "Alias",
      labelYear: "Jahr",
      labelMonth: "Monat",
      labelDay: "Tag",
      hintPassword: "Das Passwort muss 6 bis 10 Zeichen, mindestens 1 Zahl, 1 Groß- und Kleinbuchstaben enthalten",
      birthdate: "Geburtsdatum:",
      buttonChangePassword: "Passwort ändern",
      buttonUpdateEmail: "E-Mail aktualisieren",
      public: {
        labelPublicEmail: "E-Mail",
        labelPublicPhone: "Telefon",
        labelDescription: "Beschreibung",
        save: "Profil speichern"
      }
    },
    tenant: {
      titleEditTenant: "Team bearbeiten",
      titleCreateTenant: "Benenne dein Team",
      titleMembers: "Mitglieder",
      titleInvitationCodes: "Einladungen",
      titlePreview: "Vorschau:",
      titleCustomLabels: "Benutzerdefinierte Etiketten",
      labelDisplayName: "Anzeigename*",
      labelCompanyName: "Firmenname*",
      labelCompanyAdress: "Straße",
      labelCompanyCountry: "Land",
      labelCompanyZip: "PLZ",
      labelCompanyCity: "Stadt",
      labelCompanyContactPerson: "Kontaktperson",
      labelCompanyContactPersonPosition: "Position der Kontaktperson",
      labelCompanyContactPersonEmail: "E-Mail der Kontaktperson",
      labelCompanyContactPersonPhone: "Telefonnummer der Kontaktperson",
      buttonCreateLabel: "Erstellen Sie ein neues benutzerdefiniertes Label",
      buttonEditLabel: "Ausgewähltes Label bearbeiten",
      buttonDeleteLabel: "Ausgewähltes Label löschen",
      dangerZone: {
        title: "Team löschen",
        disclaimer: "Das Löschen des Teams ist nicht wiederrufbar. Du wirst Zugriff zu allen erstellten Links verlieren.",
        contactSupport: "Support kontaktieren",
      },
    },
    link: {
      editLink: "Link bearbeiten",
      createLink: "Erstellen Sie einen neuen Link",
      labelLink: "Link",
      labelTitle: "Titel*",
      labelType: "Link-Typ",
      labelSelect: "Wähle Labels",
      labelDescription: "Beschreibung",
    },
    rules: {
      fieldnotification: 'Dieses Feld ist erforderlich',
      fieldvalidation: 'Min. 3 Zeichen',
      nowhitespaces: 'Keine Leerzeichen erlaubt',
      emailnotification: 'E-Mail ist erforderlich',
      emailvalidation: 'E-Mail muss gültig sein',
      yearhint1: "Jahr muss 4-stellig sein",
      yearhint2: "Jahr kann nicht negativ sein",
      passwordnotification: 'Passwort ist erforderlich',
      passwordnotificationminchar: 'Mindestens 6 Zeichen',
      passwordnotificationmaxchar: 'Maximal 10 Zeichen',
      passwordnotificationreg1: "Das Passwort muss mindestens 1 Großbuchstaben enthalten",
      passwordnotificationreg2: "Das Passwort muss mindestens 1 Kleinbuchstaben enthalten",
      passwordnotificationreg3: "Das Passwort muss mindestens 1 Zahl enthalten",
      passnotmatch: "Passwörter stimmten nicht überein",
      oldnewpassnotsame: "Altes Passwort und Neues Passwort können nicht dasselbe sein",
    },
    confirmDialog: {
      titleGeneric: 'Bitte bestätigen',
      messageGeneric: 'Bist du sicher?',
      messageLeaveTenant: 'Bist du sicher? Diese Aktion kann nicht rückgängig gemacht werden!',
      titleDeleteCard: 'Karte löschen',
      MessageDeleteCard: 'Karte wird dauerhaft gelöscht',
      titleMoveCard: 'Karte verschieben',
      MessageMoveCard: 'Die Karte wird dauerhaft an den Mieter verschoben',
      titleUpdateProfile: 'Profil aktualisieren',
      titleUpdateEmail: 'E-Mail aktualisieren',
      MessageUpdateEmail: 'Sie müssen Ihre neue E-Mail-Adresse bestätigen',
      titleChangePassword: 'Ändere das Passwort',
      titleDeleteLabel: 'Label löschen',
      titleSaveLabel: 'Label speichern',
      titleGenerateInvitation: 'Einladungscode generieren',
      titleDeleteCode: 'Code löschen',
      titleAcceptMember: 'Neues Mitglied akzeptieren',
      titleRejectMember: 'Neues Mitglied ablehnen',
    },
    record: {
      tipPrivate: 'Dies ist Ihr privater Link',
      tipClipboard: 'Link in Zwischenablage kopieren',
      tipTwitter: 'Link auf Twitter teilen',
      tipFacebook: 'Link auf Facebook teilen',
      tipWhatsApp: 'Teilen Sie den Link auf WhatsApp',
      tipEmail: 'Link per E-Mail teilen',
      tipLinkedIn: 'Teilen Sie den Link auf LinkedIn',
      tipQR: 'QR-Code teilen',
      tinyURL: 'Erstellen Sie einen TinyURL-Link'
    },
    codes: {
      code: 'Code',
      type: 'Codetyp',
      YndoooCodes: "Yndooo-Codes",
      generateCodes: 'Codes generieren',
      generateInvitationCode: 'Einladungscode generieren',
      generateInvitationCodeTutorial: 'Du kannst Leute mit einem Einaldungscode zu deinem Team einladen. ' +
          'Du musst den Code an die andere Person weitergeben, damit sie dem Team beitreten kann. ' +
          '<br> Die Funktion zum Beitreten kann auf der linken Seite gefunden werden, ' +
          'dort wo man auch das Team wechseln und erstellen kann.',
      invitationCodes: 'Einladungscodes',
      invitationCode: 'Einladungscode',
      pendingInvitations: "Ausstehende Einladungen",
      pendingCodes: "Ausstehende Codes",
      labelEnterCode: "Geben Sie hier Ihren Code ein",
      tipClipboard: 'Kopieren Sie den Code in die Zwischenablage',
      tipDelete: 'Code löschen',
      tipAccept: 'Akzeptiere neues Mitglied',
      tipReject: 'Neues Mitglied ablehnen'
    },
    filter: {
      titleSelectLanguage: "Wähle die Sprache",
      labelTitle: 'Label-Filter',
      labelSubtitle: 'Wähle welche Labels als Filter verwendet werden sollen. Labels verwenden die UND-Verknüpfung.',
      typeTitle: 'Typ-Filter',
      typeSubtitle: 'Wähle Dateitypen als Filter. Dateitypen verwenden die ODER-Verknüpfung.',
      SearchLabel: 'Tags nach denen gesucht werden soll',
      SearchHint: 'Verwende nicht existierende Tags, um eine Volltextsuche anzuwenden',
      clear: "Filter löschen",
      noResults: "Keine Ergebnisse für",
    },
    notificatons: {
      'user/profile-updated': 'Profil erfolgreich aktualisiert',
    }
  },
  error: {
    "404": {
      "title": "Irgendwas fehlt",
      "description": "Die Seite fehlt oder du hast den Link falsch zusammengebaut."
    },
    "500": {
      "title": "Oh, es ist ein Fehler aufgetreten.",
      "description": "Ich wurde darüber benachrichtigt und kümmere mich drum."
    },
    "return": "Zurück"
  },
  reset: {
    description: "Gib die E-Mail Addresse, die mit deinem Account verbunden ist ein und wir senden Dir einen Link, um dein Passwort zurückzusetzen.",
    email: "E-Mail",
    reset: "Weiter",
    return: "Zurück zum login",
  },
  passwordResetConfirmation: {
    description: "Erstelle ein neues Passwort für den Account von",
    password: "Passwort",
    passwordConfirmation: "Passwort Bestätigung",
    reset: "Passwort ändern",
    return: "Zurück zum Anwendung",
    contact: "Du hast Probleme beim Zurücksetzen?",
    confirmation: "Dein Password wurde erfolgreich geändert.",
    rejection: "Die Aktion ist nicht gültig. Dies kann passieren, wenn die URL ungültig ist, die Zeitgrenze überschritten wurde, oder das Passwort bereits zurückgesetzt wurde.",
    passwordError: {
      minimum: "Muss mindestens 6 Zeichen enthalten",
      maximum: "Darf maximal 50 Zeichen enthalten",
      required: "Passwort wird benötigt"
    },
    passwordConfirmationError: {
      minimum: "Muss mindestens 6 Zeichen enthalten",
      maximum: "Darf maximal 50 Zeichen enthalten",
      required: "Bitte gib dein Passwort zur Bestätigung ein",
      sameAsPassword: "Stimmt nicht mit Password überein"
    },
  },
  emailVerification: {
    return: "Zurück zur Anwendung",
    contact: "Du hast Probleme beim Verifizieren?",
    confirmation: "Deine Email wurde erfolgreich verifiziert. Du kannst dich nun mit deinen Accountdaten einloggen.",
    rejection: "Die Aktion ist nicht gültig. Dies kann passieren, wenn die URL ungültig ist, die Zeitgrenze überschritten wurde, oder das Passwort bereits zurückgesetzt wurde.",
  },
  emailRecovery: {
    return: "Zurück zur Anwendung",
    contact: "Du hast Probleme beim Herstellen?",
    confirmation: "Deine Email wurde erfolgreich zurückgesetzt.",
    rejection: "Die Aktion ist nicht gültig. Dies kann passieren, wenn die URL ungültig ist, die Zeitgrenze überschritten wurde, oder das Passwort bereits zurückgesetzt wurde.",
    passwordResetQuestion: "Möchtest du zudem dein Password zurücksetzen?",
  },
  navBar: {
    switchTo: "Stellen auf",
    privateMode: "Privatmodus",
    editProfile: "Einstellungen",
    editTenant: "Team",
    billing: "Billing",
    logOut: "Abmelden",
    userDocumentation: "Hilfe",
  },
  profile: {
    titleSelectTheme: "Farbschema auswählen",
    themeDarkModeHint: "Dieser Umschalter lässt Ihre Augen ruhen",
    themeDarkMode: "Dunkel Modus",
    titleSelectLanguage: "Sprache auswählen",
    titleProfile: "Profil Information",
    titlePublic: "Öffentliches Profil",
    titleChangePassword: "Passwort ändern",
    titleCurrentEmail: "Aktuelle E-Mail",
    labelOldpassword: "Altes Passwort",
    labelNewpassword: "Neues Passwort",
    labelRepeatnewpassword: "Neues Passwort wiederholen",
    labelName: "Name",
    labelSurname: "Nachname",
    labelEmail: "E-Mail",
    labelAlias: "Alias",
    labelYear: "Jahr",
    labelMonth: "Monat",
    labelDay: "Tag",
    hintPassword: "Das Passwort muss 6 bis 10 Zeichen, mindestens 1 Zahl, 1 Groß- und Kleinbuchstaben enthalten",
    birthdate: "Geburtsdatum:",
    buttonChangePassword: "Passwort ändern",
    buttonUpdateEmail: "E-Mail aktualisieren",
  },
  tenant: {
    titleEditTenant: "Team bearbeiten",
    titleCreateTenant: "Ein neues Team erstellen",
    btnCreateTenant: "Team erstellen",
    creation: {
      skip: "Überspringen",
      continue: "Mitglieder einladen",
      step1: {
        stepperName: "Team erstellen",
        title: "Ein neues Team erstellen",
        subtitle: "Nach dem Erstellen des Teams kannst du andere dazu einladen dem Team beizutreten.",
        name: "Name",
        create: "Erstelle Team"
      },
      step2: {
        stepperName: "Mitglieder einladen",
        title: "Lade jemanden zu deinem Team ein",
        subtitle: "Du kannst die Rechte der Mitglieder jederzeit in den Team-Einstellungen ändern.",
        name: "Name",
        email: "E-Mail",
        addAnother: "Füge weitere hinzu"
      },
      step3: {
        stepperName: "Fertigstellen",
        title: "Wähle Deinen Team-Plan",
        subtitle: "Du kannst das Abonnement jederzeit auf der Team-Seite ändern.",
        name: "Name",
      }
    },
    fields: {
      displayName: "Name",
    },
    join: {
      step1: {
        title: "Einem Team beitreten",
        subtitle: "Der Einladungscode kann dir vom Team-Owner mitgeteilt werden. Falls du per E-Mail eingeladen wurdest, findest du den Code im unteren Teil des Texts.",
        code: "Einladungscode",
        join: "Team betreten"
      },
      title: "Team beitreten",
      button: "Team beitreten",
      code: "Einladungscode",
    },
    invite: {
      btnInvite: "Mitglied einladen"
    },
    tabs: {
      overview: "Übersicht",
      members: "Mitglieder",
      labels: "Labels",
      subscription: "Abonnement"
    },
    roles: {
      owner: "Eigentümer",
      admin: "Admin",
    },
    titleMembers: "Mitglieder",
    titleInvitationCodes: "Einladungen",
    titlePreview: "Vorschau:",
    titleCustomLabels: "Benutzerdefinierte Labels",
    labelDisplayName: "Anzeigename",
    labelCompanyName: "Firmenname*",
    labelCompanyAdress: "Straße",
    labelCompanyCountry: "Land",
    labelCompanyZip: "PLZ",
    labelCompanyCity: "Stadt",
    labelCompanyContactPerson: "Kontaktperson",
    labelCompanyContactPersonPosition: "Position der Kontaktperson",
    labelCompanyContactPersonEmail: "E-Mail der Kontaktperson",
    labelCompanyContactPersonPhone: "Telefonnummer der Kontaktperson",
    labels: {
      buttonCreateLabel: "Erstelle Label",
      buttonCreateTooltip: "Erstelle Labels, um deine Cards zu gruppieren und besser filtern zu können",
      buttonEditLabel: "Label bearbeiten",
      buttonDeleteLabel: "Label löschen",
      toolbarCreateLabel: "Erstelle Label",
      toolbarEditLabel: "Bearbeite Label",
      search: "Suche nach Labels ...",
      fields: {
        actions: "Aktionen",
        title: "Titel",
        description: "Beschreibung",
        color: "Farbe"
      }
    },
    members: {
      dangerZone: "Gefahrenzone",
      dangerZoneInfo: "Diese Aktionen sind permanent und können nicht rückgängig gemacht werden.",
      leaveTenant: "Team verlassen",
      inviteAccepted: "Angenommen von",
    },
    subscription: {
      title: "Aktuelles Abonement",
      currentPlan: {
        switchToAnnual: "Zum Jahresabonnement wechseln",
        switchToMonthly: "Zum Monatsabonnement wechseln",
        manage: "Mein Abbonement verwalten",
        invoices: "Meine Rechnungen",
        annualSubscription: "Jahresabonnement",
        monthlySubscription: "Monatsabonnement",
        began: "Begonnen am",
        ends: "Endet am",
        month: "Monat",
        year: "Jahr",
        save: "Spare"
      },
      change: {
        title: "Abonement ändern",
        description: "Du kannst jetzt zu einem unserer neuen Angebote wechseln, einfach, indem du unten klickst. Brauchst du bessere Suchfunktionen, mehr Teammitglieder oder Custom Labels? Das alles findet hier statt!"
      }
    },
  },
  link: {
    editLink: "Link bearbeiten",
    createLink: "Erstelle einen neuen Link",
    labelLink: "Link*",
    labelTitle: "Titel*",
    labelType: "Link-Typ",
    labelSelect: "Wähle Labels",
    labelDescription: "Beschreibung",
  },
  rules: {
    fieldnotification: 'Dieses Feld ist erforderlich',
    fieldvalidation: 'Min. 3 Zeichen',
    nowhitespaces: 'Keine Leerzeichen erlaubt',
    emailnotification: 'E-Mail ist erforderlich',
    emailvalidation: 'E-Mail muss gültig sein',
    yearhint1: "Jahr muss 4-stellig sein",
    yearhint2: "Jahr kann nicht negativ sein",
    passwordnotification: 'Passwort ist erforderlich',
    passwordnotificationminchar: 'Mindestens 6 Zeichen',
    passwordnotificationmaxchar: 'Maximal 10 Zeichen',
    passwordnotificationreg1: "Das Passwort muss mindestens 1 Großbuchstaben enthalten",
    passwordnotificationreg2: "Das Passwort muss mindestens 1 Kleinbuchstaben enthalten",
    passwordnotificationreg3: "Das Passwort muss mindestens 1 Zahl enthalten",
    passnotmatch: "Passwörter stimmten nicht überein",
    oldnewpassnotsame: "Altes Passwort und Neues Passwort können nicht dasselbe sein",
  },
  confirmDialog: {
    titleGeneric: 'Bitte bestätigen',
    messageGeneric: 'Bist du sicher?',
    titleDeleteCard: 'Karte löschen',
    MessageDeleteCard: 'Karte wird dauerhaft gelöscht',
    titleMoveCard: 'Karte verschieben',
    MessageMoveCard: 'Die Karte wird dauerhaft an den Mieter verschoben',
    titleUpdateProfile: 'Profil aktualisieren',
    titleUpdateEmail: 'E-Mail aktualisieren',
    MessageUpdateEmail: 'Sie müssen Ihre neue E-Mail-Adresse bestätigen',
    titleChangePassword: 'Ändere das Passwort',
    titleDeleteLabel: 'Label löschen',
    titleSaveLabel: 'Label speichern',
    titleGenerateInvitation: 'Einladungscode generieren',
    titleDeleteCode: 'Code löschen',
    titleAcceptMember: 'Neues Mitglied akzeptieren',
    titleRejectMember: 'Neues Mitglied ablehnen',
  },
  record: {
    tipPrivate: 'Dies ist Ihr privater Link',
    tipClipboard: 'Link in Zwischenablage kopieren',
    tipTwitter: 'Link auf Twitter teilen',
    tipFacebook: 'Link auf Facebook teilen',
    tipWhatsApp: 'Teilen Sie den Link auf WhatsApp',
    tipEmail: 'Link per E-Mail teilen',
    tipLinkedIn: 'Teilen Sie den Link auf LinkedIn',
    tipQR: 'QR-Code teilen',
    tinyURL: 'Erstellen Sie einen TinyURL-Link'
  },
  codes: {
    code: 'Code',
    type: 'Codetyp',
    YndoooCodes: "Yndooo-Codes",
    generateCodes: 'Codes generieren',
    generateInvitationCode: 'Einladungscode generieren',
    invitationCode: 'Einladungscode',
    pendingInvitations: "Ausstehende Einladungen",
    pendingCodes: "Ausstehende Codes",
    labelEnterCode: "Geben Sie hier Ihren Code ein",
    tipClipboard: 'Kopieren Sie den Code in die Zwischenablage',
    tipDelete: 'Code löschen',
    tipAccept: 'Akzeptiere neues Mitglied',
    tipReject: 'Neues Mitglied ablehnen'
  },
  tutorial: {
    introduction: {
      default: {
        title: "Willkommen zu Yndooo",
        text: "Schön Dich zu sehen. In diesem Tutorial erklären wir dir die ersten Schritte.",
        imgUrl: "/img/tutorial/01_girl_introduction.svg",
        skipAllBtn: "Überspringen",
        backBtn: "",
        nextBtn: "Weiter",
      },
      easterPromo: {
        title: "Yndooo wünscht Dir frohe Ostern",
        text: "Schön Dich zu sehen. In diesem Tutorial erklären wir dir die ersten Schritte.",
        imgUrl: "/img/tutorial/01_girl_introduction.svg",
        skipAllBtn: "Überspringen",
        backBtn: "",
        nextBtn: "Weiter",
      }
    },
    step1: {
      title: "Dashboard",
      text: "Auf deinem Dashboard kannst du auf alle Funktionen zugreifen und zwischen Private Mode und Team Mode wechseln. Dabei ist der Private Mode wie der Name schon sagt alles was nur du dir ansehen kannst.",
      imgUrl: "/img/tutorial/02_create_team.svg",
      backBtn: "Zurück",
      nextBtn: "Weiter",
    },
    step2:{
      title: "Erstelle deine erste Card",
      text: "Nie wieder einen wichtigen Link oder eine wichtige Website verlieren! Speicher Sie einfach auf deinem Dashboard im Team oder im Private Mode indem du auf Add New klickst.  Mehr Erfahren?",
      imgUrl: "/img/tutorial/03_add_person_to_team.svg",
      backBtn: "Zurück",
      nextBtn: "Weiter",
    },
    step3: {
      title: "Erstelle dein erstes Team",
      text: "Ein Team erstellen ist ganz einfach! Gehe hierzu auf Gehe hierzu auf das linke Drop down Menü und wähle Create Team aus! Anschließend kannst du den Namen deines Teams eintragen. Mehr Erfahren?",
      imgUrl: "/img/tutorial/04_create_first_card.svg",
      backBtn: "Zurück",
      nextBtn: "Weiter",
    },
    step4: {
      title: "Lege deine Präferenzen fest",
      text: "Nun kannst du deine persönlichen Präferenzen festlegen oder dein Profil vervollständigen. Falls du später noch einmal auf diese übersicht zugreifen möchtest klicke auf Help. Viel Spaß mit Yndooo. Mehr Erfahren?",
      imgUrl: "/img/tutorial/05_preferences.svg",
      backBtn: "Zurück",
      nextBtn: "Fertig",
    },
  },
};