<template lang="pug">
  v-btn(fab icon width="auto" height="auto"  @click="onButtonClick")
      v-row
        v-col(cols="12")
          v-avatar(:size="parseInt(avatarSize)")
              img(v-if="imageUrl" :src="imageUrl")
        v-col(cols="12")
          | {{ buttonText }}
        input(
              ref="uploader"
              placeholder="Pick an avatar"
              type="file"
              accept="image/*"
              class="d-none"
              @change="onFileChanged"
          )
</template>

<script>
export default {
  name: "FileUploadAvatar",
  props: {
    imageUrl: {
      type: String,
      required: true,
      default: "",
    },
    buttonText: {
      type: String,
      required: false,
      default: "Change avatar image",
    },
    avatarSize: {
      type: String,
      required: false,
      default: "56",
    },
    reset: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isSelecting: false,
    selectedFile: null,
    tmpImgUrl: "",
  }),
  watch: {
    reset: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        this.isSelecting = false
        this.selectedFile = null
        this.tmpImgUrl = ""
      },
    },
    imageUrl: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(newVal, oldVal) {
        // eslint-disable-next-line vue/no-mutating-props
        this.tmpImgUrl = {... newVal}
      },
    },
  },
  mounted() {
    this.tmpImgUrl = this.imageUrl
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false
        },
        { once: true }
      )

      this.$refs.uploader.click()
    },
    async onFileChanged(event) {
      this.selectedFile = event.target.files[0]

      const reader = new FileReader()
      reader.onload = (e) => {
        this.tmpImgUrl = e.target.result
        this.$emit("file-changed", {
          file: this.selectedFile,
          url: this.tmpImgUrl,
        })
      }
      reader.readAsDataURL(this.selectedFile)
    },
  },
}
</script>

<style scoped>
.v-avatar:hover {
  filter: blur(1px);
}
.v-btn:hover:before {
  color: transparent;
}
.v-btn:focus:before {
  color: transparent;
}
</style>
