<template lang="pug">
  v-dialog(v-model="show" z-index="9999" persistent :fullscreen="$vuetify.breakpoint.xsOnly" max-width="700" height="100%")
    TutorialCardArtLeft(
      v-for="(item, i) in tuts"
      :key="i"
      v-show="i === step"
      :id="item.id"
      :lottiefile="item.lottieFile"
      :title="item.title"
      :text="item.text"
      :skip-all-btn="item.skipAllBtn"
      :back-btn="item.backBtn"
      :next-btn="item.nextBtn"
      @next="onNext(item)"
      @back="step -= 1; $router.back()"
      @skip="$emit('completed')"
    )
</template>

<script>
import TutorialCardArtTop from "@/components/tutorial/TutorialCardArtTop";
import TutorialCardArtLeft from "@/components/tutorial/TutorialCardArtLeft";
export default {
  name: "TutorialTenant",
  components: {TutorialCardArtLeft, TutorialCardArtTop},
  data() {
    return {
      step: 0,
      easterPromoEnabled: false,

      tuts: [
        {
          id: "tutorial-tenant-1",
          lottieFile: {
            path: '/animations/rocket.json',
            animationType: 'svg',
            loop: true,
            autoplay: true,
          },
          title: "Let`s get started",
          text: " You`ve successfully created your first tenant. Now you can start to collect, connect and share information with your team members.",
          to: "/tenant/members",
          skipAllBtn: "Skip all",
          backBtn: "",
          nextBtn: "Next",
        },
        {
          id: "tutorial-tenant-2",
          lottieFile: {
            path: '/animations/12135-email-send.json',
            animationType: 'svg',
            loop: true,
            autoplay: true,
          },
          title: "Members",
          text: "On the members page you can invite your colleagues via email or an anonymous code.",
          to: "/tenant/labels",
          skipAllBtn: "",
          backBtn: "Back",
          nextBtn: "Next",
        },
        {
          id: "tutorial-tenant-3",
          lottieFile: {
            path: '/animations/hashtag.json',
            animationType: 'svg',
            loop: true,
            autoplay: true,
          },
          title: "Labels",
          text: "You can create custom labels to organize and color code your information.",
          to: "/tenant/subscription",
          skipAllBtn: "",
          backBtn: "Back",
          nextBtn: "Next",
        },
        {
          id: "tutorial-tenant-4",
          lottieFile: {
            path: '/animations/subscription.json',
            animationType: 'svg',
            loop: true,
            autoplay: true,
          },
          title: "Subscription",
          text: "On the subscription page you get an overview of your costs and can change the payment information.",
          to: "",
          skipAllBtn: "",
          backBtn: "Back",
          nextBtn: "Finish",
        },
      ],
    }
  },
  computed: {
    tutorials() {
      const steps = Object.values(this.$t('tutorial'))
      return steps.slice(1, steps.length + 1)
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  methods: {
    onBack() {
      this.step -= 1
    },
    onNext(item) {
      this.step += 1;
      if (this.step === this.tuts.length) {
        this.$emit('completed')
      }
      console.log(item)
      this.$router.push(item.to)
    },
    onSkipAll() {
      this.$emit('completed')
    }
  },
}
</script>

<style scoped lang="scss">
.flexcard {
  display: flex;
  flex-direction: column;
}
// Add the css below if your card has a toolbar, and if your toolbar's height increases according to the flex display.
.flexcard .v-toolbar {
  flex: 0;
}
</style>