<template lang="pug">
  v-dialog(v-model="show" z-index="9999" persistent :fullscreen="$vuetify.breakpoint.xsOnly" max-width="700" height="100%")
    v-card(v-if="step === 0" max-width="700" height="100%" class="flexcard")
      v-card-actions
        v-spacer
        v-btn(icon @click="onSkipAll")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-icon(v-bind="attrs" v-on="on") mdi-close
            span {{ $vuetify.lang.t('$vuetify.close') }}
      v-card-actions
        v-row(fill-height class="align-center")
          v-col(cols="4" class="justify-center align-center")
            v-img(src="/img/tutorial/01_girl_introduction.svg" lazy-src="/img/tutorial/01_girl_introduction.svg" alt="images.smartphone.alt" aspect-ratio="1.0" contain height="470" class="text-left justify-left")
          v-col(cols="8" class="text-left align-center justify-center")
            v-card(flat)
              v-card-title {{ this.easterPromoEnabled? this.$t('tutorial.introduction.easterPromo.title') : this.$t('tutorial.introduction.default.title') }}
              v-card-text {{ this.$t('tutorial.introduction.default.text') }}
      v-card-actions
        v-spacer
        v-btn(color="primary" @click="step = 1" width="120") {{ this.$t('tutorial.introduction.default.nextBtn') }}
    TutorialCardArtTop(
      v-for="(item, i) in tutorials"
      v-if="step === (i + 1)"
      :key="i" :img-url="item.imgUrl"
      :title="item.title"
      :text="item.text"
      :skip-all-btn="item.skipAllBtn"
      :back-btn="item.backBtn"
      :next-btn="item.nextBtn"
      @skip="onSkipAll"
      @back="onBack"
      @next="onNext")
</template>

<script>
import TutorialCardArtTop from "@/components/tutorial/TutorialCardArtTop";
import { fireAnalytics, fireRemoteConfig } from "@/main";
export default {
  name: "Tutorial",
  components: {TutorialCardArtTop},
  data() {
    return {
      step: 0,
      easterPromoEnabled: false
    }
  },
  computed: {
    tutorials() {
      const steps = Object.values(this.$t('tutorial'))
      return steps.slice(1, steps.length + 1)
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  methods: {
    onBack() {
      this.step -= 1
    },
    onNext() {
      this.step += 1

      if (this.step > this.tutorials.length) {
        this.$emit('completed')
      }
    },
    onSkipAll() {
      this.$emit('completed')
    }
  },
  async mounted() {
    fireAnalytics.logEvent('tutorial_begin');

    await fireRemoteConfig.fetchAndActivate()
    this.easterPromoEnabled = fireRemoteConfig.getBoolean('promo_easter_enabled')
  }
}
</script>

<style scoped lang="scss">
.flexcard {
  display: flex;
  flex-direction: column;
}
// Add the css below if your card has a toolbar, and if your toolbar's height increases according to the flex display.
.flexcard .v-toolbar {
  flex: 0;
}
</style>