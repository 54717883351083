/**
 * Stores the Link label properties and methods
 */
export default class LinkLabel {
    /**
     * The Label's id
     * @type {string}
     */
    id = "";
    /**
     * The Label's displayed name
     * @type {string}
     */
    label = "";

    /**
     * Optional description, that can be used to give the members of a team/tenant more information about the use case
     * @type {string}
     */
    description = ""
    /**
     * The Label's color (orange, red, green, etc.)
     * @type {string}
     */
    color = "";
    /**
     * True if this is a Default system label
     * @type {boolean}
     */
    isDefault = false;
    /**
     * True if this is a Default system label and it was deleted
     * @type {boolean}
     */
     isDeleted = false;

    /**
     * @constructs LinkLabel
     * @param {string} label - the Label's displayed name
     * @param {string} description The labels description
     * @param {string} color - the Label's color (orange, red, green, etc.)
     * @param {boolean} [isDefault] - is this a Default system label
     * @param {boolean} [isDeleted] - is this a Default deleted system label
     */
    constructor(label, description, color, isDefault, isDeleted) {
        this.label = label;
        this.description = description;
        this.color = color;
        this.isDefault = isDefault ? isDefault : false;
        this.isDeleted = isDeleted ? isDeleted : false;
    }

    /**
     * Creates a new Label object based on its id
     * @static
     * @param {string}  id  - the Label's name
     * @param {LinkLabel[]} allLabels - an array of existing labels
     *
     * @returns {LinkLabel}
     */
    static fromID(id, allLabels) {
        if (id && allLabels && allLabels.length > 0) {
            let tmpLabel = allLabels.find(item => item.id === id);
            if (tmpLabel) {
                return tmpLabel;
            }
        }
        return null;
    }

    /**
     * Creates multiple Label objects based on their ids
     *
     * @static
     * @param {string[]}  ids  -  an array of Label's name
     * @param {LinkLabel[]} allLabels - an array of existing labels
     *
     * @returns {LinkLabel[]}
     */
    static multiFromID(ids, allLabels) {
        let labels = []
        if (ids && allLabels && allLabels.length > 0) {
            ids.forEach((item, i) => {
                labels.push(LinkLabel.fromID(item.id, allLabels));
            });
        }
        return labels.filter(item => item !== null);
    }

    /**
     * Updates the properties of a Label
     *
     * @param {Object} newValues - the new values for the label
     * @param {string} newValues.label - the Label's display name
     * @param {string} newValues.description - the Label's display name
     * @param {string} newValues.color - The Label's color.
     */
    update(newValues) {
        this.label = newValues.label;
        this.description = newValues.description;
        this.color = newValues.color;
    }

    /**
     * Creates a copy of this Link Label object
     *
     * @return {LinkLabel} Returns a new Link object
     */
    copy() {
        let tmpLabel = new LinkLabel(this.label, this.description, this.color, this.isDefault, this.isDeleted);
        tmpLabel.id = this.id;
        return tmpLabel;
    }

    /**
     * Generates an Empty Link Label object
     * @static
     * @return {LinkLabel} Returns an empty Link object
     */
    static empty() {
        return new LinkLabel("", "","#000000", false, false);
    }

    /**
     * converts this LinkLabel into JSON object
     *
     * @returns {Object}
     */
    toJSON() {
        return {
            id: this.id,
            label: this.label,
            description: this.description,
            color: this.color,
            isDefault: this.isDefault,
            isDeleted: this.isDeleted,
        }
    }
}
