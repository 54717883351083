<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    v-row(align="center" justify="center")
      v-col(cols="12" align="center" justify="center" class="mb-0 pb-0")
        Logo
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="5" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
          v-form(@submit.prevent="forgotPassword" class="ml-12 mr-12")
            v-row(align="center" justify="center")
              v-col(cols="12" class="text-left")
                h2.text-h6.font-weight-regular {{ $vuetify.lang.t('$vuetify.reset.title') }}
              v-col(cols="12" class="mb-0 pb-0 text-start")
                p.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.reset.description') }}
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0 text-start")
                p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.email') }}
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                v-text-field(
                  v-model="login.email"
                  required
                  outlined
                  dense
                  label=""
                  autofocus
                  autocomplete="username"
                  :error-messages="emailErrors"
                  @input="$v.login.email.$touch()"
                  @blur="$v.login.email.$touch()"
                )
              v-col(cols="12")
                v-btn(type="submit" block color="primary" :loading="loading") {{ $vuetify.lang.t('$vuetify.login.login') }}
              v-col(cols="12")
                router-link(to="/login")  {{ $vuetify.lang.t('$vuetify.reset.return') }}
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form(@submit.prevent="forgotPassword" class="ml-12 mr-12")
          v-row(align="center" justify="center")
            v-col(cols="12" class="text-left")
              h2.text-h6.font-weight-regular {{ $vuetify.lang.t('$vuetify.reset.title') }}
            v-col(cols="12" class="text-start")
              h2.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.reset.description') }}
            v-col(cols="12" class="mb-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.email') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="login.email"
                required
                outlined
                dense
                label=""
                autofocus
                autocomplete="username"
                type="email"
                :error-messages="emailErrors"
                @input="$v.login.email.$touch()"
                @blur="$v.login.email.$touch()"
              )
            v-col(cols="12")
              v-btn(type="submit" block color="primary" :loading="loading") {{ $vuetify.lang.t('$vuetify.reset.reset') }}
            v-col(cols="12")
              router-link(to="/login")  {{ $vuetify.lang.t('$vuetify.reset.return') }}
      v-col(cols="12" class="text-center")
        p.text-body-2.fontColorLight--text
          | {{ $vuetify.lang.t('$vuetify.login.register.question') }}
          router-link(to="/register")  {{ $vuetify.lang.t('$vuetify.login.register.linkText') }}
</template>

<script>
import {validationMixin} from "vuelidate";
import {email, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import Logo from "../core/Logo";

export default {
  name: "PasswordResetForm",
  components: {Logo},
  mixins: [validationMixin],
  validations: {
    login: {
      email: {required, email},
    },
  },
  data() {
    return {
      loading: false,
      confirmation: false,
      login: {
        email: '',
      },
      defaultLogin: {
        email: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "currentUser",
    ]),
    landingPageUrl(){
      return process.env.VUE_APP_ADV_PUBLIC_ID === 'test' ? 'https://yndooo-web-dev.web.app/' : 'https://yndooo.com/'
    },
    emailErrors() {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.login.email.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "sendPasswordResetEmail",
      "StoreNotification",
    ]),
    resetForm() {
      this.$v.$reset()
      this.login = {... this.defaultLogin }
    },
    async forgotPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      await this.sendPasswordResetEmail({ email: this.login.email })
      .then(() => {
        this.$emit('password-reset-completed', this.login.email)
        this.resetForm()
      })
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>