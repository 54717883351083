const AVATAR_COLORS = [
    '#bca2a6',
    '#ccb3b7',
    '#bb839a',
    '#855d92',
    '#68558a',
    '#7963a8',
    '#40529a',
    '#787ab7',
    '#afb0d3',
    '#b7ced9',
    '#8bbcb8',
    '#5d979f',
    '#748a8b',
    '#4e6b7c',
    '#61859e',
    '#86a0b9'
];


export const createAvatarColor = function (ignoreColors) {
    const exclude = ignoreColors? ignoreColors : []

    const filteredColors = AVATAR_COLORS.filter(function(item) {
        return !exclude.includes(item);
    })

    const min = Math.ceil(0);
    const max = Math.floor(filteredColors.length);
    const index =  Math.floor(Math.random() * (max - min + 1)) + min;

    return filteredColors[index];
}