<template lang="pug">
  v-container(fluid)
    v-row
      v-col(cols="12")
        SubscriptionOverview
</template>

<script>
// import FirestoreDB from "../../firebase/FirestoreDB";

import PricingCard from "@/components/billing/PricingCard";
import Dashboard from "@/views/Dashboard";
import SubscriptionOverview from "@/components/subscription/SubscriptionOverview";
import TenantDangerZone from "@/components/tenant/TenantDangerZone";
export default {
  name: "PricingOverview",
  components: {TenantDangerZone, SubscriptionOverview, Dashboard, PricingCard},
  data: () => {
    return {
      selection: "",
      icons: {
        mdiArrowRight: 'mdi-arrow-right',
        mdiCheck: 'mdi-check',
        mdiClose: 'mdi-close',
      },
      paymentPlansBackup: [
        {
          active: true,
          title: "Bronze",
          subtitle:
            "Try us out! Collect, connect, share with up to 5 members in your team.",
          price: "0",
          priceYear: "0",
          currency: "€",
          highlights: [
            "Up to 5 team members",
            "Collect and share links & files.",
            "Sort through your data",
          ],
          features: {
            "advanced sorting": "true",
            "card lock": "true",
            "max t-users": "true",
            "no ads": "true",
            "pm-ads-free-for-t-members": "true",
            "t-admins": "true",
            "t-custom-labels": "true",
          },
          btn: {
            text: "Change Plan",
            filled: false,
          },
          link: "https://ynd.ooo/register?plan=bronze",
          elevation: "2",
        },
        {
          title: "Silver",
          subtitle:
            "Optimized for small teams for up to 10 members. Get yourself the support you need.",
          price: "29,95",
          priceYear: "0",
          currency: "€",
          highlights: [
            "Up to 10 team members",
            "No Ads",
            "Content locks",
            "Admin role",
          ],
          features: {
            "advanced sorting": "true",
            "card lock": "true",
            "max t-users": "true",
            "no ads": "true",
            "pm-ads-free-for-t-members": "true",
            "t-admins": "true",
            "t-custom-labels": "true",
          },
          btn: {
            text: "Change Plan",
            filled: true,
          },
          link: "https://ynd.ooo/register?plan=silver",
          elevation: "4",
        },
        {
          title: "Gold",
          subtitle:
            "Get your increasingly expanding company to the next level with advanced sorting.",
          price: "79,95",
          priceYear: "79,95",
          currency: "€",
          highlights: [
            "Up to 40 team members",
            "Advanced sorting",
            "Customization",
            "Advanced roles",
          ],
          features: {
            "advanced sorting": "true",
            "card lock": "true",
            "max t-users": "true",
            "no ads": "true",
            "pm-ads-free-for-t-members": "true",
            "t-admins": "true",
            "t-custom-labels": "true",
          },
          btn: {
            text: "Change Plan",
            filled: true,
          },
          link: "https://ynd.ooo/register?plan=gold",
          elevation: "4",
        },
        {
          title: "Platinum",
          subtitle:
            "Optimized for large enterprises. No matter your size, we`ve got your back",
          price: "99,95",
          priceYear: "",
          currency: "€",
          highlights: [
            "Unlimited members",
            "Unlimited storage",
            "Advanced customization",
          ],
          features: {
            "advanced sorting": "true",
            "card lock": "true",
            "max t-users": "true",
            "no ads": "true",
            "pm-ads-free-for-t-members": "true",
            "t-admins": "true",
            "t-custom-labels": "true",
          },
          btn: {
            text: "Change Plan",
            filled: false,
          },
          link: "https://ynd.ooo/register?plan=platinum",
          elevation: "2",
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-content .v-card.v-sheet.theme--light {
  background-color: #ffffff !important;
  border-left: 5px solid red !important
}
</style>
