import firebase from 'firebase/app'
import { db } from '../../src/main'
import Profile from '../../src/store/classes/Profile'
import Tenant from "@/store/classes/Tenant";
import {createAvatarColor} from "@/store/classes/Colors";

const profileConverter = {
  /**
   * Converts A Tenant class object into JSON object
   *
   * @param {Profile}  profile  a Link class object
  */
  toFirestore: function(profile) {
    return {
      activeTenant: profile.activeTenant,
      firstname: profile.firstname,
      surname: profile.surname,
      avatarColor: profile.avatarColor,
      email: profile.email,
      birthyear: profile.birthyear,
      birthmonth: profile.birthmonth,
      birthday: profile.birthday,
      likedCards: profile.likedCards ? profile.likedCards : [],
      hideTutorial: profile.hideTutorial,
    }
  },
  /**
   * Converts Firestore document data into a Tenant class object
   *
   * @param {firebase.firestore.QuerySnapshot}  snapshot  retrieved Document data
   * @param {object}  snapshot  query options
   * 
   * @returns {Profile}
  */
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    // Legacy: firstname was mistakenly named username in previous versions
    const aProfile = new Profile(data.activeTenant, data.firstname? data.firstname : data.username? data.username : "", data.surname, data.email, "", data.birthyear, data.birthmonth, data.birthday, "", data.likedCards, data.avatarColor, data.hideTutorial)
    return aProfile;
  }
}

/**
 * Deletes all documents in a collection
 * 
 * @param {firebase.firestore.CollectionReference}  collectionRef  collection Reference
 * @param {number}  [batchSize=100]  delete operation batch size
*/
async function deleteCollection(collectionRef, batchSize) {
  batchSize = batchSize ? batchSize : 100;
  const query = collectionRef.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}
async function deleteQueryBatch(query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.
  process.nextTick(() => {
    deleteQueryBatch(db, query, resolve);
  });
}

export default {
  methods: {
    /**
     * Creates a profile in Firestore
     *
     * @param {string} userid  a user's id.
     * @param {Profile} profile  a user's Profile class object
     *
     * @returns {string} returns user id
    */
    createProfile(userid, profile) {
      return new Promise(function (resolve, reject){  
        if (profile.email != "") {
          profile.createdAt = new Date()
          const profileRef = db.collection('profiles').doc(userid);
          profileRef.set({
              alias: profile.alias,
              avatarColor: createAvatarColor(),
              createdAt: profile.createdAt,
            })
            .then(() => {
              profileRef
                .collection('private')
                .doc('basic')
                .withConverter(profileConverter)
                .set(profile)
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        }
    })},
    /**
     * retrieve a profile from Firestore
     *
     * @param {string}  id  a user's id.
     *
     * @returns {firebase.firestore.DocumentData} returns user profile
    */
    readProfile(id) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles').doc(id);
        profileRef.get()
          .then(profileDoc => {
            if (!profileDoc.exists) {
              reject("Profile with this ID does not exist");
            } else {
              //Update the profile if it uses the older structure
              if (profileDoc.data().email) {
                // Legacy: firstname was mistakenly named username in previous versions
                const oldProfile = new Profile(profileDoc.data().activeTenant, profileDoc.data().firstname? profileDoc.data().firstname : profileDoc.data().username, profileDoc.data().surname, profileDoc.data().email, profileDoc.data().alias, profileDoc.data().birthyear, profileDoc.data().birthmonth, profileDoc.data().birthday, profileDoc.data().createdAt, profileDoc.data().likedCards, profileDoc.data().avatarColor, profileDoc.data().hideTutorial)
                profileRef.set({
                  alias: oldProfile.alias,
                  createdAt: oldProfile.createdAt,
                  avatarColor: oldProfile.avatarColor,
                  hideTutorial: oldProfile.hideTutorial
                })
                .then(() => {
                  profileRef
                    .collection('private')
                    .doc('basic')
                    .withConverter(profileConverter)
                    .set(oldProfile)
                    .then(() => {                      
                      resolve(oldProfile);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
                .catch((error) => {
                  reject(error);
                });
                // otherwise get private info
              } else {
                profileRef
                    .collection('private')
                    .doc('basic')
                    .withConverter(profileConverter)
                    .get()
                    .then((profilePrivateDoc) => {                      
                      let aReadProfile = profilePrivateDoc.data();
                      aReadProfile.id = profileDoc.id;
                      aReadProfile.alias = profileDoc.data().alias;
                      aReadProfile.createdAt = profileDoc.data().createdAt;
                      //Read public Profile information
                      profileRef
                        .collection('public')
                        .doc('basic')
                        .get()
                        .then((profilePublicDoc) => {
                          if (profilePublicDoc.exists) {
                            aReadProfile.public.email = profilePublicDoc.data().email;
                            aReadProfile.public.phone = profilePublicDoc.data().phone;
                            aReadProfile.public.phone = profilePublicDoc.data().description;
                            resolve(aReadProfile);
                            // update public profile document if it does not exist
                          } else {
                            profileRef
                              .collection('public')
                              .doc('basic')
                              .set({
                                email: aReadProfile.email,
                                phone: '',
                                description: ''
                              })
                              .then(()=> {
                                aReadProfile.public.email = aReadProfile.email;
                                aReadProfile.public.phone = '';
                                aReadProfile.public.phone = '';
                                resolve(aReadProfile);
                              })
                              .catch((error) => {
                                reject(error);
                              }); 
                          }
                        })
                        .catch((error) => {
                          reject(error);
                        });                      
                    })
                    .catch((error) => {
                      reject(error);
                    });
              }
              
            }
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates a profile in Firestore
     *
     * @param {string}  userid  a user's id.
     * @param {object}  payload  profile payload
     * @param {string}  payload.firstname a user's name
     * @param {string}  payload.surname a user's surname
     * @param {string}  payload.alias a user's alias
     * @param {string}  payload.birthyear a user's birthyear
     * @param {string}  payload.birthmonth a user's birthmonth
     * @param {string}  payload.birthday a user's birthday
     * @param {string}  [payload.activeTenant] a user's active tenant id
     * @param {string}  [payload.tenantStatus] a user's status inside tenant
     * @param {string}  [payload.avatarColor] a user's avatar color
     * @param {string}  [payload.hideTutorial] a user's tutorial preference
     */
    updateProfile(userid, payload) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles').doc(userid)
          .collection('private')
          .doc('basic')
          .update({
            firstname: payload.firstname,
            surname: payload.surname,
            username: payload.firstname === "" ? "" : "",
            avatarColor: payload.avatarColor,
            birthyear: payload.birthyear,
            birthmonth: payload.birthmonth,
            birthday: payload.birthday,
            activeTenant: payload.activeTenant,
            tenantStatus: payload.tenantStatus,
            hideTutorial: payload.hideTutorial,
          })
          .then(() => {
            resolve("Profile successfully updated!");
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates profile public information in Firestore
     *
     * @param {string}  userid  a user's id.
     * @param {object}  payload  public profile payload
     * @param {string}  payload.publicEmail a user's name
     * @param {string}  payload.phone a user's surname
     * @param {string}  payload.description a user's alias
     */
    updatePublicProfile(userid, payload) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles').doc(userid)
          .collection('public')
          .doc('basic')
          .update({
            email: payload.publicEmail,
            phone: payload.phone,
            description: payload.description,
          })
          .then(() => {
            resolve("Public profile successfully updated!");
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates user's Alias
     *
     * @param {string}  userid  a user's id.
     * @param {string}  alias a user's alias
    */
    updateAlias(userid, alias) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles').doc(userid)
          .update({alias: alias})
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
    })},
    /**
     * Updates an active tenant in user profile in Firestore
     *
     * @param {string}  userid  a user's id.
     * @param {object}  tenantId  Tenant's id
    */
    updateActiveTenant(userid, tenantId) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles')
          .doc(userid)
          .collection('private')
          .doc('basic')
          .update({
            activeTenant: tenantId,
          })
          .then(() => {
            resolve("Active tenant Set!");
          })
          .catch((error) => {
            reject(error);
            return error;
          });
    })},
    /**
     * Updates a profile's email in Firestore
     *
     * @param {string}  userid  a user's id.
     * @param {string}  newemail a user's new email adress
    */
    updateProfileEmail(userid, newemail) {
      return new Promise(function (resolve, reject){
        let profileRef = db.collection('profiles')
          .doc(userid)
          .collection('private')
          .doc('basic')
          .update({
            email: newemail,
          })
          .then(() => {
            resolve("Profile successfully updated!");
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Deletes a profile from Firestore
     *
     * @param {string}  id  a user's id.
    */
    deleteProfile(id) {
      return new Promise(function (resolve, reject){
        const profileRef = db.collection('profiles').doc(id);
        const privateRef = profileRef.collection('private');
        const cardsRef = profileRef.collection('cards');
        const tenantsRef = profileRef.collection('tenants');

        deleteCollection(privateRef)
          .then(() => {
            deleteCollection(cardsRef)
              .then(() => {
                deleteCollection(tenantsRef)
                  .then(() => {
                    profileRef.delete()
                      .then(() => {
                        resolve("Profile successfully deleted!");
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * add a Liked card to the user profile
     *
     * @param {string} userid  a user's id.
     * @param {string} tenantid  a tenants's id.
     * @param {string} cardid  a card's id.
    */
    addLikedCard(userid, tenantid, cardid) {
      return new Promise(function (resolve, reject){        
        const cardRef = db.collection('tenants').doc(tenantid).collection('cards').doc(cardid);
        const newCard = firebase.firestore.FieldValue.arrayUnion(cardRef);

        const docRef = db.collection('profiles')
          .doc(userid)
          .collection('private')
          .doc('basic')
          .update({ likedCards: newCard })
          .then(() => {
            resolve(cardRef);
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Removes non existing cards from the list of liked cards
     *
     * @param {firebase.firestore.DocumentReference[]} cardRefs  card refereneces
     * @param {string} userid  a user's id.
     * 
     * @returns {string} returns user id
    */
    cleanUpLikedCard(cardRefs,userid) {
      const docRef = db.collection('profiles').doc(userid).collection('private').doc('basic');
      var promises = [];
      cardRefs.forEach(item => {
        item.get()
          .then((doc) => {
            if (!doc.exists) {
              let removedRef = firebase.firestore.FieldValue.arrayRemove(item);
              promises.push(docRef.update({likedCards: removedRef}));
            }
          })
          .catch((error) => {
            promises.push(Promise.reject(error))
          });
        })
      return Promise.all(promises);
    },
    /**
     * Saves a tenant info in user's profile
     *
     * @param {string} userid  a user's id.
     * @param {string} tenantid  a tenant's id.
     * @param {string} displayName  a tenant's displayName.
     * @param {string} avatarColor a tenants avatar color
     */
    addTenantToUser(userid, tenantid, displayName, avatarColor) {
      return new Promise(function (resolve, reject){
        let joinedAt = new Date();
        const docRef = db.collection('profiles')
          .doc(userid)
          .collection('tenants')
          .doc(tenantid)
          .set({
            joinedAt: joinedAt,
            displayName: displayName,
            avatarColor: avatarColor,
          })
          .then(() => {
            resolve("tenant successfully added!");
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * removes a tenant from user's profile
     *
     * @param {string} userid  a user's id.
     * @param {string} tenantid  a tenant's id.
    */
    removeTenantFromUser(userid, tenantid) {
      return new Promise(function (resolve, reject){
        let joinedAt = new Date();
        const docRef = db.collection('profiles')
          .doc(userid)
          .collection('tenants')
          .doc(tenantid)
          .delete()
          .then(() => {
            resolve("tenant successfully deleted!");
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * retrieves all user's tenants
     *
     * @param {string} userid  a user's id.
    */
    readUserTenants(userid) {
      return new Promise(function (resolve, reject){
        const collRef = db.collection('profiles')
          .doc(userid)
          .collection('tenants')
          .get()
          .then((response) => {
            let someTenants = [];
            response.forEach(doc => {
              let someTenant = doc.data();
              someTenant.id = doc.id;
              someTenant.joinedAt = doc.data().joinedAt.toDate();
              someTenant.initials = Tenant.createInitials(doc.data().displayName)
              someTenants.push(someTenant);
            });
            resolve(someTenants);
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates a tenants's display Name inside user profile
     *
     * @param {string} tenantid - the Tenants's Display name
     * @param {string} userid - the Tenants's Display name
     * @param {string} displayName - the Tenants's Display name
    */
    updateTenantDisplayName(tenantid, userid, displayName) {
      return new Promise(function (resolve, reject){
        const docRef = db.collection('profiles')
          .doc(userid)
          .collection('tenants')
          .doc(tenantid)
          .update({
            displayName: displayName,
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Checks if alias is already taken
     * 
     * @param {string} alias an alias to check
     * @returns {Promise<boolean>} returns true if taken
     */
    isUserAliasTaken(alias) {
      return new Promise(function (resolve, reject){
        if (alias === "") { resolve(false)}
        else {
          const profileRef = db.collection('profiles')
            .where('alias', '==', alias)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.size > 0){
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    /**
     * Used to check if latest Stripe checkout session is processed, and set it to processed if it was not
     * 
     * @param {string} userid - the User ID
     * @returns {Promise<Object>} returns checkout session object if it was processed
     */
     isLatestCheckoutSessionProcessed(userid) {
      return new Promise(function (resolve, reject){
        const docRef = db.collection('profiles').doc(userid).collection('checkout_sessions');
        docRef.orderBy('created', 'desc').limit(1)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size > 0){
              if (querySnapshot.docs[0].data().processed){
                reject({message:'Checkout session already processed'})
              }else{
                querySnapshot.docs[0].ref.set({ processed: true},{merge: true})
                  .then(() => { resolve(querySnapshot.docs[0].data())} )
                  .catch((error) => { reject(error); });  
              }              
            } else {
              reject({message:'This user has no checkout sessions'});
            }
          })
          .catch((error) => {
            reject(error);
          });      
      });
    }
  },
};