<template>
  <v-card shaped>
    <v-card-title>
      {{ $t("$vuetify.codes.invitations") }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="allTenantCodes"
        item-key="id"
        flat
      >
        <template v-slot:[`item.properties.inviteeEmail`]="{ item }">
          <span v-if="item.properties.inviteeEmail">{{ item.properties.inviteeEmail }}</span>
          <span v-else></span>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <v-input
          class="font-weight-regular body-2  pt-4 pb-3"
          dense
          readonly

          hide-details="true">
          <input
            type="text"
            :ref="item.code"
            v-model="item.code"
            readonly
            size="25"
          />
          </v-input>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ $vuetify.lang.t('$vuetify.codes.' + item.type) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{item.status === 'pending'? `${$t('tenant.members.inviteAccepted')} ${item.properties.applicantDisplayName}` : item.status}}
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ item.created.toLocaleString() }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
<!--          <v-tooltip v-if="item.status !== 'pending'"  bottom>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-icon-->
<!--                @click="copyToClipBoard(item)"-->
<!--                color="grey"-->
<!--                lighten-4-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--                :id="`copyToClipboard-${item.id}`"-->
<!--                >mdi-content-copy</v-icon-->
<!--              >-->
<!--            </template>-->
<!--            <span>-->
<!--              {{ $vuetify.lang.t('$vuetify.codes.tipClipboard') }}-->
<!--            </span>-->
<!--          </v-tooltip>-->
          <v-tooltip v-if="item.status !== 'pending'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="deleteCodeClicked(item)"
                grey
                lighten-4
                v-bind="attrs"
                v-on="on"
                :id="`deleteInviteCode-${item.id}`"
                >mdi-delete</v-icon
              >
            </template>
            <span>
              {{ $vuetify.lang.t('$vuetify.codes.tipDelete') }}
            </span>
          </v-tooltip>
          <v-tooltip v-if="item.status !== 'pending'" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="sendEmail(item)"
                grey
                lighten-4
                v-bind="attrs"
                v-on="on"
                class="ml-2"
                >mdi-email</v-icon
              >
            </template>
            <span>
              {{ $vuetify.lang.t('$vuetify.codes.tipMail') }}
            </span>
          </v-tooltip>
          <v-tooltip
              v-if="item.status === 'pending'"
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="AcceptClicked(item)"
                  grey
                  lighten-4
                  v-bind="attrs"
                  v-on="on"
              >mdi-check</v-icon
              >
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.codes.tipAccept') }}
              </span>
          </v-tooltip>
          <v-tooltip
              v-if="item.status === 'pending'"
              bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="RejectClicked(item)"
                  grey
                  lighten-4
                  v-bind="attrs"
                  v-on="on"
              >mdi-close</v-icon
              >
            </template>
            <span>
                {{ $vuetify.lang.t('$vuetify.codes.tipReject') }}
              </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { CodeTypes } from '../store/classes/LinkrCode';
import { CodeStatus } from '../store/classes/LinkrCode';

export default {
  data() {
    return {
      validCode: true,
      pendingInvites: [],
      /* Code table headers */
      headers: [
        { text: this.$vuetify.lang.t('$vuetify.codes.inviteeEmail'), value: "properties.inviteeEmail", sortable: true, },
        // { text: this.$vuetify.lang.t('$vuetify.comment'), value: "comment", sortable: true, },
        { text: this.$vuetify.lang.t('$vuetify.status'), value: "status", sortable: true, },
        { text: this.$vuetify.lang.t('$vuetify.created'), value: "created", sortable: true, },
        {
          text: this.$vuetify.lang.t('$vuetify.codes.code'),
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: this.$vuetify.lang.t('$vuetify.actions'), value: "actions", sortable: false, },
      ],
      headersInvites: [
        {
          text: this.$vuetify.lang.t('$vuetify.codes.code'),
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: this.$vuetify.lang.t('$vuetify.displayName'), value: "properties.applicantDisplayName", sortable: true, },
        { text: this.$vuetify.lang.t('$vuetify.status'), value: "status", sortable: true, },
        { text: this.$vuetify.lang.t('$vuetify.actions'), value: "actions", sortable: false, },
      ],
      copyToClipboardColor: 'grey',
    };
  },
  computed: {
    ...mapGetters([
      'allTenantCodes',
      'currentTenant',
      'currentProfile',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchAllTenantInviteCodes',
      'generateTenantInviteCode',
      'deleteTenantCode',
      'acceptTenantInviteCode',
      'rejectCode',
      'sendTeamInviteMail',
    ]),
    generateInviteCodeClicked() {
      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleGenerateInvitation'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), 
        { color: 'green', inputLabel: 'Comment (Optional)' },
        true)
        .then((response) => {
          if (response.confirmed){
            this.generateTenantInviteCode(response.input);           
          }
        })      
    },
    async deleteCodeClicked(code){
      // Begin animation
      const icon = document.getElementById(`deleteInviteCode-${code.id}`)
      icon.classList.remove("grey--text");
      icon.classList.add('red--text')
      console.log(icon)

      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleDeleteCode'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), 
        { color: 'red' })
        .then((response) => {
          if (response.confirmed){
            this.deleteTenantCode(code);           
          }
        })

      // End animation
      await new Promise(r => setTimeout(r, 300));
      icon.classList.remove('light-red--text')
      icon.classList.add("grey--text");
    },
    async copyToClipBoard(code) {
      // Begin animation
      const icon = document.getElementById(`copyToClipboard-${code.id}`)
      icon.classList.remove("grey--text");
      icon.classList.add('light-blue--text')

      let element = this.$refs[code.code];
      element.select();
      element.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      //remove Focus
      window.getSelection().removeAllRanges()
      element.blur();

      // End animation
      await new Promise(r => setTimeout(r, 300));
      icon.classList.remove('light-blue--text')
      icon.classList.add("grey--text");
    },
    sendEmail(code){
      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleSendTeamInviteEmail'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageSendTeamInviteEmail'), 
        { color: 'green', inputLabel: 'E-Mail' },
        true)
        .then((response) => {
          if (response.confirmed){
            let aDisplayName = this.currentProfile.alias ? this.currentProfile.alias : this.currentProfile.firstname + this.currentProfile.surname;
            this.sendTeamInviteMail({code: code, to: response.input, displayName: aDisplayName, tenantName: this.currentTenant.displayName});           
          }
        })
    },
    formatDate(date) {
      let aDate = new Date(date);
      return aDate.toLocaleString;
    },
    AcceptClicked(code) {
      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleAcceptMember'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), 
        { color: 'green' })
        .then((response) => {
          if (response.confirmed){
            this.acceptTenantInviteCode(code)
          }
        })
    },
    RejectClicked(code) {
      this.$root.$confirm(
        this.$vuetify.lang.t('$vuetify.confirmDialog.titleRejectMember'), 
        this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), 
        { color: 'red' })
        .then((response) => {
          if (response.confirmed){
            this.rejectCode(code);
          }
        })
    }
  },
  watch: {
    allTenantCodes(value) {
      if (value) {
        this.pendingInvites.length = 0;
        if (value.length > 0) {
          value.forEach( (item) => {
            if (item.type === CodeTypes.INVITE && item.status != CodeStatus.OPEN) {
              this.pendingInvites.push(item)
            }
          })
        }
      }
    },
  },
}
</script>