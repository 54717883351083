<template lang="pug">
  v-container(fluid)
    v-row(align="center" justify="center")
      v-col(cols="12" align="center" :justify="$vuetify.breakpoint.sm? 'left' : 'center'")
        Logo
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="4" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
          v-row(align="center" justify="center" class="pl-12 pr-12").px-5.pt-5.pb-0
            v-col(cols="12")
              v-card(flat height="200" )
                v-card-text
                  div(id="lottie-confirmation")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light Confirmation
            v-col(cols="8" class="text-center")
              h2.text-subtitle-2.font-weight-regular {{ description? description : $vuetify.lang.t('$vuetify.register.feedback.description') }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/login')") {{ $vuetify.lang.t('$vuetify.register.feedback.return') }}
            v-col(cols="12")
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form
          v-row(align="center" justify="center")
            v-col(cols="8" class="text-center")
              v-col(cols="12")
                v-card(flat height="200" )
                  v-card-text
                    div(id="lottie-confirmation-mobile")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light Confirmation
            v-col(cols="8" class="text-center mt-0 pt-0")
              h2.text-subtitle-2.font-weight-regular {{ description? description :  $vuetify.lang.t('$vuetify.register.feedback.description') }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/login')") {{ $vuetify.lang.t('$vuetify.register.feedback.return') }}
            v-col(cols="12")
</template>

<script>

import Logo from "../core/Logo";
export default {
  name: "EmailSendFeedback",
  components: {Logo},
  data() {
    return {
      loading: false,
    };
  },
  props: {
    description: {
      type: String,
      default: "",
      required: false,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      const svgContainerMobile = document.getElementById('lottie-confirmation-mobile');
      const animItemMobile = bodymovin.loadAnimation({
        wrapper: svgContainerMobile,
        animType: 'svg',
        loop: false,
        path: '/animations/4964-check-mark-success-animation.json'
      });
    } else {
      const svgContainer = document.getElementById('lottie-confirmation');
      const animItem = bodymovin.loadAnimation({
        wrapper: svgContainer,
        animType: 'svg',
        loop: false,
        path: '/animations/4964-check-mark-success-animation.json'
      });
    }
  }
}
</script>

<style scoped>
.filter-primary {
  filter: invert(24%) sepia(27%) saturate(7097%) hue-rotate(198deg) brightness(99%) contrast(101%);
}

#lottie-confirmation {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#lottie-confirmation-mobile {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>