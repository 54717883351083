<template>
  <v-dialog 
    max-width="600px" 
    v-model="dialogVmodel" 
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar>
        <v-toolbar-title>
          {{ editing ? $vuetify.lang.t("$vuetify.link.editLink") : $vuetify.lang.t("$vuetify.link.createLink") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn 
          class="float-right" 
          icon
          :disabled="!valid"
          @click="submit"
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.mobile"
          class="float-right mr-2"
          icon
          @click="cancelDialog"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form
          class="px-3"
          ref="addnewlinkform"
          v-model="valid"
          lazy-validation
        >
          <v-btn
            icon
            :href="href"
            target="_blank"
            :disabled="href && (href.length < 5)"
            class="mx-2 mt-3 float-right"
            color="green"
            :loading="loading"
          >
            <v-icon>mdi-file-find </v-icon>
          </v-btn>
          <v-text-field
            autofocus
            v-model="href"
            counter="256"
            v-bind:label="$vuetify.lang.t('$vuetify.link.labelLink')"
            ref="hreffield"
            :disabled="editing && currentEditedLocalCopy.locked"
            class="mt-5"
            clearable
            @click:clear="hrefCleared"
            required
            :error-messages="hrefErrors"
            :tabindex="1"
            @input="$v.href.$touch()"
            @blur="$v.href.$touch(); hrefLostfocus();"
          ></v-text-field>
          <v-text-field
            v-model="currentEditedLocalCopy.title"
            counter="256"
            v-bind:label="$vuetify.lang.t('$vuetify.link.labelTitle')"
            :disabled="editing && currentEditedLocalCopy.locked"
            required
            :error-messages="titleErrors"
            :tabindex="2"
            @input="$v.currentEditedLocalCopy.title.$touch()"
            @blur="$v.currentEditedLocalCopy.title.$touch()"
          ></v-text-field>
          <v-select
            :items="allTypes"
            v-bind:label="$vuetify.lang.t('$vuetify.link.labelType')"
            v-model="currentEditedLocalCopy.type"
            :disabled="editing && currentEditedLocalCopy.locked"
            item-text="label"
            item-value="label"
            return-object
            @change="typeSelected"
            :tabindex="3"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                label
                outlined
                small
                :tabindex="4"
              >
                <v-icon 
                  small 
                  left
                >
                  {{ data.item.icon }}
                </v-icon>
                {{ data.item.label }}
              </v-chip>
            </template>
            <template 
              v-slot:item="data"
            >
              <v-list-item-content>
                <v-list-item-title
                  color="red"
                  v-html="data.item.label"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
          <v-autocomplete
            v-model="currentEditedLocalCopy.labels"
            :items="computedLabels"
            chips
            v-bind:label="$vuetify.lang.t('$vuetify.link.labelSelect')"
            :disabled="editing && currentEditedLocalCopy.locked"
            item-text="label"
            item-value="label"
            return-object
            multiple
            hide-selected
            clearable
            :tabindex="5"
          >
            <template 
              v-slot:selection="data"
            >
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                label
                outlined
                small
                :color="data.item.color"
                @click="data.select"
                @click:close="removeLabel(data.item)"
              >
                {{ data.item.label }}
              </v-chip>
            </template>
            <template 
              v-slot:item="data"
            >
              <v-list-item-content>
                <v-list-item-title 
                  outlined 
                  v-html="data.item.label"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
<!--          <TagDropDown-->
<!--            @tags-changed="updateTags"-->
<!--            :selectedTags="currentEditedLocalCopy.tags"-->
<!--            v-bind:customLabel="$vuetify.lang.t('$vuetify.link.tagLabel')" -->
<!--            v-bind:customHint="$vuetify.lang.t('$vuetify.link.tagHint')" -->
<!--            :lockstatus="editing && currentEditedLocalCopy.locked"-->
<!--          ></TagDropDown>-->
          <v-textarea
            class="pb-0 mb-0"
            v-model="currentEditedLocalCopy.description"
            counter="256"
            rows="3"
            v-bind:label="$vuetify.lang.t('$vuetify.link.labelDescription')"
            :disabled="editing && currentEditedLocalCopy.locked"
            :error-messages="descriptionErrors"
            :tabindex="6"
            @input="$v.currentEditedLocalCopy.description.$touch()"
            @blur="$v.currentEditedLocalCopy.description.$touch()"
          ></v-textarea>
          <small
            class="text-center"
          >
            {{ $vuetify.lang.t('$vuetify.requiredFieldIndication') }}
          </small>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="cancelDialog"
            class="mx-0 mt-3"
            :loading="loading"
            :tabindex="7"
            >
            {{ $vuetify.lang.t('$vuetify.close') }}
          </v-btn>
          <v-btn
            @click="submit"
            class="mx-0 mt-3 float-right"
            color="primary"
            rounded
            :loading="loading"
            :disabled="this.$v.$invalid"
            :tabindex="8"
            >
            {{ this.$vuetify.lang.t('$vuetify.save') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Link from "../store/classes/Link.js";
import LinkType from "../store/classes/LinkType"

export default {
  mixins: [validationMixin],
  validations: {
    href: { 
      required, 
      minLength: minLength(3),
      maxLength: maxLength(256), 
    },
    currentEditedLocalCopy: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256), 
      },
      description: {
        maxLength: maxLength(256), 
      },
    }
  },
  data() {
    return {
      href: "",
      typeSelectedByUser: false,
      currentEditedLocalCopy: Link.empty(),
      editing: false,
      inputRulesName: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.rules.fieldnotification'),
        (v) => (v && v.length >= 3) || this.$vuetify.lang.t('$vuetify.rules.fieldvalidation'),
      ],
      inputRulesHref: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.rules.fieldnotification'),
        (v) => (v && v.length >= 3) || this.$vuetify.lang.t('$vuetify.rules.fieldvalidation'),
        (v) => (v && (this.typeSelectedByUser || (/^\S+$/.test(v) || this.currentEditedLocalCopy.type.name=="phone")) ) || this.$vuetify.lang.t('$vuetify.rules.nowhitespaces'),
        (v) => (v && (this.typeSelectedByUser || this.currentEditedLocalCopy.type.regexToComplete.test(v))) || "Wrong Format:"+this.currentEditedLocalCopy.type.regexToComplete,
      ],
      loading: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentLink",
      "showEditLinkDialog",
      "allCustomLabels",
      'allDefaultLabels',
      "allTypes",
      "currentUser",
      "currentProfile",
      "isPrivateMode",
    ]),
    dialogVmodel: {
      get() {
        return this.showEditLinkDialog;
      },
      set(val) {
        this.closeEditLinkDialog;
      },
    },
    hrefErrors() {
      const errors = []
      if (!this.$v.href.$dirty) return errors
      !this.$v.href.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.href.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.href.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))

      !(this.typeSelectedByUser || (/^\S+$/.test(this.href) || this.currentEditedLocalCopy.type.name=="phone")) &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.nowhitespaces'));

      !(this.typeSelectedByUser || this.currentEditedLocalCopy.type.regexToComplete.test(this.href)) &&
      errors.push("Wrong Format:"+this.currentEditedLocalCopy.type.regexToComplete);

      return errors
    },
    titleErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.title.$dirty) return errors
      !this.$v.currentEditedLocalCopy.title.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.title.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.title.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.description.$dirty) return errors
      !this.$v.currentEditedLocalCopy.description.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    computedLabels() {
      if (this.isPrivateMode) {
        return this.allDefaultLabels;
      } else {
        return this.allCustomLabels;
      }
    },
  },
  methods: {
    ...mapActions([
      "addLink",
      "closeEditLinkDialog",
      "finishEditingLink",
      "detectTypeFromURL"
    ]),
    submit() {
      this.loading = true;

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.isValidForm()) {
        this.currentEditedLocalCopy.setHref(this.href);
        this.currentEditedLocalCopy.locked = false;
        //store cards in active tenant if not in private mode
        if (!this.isPrivateMode) {
          this.currentEditedLocalCopy.tenant = this.currentProfile.activeTenant;            
        }
        if (!this.currentEditedLocalCopy.author){
          this.currentEditedLocalCopy.author = this.currentUser.uid;
        } else {
          this.currentEditedLocalCopy.modifiedBy = this.currentUser.uid;
        }
        if (!this.currentEditedLocalCopy.labels)
        {
          this.currentEditedLocalCopy.labels = [];
        }
        this.addLink(this.currentEditedLocalCopy);
        this.finishEditingLink();
        this.currentEditedLocalCopy = Link.empty();
        this.href = "";
      }
      this.loading = false;
    },
    updateTags(tags) {
      this.currentEditedLocalCopy.tags = tags;
    },
    isValidForm() {
      if (this.$refs.addnewlinkform) {
        return this.$refs.addnewlinkform.validate();
      }
      return false;
    },
    compareLabels(a, b) {
      return a.name === b.name;
    },
    typeSelected() {
      this.typeSelectedByUser = true;
      this.href = this.currentEditedLocalCopy.type.reformatURL(this.href);
      this.$refs.addnewlinkform.validate();
    },
    cancelDialog() {
      this.$refs.addnewlinkform.reset();
      this.$v.$reset();
      this.currentEditedLocalCopy = Link.empty();
      this.href = "";
      this.closeEditLinkDialog();
      this.typeSelectedByUser = false;
    },
    removeLabel(item) {
      const index = this.currentEditedLocalCopy.labels.findIndex(
        (el) => el.id === item.id
      );
      if (index >= 0) this.currentEditedLocalCopy.labels.splice(index, 1);
    },
    hrefLostfocus() {
      if (!this.typeSelectedByUser){
        this.detectTypeFromURL({url: this.href})
          .then((result) => {
            this.currentEditedLocalCopy.type = result;
            this.href = this.currentEditedLocalCopy.type.reformatURL(this.href);
            this.$refs.addnewlinkform.validate();
          })
      }
    },
    hrefCleared () {
      this.href="";
      this.currentEditedLocalCopy.type = LinkType.undefined();
      this.$refs.addnewlinkform.validate();
      this.typeSelectedByUser = false;
    }
  },
  watch: {
    currentLink: function(val) {
      if (val) {        
        this.currentEditedLocalCopy = val.copy();
        this.href = this.currentEditedLocalCopy.href;
        this.editing = true;
        this.typeSelectedByUser = true;
      } else {
        this.$refs.addnewlinkform.reset();
        this.currentEditedLocalCopy = Link.empty();
        this.href = "";
        this.editing = false;
        this.typeSelectedByUser = false;
      }
    },
  },
};
</script>
