<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card shaped>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titleCurrentEmail')}}</v-card-title>
          <v-card-text>
            <v-form
                ref="updateemailform"
                v-model="validEmail"
                class="px-3"
                @submit="UpdateEmailClicked"
                onSubmit="return false;"
            >
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="email"
                          :label="$vuetify.lang.t('$vuetify.profile.labelEmail')"
                          :filled="changedEmailFields"
                          @input="$v.email.$touch()"
                          :error-messages="emailErrors"
                          @blur="$v.email.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                          color="primary"
                          :disabled="!validEmail || !changedEmailFields"
                          @click="UpdateEmailClicked"
                      >
                        {{ $vuetify.lang.t('$vuetify.profile.buttonUpdateEmail')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card shaped>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.titleChangePassword')}}</v-card-title>
          <v-card-text>
            <v-form
                ref="updatepasswordform"
                v-model="validPassword"
                class="px-3"
                lazy-validation
            >
              <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="oldpassword"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          :label="$vuetify.lang.t('$vuetify.profile.labelOldpassword')"
                          autocomplete="current-password"
                          :hint="$vuetify.lang.t('$vuetify.rules.passwordHint')"
                          :filled="changedOldPassword"
                          @click:append="show1 = !show1"
                          @input="changedOldPassword = true; $v.oldpassword.$touch()"
                          :error-messages="oldpasswordErrors"
                          @blur="$v.oldpassword.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="newpassword"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          :label="$vuetify.lang.t('$vuetify.profile.labelNewpassword')"
                          autocomplete="new-password"
                          :hint="$vuetify.lang.t('$vuetify.rules.passwordHint')"
                          :filled="changedNewPassword"
                          @click:append="show2 = !show2"
                          @input="changedNewPassword = true; $v.newpassword.$touch()"
                          :error-messages="newpasswordErrors"
                          @blur="$v.newpassword.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="repeatnewpassword"
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'"
                          :label="$vuetify.lang.t('$vuetify.profile.labelRepeatnewpassword')"
                          :filled="changedRepeatNewPassword"
                          autocomplete="new-password"
                          :hint="$vuetify.lang.t('$vuetify.rules.passwordHint')"
                          @click:append="show3 = !show3"
                          @input="changedRepeatNewPassword = true; $v.repeatnewpassword.$touch()"
                          :error-messages="repeatnewpasswordErrors"
                          @blur="$v.repeatnewpassword.$touch()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                          color="primary"
                          :disabled="!validPassword || !changedPasswordFields"
                          @click="ChangePasswordClicked"
                      >
                        {{ $vuetify.lang.t('$vuetify.profile.buttonChangePassword')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card shaped>
          <v-card-title>{{ $vuetify.lang.t('$vuetify.profile.dangerZone.title')}}</v-card-title>
          <v-card-text>
            {{ $vuetify.lang.t('$vuetify.profile.dangerZone.disclaimer')}}
          </v-card-text>
          <v-card-text>
            <v-btn
                color="primary"
                href="mailto:sales@yndooo.com"
            >
              {{ $vuetify.lang.t('$vuetify.profile.dangerZone.contactSupport')}}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, between } from 'vuelidate/lib/validators'

export default {
  name: "UserAccount",
  mixins: [validationMixin],
  validations: {
    username: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(256),
    },
    surname: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(256),
    },
    alias: {
      maxLength: maxLength(256),
    },
    birthyear: {
      between: between(1900, 2020),
    },
    email: { required, email },
    oldpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
    newpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
    repeatnewpassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
  },
  data() {
    return {
      validProfile: true,
      validEmail: true,
      validPassword: true,
      email: "",
      oldpassword: "",
      newpassword: "",
      repeatnewpassword: "",
      changedOldPassword: false,
      changedNewPassword: false,
      changedRepeatNewPassword: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "currentProfile",
    }),
    changedEmailFields() {
      return this.email && this.email !== this.profile.email
    },
    changedPasswordFields() {
      return this.oldpassword && this.newpassword && this.repeatnewpassword
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.email.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
    oldpasswordErrors() {
      const errors = []
      if (!this.$v.oldpassword.$dirty) return errors
      !this.$v.oldpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.oldpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.oldpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      return errors
    },
    newpasswordErrors() {
      const errors = []
      if (!this.$v.newpassword.$dirty) return errors
      !this.$v.newpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.newpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.newpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      !(this.newpassword != this.oldpassword) && errors.push(this.$vuetify.lang.t('$vuetify.rules.oldnewpassnotsame'))
      return errors
    },
    repeatnewpasswordErrors() {
      const errors = []
      if (!this.$v.repeatnewpassword.$dirty) return errors
      !this.$v.repeatnewpassword.minLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.repeatnewpassword.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.repeatnewpassword.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      !(this.newpassword === this.repeatnewpassword) && errors.push(this.$vuetify.lang.t('$vuetify.rules.passnotmatch'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "updateProfile",
      "updateUserAlias",
      "updateEmail",
      "updatePassword",
      "StoreNotification",
      "removeTenantMember",
      "isUserAliasTaken",
    ]),
    cleanUpProfile: function() {
      this.changedProfileFields = false;
      this.changedEmailFields = false;
    },
    SaveChangesClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdateProfile'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.profileform.validate()){
                if (this.filledAlias){
                  this.isUserAliasTaken(this.alias)
                      .then( isTaken => {
                        if (isTaken) {
                          this.StoreNotification({code: "user/alias-taken", type: "info"});
                          this.alias = this.profile.alias;
                        } else {
                          this.updateUserAlias(this.alias)
                        }
                      })
                }
                if (this.filledName || this.filledSurname || this.filledYear || this.filledMonth || this.filledDay) {
                  this.updateProfile(this.$data)
                }
                this.cleanUpProfile();
              }
            } else {
              //Revert changes
              this.username = this.profile.username;
              this.surname = this.profile.surname;
              this.alias = this.profile.alias;
              this.birthyear = this.profile.birthyear;
              this.birthmonth = this.profile.birthmonth;
              this.birthday = this.profile.birthday;
              this.cleanUpProfile();
            }
          })
    },
    cleanUpEmail: function() {
      this.changedEmailFields = false;
      this.filledEmail = false;
    },
    UpdateEmailClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleUpdateEmail'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageUpdateEmail'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.updateemailform.validate()) {
                this.updateEmail({ email: this.email })
                    .then (() => {
                      this.cleanUpEmail();
                    })
              }
            } else {
              //Revert changes
              this.email = this.profile.email;
              this.cleanUpEmail();
            }
          })
    },
    cleanUpPassword: function() {
      this.changedPasswordFields = false;
      this.$refs.updatepasswordform.reset();
    },
    ChangePasswordClicked: function() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleChangePassword'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'blue' })
          .then((response) => {
            if (response.confirmed){
              if (this.$refs.updatepasswordform.validate()) {
                this.updatePassword({
                  email: this.email,
                  oldpassword: this.oldpassword,
                  newpassword: this.newpassword,
                })
                    .then (() => {
                      this.cleanUpPassword();
                    })
              }
            } else {
              this.cleanUpPassword();
            }
          })
    },
    leaveTenantClicked(tenant) {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleLeaveTenant'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), { color: 'red' })
          .then((response) => {
            if (response.confirmed){
              this.removeTenantMember( {userId: this.profile.id, tenantId: tenant.id})
            }
          })
    },
    setFieldValues() {
      if (this.profile) {
        this.email = this.profile.email;
      }
    }
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    profile(value) {
      if (value) {
        this.setFieldValues();
      }
    },
  },
};
</script>
