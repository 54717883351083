<template lang="pug">
  v-menu(
    v-if="signedIn && !$vuetify.breakpoint.mobile"
    value="menuOpen"
    close-on-click
    close-on-content-click
    :fullscreen="$vuetify.breakpoint.xsOnly"
    offset-y)
    template(v-slot:activator="{ on, attrs }")
      v-list
        v-list-item(v-bind="attrs" v-on="on")
          v-list-item-avatar(:color="isPrivateMode? 'primary' : currentTenant && currentTenant.avatarColor? currentTenant.avatarColor : '#ffb84a'" class="justify-space-around")
            span(class="white--text headline") {{ (isPrivateMode) ? 'PM' : currentTenant ? currentTenant.initials : '' }}
          v-list-item-content
            v-list-item-title(
              v-text="(isPrivateMode) ? $vuetify.lang.t('$vuetify.navBar.privateMode') : tenantDisplayName"
              class="font-weight-bold")
          v-list-item-action
            v-icon(size="32") mdi-chevron-down
    v-list(v-if="currentProfile" class="overflow-y-auto" :style="{ 'max-height': '400px' }")
      v-list-item(class="text-center font-weight-bold"
        v-if="!isPrivateMode"
        @click="SwitchToPrivateModeClicked")
        v-list-item-avatar(color="primary " class="justify-space-around")
          span(class="white--text headline") PM
        v-list-item-content
          v-list-item-title(class="text-left") {{ $vuetify.lang.t('$vuetify.navBar.privateMode') }}
      v-list-item(
        v-for="aTenant in currentProfile.tenants"
        :key="aTenant.id"
        @click="switchToTenantClicked(aTenant.id)"
        class="font-weight-bold"
        :disabled="currentTenant ? (!isPrivateMode && currentTenant.id === aTenant.id) : false")
        v-list-item-avatar(:color="aTenant.avatarColor? aTenant.avatarColor : '#ffb84a'" class="justify-space-around")
          span(class="white--text headline") {{ aTenant.initials }}
        v-list-item-content
          v-list-item-title(v-text="aTenant.displayName" class="text-left")
      v-divider
      v-list-item(class="text-center justify-center align-center pt-2 pl-0 pr-0 ml-0 mr-0")
        CreateTenantDialog
      v-list-item(class="text-center justify-center align-center pl-0 pr-0 ml-0 mr-0")
        JoinTenantDialog
  v-dialog(v-else v-model="dialogue" fullscreen persistent height="100%" z-index="9999")
    template(v-slot:activator="{ on, attrs }")
      v-list
        v-list-item(v-bind="attrs" v-on="on")
          v-list-item-avatar(:color="isPrivateMode? 'primary' : currentTenant && currentTenant.avatarColor? currentTenant.avatarColor : '#ffb84a'" class="justify-space-around")
            span(class="white--text headline") {{ (isPrivateMode) ? 'PM' : currentTenant ? currentTenant.initials : '' }}
          v-list-item-content
            v-list-item-title(
              v-text="(isPrivateMode) ? $vuetify.lang.t('$vuetify.navBar.privateMode') : tenantDisplayName"
              class="font-weight-bold")
          v-list-item-action
            v-icon(size="32") mdi-chevron-down
    v-list(v-if="currentProfile" class="overflow-y-auto")
      v-list-item
        v-list-item-title Select your team
      v-list-item(class="text-center font-weight-bold"
        v-if="!isPrivateMode"
        @click="SwitchToPrivateModeClicked")
        v-list-item-avatar(color="primary " class="justify-space-around")
          span(class="white--text headline") PM
        v-list-item-content
          v-list-item-title(class="text-left") {{ $vuetify.lang.t('$vuetify.navBar.privateMode') }}
      v-list-item(
        v-for="aTenant in currentProfile.tenants"
        :key="aTenant.id"
        @click="switchToTenantClicked(aTenant.id)"
        class="font-weight-bold"
        :disabled="currentTenant ? (!isPrivateMode && currentTenant.id === aTenant.id) : false")
        v-list-item-avatar(:color="aTenant.avatarColor? aTenant.avatarColor : '#ffb84a'" class="justify-space-around")
          span(class="white--text headline") {{ aTenant.initials }}
        v-list-item-content
          v-list-item-title(v-text="aTenant.displayName" class="text-left")
      v-divider
      v-list-item(class="text-center justify-center align-center pt-2 pl-0 pr-0 ml-0 mr-0")
        CreateTenantDialog
      v-list-item(class="text-center justify-center align-center pl-0 pr-0 ml-0 mr-0")
        JoinTenantDialog
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import CreateTenantDialog from "@/components/tenant/creation/CreateTenantDialog";
import JoinTenantDialog from "@/components/tenant/joining/JoinTenantDialog";

export default {
  name: "TenantSelection",
  components: {JoinTenantDialog, CreateTenantDialog},
  data() {
    return {
      tenantDisplayName: "",
      dialogue: false,
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "signedIn",
      "filter",
      "currentTenant",
      "currentProfile",
      "isPrivateMode",
      "sortStrategies",
      "currentTenant",
      "currentProfile",
    ]),
    filteredTenants() {
      return (this.currentProfile && this.currentProfile.tenants) ? this.currentProfile.tenants.filter((item) => {
        return item.companyName !== this.currentTenant.companyName
      }) : []
    },
    userInitials() {
      const name = !this.currentProfile ? '' : this.currentProfile.alias ? this.currentProfile.alias : this.currentProfile.email;

      const segments = name.split(" ").filter(Boolean);
      const letters = []

      for (let i = 0; i < segments.length; i++) {
        letters.push(segments[i].charAt(0))
      }

      const initials = letters.join('');

      return initials? initials : ''
    },
    userAvatarColor() {
      return (this.currentProfile && this.currentProfile.avatarColor)? this.currentProfile.avatarColor : '#ffb84a'
    },
  },
  methods: {
    ...mapActions([
      "openFilter",
      "closeFilter",
      "newLink",
      "appLoggedOut",
      "readProfile",
      "SwitchToPrivateMode",
      "SetActiveTenant",
      "searchCards",
      "createTenant",
    ]),
    /**
     * Redirects to the Dashboard
     */
    gotoDashboard() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    /**
     * Switches to private mode and goes to dashboard
     */
    SwitchToPrivateModeClicked() {
      this.SwitchToPrivateMode();

      if(this.$vuetify.breakpoint.mobile) { this.dialogue = false }

      this.gotoDashboard();
    },
    /**
     * Switches to selected tenant and goes to dashboard
     * @param {string} tenantId Tenant's ID
     */
    switchToTenantClicked(tenantId) {
      this.SetActiveTenant(tenantId);

      if(this.$vuetify.breakpoint.mobile) { this.dialogue = false }

      this.gotoDashboard();
    },
  },
  watch: {
    currentNavItem() {
      this.drawer = false;
    },
    currentTenant(val) {
      if (val) {
        this.tenantDisplayName = val.displayName;
      } else {
        this.tenantDisplayName = "";
      }
    },
    open(newVal){
      this.drawer = newVal
    }
  },
}
</script>

<style scoped>

</style>