<template lang="pug">
  v-container(fluid)
    v-btn(to="/" icon :width="$vuetify.breakpoint.mobile? 150 : 300"
    v-ripple="{ class: 'transparent--text', background: 'transparent' }"
    class="text-left justify-left" mb-0 pb-0)
      v-img(v-if="$vuetify.theme.dark" src="@/assets/images/logo/logoWhite.svg" alt="images.smartphone.alt"
      aspect-ratio="1.0" contain max-height="35" class="text-left justify-left"
      @load="logoLoaded = true")
      v-img(v-else src="@/assets/images/logo/logoBlack.svg" alt="images.smartphone.alt" aspect-ratio="1.0" contain
      max-height="35" class="text-left justify-left" @load="logoLoaded = true")
</template>

<script>
export default {
name: "Logo"
}
</script>

<style scoped>
.v-btn--active.no-active::before {
  background-color: transparent !important;
}

.linkAsText {
  color: var(--secondary--text);
  text-decoration: none;
}

.v-btn::before {
  display: none;
}

.v-btn--active {
  font-weight: bold;
}
</style>