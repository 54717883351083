import { db } from '../../src/main'
import LinkrCode, { CodeStatus } from '../../src/store/classes/LinkrCode'
import { CodeTypes } from '../../src/store/classes/LinkrCode';

const CodeConverter = {
  /**
   * converts a LinkrCode class object
   * @param {LinkrCode}  code  a LinkrCode class object
  */
  toFirestore: function(code) {
    return {
      code: code.code,
      type: code.type,
      status: code.status,
      comment: code.comment,
      created: code.created,
      properties: code.properties,
    }
  },
  /**
   * Converts Firestore document data into a LinkrCode class object
   * @param {firebase.firestore.QuerySnapshot}  snapshot  retrieved Document data
   * @param {object}  snapshot  query options
   * 
   * @returns {LinkrCode}
  */
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    let aCode = new LinkrCode(data.type, data.code, data.created.toDate(), data.comment);
    aCode.properties = data.properties;
    aCode.status = data.status;    
    return aCode;
  }
}
/**
 * asynchronosly reads multiple codes from firestore
 *
 * @param {firebase.firestore.CollectionReference}  collRef  Firebase collection reference
 * 
 * @returns {LinkrCode[]}
*/
async function ReadCodes(collRef) {
  let snapshot = await collRef.withConverter(CodeConverter).get();
  let allCodes = [];
  if (!snapshot.empty) {
    snapshot.forEach(doc => {
      let aCode = doc.data();
      aCode.id = doc.id;
      allCodes.push(aCode);    
    });
  }  
  return allCodes;
}

export default {
  methods: {
    /**
     * Creates a new code in Firestore
     *
     * @param {LinkrCode} code - the code object
     *
     * @returns {LinkrCode} returns an updated code object with id
    */
    createCode(code) {
      return new Promise(function (resolve, reject){
        let collRef = null;
        switch(code.type) {
          case CodeTypes.INVITE:
            collRef = db.collection('tenants').doc(code.properties.tenantId).collection('inviteCodes');
            break;
          default :
            reject({message: 'Unknown code type'})
        }
        const globalRef = db
          .collection('globalCodesRef')
          .add({
            tenantId: code.properties.tenantId,
          })
          .then((newGlobalDoc) => {
            code.code = newGlobalDoc.id;
            collRef
              .doc(newGlobalDoc.id)
              .withConverter(CodeConverter)
              .set(code)
              .then(() => {
                code.id = newGlobalDoc.id;
                resolve(code);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    /**
     * Creates a personal code copy in Firestore
     *
     * @param {LinkrCode} code - the code object
     *
     * @returns {LinkrCode} returns an updated code object with id
    */
    createPersonalCodeCopy(code) {
      return new Promise(function (resolve, reject){
        const collRef = db
          .collection('profiles')
          .doc(code.properties.applicantId)
          .collection('codes')
          .doc(code.id)
          .set(CodeConverter.toFirestore(code))
          .then(() => {
            resolve(code);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    /**
     * Get all invitation codes
     *
     * @param {string} tenantId - the Tenants's ID
     *
     * @returns {LinkrCode []} returns all invite codes
    */
    getAllInviteCodes(tenantId) {
      return new Promise(function (resolve, reject){
        const collRef = db.collection('tenants').doc(tenantId).collection('inviteCodes');
        try {
          let aResult = ReadCodes(collRef);
          resolve(aResult);
        } catch (error) {
          reject(error);
        }
    })},
    /**
     * Get all invitation codes
     *
     * @param {string} userId - the user's ID
     * @returns {LinkrCode []} returns all personal code copies
    */
    getAllPersonalCodeCopies(userId) {
      return new Promise(function (resolve, reject){
        const collRef = db.collection('profiles').doc(userId).collection('codes');
        try {
          let aResult = ReadCodes(collRef);
          resolve(aResult);
        } catch (error) {
          reject(error);
        }
    })},
    /**
     * Delete a code
     *
     * @param {LinkrCode} code - the code object
    */
    deleteCode(code) {
    return new Promise(function (resolve, reject){
      let globalDocRef = null;
      let docRef = null;
      if (code.properties.isPersonalCopy) {
        docRef = db.collection('profiles').doc(code.properties.applicantId).collection('codes').doc(code.id);
      } else {
        globalDocRef = db.collection('globalCodesRef').doc(code.code);
        switch(code.type) {
          case CodeTypes.INVITE:
            docRef = db.collection('tenants').doc(code.properties.tenantId).collection('inviteCodes').doc(code.id);
            break;
        }   
      }
      docRef
        .delete()
        .then(() => {
          if (!code.properties.isPersonalCopy){
            globalDocRef
              .delete()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        });
    })},
    /**
     * Updates a code in firestore
     *
     * @param {LinkrCode} code - the code object
     * @param {String[]} [applicantTenantsIds] a list of tenant ids that the applicant belongs to
    */
    updateCode(code, applicantTenantsIds) {
      return new Promise(function (resolve, reject){
        const docRefPersonal = code.properties.applicantId ? 
          db.collection('profiles').doc(code.properties.applicantId).collection('codes').doc(code.id):
          null;
        let docRef = null          
        switch(code.type) {
          case CodeTypes.INVITE:
            // check if the user already belongs to this tenant
            if (applicantTenantsIds && applicantTenantsIds.includes(code.properties.tenantId)){
              return reject("code/already-a-member")
            }
            docRef = db.collection('tenants').doc(code.properties.tenantId).collection('inviteCodes').doc(code.id);
            break;
        }
        docRef
          .update(CodeConverter.toFirestore(code))
          .then(() => {
            //Also update users code copy if status has changed
            if (docRefPersonal && code.status != CodeStatus.PENDING) {
              code.properties.isPersonalCopy = true;
              docRefPersonal
                .get()
                .then((doc) => { 
                  if (doc.exists) {
                    docRefPersonal.update(CodeConverter.toFirestore(code))
                    .then(() => {
                      resolve();
                    })
                    .catch((error) => {
                      reject(error);
                    });
                  } else {
                    resolve();
                  }
                })                
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Retrieves a code
     *
     * @param {string} codeId - the id of a code
     * @returns {LinkrCode []} returns a code
    */
    RetrieveCode(codeId) {
      return new Promise(function (resolve, reject){
        const globalDocRef = db.collection('globalCodesRef').doc(codeId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const snapshot = db
                .collection('tenants')
                .doc(doc.data().tenantId)
                .collection('inviteCodes')
                .withConverter(CodeConverter)
                .where('code', '==', doc.id)                
                .get()
                .then( (response) => {
                  if (response.empty) {
                    reject('code/not-found')
                  } else {
                    //TODO maybe add a check if more than one code matched
                    response.forEach(doc => {
                      let aRetrievedCode = doc.data();
                      aRetrievedCode.id = doc.id;
                      resolve(aRetrievedCode);
                    });
                  }
                })
                .catch((error) => {
                  reject(error);
                });
            }else{
              reject('code/not-found')
            }
          })
          .catch((error) => {
            reject(error);
          });
    })},
  }
};