import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import {i18n} from './plugins/vuetify';
import firebase from '../firebase/firebaseInit';
import qrCode from 'qrcode';


// Initialize database
export const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true }); 

// Initialize performance monitoring
export const firePerf = firebase.performance();
Vue.prototype.$performance = firePerf

// Initialize analytics
export const fireAnalytics = firebase.analytics();
Vue.prototype.$fireAnalytics = fireAnalytics

// Initialize remote config
export const fireRemoteConfig = firebase.remoteConfig();
Vue.prototype.$remoteConfig = fireRemoteConfig
fireRemoteConfig.settings.minimumFetchIntervalMillis = 3600000;
fireRemoteConfig.defaultConfig = {
  "promo_easter_enabled": false
};

// Initialize QR code library
export const qrcode = qrCode;

// Configure vue instance
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
