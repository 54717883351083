<template lang="pug">
  v-container.fill-height
    transition(name="layout" mode="out-in" enterActiveClass="animated fadeInDown" leaveActiveClass="animated fadeOutDown")
      v-row(class="justify-center align-center text-center")
        v-col(cols="12")
          v-img(src="@/assets/lostInSpace.svg" aspect-ratio="0.5" contain max-height="400")
        v-col(cols="8")
          h1 {{ $t('error.404.title') }}
        v-col(cols="8")
          p.title.font-weight-lighter {{ $t('error.404.description') }}
        v-col.text-center(cols="12" md="12")
          v-btn(text large color="primary" @click="$router.back()")
            v-icon mdi-arrow-left
            | {{ $t('error.return') }}
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Error",
  computed: {
    ...mapGetters({
      profile: "currentProfile",
    }),
  },
  methods: {
    ...mapActions([
      'appLoggedIn',
    ]),
  },
};
</script>

<style scoped></style>