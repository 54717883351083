const  state = {
  tags: []
};


const getters = {
  allTags(){
    return state.tags
  },
  filteredTags (tagFragment)  {
      //load original tags
      if(tags.length == 0){
        actions.setTags();
      }
      //filter those for the fragment
      this.tags.filter(tag => tag.includes(tagFragment));
  }
};

const actions = {
   fetchTags(context) {
      context.rootState.links.links.forEach((item, i) => {
        if(item.tags){
          item.tags.forEach((item, i) => {
            if(!state.tags.includes(item)){
              state.tags.push(item);
            }
          });
        }
      });
      state.tags.sort();
    },
    addTag(context) {
       context.rootState.links.links.forEach((item, i) => {
         if(item.tags){
           item.tags.forEach((item, i) => {
             if(!this.tags.includes(item)){
               context.commit("addTag", item);
             }
           });
         }
       });
       tags.sort();
     }
};

const mutations = {
  setTags: (state, tags) => (state.tags = tags),
  addTag: (state, tag) =>  {
    if(!state.tags.includes(tag)){
      state.tags.push(tag);
    }
  },
  removeTag: (state, tag) =>  {
    if(state.tags.includes(tag)){
      state.tags = state.tags.filter(tmp => tmp !== tag);
    }
  },
  clearTags: (state) => {
    state.tags.length = 0;
  }
};

export default{
  state,
  getters,
  actions,
  mutations
};
