/**
 * Contains all the Address properties and methods
 */
export default class Address {
    /**
     * Street
     * @type {string}
     */
    street = "";
    /**
     * Street
     * @type {string}
     */
    city = "";
    /**
     * Street
     * @type {string}
     */
    country = "";
    /**
     * Street
     * @type {string}
     */
    zipCode = "";

    /**
     * @constructs Address
     * @param {string} street
     * @param {string} city
     * @param {string} country
     * @param {string} zipCode
     */
    constructor(street, city, country, zipCode) {
        this.street = street;
        this.city = city;
        this.country = country;
        this.zipCode = zipCode;
    }

    /**
     * Generates an Empty Address object
     * @static
     * @return {Address} Returns an empty Address object
     */
    static empty() {
        return { street: "", city: "", country: "", zipCode: ""}
    }

    /**
     * Creates a copy of this Address object
     *
     * @return {Address} Returns a new Address object
     */
    copy() {
        let tmp = new Address(this.street, this.city, this.country, this.zipCode);
        tmp.id = this.id;
        return tmp;
    }
}
