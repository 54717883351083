var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"400px","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialogVmodel),callback:function ($$v) {_vm.dialogVmodel=$$v},expression:"dialogVmodel"}},[(this.currentLink)?_c('v-card',{staticClass:"text-center",attrs:{"dark":"","color":"rgba(0, 0, 0, 0.8)"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex flex-column justify-end",staticStyle:{"min-height":"600px"},attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{staticClass:"flex-column white--text",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{staticClass:"float-right",attrs:{"icon":""},on:{"click":_vm.closeShareLinkDialog}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-col',{staticClass:"px-4 text-truncate",staticStyle:{"min-height":"25px"}},[_c('a',{staticClass:"share-link",attrs:{"href":this.currentLink.href,"target":"_blank"}},[_vm._v(" "+_vm._s(this.currentLink.href)+" ")])]),_c('v-col',[_c('p',{staticClass:"px-4 my-2 font-weight-bold text-truncate"},[_vm._v(" "+_vm._s(this.currentLink.title)+" ")])]),_c('v-col',[_c('p',{staticClass:"line-clamp px-4 my-2"},[_vm._v(" "+_vm._s(this.currentLink.description)+" ")])])],1)],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_vm.qrSrc)?_c('img',{attrs:{"src":_vm.qrSrc,"height":"160px"}}):_vm._e()])],1)],1),_c('v-col',[_c('v-row',{staticClass:"pa-4 align-self-end",attrs:{"no-gutters":"","justify":'space-between'}},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":_vm.copyToClipBoard}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,false,1459007601)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipClipboard'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.share('twitter')}}},'v-icon',attrs,false),on),[_vm._v("mdi-twitter")])]}}],null,false,2609255689)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipTwitter'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.share('facebook')}}},'v-icon',attrs,false),on),[_vm._v("mdi-facebook")])]}}],null,false,2159516169)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipFacebook'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.share('whatsapp')}}},'v-icon',attrs,false),on),[_vm._v("mdi-whatsapp")])]}}],null,false,812414729)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipWhatsApp'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){$event.preventDefault();return _vm.shareEmail.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v("mdi-email-outline")])]}}],null,false,3222899760)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipEmail'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.share('linkedin')}}},'v-icon',attrs,false),on),[_vm._v("mdi-linkedin")])]}}],null,false,2840050761)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tipLinkedIn'))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){$event.preventDefault();return _vm.share('tinyurl')}}},'v-icon',attrs,false),on),[_vm._v("mdi-link-variant")])]}}],null,false,860474283)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.record.tinyURL'))+" ")])])],1)],1)],1),_c('v-btn',{staticClass:"mx-0 mt-3",attrs:{"text":""},on:{"click":_vm.closeShareLinkDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.close'))+" ")])],1)],1)],1):_vm._e(),(this.currentLink)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(this.currentLink.href),expression:"this.currentLink.href"}],ref:"href",staticClass:"copyhref",staticStyle:{"display":"none"},domProps:{"value":(this.currentLink.href)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(this.currentLink, "href", $event.target.value)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }