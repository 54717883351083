<template lang="pug">
  v-tabs(v-model="tab" color="primary" background-color="transparent" show-arrows )
    v-tab(to="/tenant" exact) {{ $t('tenant.tabs.overview') }}
    v-tab(to="/tenant/members" exact) {{ $t('tenant.tabs.members') }}
    v-tab(to="/tenant/labels" exact)  {{ $t('tenant.tabs.labels') }}
    v-tab(v-if="currentTenant && isOwner" to="/tenant/subscription" exact) {{ $t('tenant.tabs.subscription') }}
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TenantNavigation",
  computed: {
    ...mapGetters([
      'isOwner',
      'isAdmin',
      'currentTenant'
    ]),
  },
  data: () => ({
    tab: null,
  }),
}
</script>