import firebase from "firebase/app";
//import { firebase } from '../../../firebase/firebaseInit';
import FirestoreUser from "../../../firebase/firestore/FirestoreUser";
import {db, fireAnalytics} from '../../main';
import Tenant from "../classes/Tenant";
import router from '../../router/index'

const getDefaultFeatureLimitations = () => {
  return {
    'advanced sorting': false,
    'card lock': false,
    'max t-users': 5,
    'no ads': false,
    'pm-ads-free-for-t-members': false,
    't-admins': false,
    't-custom-labels': 5,
  };
};

const state = {
  /**
   * Contains all data regarding payment plans
   * @type {Object[]}
   */
  paymentPlans: [],
  /**
   * Stripe api for Front end
   * @type {Object}
   */
  stripe: Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY),
  /**
   * Tax ids from Stripe dashboard
   * https://dashboard.stripe.com/tax-rates
   */
  taxRates: [process.env.VUE_APP_STRIPE_TAX_RATE_ID_LATVIA],
  /**
   * Location of Firebase cloud functions
   * @type {String}
   */
  functionLocation: 'europe-west3',
  /**
   * The active subscription plan of the current user
   * @type {Object}
   */
  activeSubscription: null,
  /**
   * Custom Stripe claim role
   * @type {string}
   */
  customClaimRole: null,
  /**
   * A list current feature limitations
   * @type {Object}
   */
  currentFeatureLimitations: getDefaultFeatureLimitations(),
};

const getters = {
  /**
   * Gets the payment plans from the vuex store
   */
  getPaymentPlans: () => state.paymentPlans,

  /**
   * Gets the payment plans from the vuex store, that are active inside stripe (not deactivated)
   * @return {Object[]}
   */
  getActivePaymentPlans: () => state.paymentPlans.filter(item => {
    return item.active
  }),

  /**
   * gets the active subscription plan of the current user
   */
  getActiveSubscription: () => state.activeSubscription,
  /**
   * Get custom claim role helper
   */
  getCustomClaimRole:() => state.customClaimRole,
  /**
   * Get current feature limitations
   */
  getCurrentFeatureLimits:() => state.currentFeatureLimitations, 
  /**
   * Checks if a certain feature is enablex
   * @returns {boolean}
  */
  isFeatureEnabled: state => featureKey =>  {
    return state.currentFeatureLimitations ? 
      state.currentFeatureLimitations[featureKey] ? 
        state.currentFeatureLimitations[featureKey] : 
        false : 
      false;
  },
  /**
   * Checks if a value is under certain feature limitations 
   * @returns {boolean}
  */
  isUnderFeatureLimit: state => (featureKey, value) =>  {
    return state.currentFeatureLimitations ? 
      state.currentFeatureLimitations[featureKey] ? 
        state.currentFeatureLimitations[featureKey] > value : 
        false : 
      false;
  },
};

const actions = {
  /**
   * Retrieves all Payment plans from firebase
   * @param {*} context Vue context
   */
  async retrivePaymentPlans(context) {
    const newPlans = [];
    // Get all plans
    db.collection('products')
      .where('active', '==', true)
      .get()
      .then(productSnap => {
        productSnap.forEach(async function (doc) {
          const product = doc.data();
          product.id = doc.id;
          product.prices = [];
          // Get all prices for the plan
          const priceSnap = await doc.ref
            .collection('prices')
            .orderBy('unit_amount')
            .get();

          priceSnap.docs.forEach((doc) => {
            let aPrice = doc.data();
            aPrice.id = doc.id;
            product.prices.push(aPrice);
          });
          newPlans.push(product);
      });
    });
    context.commit('setPaymentPlans', newPlans);
  },
  /**
   * Opens the Stripe Biling portal
   */
  async goToBillingPortal() {
    // Call billing portal function
    const functionRef = firebase
      .app()
      .functions(state.functionLocation)
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
    fireAnalytics.logEvent('billing_portal_opened');
  },
  /**
   * Retrieves the active subscription of the current tenant from firebase
   * @param {*} context Vue context 
   * @param {Tenant} [tenant] current tennat
   */
  async retrieveActiveTenantSubscription(context, tenant){
    if (context.rootState.user.user) {
      if (context.rootState.user.tenant || tenant) {
        const tenantID = tenant ? tenant.id : context.rootState.user.tenant.id;
        db.collection('tenants')
          .doc(tenantID)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .onSnapshot(async (snapshot) => {
            // In this implementation we only expect one Subscription to exist
            let subscription = null;
            if (!snapshot.empty){
              subscription = snapshot.docs[0].data();
              if (subscription.price) {
                subscription.priceData = (await subscription.price.get()).data();
              }
            }
            context.commit('setActiveSubscription', subscription);
          });
          context.dispatch('retrieveCustomClaimRole');
        } else {
          context.dispatch("StoreNotification" , {code: 'tenant/no-active-tenant', type: "info"}) 
        }
      } else {
        context.dispatch("StoreNotification" , {code: "user/not-signed-in", type: "info"}) 
      }
  },
  /**
   * Subscribes the current authenticated user to a subscription plan based on price ID
   * @param {*} context Vue context
   * @param {Object} payload Payment payload
   * @param {String} payload.priceId Price Id
   * @param {String} payload.value Price value
   * @param {String} payload.currency Price value
   * @param {String} payload.package_name Package name
   */
  async subscribeToPlan(context, payload) {
    if (context.rootState.user.user) {
      if (context.rootState.user.tenant) {
        const docRef = await db
          .collection('profiles')
          .doc(context.rootState.user.user.uid)
          .collection('checkout_sessions')
          .add({
            price: payload.priceId,
            allow_promotion_codes: true,
            tax_rates: state.taxRates,
            success_url: window.location.protocol+'//'+window.location.host+'/usermgmt?mode=payment&oobCode=success',
            cancel_url: window.location.protocol+'//'+window.location.host+'/usermgmt?mode=payment&oobCode=cancel',
            metadata: {
              tenant_id: context.rootState.user.tenant.id,
              value: payload.value,
              currency: payload.currency,
              package_name: payload.package_name,
            },
          })
          .catch(error => {
            router.push({ name: 'User Management', query: { mode: 'payment', oobCode: 'error', descr: error.message } })
            context.dispatch("StoreError" , {error: error, showNotification: true})
          });
        fireAnalytics.logEvent('subscription_update_started', {
          price: payload.priceId,
          allow_promotion_codes: true,
          tax_rates: state.taxRates,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
          metadata: {
            tenant_id: context.rootState.user.tenant.id,
          },
        });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            // Show an error to your customer and then inspect your function logs.
            router.push({ name: 'User Management', query: { mode: 'payment', oobCode: 'error', descr: error.message } })
            context.dispatch("StoreError" , {error: error, showNotification: true})
            fireAnalytics.logEvent('subscription_update_canceled', {
              price: payload.priceId,
              allow_promotion_codes: true,
              tax_rates: state.taxRates,
              success_url: window.location.origin,
              cancel_url: window.location.origin,
              metadata: {
                tenant_id: context.rootState.user.tenant.id,
              },
              error: error,
            });
          }
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            state.stripe.redirectToCheckout({ sessionId })
            .then(result => {
              // If `redirectToCheckout` fails due to a browser or network
              // error, you should display the localized error message to your
              // customer using `error.message`.
              if (result.error) {
                router.push({ name: 'User Management', query: { mode: 'payment', oobCode: 'error', descr: error.message } })
                context.dispatch("StoreError" , {error: result.error, showNotification: true})
              }
            });
            // This is not enough: a cancel is interpreted the same as a completed transaction
            // fireAnalytics.logEvent('subscription_update_completed', {
            //   price: priceId,
            //   allow_promotion_codes: true,
            //   tax_rates: state.taxRates,
            //   success_url: window.location.origin,
            //   cancel_url: window.location.origin,
            //   metadata: {
            //     tenant_id: context.rootState.user.tenant.id,
            //   },
            // });
          }
        });
      } else {
        context.dispatch("StoreNotification" , {code: 'tenant/no-active-tenant', type: "info"}) 
      }
    } else {
      context.dispatch("StoreNotification" , {code: "user/not-signed-in", type: "info"}) 
    }
  },
  /**
   * Retrieves custom Stripe Role using firebase tokens
   * @param {*} context Vue context
   */
  retrieveCustomClaimRole(context) {
    return new Promise(function (resolve, reject){
      if (context.rootState.user.user) {
        if (context.rootState.user.tenant) {
          const docRef = db
          .collection('tenants')
          .doc(context.rootState.user.tenant.id)
          .collection('subscriptions')
          .where('status', 'in', ['trialing', 'active'])
          .get()
          .then(response => {
            // In this implementation we only expect one Subscription to exist
            if (!response.empty && response.docs[0].data().role) {
              context.commit('setCustomClaimRole', response.docs[0].data().role);
              resolve();
            } else {
              context.commit('setCustomClaimRole', "free_plan");
              resolve();
            }
          })
          .catch(error =>{
            context.dispatch("StoreError" , {error: error, showNotification: true})
            reject(error);
          });
        } else {
          context.dispatch("StoreNotification" , {code: 'tenant/no-active-tenant', type: "info"})
          reject();
        }
      } else {
        context.dispatch("StoreNotification" , {code: "user/not-signed-in", type: "info"})
        reject();
      }
    })
  },
  /**
   * Retrieves current feature limitations from firebase based on the current Stripe role
   * @param {*} context Vue Context
   */
  async retrieveCurrentFeatureList(context) {
    if (!state.customClaimRole) {
      //await this.retrieveCustomClaimRole(context);
      await actions.retrieveCustomClaimRole(context);
    }

    const currentRole = state.customClaimRole ? state.customClaimRole : "free_plan"
    db.collection('products')
      .where('role', '==', currentRole)
      .onSnapshot(async (snapshot) => {
        // In this implementation we only expect one Subscription to exist
        const limits = getDefaultFeatureLimitations()
        limits["advanced sorting"] = snapshot.docs[0].data()['stripe_metadata_advanced_sorting'] ? 
          (snapshot.docs[0].data()['stripe_metadata_advanced_sorting'] == 'true') : false;
        limits["card lock"] = snapshot.docs[0].data()['stripe_metadata_card_lock'] ? 
          (snapshot.docs[0].data()['stripe_metadata_card_lock'] == 'true') : false;
        limits["max t-users"] = snapshot.docs[0].data()['stripe_metadata_max_t-users'] ? 
          parseInt(snapshot.docs[0].data()['stripe_metadata_max_t-users']) : 5;
        limits["no ads"] = snapshot.docs[0].data()['stripe_metadata_no_ads'] ? 
          (snapshot.docs[0].data()['stripe_metadata_no_ads'] == 'true') : false;
        limits["pm-ads-free-for-t-members"] = snapshot.docs[0].data()['stripe_metadata_pm-ads-free-for-t-members'] ? 
          (snapshot.docs[0].data()['stripe_metadata_pm-ads-free-for-t-members'] == 'true') : false;
        limits["t-admins"] = snapshot.docs[0].data()['stripe_metadata_t-admins'] ? 
          (snapshot.docs[0].data()['stripe_metadata_t-admins'] == 'true') : false;
        limits["t-custom-labels"] = snapshot.docs[0].data()['stripe_metadata_t-custom-labels'] ? 
          parseInt(snapshot.docs[0].data()['stripe_metadata_t-custom-labels']) : 5;
        
        context.commit('setCurrentFeatureLimits', limits);
    });
    
  },
  /**
   * Fires an analytics event based on the checkout session status
   * @param {*} context Vue context
   * @param {Object} payload payload
   * @param {String} payload.status scheckout session status
   * @param {String} [payload.description] Error description (Optional)
   */
  processCheckoutSession(context, payload) {
    if (context.rootState.user.user) {
      FirestoreUser.methods.isLatestCheckoutSessionProcessed(context.rootState.user.user.uid)
        .then(response => {
          if (response) {
            switch (payload.status) {
              case "success":
                fireAnalytics.logEvent('purchase', {
                  transaction_id: response.sessionId,
                  package_name: response.metadata.package_name, 
                  value: response.metadata.value,
                  currency: response.metadata.currency
                });
                break;
              case "cancel":
                fireAnalytics.logEvent('purchase_cancelled', {
                  transaction_id: response.sessionId,
                  package_name: response.metadata.package_name,
                });
                break;
              case "error":
              default:
                fireAnalytics.logEvent('purchase_error', {
                  transaction_id: response.sessionId,
                  package_name: response.metadata.package_name, 
                  error_description: payload.description ? payload.description : "Unknown error"
                });
            }
          } else {
            console.log("Checkout session already processed");
          }
        })
        .catch(error =>{
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
    } 
    else {
      context.dispatch("StoreNotification" , {code: "user/not-signed-in", type: "info"})
    }
  }
};

const mutations = {
  setPaymentPlans(state, plans) {
    state.paymentPlans = plans
    console.log("Payment plans updated: ", plans);
  },
  setActiveSubscription(state, subscription) {
    state.activeSubscription = subscription
    console.log("Active Subscription updated: ", subscription);
  },
  setCustomClaimRole(state, role) {
    state.customClaimRole = role
    console.log("Current active claim role updated: ", role);
  },
  setCurrentFeatureLimits(state, limits) {
    state.currentFeatureLimitations = limits
    console.log("Current Feature limitations updated: ", limits);
  },
  clearPaymentInfo(state) {
    state.activeSubscription = null;
    state.customClaimRole = null;
    state.currentFeatureLimitations = null;
    console.log("Payment info cleared");
  }
};

export default {
    state,
    getters,
    actions,
    mutations
};
