<template lang="pug">
  v-container(class="fill-height" fluid id="registerLeftHalf")
    v-row(align="center" justify="start" :class="{'mb-0': $vuetify.breakpoint.smAndDown, 'mb-10': $vuetify.breakpoint.md,  'mb-12': $vuetify.breakpoint.lgAndUp, 'pb-6': $vuetify.breakpoint.lgAndUp}")
      v-col(cols="12" class="text-start pb-12")
        Logo
      v-col(cols="12" class="text-start justify-center")
        v-img(:src="imageUrl")
  </template>

<script>
import Footer from "@/components/core/Footer";
import SellingPointList from "@/components/register/SellingPointList";
import Logo from "../core/Logo";
export default {
name: "RegisterLeftHalf",
  components: {SellingPointList, Footer, Logo},
  computed: {
    landingPageUrl(){
      return process.env.VUE_APP_ADV_PUBLIC_ID === 'test' ? 'https://yndooo-web-dev.web.app/' : 'https://yndooo.com/'
    },
    imageUrl() {
      let url =  ""

      switch(this.$route.query.plan) {
        case "free":
          url = "/img/pricing/default_plan.png"
          break;
        case "team":
          url = "/img/pricing/team_plan.png"
          break;
        case "business":
          url = "/img/pricing/business_plan.png"
          break;
        case "enterprise":
          url = "/img/pricing/enterprise_plan.png"
          break;
        default:
          url = "/img/pricing/default_plan.png"
          break;
      }

      return url
    },
  },
}
</script>