export default class Filter {
  labelFilters = null;
  tagFilters = null;
  typeFilters = null;

  constructor(){
    this.labelFilters = [];
    this.tagFilters = [];
    this.typeFilters = [];
  }

  addLabelFilter(label){
    if(label){
      let tmpExisting = this.labelFilters.find(item => item.id === label.id);
      if(!tmpExisting){
          console.log("Add label filter: ", label);
          this.labelFilters.push(label);
      }
    }
  }

  removeLabelFilter(label){
    if(label){
      let indexOfElement = this.labelFilters.findIndex(item => item.id === label.id);
      if(indexOfElement > -1){
        console.log("Remove label filter: ", label);
        this.labelFilters.splice(indexOfElement, 1);
      }
    }
  }

  setTagsFilters(filters, allTags){
    this.tagFilters = filters ? filters : [];
    console.log("Tag filters set: ", filters);
  }



  addTypeFilter(type){
    if(type){
      let tmpExisting = this.typeFilters.find(item => item.name === type.name);
      if(!tmpExisting){
          console.log("Add type filter: ", type);
          this.typeFilters.push(type);
      }
    }
  }

  removeTypeFilter(type){
    if(type){
      let indexOfElement = this.typeFilters.findIndex(item => item.name === type.name);
      if(indexOfElement > -1){
        console.log("Remove type filter: ", type);
        this.typeFilters.splice(indexOfElement, 1);
      }
    }
  }

  numberOfAppliedFilters(){
    return this.labelFilters.length + this.tagFilters.length + this.typeFilters.length;
  }

  reset(){
    this.labelFilters = [];
    this.tagFilters = [];
    this.typeFilters = [];
  }

  /**
  *  Tests if link is covered by the filter
  */
  test(link){
    if(!link || !link.copy){
      return true;
    }

    let countMatches = 0;
    if(this.labelFilters.length > 0){
      this.labelFilters.forEach((item, i) => {
          if(link.hasLabel(item)){
            countMatches ++;
          }
      });

      if(countMatches !== this.labelFilters.length){
        return false;
      }
    }
    countMatches = 0;
    if(this.typeFilters.length > 0){
      this.typeFilters.forEach((item, i) => {
          if(link.hasType(item)){
            countMatches ++;
          }
      });
      if(countMatches < 1){
        return false;
      }
    }
    return true;
  }
}
