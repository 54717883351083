export const defaultPublicInfo = {
  email: null,
  phone: null,
  description: null,
}

/**
  * Contains all the user profile properties and methods
*/
import {createAvatarColor} from "@/store/classes/Colors";

export default class Profile {
  /**
   * the user's id
   * @type {string}
  */
  id = "";
  /**
   * the user's active tenant id
   * @type {string}
  */
  activeTenant = "";
  /**
   * the user's name
   * @type {string}
  */
  firstname = "";
  /**
   * the user's surname
   * @type {string}
  */
  surname = "";
  /**
   * the user's email
   * @type {string}
  */
  email = "";
  /**
   * the user's alias
   * @type {string}
  */
  alias = "";

  /**
   *
   * @type {string}
   */
  avatarColor = createAvatarColor();
  /**
   * the user's birth year
   * @type {string}
  */
  birthyear = "";
  /**
   * the user's birth month
   * @type {string}
  */
  birthmonth = "";
  /**
   * the user's birthday
   * @type {string}
  */
  birthday = "";
  /**
   * the user's profile creation date
   * @type {Date}
  */
  createdAt = null;
  /**
   * the list of cards the user have liked
   * @type {firebase.firestore.DocumentReference[]}
  */
  likedCards = [];
  /**
   * the list of tenant id's that the user belongs to
   * @type {Object[]}
  */
  tenants = [];
  /**
   * Public profile information
   * @type {defaultPublicInfo}
   */
  public = defaultPublicInfo;

  hideTutorial = false;

  /**
   * @constructs Profile
   * @param {string} [activeTenant] - the user's activeTenant id
   * @param {string} firstname - the users firstname
   * @param {string} surname - the user's surname
   * @param {string} email - The user's email.
   * @param {string} [alias] - The users alias.
   * @param {string} birthyear - The users birth year
   * @param {string} [birthmonth] - The users birth year
   * @param {string} [birthday] - The users birth year
   * @param {Date} createdAt - The user profile creation date
   * @param {object[]} likedCards - a list of liked cards
   * @param avatarColor
   * @param hideTutorial
   */
  constructor(activeTenant, firstname, surname, email, alias, birthyear, birthmonth, birthday, createdAt, likedCards, avatarColor, hideTutorial){
    this.activeTenant = activeTenant ? activeTenant : null;
    this.firstname = firstname;
    this.surname = surname;
    this.email = email;
    this.avatarColor = avatarColor? avatarColor : createAvatarColor();
    this.alias = alias ? alias : null;
    this.birthyear = birthyear;
    this.birthmonth = birthmonth ? birthmonth : null;
    this.birthday = birthday ? birthday : null;
    this.createdAt = createdAt;
    this.likedCards = likedCards ? likedCards : [];
    this.hideTutorial = hideTutorial? hideTutorial : false;
  };
  /**
   * Checks if the user have already liked a card
   *
   * @return {boolean} 
  */
  isCardAlreadyLiked(cardid){
    let aResult = false;
    this.likedCards.forEach( item => {
      if (item.id == cardid) {
        aResult = true;
      }
    })
    return aResult;
  }
  /**
   * Creates a copy of this Profile object
   *
   * @return {Profile} Returns a new Tenant object
  */
  copy(){
    let tmpProfile = new Profile(this.activeTenant, this.firstname, this.surname, this.email, this.alias, this.birthyear, this.birthmonth, this.birthday, this.createdAt, this.likedCards, this.avatarColor, this.hideTutorial);
    tmpProfile.id = this.id;
    return tmpProfile;
  }

  /**
   * Generates an Empty Profile object
   * @static
   * @return {Profile} Returns an empty Tenant object
  */
  static empty(){
    return new Profile(this.activeTenant, this.firstname, this.surname, this.email, this.alias, this.birthyear, this.birthmonth, this.birthday, new Date(), this.likedCards, createAvatarColor(), this.hideTutorial);
  }
}