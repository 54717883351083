<template lang="pug">
  v-card(max-width="700" height="100%" class="flexcard fill-height")
    v-card-actions
      v-spacer
      v-btn(icon @click="$emit('skip')")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on") mdi-close
          span {{ $vuetify.lang.t('$vuetify.close') }}
    v-card-actions
      v-row(fill-height class="align-center")
        v-col(cols="4" class="justify-center align-center")
          div(v-if="lottiefile" :id="`${this.id}-desktop`")
        v-col(cols="8" class="text-left align-center justify-center")
          v-card(flat)
            v-card-title {{ title }}
            v-card-text {{ text }}
    v-card-actions
      v-btn(v-if="skipAllBtn" color="primary" text @click="$emit('skip')") {{ skipAllBtn }}
      v-btn(v-if="backBtn" color="primary" text @click="$emit('back')") {{ backBtn }}
      v-spacer
      v-btn(color="primary" width="120" @click="$emit('next')") {{ nextBtn }}
</template>

<script>
export default {
  name: "TutorialCardArtLeft",
  props: {
    id: {
      type: String,
      required: true,
      default: ""
    },
    title: {
      type: String,
      required: true,
      default: ""
    },
    text: {
      type: String,
      required: true,
      default: "",
    },
    imgUrl: {
      type: String,
      required: false,
      default: ""
    },
    lottiefile: {
      type: Object,
      required: false,
      default: (() => {return  { path: "", loop: false, animationType: "svg", autoplay: false }})
    },
    skipAllBtn: {
      type: String,
      required: true,
      default: ""
    },
    nextBtn: {
      type: String,
      required: true,
      default: ""
    },
    backBtn: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      animation: null,
    }
  },

  methods: {
    loadAnimation() {
      if (this.$vuetify.breakpoint.xs) {
        const svgContainerMobile = document.getElementById( `${this.id}-mobile`);
        this.animation = bodymovin.loadAnimation({
          wrapper: svgContainerMobile,
          animType: this.lottiefile.animationType,
          loop: this.lottiefile.loop,
          path: this.lottiefile.path,
          autoplay: this.lottiefile.autoplay,
        });
      } else {
        const svgContainer = document.getElementById(`${this.id}-desktop`);
        this.animation = bodymovin.loadAnimation({
          wrapper: svgContainer,
          animType: this.lottiefile.animationType,
          loop: this.lottiefile.loop,
          path: this.lottiefile.path,
          autoplay: this.lottiefile.autoplay,
        });
      }
    },
    destroyAnimation() {
      const svgContainerMobile = document.getElementById( `${this.id}-mobile`);
      bodymovin.destroy(svgContainerMobile)
    },
  },
  async mounted() {
    // The mounted initialization is only needed to load the lottief iles animation, otherwise it can be skipped
    if(!this.lottiefile) return
    this.loadAnimation()
  },
  beforeUpdate() {
    bodymovin.play()
  },
  beforeDestroy() {
    bodymovin.destroy(`${this.id}-mobile`)
    bodymovin.destroy(`${this.id}-desktop`)
  }
}
</script>

<style scoped lang="scss">
.flexcard {
  display: flex;
  flex-direction: column;
}
// Add the css below if your card has a toolbar, and if your toolbar's height increases according to the flex display.
.flexcard .v-toolbar {
  flex: 0;
}
</style>