<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    v-row(class="justify-center align-begin")
      v-col(cols="12" class="text-center")
        h1.h1 Global Privacy Policy — Worldwide
        p Last updated: October 18, 2020
        p Welcome to Yndooo!
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
export default {
  mixins: [validationMixin],
  validations: {
    login: {
      email: { required, email },
    },
  },
  data() {
    return {
      loading: false,
      login: {
        email: '',
      },
      defaultLogin: {
        email: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "currentUser",
    ]),
    emailErrors() {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.login.email.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "sendPasswordResetEmail",
      "StoreNotification",
    ]),
    resetForm() {
      this.$v.$reset()
      this.login = {... this.defaultLogin }
    },
    async forgotPassword() {
      console.log("clicked")
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      await this.sendPasswordResetEmail({ email: this.login.email });
      this.resetForm()
      this.loading = false
      console.log("send")
    },
  },
};
</script>

<style scoped>
</style>