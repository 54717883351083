<template lang="pug">
  v-container(fluid)
    v-row(align="center" justify="center")
      v-col(cols="12" align="center" :justify="$vuetify.breakpoint.sm? 'left' : 'center'")
        Logo
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="4" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
          v-row(align="center" justify="center" class="pl-12 pr-12").px-5.pt-5.pb-0
            v-col(cols="12")
              v-card(flat height="200" )
                v-card-text
                  div(id="lottie-confirmation")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light {{ title }}
            v-col(cols="8" class="text-center")
              h2.text-subtitle-2.font-weight-regular {{ descr }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/')") {{ returnBtn }}
            v-col(cols="12")
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form
          v-row(align="center" justify="center")
            v-col(cols="8" class="text-center")
              v-col(cols="12")
                v-card(flat height="200" )
                  v-card-text
                    div(id="lottie-confirmation-mobile")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light {{ title }}
            v-col(cols="8" class="text-center mt-0 pt-0")
              h2.text-subtitle-2.font-weight-regular {{ descr }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/')") {{ returnBtn }}
            v-col(cols="12")
</template>

<script>
import Logo from "../core/Logo";
import {mapActions, mapGetters} from "vuex";
import firebase from 'firebase/app'

export default {
  name: "PaymentConfirmationFeedback",
  components: {Logo},
  data() {
    return {
      loading: false,
      title: "",
      returnBtn: "",
      animPath: "",
      descr: "",
    };
  },
  props: {
    actionCode: {
      type: String,
      default: "error",
      required: false,
    },
    description: {
      type: String,
      default: "",
      required: false,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    }
  },
  methods: {
    ...mapActions([
      "processCheckoutSession",
      "appLoggedIn",
    ]),
  },
  computed: {
    ...mapGetters([
      "signedIn",
    ]),
  },
  mounted() {
    // check if user is stored in local storage
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.appLoggedIn(user);
      } 
    });

    switch (this.actionCode) {
      case "success":
        this.title = this.$t('tenant.subscription.feedback.success.title');
        this.descr = this.description ? this.description : this.$t('tenant.subscription.feedback.success.description');
        this.returnBtn = this.$t('tenant.subscription.feedback.success.return');
        this.animPath = '/animations/subscription.json';
        break;
      case "cancel":
        this.title = this.$t('tenant.subscription.feedback.cancel.title');
        this.descr = this.description ? this.description : this.$t('tenant.subscription.feedback.cancel.description');
        this.returnBtn = this.$t('tenant.subscription.feedback.cancel.return');
        this.animPath = '/animations/4970-unapproved-cross.json';
        break;
      case "error":
        this.title = this.$t('tenant.subscription.feedback.error.title');
        this.descr = this.description ? this.description : this.$t('tenant.subscription.feedback.error.description');
        this.returnBtn = this.$t('tenant.subscription.feedback.error.return');
        this.animPath = '/animations/4970-unapproved-cross.json';
    }
    if (this.$vuetify.breakpoint.xs) {
      const svgContainerMobile = document.getElementById('lottie-confirmation-mobile');
      const animItemMobile = bodymovin.loadAnimation({
        wrapper: svgContainerMobile,
        animType: 'svg',
        loop: false,
        path: this.animPath
      });
    } else {
      const svgContainer = document.getElementById('lottie-confirmation');
      const animItem = bodymovin.loadAnimation({
        wrapper: svgContainer,
        animType: 'svg',
        loop: false,
        path: this.animPath
      });
    }
  },
  watch: {
    signedIn(value) {
      if (value) {
        this.processCheckoutSession({status: this.actionCode, description: this.descr});
      }
    },
  },
}
</script>

<style scoped>
.filter-primary {
  filter: invert(24%) sepia(27%) saturate(7097%) hue-rotate(198deg) brightness(99%) contrast(101%);
}

#lottie-confirmation {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#lottie-confirmation-mobile {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>