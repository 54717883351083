const  state = {
  currentLink: null,
  currentFilter: {},
  filterOpen: false,
  editLinkDialogOpen: false,
  shareLinkDialogOpen: false,
  loadingGlobal: false,
};

const getters = {
  currentLink: () => state.currentLink,
  showEditLinkDialog: () => state.editLinkDialogOpen,
  showShareLinkDialog: () => state.shareLinkDialogOpen,
  showFilter: () => state.filterOpen,
  isLoadingGlobal: () => state.loadingGlobal,
};

const actions = {
    editLink(context, link){
      context.commit('setCurrentLink', link);
      context.commit('setEditLinkDialogStatus',true);
    },
    newLink(context){
      context.commit('setCurrentLink', null);
      context.commit('setEditLinkDialogStatus', true);
    },
    shareLink(context, link){
      context.commit('setCurrentLink', link);
      context.commit('setShareLinkDialogStatus', true);
    },
    closeEditLinkDialog({commit}){
      commit('setEditLinkDialogStatus', false);
      commit('setCurrentLink', null);
    },
    closeShareLinkDialog({commit}){
      commit('setShareLinkDialogStatus', false);
      commit('setCurrentLink', null);
    },
    closeFilter({commit}){
      commit('setFilterStatus', false);
    },
    openFilter({commit}){
      commit('setFilterStatus', true);
    },
    finishEditingLink({commit}){
      commit('cleanUpEditLinkDialog');
    },
    startLoadingSpinner({commit}){
      commit('setLoadingStatus', true);
    },
    stopLoadingSpinner({commit}){
      commit('setLoadingStatus', false);
    },
};

const mutations = {
  setCurrentLink: (state, linkToSet) => state.currentLink = linkToSet,
  setFilterStatus(state, status) {
    state.filterOpen = status
  },
  setEditLinkDialogStatus(state, status){
    state.editLinkDialogOpen = status
  },
  setShareLinkDialogStatus(state, status){
    state.shareLinkDialogOpen = status
  },
  cleanUpEditLinkDialog(state){
    state.editLinkDialogOpen = false
    state.currentLink = null
  },
  setLoadingStatus(state, status) {
    state.loadingGlobal = status
  },
};

export default{
  state,
  getters,
  actions,
  mutations
};
