<template>
  <div class="text-center">
    <v-snackbar
      v-for="item in notifications" v-bind:key="item.index"
      v-model="snackbar"
      :color="item.color"
      :timeout="item.timeout"
      :absolute= "notificationProps.absolute"
      :top= "notificationProps.top"
      :bottom= "notificationProps.bottom"
      :left= "notificationProps.left"
      :right= "notificationProps.right"
      :multi-line= "notificationProps.multi_line"
      :vertical= "notificationProps.vertical"
    >      
      {{ item.message }}      
      <template
        v-slot:action="{ attrs }"
      >
        <v-btn
          class="ma-3"
          dark
          text
          v-bind="attrs"
          @click="snackbarCloseClicked"
        >        
          {{ $vuetify.lang.t('$vuetify.close')}}
          <br />
          {{ getMessagesLeft() }}          
        </v-btn>
      </template>
      <br />
      <v-btn
        class="mt-3"
        v-if="item.action"
        @click="takeActionClicked(item.action)"
      >{{item.action}}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    data () {
      return {
        mode: '',
        snackbar: true,
        timeout: 0,
        text: 'Hello, I\'m a snackbar',
      }
    },
    computed: {
        ...mapGetters({
        notifications: 'getAllNotifications',
        notificationProps: 'getNotificationProperties',
      })
    },
    methods: {
      ...mapActions(['RemoveNotification', 'resendVerificationEmail']),
      snackbarCloseClicked: function(){
        this.RemoveNotification({'id':'latest'});
      },
      getMessagesLeft: function(){
        if (this.notifications.length > 1){
          return this.notifications.length + " left"
        }
      },
      takeActionClicked: function(action){
        if (action === "Resend E-mail"){
          this.resendVerificationEmail();
          this.RemoveNotification({'id':'latest'});
        }
      },
    },
    watch:{
      notifications(value){
        if (value.length > 0){
          this.snackbar = true;
          if (value[value.length - 1].timeout > 0){
            const aNotification = value[value.length - 1];
            setTimeout(() => { this.RemoveNotification(aNotification) }, aNotification.timeout);
          }
        }
      }
    }
  }
</script>

<style>
.v-snack__wrapper {
  top: -40px;
}
.v-snack__action {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
}
</style>