<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    v-row(align="center" justify="center")
      v-col(cols="12" align="center" :justify="$vuetify.breakpoint.sm? 'left' : 'center'")
        Logo
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="4" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
            v-form(@submit.prevent="onLogin" class="pl-12 pr-12")
              v-row(align="center" justify="center")
                v-col(cols="12" class="text-start")
                  h2.text-h6.font-weight-regular {{ $vuetify.lang.t('$vuetify.login.title') }}
                v-col(cols="12" class="mb-0 pb-0 text-start")
                  p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.email') }}
                v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                  v-text-field(
                    v-model="login.email"
                    required
                    outlined
                    dense
                    type="email"
                    label=""
                    autofocus
                    autocomplete="username"
                    :error-messages="emailErrors"
                    :tabindex="0"
                    @input="$v.login.email.$touch()"
                    @blur="$v.login.email.$touch()"
                  )
                v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                  v-row
                    v-col(cols="4" class="mt-0 mb-0 pt-0 pb-0 text-start")
                      p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.password') }}
                    v-col(cols="8" class="mt-0 mb-0 pt-0 pb-0 text-end")
                      p.text-subtitle-2
                        router-link(to="/reset" :tabindex="-1") {{ $vuetify.lang.t('$vuetify.login.forgotPassword') }}
                v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                  v-text-field(
                    v-model="login.password"
                    required
                    type="password"
                    outlined
                    dense
                    label=""
                    autocomplete="current-password"
                    :tabindex="0"
                    :error-messages="passwordErrors"
                    @input="$v.login.password.$touch()"
                    @blur="$v.login.password.$touch()"
                    )
                v-col(cols="12")
                  v-btn(type="submit" block color="primary" :tabindex="0" :loading="loading") {{ $vuetify.lang.t('$vuetify.login.login') }}
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form(@submit.prevent="onLogin" class="ml-12 mr-12")
          v-row(align="center" justify="center")
            v-col(cols="12" class="text-start")
              h2.text-h6.font-weight-regular {{ $vuetify.lang.t('$vuetify.login.title') }}
            v-col(cols="12" class="mb-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.email') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="login.email"
                required
                outlined
                dense
                label=""
                autofocus
                type="email"
                autocomplete="username"
                :tabindex="0"
                :error-messages="emailErrors"
                @input="$v.login.email.$touch()"
                @blur="$v.login.email.$touch()"
              )
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-row
                v-col(cols="4" class="mt-0 mb-0 pt-0 pb-0 text-start")
                  p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.login.password') }}
                v-col(cols="8" class="mt-0 mb-0 pt-0 pb-0 text-end")
                  p.text-subtitle-2
                    router-link(to="/reset" :tabindex="-1") {{ $vuetify.lang.t('$vuetify.login.forgotPassword') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="login.password"
                required
                type="password"
                outlined
                dense
                label=""
                autocomplete="current-password"
                :tabindex="0"
                :error-messages="passwordErrors"
                @input="$v.login.password.$touch()"
                @blur="$v.login.password.$touch()"
              )
            v-col(cols="12")
              v-btn(type="submit" block color="primary" :loading="loading" :tabindex="0") {{ $vuetify.lang.t('$vuetify.login.login') }}
      v-col(cols="12" class="text-center")
        p.text-body-2.fontColorLight--text
          | {{ $vuetify.lang.t('$vuetify.login.register.question') }}
          router-link(to="/register" :tabindex="0")  {{ $vuetify.lang.t('$vuetify.login.register.linkText') }}
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import Logo from "../components/core/Logo";
export default {
  components: {Logo},
  mixins: [validationMixin],
  validations: {
    login: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
    },
  },
  data() {
    return {
      loading: false,
      login: {
        email: '',
        password: '',
        staySignedIn: false
      },
      defaultLogin: {
        email: '',
        password: '',
        staySignedIn: false
      },
    };
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "currentUser",
    ]),
    landingPageUrl(){
      return process.env.VUE_APP_ADV_PUBLIC_ID === 'test' ? 'https://yndooo-web-dev.web.app/' : 'https://yndooo.com/'
    },
    emailErrors() {
      const errors = []
      if (!this.$v.login.email.$dirty) return errors
      !this.$v.login.email.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.login.email.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.login.password.$dirty) return errors
      !this.$v.login.password.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.minimum'))
      !this.$v.login.password.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.maximum'))
      !this.$v.login.password.required && errors.push(this.$vuetify.lang.t('$vuetify.login.passwordError.required'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "signInAction",
      "readProfile",
      "sendPasswordResetEmail",
      "StoreNotification",
    ]),
    ...mapMutations([
      "setUser",
    ]),
    resetForm() {
      this.$v.$reset()
      this.login = {... this.defaultLogin }
    },
    async onLogin() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      try {
        await this.signInAction({email: this.login.email, password: this.login.password})
        this.resetForm()
      } catch (error) {
        await this.$store.dispatch("StoreError" , {error: error, showNotification: true}, {root: true})
      }
      this.loading = false
    },
  },
  watch: {
    signedIn(value) {
      if (value) {
        if (this.currentUser.emailVerified) {
          this.$router.push("/")
          .catch( error => {
            console.log(error)
          })
        }
      }
    },
  },
};
</script>

<style scoped>
</style>