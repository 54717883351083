<template lang="pug">
  v-row(justify="center")
    v-col(cols="12" lg="6")
      v-card(flat color="transparent")
        v-card-actions
          v-row
            v-col(cols="12")
              h4(class="text-h5 text-center") {{ this.$t('tenant.creation.step2.title') }}
            v-col(cols="12" class="pt-0 pb-0")
              p {{ this.$t('tenant.creation.step2.subtitle') }}
            v-col(v-for="(item, index) in members.length" :key="`member-${index}`" cols="12" class="pb-0 pt-0")
              v-text-field(
                :label="$t('tenant.creation.step2.email')"
                v-model="members[index]"
                :error-messages="memberErrors[index]"
                outlined
                @input="$v.members.$touch()"
                @blur="$v.members.$touch()")
            v-col(cols="12" class="pt-0 text-start")
              v-btn(@click="addTenantMemberSlot" color="primary" outlined :disabled="members.length >= maxNumberOfMemberSlots") {{ this.$t('tenant.creation.step2.addAnother') }}
            v-col(cols="12")
              v-btn(@click="inviteTenantMembers" :loading="loading" color="primary" :disabled="members.filter(Boolean).length <= 0 || $v.members.$invalid") {{ this.$t('tenant.creation.continue') }}
            v-col(cols="12" v-if="skipButton" class="pt-0")
              v-btn(@click="onSkipStep" color="primary" text) {{ this.$t('tenant.creation.skip') }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { email } from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CreateTenantStepperTwo",
  mixins: [validationMixin],
  validations: {
    members: {
      $each: {
        email: email // each field of "values" array has to be a valid email address
      }
    }
  },
  data() {
    return {
      loading: false,
      members: ["", "", ""],
      maxNumberOfMemberSlots: 4,
    }
  },
  props: {
    forceClose: {
      required: true,
      default: false,
      type: Boolean
    },
    skipButton: {
      type: Boolean,
      default: true,
      required: false,
    }
  },
  watch: {
    forceClose: function (newVal, oldVal) {
      this.reset()
    }
  },
  computed: {
    ...mapGetters([
      'currentTenant',
      'currentProfile'
    ]),
    memberErrors() {
      const errors = []
      if (!this.$v.members.$dirty) return errors
      for(let i = 0; i < this.members.length; i++) {
        if(!this.$v.members.$each[i].email)
          errors.splice(i, 0,'Must be a valid email')
        else
          errors.splice(i, 0,'')
      }
      return errors
    },
  },
  methods: {
    ...mapActions([
      'generateTenantInviteCode',
      'sendTeamInviteMail',
    ]),
    addTenantMemberSlot() {
      if (this.members.length === this.maxNumberOfMemberSlots) return
      this.members.push('')
    },
    reset() {
      this.loading =  false,
      this.members = ["", "", ""]
    },
    async inviteTenantMembers() {
      this.$v.members.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true

      // Remove all empty strings & remove all duplicates
      let emails = this.members.filter(Boolean)
      emails = [...new Set(emails)];

      // Invite the members via email
      for (const email of emails) {
        const inviteCode = await this.generateTenantInviteCode('')
        let aDisplayName = this.currentProfile.alias ? this.currentProfile.alias : this.currentProfile.firstname + this.currentProfile.surname;
        this.sendTeamInviteMail({ code: inviteCode, to: email, displayName: aDisplayName, tenantName: this.currentTenant.displayName });
      }

      this.$fireAnalytics.logEvent('tenant_creation_step2_completed', {
        membersInvited: true,
        numInvites: emails.length,
      });

      this.loading = false
      this.$emit('next')
      this.reset()
    },
    onSkipStep() {
      this.$fireAnalytics.logEvent('tenant_creation_step2_completed', {
        membersInvited: false,
      });

      this.$emit('next')
      this.reset()
    }
  }
}
</script>

<style scoped>

</style>