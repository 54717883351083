import firebase from 'firebase/app'

  export default {
    methods: {
      /**
       * Registers a new user in Firebase 
       *
       * @param {String}  email  a user's email.
       * @param {String}  password  a user's password
       *
       * @return {firebase.auth.UserCredential} Returns the user firebase credentials, or error.
      */
      signUp (email, password) {
        return new Promise(function (resolve, reject){
          firebase.auth()
            .createUserWithEmailAndPassword(email, password)
            .then((user) => {
                resolve(user);
                return user;
            }).catch((err) => {
                reject(err);
                return err;
            });
      })},
      /**
       * Signs in a user into Firebase 
       *
       * @param {String}  email  a user's email.
       * @param {String}  password  a user's password
       *
       * @return {firebase.auth.UserCredential} Returns the user firebase credentials, or error.
      */
      signIn (email, password, disablePersistance, useSessionPersistance) {
        return new Promise(function (resolve, reject){
          firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                resolve(user);
            }).catch((err) => {
                reject(err);
            });
        })
      },
      /**
       * Signs out the current user from Firebase 
      */
     signOut () {
      return new Promise(function (resolve, reject){
        firebase.auth().signOut()
          .then(function() {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      })
    },
      /**
       * Sends a verification email to Firebase user
       *
       * @param {firebase.auth.UserCredential}  user user firebase credentials.               
      */
      sendVerificationEmail (user) {
        return new Promise(function (resolve, reject){
          user
            .sendEmailVerification()
            .then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
                return err;
            });
      })},
      /**
       * Sends a password reset email to Firebase user
       *
       * @param {string}  email user's email
      */
      sendEmailPasswordReset (email) {
        return new Promise(function (resolve, reject){
          firebase.auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
                return err;
            });
      })},
      /**
       * Updates a firebase user's email
       *
       * @param {firebase.auth.UserCredential}  user  a user's firebase credentials.
       * @param {String}  newemail  a user's new email adress
      */
      updateEmail (user, newemail) {
        return new Promise(function (resolve, reject){
          user
            .updateEmail(newemail)
            .then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
                return err;
            });
      })},
      /**
       * Updates a firebase user's password
       *
       * @param {String}  email  a user's email adress
       * @param {String}  oldpassword  a user's old password.
       * @param {String}  newPassword  a user's new password
      */
      updatePassword (email, oldpassword, newPassword) {
        return new Promise(function (resolve, reject){
          firebase.auth()
            .signInWithEmailAndPassword(email, oldpassword)
            .then((user) => {
              firebase
                .auth()
                .currentUser
                .updatePassword(newPassword)
                  .then(() => {
                  resolve();
                }).catch((err) => {
                  return err;
                });
                resolve(user);
                return user;
            }).catch((err) => {
                reject(err);
                return err;
            });
      })},
      /**
       * Check if password reset code is valid
       * @param {string} actionCode
       * @returns {string} User Email
       */
      checkPaswordResetCode(actionCode) {
        return new Promise(function (resolve, reject){
          firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
            resolve(email);
          }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            reject(error);
          });
        });
      },
      /**
       * Resets the user password based on the email code
       * @param {string} actionCode 
       * @param {string} newPassword 
       */
      handleResetPassword(actionCode, newPassword) {
        return new Promise(function (resolve, reject){
          firebase.auth().confirmPasswordReset(actionCode, newPassword).then((resp) => {
            // Password reset has been confirmed and new password updated.
            resolve(resp)
          }).catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            reject(error);
          });
        });
      },
      /**
       * Recovers a user Email after it was changed using a confirmation code that is sent to the user original Email
       * @param {string} actionCode Email restore code
       * @param {string} lang UI language
       */
      handleRecoverEmail(actionCode, lang) {
        return new Promise(function (resolve, reject){
          // Revert to the old email.
          firebase.auth().applyActionCode(actionCode).then((resp) => {
            // Account email reverted to restoredEmail
            resolve(resp)
          }).catch((error) => {
            // Invalid code.
            reject(error);
          });
        });
      },
      /**
       * Verifies a user Email using a confirmation code that is sent to the user Email
       * @param {String} actionCode Email verification code
       * @param {String} [continueUrl] a URL that can be used to redirect to a specific page
       * @param {String} [lang] UI language
       */
      handleVerifyEmail(actionCode, continueUrl, lang) {
        return new Promise(function (resolve, reject){
          firebase.auth().applyActionCode(actionCode)
              .then((resp) => {
                resolve(resp);
          })
              .then(() => {
                return firebase.auth().signOut()
              })
              .catch((error) => {
            // Code is invalid or expired.
            reject(error);
          });
        });
      },
      /**
       * Confirm the action code is valid.
       * @param {string} actionCode
       * @returns {Object} Information about the code
       */
      checkActionCode(actionCode){
        return new Promise(function (resolve, reject){
          firebase.auth()
            .checkActionCode(actionCode)
            .then((info) => {
            resolve(info);
          }).catch((error) => {
            reject(error);
          });
        });
      }
    }
  }