<template lang="pug">
  v-container(v-if="isOwner" fluid)
    v-row
      v-col(cols="12" class="text-left")
        v-card(shaped)
          v-card-title {{ $vuetify.lang.t('$vuetify.tenant.dangerZone.title')}}
          v-card-text {{ $vuetify.lang.t('$vuetify.tenant.dangerZone.disclaimer')}}
          v-card-text
            v-btn(color="primary" href="mailto:sales@yndooo.com") {{ $vuetify.lang.t('$vuetify.tenant.dangerZone.contactSupport')}}
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TenantDangerZone",
  computed: {
    ...mapGetters([
      'isOwner',
      'isAdmin',
      'currentTenant'
    ]),
  }
}
</script>

<style scoped>

</style>