<template lang="pug">
  v-card
    v-row(justify="center")
      v-col(cols="12")
        v-card(flat color="transparent")
          v-row
            v-col(cols="12")
              h4(class="text-h5 text-center")  {{ this.$t('tenant.creation.step3.title') }}
            v-col(cols="12" class="pt-0 pb-0")
              p  {{ this.$t('tenant.creation.step3.subtitle') }}
            v-col(cols="12")
              PlanList(:free-tier-selection-enabled="freeTierSelectionEnabled" :hover-disabled="hoverDisabled" @plan-selected="planSelected")
</template>

<script>
import PlanList from "@/components/subscription/PlanList";
export default {
  name: "CreateTenantStepperThree",
  components: {PlanList},
  data() {
    return {
      freeTierSelectionEnabled: true,
      hoverDisabled: false
    }
  },
  methods: {
    async planSelected(plan) {

      // Analytics
      this.$fireAnalytics.logEvent('tenant_creation_step3_completed', {
        plan: plan,
      });

      // Close dialogue if the free plan was selected
      if(plan.role === 'free_plan') {
        this.$emit('next')
      }

      // Close dialogue if a plan was selected that forces the user to contact sales
      if(!plan.prices[0].active && !plan.prices[1].active) {
        this.$emit('next')
      }
    }
  }
}
</script>

<style scoped>

</style>