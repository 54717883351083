<template lang="pug">
  v-dialog(v-model="dialog" fullscreen persistent height="100%" z-index="9999")
    template(v-slot:activator="{ on, attrs }")
      v-list-item-title(v-bind="attrs" v-on="on" class="text-left font-weight-bold")
        v-btn(text block @click="openDialog") {{ $t('tenant.join.title') }}
    v-card(fill-height)
      v-toolbar(flat color="transparent")
        v-spacer
        v-btn(icon @click="closeDialog")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-icon(v-bind="attrs" v-on="on") mdi-close
            span {{ $vuetify.lang.t('$vuetify.close') }}
      v-container(fill-height)
        v-row(justify="center" align="start" class="fill-height")
          v-col(cols="12" class="justify-center")
            v-stepper(v-model="step" flat class="elevation-0" :style="{ 'background': 'transparent' }")
              v-stepper-header(v-show="step > 1" class="elevation-0")
                v-stepper-step(step="1") Join team
              v-stepper-items
                v-stepper-content(step="1" color="transparent")
                  JoinTenantStepperOne(@close="closeDialog" @next="step = 2" :force-close="dialog")
</template>

<script>
import JoinTenantStepperOne from "@/components/tenant/joining/JoinTenantStepperOne";

export default {
  name: "JoinTenantDialog",
  components: {JoinTenantStepperOne},
  data() {
    return {
      dialog: false,
      loading: false,
      step: 1,
      editedItem: {
        inviteCode: "",
      },
      defaultItem: {
        inviteCode: "",
      }
    }
  },
  methods: {
    reset() {
      this.step = 1
      this.dialog = false;
    },
    closeDialog() {
      // After the first step the tenant was successfully created
      if (this.step > 1) {
        this.$router.push('/tenant')
      }
      this.reset()
    },
    openDialog() {
      // fireAnalytics.logEvent('help_clicked');
      console.log('tenant creation clicked')
    },
  }
}
</script>

<style scoped>

</style>