<template lang="pug">
  v-container(class="justify-center align-center" fluid)
    v-row(justify="start" align="center")
      v-col(cols="12" class="ml-md-8 mb-0 pb-0")
        h2.text-h4 Team
      v-col(cols="12" class="ml-xs-0 mr-xs-0 pl-md-8")
        TenantNavigation
      v-col(cols="12" class="ml-xs-0 mr-xs-0 pl-md-8")
        TenantMembers
</template>
<script>


import TenantMembers from "@/components/tenant/TenantMembers";
import TenantNavigation from "@/components/tenant/TenantNavigation";
export default {
  name: "TenantMembersPage",
  components: {TenantNavigation, TenantMembers},
};
</script>