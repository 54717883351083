<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    EmailRecovery(v-if="mode==='recoverEmail'"  :action-code="actionCode" :continue-url="continueUrl" :lang="lang")
    EmailVerification(v-else-if="mode==='verifyEmail'" :action-code="actionCode" :continue-url="continueUrl" :lang="lang")
    PasswordResetConfirmationForm(v-else-if="mode==='resetPassword'" :action-code="actionCode" :continue-url="continueUrl")
    PaymentConfirmationFeedback(v-else-if="mode==='payment'" :actionCode="actionCode" :description="description" :lang="lang")
    p(v-else) Unrecognized mode
</template>

<script>
import { mapActions } from "vuex";
import PasswordResetConfirmationForm from "@/components/recovery/PasswordResetConfirmationForm";
import EmailVerification from "@/components/emailVerification/EmailVerification";
import EmailRecovery from "@/components/recovery/EmailRecovery";
import PaymentConfirmationFeedback from "@/components/feedback/PaymentConfirmationFeedback";

/**
 * Firebase Custom Email Handler
 * https://firebase.google.com/docs/auth/custom-email-handler
 */
export default {
  components: {EmailRecovery, EmailVerification, PasswordResetConfirmationForm,PaymentConfirmationFeedback},
  data() {
    return {
      mode: "",
      actionCode: "",
      continueUrl: "",
      description: "",
      lang: "",
      response: null,
      validModes: ['resetPassword', 'recoverEmail', 'verifyEmail', 'joinTeam','payment'],
    };
  },
  methods: {
    ...mapActions([
      "storeInviteCode",
    ]),
  },
  mounted(){
    // Get the action to complete.
    this.mode = this.$route.query.mode;
    // Get the one-time code from the query parameter.
    this.actionCode = this.$route.query.oobCode;
    // (Optional) Get the continue URL from the query parameter if available.
    this.continueUrl = this.$route.query.continueUrl;
    // (Optional) Get the language code if available.
    this.lang = this.$route.query.lang || 'en';
    // (Optional) Get Event or error description.
    this.description = this.$route.query.descr || '';

    // The webpage can be accessed from apps, which is why we have to set the locale manually
    if (this.lang) {
      this.$vuetify.lang.current = this.lang
      this.$i18n.locale = this.$vuetify.lang.current;
    }

    // If the mode is not valid we will redirect to the login page
    if(!this.validModes.includes(this.mode)) {
      this.$router.push('/login')
    }
    if (this.mode == 'joinTeam') {
      this.storeInviteCode(this.actionCode);
      this.$router.push('/profile')
    }
  },
}
</script>

<style>

</style>