<template lang="pug">
  v-container(class="fill-height" fluid id="container" ref="container")
    v-row(align="center" justify="start")
      v-col(cols="12" sm="12" md="12" lg="8" xl="6")
        v-form(@submit.prevent="onRegister" class="ml-12 mr-12")
          v-row(align="center" justify="center")
            v-col(cols="12")
              h2.text-h5.font-weight-regular {{ $vuetify.lang.t('$vuetify.register.title') }}
            v-col(cols="12" class="mb-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.register.email') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="register.email"
                required
                outlined
                dense
                type="email"
                label=""
                :error-messages="emailErrors"
                @input="$v.register.email.$touch()"
                @blur="$v.register.email.$touch()"
              )
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.register.password') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="register.password"
                required
                outlined
                dense
                label=""
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages="passwordErrors"
                @input="$v.register.password.$touch()"
                @blur="$v.register.password.$touch()"
                @click:append="showPassword = !showPassword"
              )
            v-col(cols="12" class="mt-0 pt-0")
              p.text-caption.fontColorLight--text
                | {{ $vuetify.lang.t('$vuetify.register.productUpdates.description') }}
                router-link(to="/privacy")  {{ $vuetify.lang.t('$vuetify.register.productUpdates.linkText') }}
            v-col(cols="12")
              v-btn(type="submit" block color="primary" :loading="loading") {{ $vuetify.lang.t('$vuetify.register.register') }}
            v-col(cols="12" class="text-center")
              p.text-body-2.fontColorLight--text
                | {{ $vuetify.lang.t('$vuetify.register.login.question') }}
                router-link(to="/login")  {{ $vuetify.lang.t('$vuetify.register.login.linkText') }}
</template>

<script>
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, alpha, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters } from "vuex";
import Logo from "../core/Logo";

export default {
name: "RegisterForm",
  mixins: [validationMixin],
  components: {Logo},
  validations: {
    register: {
      email: { required, email },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
    },
  },
  data() {
    return {
      loading: false,
      showPassword: false,
      register: {
        email: '',
        password: '',
        firstname: '',
        surname: '',
        newsletter: false
      },
      defaultRegister: {
        email: '',
        password: '',
        firstname: '',
        surname: '',
        newsletter: false
      },
    };
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "currentUser",
    ]),
    emailErrors() {
      const errors = []
      if (!this.$v.register.email.$dirty) return errors
      !this.$v.register.email.email && errors.push(this.$vuetify.lang.t('$vuetify.register.emailError.valid'))
      !this.$v.register.email.required && errors.push(this.$vuetify.lang.t('$vuetify.register.emailError.required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.register.password.$dirty) return errors
      !this.$v.register.password.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.register.passwordError.minimum'))
      !this.$v.register.password.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.register.passwordError.maximum'))
      !this.$v.register.password.required && errors.push(this.$vuetify.lang.t('$vuetify.register.passwordError.required'))
      return errors
    },
  },
  methods: {
    resetForm() {
      this.$v.$reset()
      this.register = {... this.defaultRegister }
    },
    ...mapActions(['signUpAction']),
    async onRegister() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      await this.signUpAction({
        email: this.register.email,
        alias: this.register.email.substring(0, this.register.email.indexOf("@")),
        password: this.register.password
      }).then(async () => {
        await this.$emit('registration-completed', this.register)
        this.resetForm()
      })
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>