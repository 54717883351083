export default {
    $vuetify: {
        productName: "Yndooo",
        paidFeatureHint: 'This is a paid feature',
        menu: "menu",
        search: "Search for ...",
        badge: "Badge",
        close: "Close",
        save: "Save",
        cancel: 'Cancel',
        confirm: 'Confirm',
        locked: 'Locked',
        lock: 'Lock',
        unlock: 'Unlock',
        delete: 'Delete',
        share: 'Share',
        comment: 'Comment',
        status: 'Status',
        created: 'Created',
        modified: 'Modified',
        creator: 'Creator',
        by: 'by',
        title: 'Title',
        likes: 'Likes',
        joined: 'Joined',
        actions: 'Actions',
        edit: 'Edit',
        move: 'Move to Tenant',
        join: 'Join',
        displayName: 'Display Name',
        setAsActive: 'Set as Active',
        requiredFieldIndication: "*indicates required field",
        generate: 'Generate',
        undefined: 'Undefined',
        loading: 'Loading',
        addNewCard: "New card",
        testLink: "Test Link",
        color: "Color",
        labels: "Labels",
        newLabel: "New Label",
        newItem: "New Item",
        editItem: "Edit Item",
        dataIterator: {
            noResultsText: "No matching records found",
            loadingText: "Loading items...",
        },
        dataTable: {
            itemsPerPageText: "Rows per page:",
            ariaLabel: {
                sortDescending: "Sorted descending.",
                sortAscending: "Sorted ascending.",
                sortNone: "Not sorted.",
                activateNone: "Activate to remove sorting.",
                activateDescending: "Activate to sort descending.",
                activateAscending: "Activate to sort ascending.",
            },
            sortBy: "Sort by",
        },
        dataFooter: {
            itemsPerPageText: "Items per page:",
            itemsPerPageAll: "All",
            nextPage: "Next page",
            prevPage: "Previous page",
            firstPage: "First page",
            lastPage: "Last page",
            pageText: "{0}-{1} of {2}",
        },
        datePicker: {
            itemsSelected: "{0} selected",
            nextMonthAriaLabel: "Next month",
            nextYearAriaLabel: "Next year",
            prevMonthAriaLabel: "Previous month",
            prevYearAriaLabel: "Previous year",
        },
        noDataText: "No data available",
        carousel: {
            prev: "Previous visual",
            next: "Next visual",
            ariaLabel: {
                delimiter: "Carousel slide {0} of {1}",
            },
        },
        calendar: {
            moreEvents: "{0} more",
        },
        fileInput: {
            counter: "{0} files",
            counterSize: "{0} files ({1} in total)",
        },
        timePicker: {
            am: "AM",
            pm: "PM",
        },
        pagination: {
            ariaLabel: {
                wrapper: "Pagination Navigation",
                next: "Next page",
                previous: "Previous page",
                page: "Goto Page {0}",
                currentPage: "Current Page, Page {0}",
            },
        },
        login: {
            title: "Sign in to your account",
            email: "Email",
            emailError: {
                valid: "Must be a valid email",
                required: "Email is required"
            },
            passwordError: {
                minimum: "Minimum length of 6 characters",
                maximum: "Maximum length of 50 characters",
                required: "Password is required"
            },
            password: "Password",
            forgotPassword: "Forgot your password?",
            login: "Continue",
            register: {
                question: "Don't have an account?",
                linkText: "Sign up"
            },
            staySignedIn: "Stay signed in for a week"
        },
        register: {
            title: "Create your Yndooo account",
            email: "Email",
            emailError: {
                valid: "Must be a valid email",
                required: "Email is required"
            },
            passwordError: {
                minimum: "Minimum length of 6 characters",
                maximum: "Maximum length of 50 characters",
                required: "Password is required"
            },
            password: "Password",
            firstname: "Firstname",
            firstnameErrors: {
                minimum: "Minimum length of 1 characters",
                maximum: "Maximum length of 1500 characters",
                alpha: "A name can only consist of letters",
                required: "Firstname is required"
            },
            surname: "Surname",
            surnameErrors: {
                minimum: "Minimum length of 1 characters",
                maximum: "Maximum length of 1500 characters",
                alpha: "A name can only consist of letters",
                required: "Surname is required"
            },
            username: "Username",
            usernameErrors: {
                minimum: "Minimum length of 4 characters",
                maximum: "Maximum length of 150 characters",
                alpha: "A name can only consist of letters",
                required: "Username is required"
            },
            register: "Create account",
            login: {
                question: "Have and account?",
                linkText: "Sign in"
            },
            productUpdates: {
                description: "By signing up for and by signing in to this service you accept our",
                linkText: "Terms of use & Privacy Policy"
            },
            numberOfSellingPoints: 3,
            sellingPoints: [
                {
                    title: "Quick and free sign‑up",
                    description: "Enter your email address to create an account."
                },
                {
                    title: "Start organizing your company",
                    description: "Use Yndooo’s dashboard to save, sort, share links and more."
                },
                {
                    title: "DSGVO conform",
                    description: "Your privacy is important to us."
                },
            ],
            feedback: {
                title: "Email verification",
                description: "We have send you an email to confirm your account.",
                return: "Return to sign in",
                resend: {
                    question: "Email has not arrived?",
                    link: "Resend the link."
                },
            }
        },
        reset: {
            title: "Password reset",
            description: "Enter the email address associated with your account and we'll send you a link to reset your password.",
            email: "Email",
            reset: "Continue",
            return: "Return to sign in",
            confirmation: "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes."
        },
        passwordResetConfirmation: {
            description: "Create a new password for",
            password: "Password",
            passwordConfirmation: "Password Confirmation",
            reset: "Change password",
            return: "Return to the application",
            contact: "Problems while resettings your password?",
            confirmation: "Your password was successfully changed.",
            rejection: "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
            passwordError: {
                minimum: "Minimum length of 6 characters",
                maximum: "Maximum length of 50 characters",
                required: "Password is required"
            },
            passwordConfirmationError: {
                minimum: "Minimum length of 6 characters",
                maximum: "Maximum length of 50 characters",
                required: "Please confirm your password",
                sameAsPassword: "Does not match password"
            },
        },
        emailVerification: {
            return: "Return to the application",
            contact: "Problems while verifying your email?",
            confirmation: "Your email was successfully verified. You can now sign in with you account credentials.",
            rejection: "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
        },
        emailRecovery: {
            return: "Return to the application",
            contact: "Problems while restoring your email?",
            confirmation: "Your email was successfully restored.",
            rejection: "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
            passwordResetQuestion: "Would you like to reset you password as well?",
        },
        navBar: {
            switchTo: "Switch to",
            privateMode: "Private Mode",
            editProfile: "Settings",
            editTenant: "Edit Tenant",
            logOut: "Log Out",
            dashboard: "Dashboard",
            userDocumentation: "Help",
        },
        profile: {
            title: "User Profile",
            tabs: {
                account: "Account",
                profile: "Profile",
                preferences: "Preferences",
                public: "Public Profil",
            },
            dangerZone: {
                title: "Delete Account",
                disclaimer: "The deletion of your account is permanent. You will loose access to all your teams and links.",
                contactSupport: "Contact support",
            },
            titleSelectTheme: "Select a theme",
            themeDarkModeHint: "This toggles let your eyes rest",
            themeDarkMode: "Dark Mode",
            titleSelectLanguage: "Select language",
            titleProfile: "Profile Information",
            titlePreferences: "Preferences",
            titleChangePassword: "Change Password",
            titleCurrentEmail: "Current Email",
            titleTenants: "Tenants",
            titlePublic: "Public Profile",
            labelOldpassword: "Old Password",
            labelNewpassword: "New Password",
            labelRepeatnewpassword: "Repeat New Password",
            labelName: "Name",
            labelSurname: "Surname",
            labelEmail: "Email",
            labelAlias: "Alias",
            labelYear: "Year",
            labelMonth: "Month",
            labelDay: "Day",
            labelPublicEmail: "Public Email",
            labelPublicPhone: "Phone Number",
            labelDescription: "Description",
            birthdate: "Date of Birth:",
            buttonChangePassword: "Change Password",
            buttonUpdateEmail: "Update Email",
            buttonLeaveTenant: "Leave Tenant",
            public: {
                labelPublicEmail: "Email",
                labelPublicPhone: "Phone number",
                labelDescription: "Description",
                save: "Save profile"
            }
        },
        tenant: {
            titleEditTenant: 'Edit Tenant Information',
            titleCreateTenant: "Name your team",
            titleMembers: "Members",
            titleInvitationCodes: "Invitations",
            titlePreview: "Preview:",
            titleCustomLabels: "Custom Labels",
            labelDisplayName: "Display name",
            labelCompanyName: "Company name*",
            labelCompanyAdress: "Street",
            labelCompanyCountry: "Country",
            labelCompanyZip: "Zip code",
            labelCompanyState: "State",
            labelCompanyCity: "City",
            labelCompanyContactPerson: "Contact person name",
            labelCompanyContactPersonPosition: "Contact person position",
            labelCompanyContactPersonEmail: "Contact person e-mail",
            labelCompanyContactPersonPhone: "Contact person phone number",
            buttonCreateLabel: "Create a new custom label",
            buttonEditLabel: "Edit selected label",
            buttonDeleteLabel: "Delete selected label",
            dangerZone: {
                title: "Delete Team",
                disclaimer: "The deletion of your team is permanent. You will loose access to all associated links.",
                contactSupport: "Contact support",
            },
        },
        link: {
            editLink: "Edit Link",
            createLink: "Create a new Link",
            labelLink: "Link*",
            labelTitle: "Title*",
            labelType: "Link type",
            labelSelect: "Select labels",
            labelDescription: "Description",
            tagLabel: 'Select Tags',
            tagHint: 'Type anything to create a new tag',
        },
        rules: {
            fieldnotification: 'This field is required',
            fieldvalidation: 'Min 3 characters',
            mincharacters: 'Min {0} characters',
            maxcharacters: 'Max {0} characters',
            between: 'The value must be between {0} and {1}',
            nowhitespaces: 'No white spaces allowed',
            emailnotification: 'E-mail is required',
            emailvalidation: 'E-mail must be valid',
            yearhint1: "Year must be 4 digits",
            yearhint2: "Year cant be negative",
            passwordHint: "Password must contain at least 6 characters",
            passwordnotification: 'Password is required',
            passwordnotificationminchar: 'Min 6 characters',
            passwordnotificationmaxchar: 'Max 10 characters',
            passwordnotificationreg1: 'Password must contain at least 1 uppercase character',
            passwordnotificationreg2: 'Password must contain at least 1 lowercase character',
            passwordnotificationreg3: 'Password must contain at least 1 number',
            passnotmatch: "Passwords did not match",
            oldnewpassnotsame: "Old password and New password can not be the same",
        },
        confirmDialog: {
            titleGeneric: 'Please Confirm',
            messageGeneric: 'Are you sure?',
            messageLeaveTenant: 'Are you sure? This process can not be undone!',
            titleDeleteCard: 'Delete Card',
            messageDeleteCard: 'Card will be permanantly deleted',
            titleMoveCard: 'Move Card',
            messageMoveCard: 'Card will be permanantly moved to Tenant',
            titleUpdateProfile: 'Update Profile',
            titleUpdatePublicProfile: 'Update Public Profile',
            titleUpdateEmail: 'Update Email',
            messageUpdateEmail: 'You will have to verify your new e-mail',
            titleChangePassword: 'Change Password',
            titleDeleteLabel: 'Delete Label',
            titleSaveLabel: 'Save Label',
            titleGenerateInvitation: 'Generate Invite Code',
            titleDeleteCode: 'Delete Code',
            titleAcceptMember: 'Accept New Member',
            titleRejectMember: 'Reject New Member',
            titleLeaveTenant: 'Leave Tenant',
            titleChangeMemberStatus: 'Change Member Status',
            titleSendTeamInviteEmail: 'Send Invite Code E-Mail',
            messageSendTeamInviteEmail: 'Please enter the E-Mail address to which you would like to send the Invite Code'
        },
        record: {
            tipPrivate: 'This is your private Link',
            tipClipboard: 'Copy Link to clipboard',
            tipTwitter: 'Share Link on Twitter',
            tipFacebook: 'Share Link on Facebook',
            tipWhatsApp: 'Share Link on WhatsApp',
            tipEmail: 'Share Link by E-Mail',
            tipLinkedIn: 'Share Link on LinkedIn',
            tipQR: 'Share QR Code',
            tinyURL: 'Create a TinyURL link'
        },
        codes: {
            code: 'Code',
            type: 'Code Type',
            YndoooCodes: "Yndooo Codes",
            inviteeEmail: "Invitee E-Mail",
            generateCodes: 'Generate Codes',
            generateInvitationCode: 'Generate Invitation code',
            generateInvitationCodeTutorial: "You can invite people to your team with an invitation code. You have to give the code to the person you would like to invite, so that they can use it to join your team. <br> The join team function can be found to the left, where one can switch the team.",
            invitationCode: 'Invitation Code',
            invitationCodes: 'Invitation Codes',
            invitations: 'Invitations',
            pendingInvitations: "Pending Invitations",
            pendingCodes: "Pending Codes",
            labelEnterCode: "Enter your code here",
            tipClipboard: 'Copy Code to clipboard',
            tipDelete: 'Delete Code',
            tipAccept: 'Accept new member',
            tipReject: 'Reject new member',
            tipMail: 'Send again'
        },
        filter: {
            titleSelectLanguage: "Select Language",
            labelTitle: 'Label filter',
            labelSubtitle: 'Select labels which will be used as filters. Labels are used with an AND condition.',
            typeTitle: 'Type filter',
            typeSubtitle: 'Select types which will be used as filters. Types are used with an OR condition.',
            SearchLabel: 'Tags to filter for',
            SearchHint: 'Use non existing tags to do a full text search',
            noResults: "No results matching",
            clear: "Clear filters",
        },
        notificatons: {
            'card/created-private': 'Private card successfully created',
            'card/moved': 'Card successfully moved',
            'card/moved-tenant': 'Card moved to tenant',
            'card/already-liked': 'You already like this card',
            'user/created': 'Profile successfully created, please verify your e-mail before signing in',
            'user/not-signed-in': 'User not signed in,',
            'user/profile-updated': 'Profile successfully updated',
            'user/profile-deleted': 'Profile successfully deleted',
            'user/public-profile-updated': 'Profile public Information updated',
            'user/alias-updated': 'Alias successfully updated',
            'user/alias-taken': 'Alias is Taken',
            'user/email-changed': 'E-mail successfully changed',
            'user/email-verify': 'Please verify your e-mail before signing in',
            'user/email-verify-sent': 'Verification E-mail successfully sent',
            'user/password-reset-sent': 'Password reset E-mail successfully sent',
            'user/password-changed': 'Password successfully changed',
            'user/no-tenants': 'User does not belong to any Tenant',
            'tenant/created': 'Tenant successfully created',
            'tenant/updated': 'Tenant successfully updated',
            'tenant/member-added': 'Member succesfully added',
            'tenant/member-status-updated': 'Member status updated',
            'tenant/member-status-update-self': 'You can not edit your own priveleges',
            'tenant/no-member-count': 'Tenant does not contain a member count',
            'tenant/not-member': 'User is not a member of this tenant',
            'tenant/no-members': 'Tenant has no members',
            'tenant/displayname-updated': 'Display Name updated',
            'tenant/left': 'Successfully left the tenant',
            'tenant/does-not-exist': 'Tenant with this ID does not exist',
            'tenant/no-active-tenant': 'There is no active tenant',
            'tenant/no-label-count': 'Tenant does not contain a custom label count',
            'code/created': 'Code successfully created',
            'code/deleted': 'Code successfully deleted',
            'code/recognized': 'Code Recognized',
            'code/already-used': 'This code has already been used',
            'code/not-found': 'Code not found',
            'code/already-a-member': 'You are already a member of this Team',
            'code/rejected': 'Code Rejected',
            'code/email-sent': 'E-mail with Invite code successfully sent',
            'code/wrong-email': 'This code is already associated with an account with a different Email',
            'code/locked': 'This code is locked',
            errors: {
                'yndooo/missing-card': 'Could not find the card on Server, removing from local Store',
                'yndooo/not-invite-code': 'This is not an invite Code',
                'yndooo/tenant-members-empty': 'Tenant has no members',
                'auth/wrong-password': 'The password is invalid or the user does not have a password',
                'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
                'auth/invalid-email': 'Email address is not valid.',
                'auth/user-disabled': 'This account has been disabled.',
                'auth/user-not-found': 'The password is invalid or the user does not have a password',
                'auth/email-already-in-use': 'This Email is already in use',
                'auth/requires-recent-login': 'This operation requires you to be recently loged in',
                'unknown': 'Unknown error or an error from a different error domain.',
                'invalid-argument': 'Client specified an invalid argument.',
                'not-found': 'Some requested document was not found.',
                'already-exists': 'Some document that we attempted to create already exists.',
                'permission-denied': 'You do not have permission to execute the specified operation.',
                'internal': 'Internal errors.',
                'unavailable': 'The service is currently unavailable.',
                'data-loss': 'Unrecoverable data loss or corruption.',
                'unauthenticated': 'The request does not have valid authentication credentials for the operation.',
            }
        },
        mail: {
            teamInvite: {
                subject: 'Yndooo team invitation',
                body1: 'Hello,<br>You are invited to a Yndooo team - ',
                body2: '<br><br> Follow this link to login to your Yndooo account, and accept the invitation<br><br> <a href="',
                body3: '">Join Team</a><br><br> If you do not yet have a Yndooo account then please use the next link to sign up for a yndooo account <br><br> <a href="',
                body4: '">Sign up</a><br><br>Once you are signed up and have verified your e-mail, you can use the previous link to accept the invitation or you can use the following code directly in your user profile settings:<br><br>',
                body5: '<br><br>Please note that this invitation code will only work with account that is associated with this e-mail'
            }
        }
    },
    error: {
        "404": {
            "title": "Whoops! Lost in Space?",
            "description": "The page you are looking for isn`t found."
        },
        "500": {
            "title": "Oh, no. An error occurred",
            "description": "We were notified and are working on the issue."
        },
        "return": "Go Back"
    },
    reset: {
        title: "Passwort recovery",
        description: "Enter the email address associated with your account and we'll send you a link to reset your password.",
        email: "Email",
        reset: "Continue",
        return: "Return to sign in",
        confirmation: {
            title: "Recovery email",
            description: "We have send you an email to reset your password. It should arrive within a few minutes.",
        }
    },
    navBar: {
        switchTo: "Switch to",
        privateMode: "Private Mode",
        editProfile: "Settings",
        editTenant: "Team",
        logOut: "Log Out",
        dashboard: "Dashboard",
        billing: "Payments",
        userDocumentation: "Help",
    },
    pricing: {
        "title": {
            "partOne": "Flexible",
            "partTwo": " Plans"
        },
        "description": "Choose a plan that works best for you and your team.",
        "plans": {
            "max t-users": "Max Users",
            "no ads": "No Ads",
            "card lock": "Card Lock",
            "t-admins": "Admins",
            "advanced sorting": "Advanced sorting",
            "t-custom-labels": "Custom labels",
            "pm-ads-free-for-t-members": "No Ads for members"
        },
    },
    profile: {
        titleSelectTheme: "Select a theme",
        themeDarkModeHint: "This toggles let your eyes rest",
        themeDarkMode: "Dark Mode",
        titleSelectLanguage: "Select language",
        titleProfile: "Profile Information",
        titleChangePassword: "Change Password",
        titleCurrentEmail: "Current Email",
        titleTenants: "Tenants",
        labelOldpassword: "Old Password",
        labelNewpassword: "New Password",
        labelRepeatnewpassword: "Repeat New Password",
        labelName: "Name",
        labelSurname: "Surname",
        labelEmail: "Email",
        labelAlias: "Alias",
        labelYear: "Year",
        labelMonth: "Month",
        labelDay: "Day",
        birthdate: "Date of Birth:",
        buttonChangePassword: "Change Password",
        buttonUpdateEmail: "Update Email",
        buttonLeaveTenant: "Leave Tenant",
    },
    tenant: {
        titleEditTenant: 'Edit Tenant Information',
        titleCreateTenant: "Name your team",
        btnCreateTenant: "Create team",
        creation: {
            skip: "Skip for now",
            continue: "Continue",
            step1: {
                stepperName: "Create team",
                title: "Name your team",
                subtitle: "After creating a team, you can invite others to join.",
                name: "Name",
                create: "Create team",
            },
            step2: {
                stepperName: "Add team members",
                title: "Add your collaborators",
                subtitle: "You can update user permissions on the team page after setting up.",
                email: "Email",
                name: "Name",
                addAnother: "Add another",
            },
            step3: {
                stepperName: "Finish setup",
                title: "Choose your team plan",
                subtitle: "You can update the payment plan later on in the team settings.",
            }
        },
        fields: {
            displayName: "Name",
        },
        join: {
            step1: {
                title: "Join a team",
                subtitle: "The team owner can give you the invitation code. If you were invited via email, the code can be found at the bottom half of the invitation.",
                code: "Invitation code",
                join: "Join team"
            },
            title: "Join team",
            button: "Join team",
            code: "Invitation code",
        },
        invite: {
            btnInvite: "Invite"
        },
        roles: {
            owner: "Owner",
            admin: "Admin",
        },
        tabs: {
            overview: "Overview",
            members: "Members",
            labels: "Labels",
            subscription: "Subscription"
        },
        titleMembers: "Members",
        titleInvitationCodes: "Invitations",
        titlePreview: "Preview:",
        titleCustomLabels: "Custom Labels",
        labelDisplayName: "Display name",
        labelCompanyName: "Company name*",
        labelCompanyAdress: "Street",
        labelCompanyCountry: "Country",
        labelCompanyZip: "Zip code",
        labelCompanyCity: "City",
        labelCompanyContactPerson: "Contact person name",
        labelCompanyContactPersonPosition: "Contact person position",
        labelCompanyContactPersonEmail: "Contact person e-mail",
        labelCompanyContactPersonPhone: "Contact person phone number",
        labels: {
            buttonCreateLabel: "Create label",
            buttonEditLabel: "Edit label",
            buttonDeleteLabel: "Delete label",
            toolbarCreateLabel: "Create label",
            toolbarEditLabel: "Edit label",
            search: "Search for labels ...",
            fields: {
                actions: "Actions",
                title: "Title",
                description: "Description",
                color: "Color"
            }
        },
        members: {
            dangerZone: "Danger Zone",
            dangerZoneInfo: "Please note that these actions are permanent. There is no way to undo them.",
            leaveTenant: "Leave Team",
            inviteAccepted: "Accepted by"
        },
        subscription: {
            title: "Active Subscription",
            currentPlan: {
                switchToAnnual: "Switch to annual plan",
                switchToMonthly: "Switch to monthly plan",
                manage: "Manage my subscription",
                invoices: "My Invoices",
                annualSubscription: "Annual Subscription",
                monthlySubscription: "Monthly Subscription",
                began: "Began",
                ends: "Ends",
                month: "month",
                year: "year",
                save: "Save"
            },
            plans: {
                'Yndooo - Free Subscription': "Free",
                'Yndooo - Team Subscription': "Team",
                'Yndooo - Business Subscription': "Business",
                'Yndooo - Enterprise Subscription': "Enterprise",
            },
            change: {
                title: "Change Subscription",
                description: "You can now switch to one of our new offers simply by clicking below. Do you need better search functions, more team members or custom labels? It's all here!"
            },
            limits: {
                overLabelLimit: "You have reached your Custom Label limit",
            },
            feedback: {
                success: {
                    title: "Payment Successfull",
                    description: "You now have an active subscription",
                    return: "Return to dashboard",
                },
                cancel: {
                    title: "Payment Cancelled",
                    description: "Subscription payment was cancelled",
                    return: "Return to dashboard",
                },
                error: {
                    title: "Payment Error",
                    description: "Unrecognized error",
                    return: "Return to dashboard",
                }
            }
        },
    },
    link: {
        editLink: "Edit Link",
        createLink: "Create a new Link",
        labelLink: "Link*",
        labelTitle: "Title*",
        labelType: "Link type",
        labelSelect: "Select labels",
        labelDescription: "Description",
        tagLabel: 'Select Tags',
        tagHint: 'Type anything to create a new tag',
    },
    rules: {
        fieldnotification: 'This field is required',
        fieldvalidation: 'Min 3 characters',
        nowhitespaces: 'No white spaces allowed',
        emailnotification: 'E-mail is required',
        emailvalidation: 'E-mail must be valid',
        yearhint1: "Year must be 4 digits",
        yearhint2: "Year cant be negative",
        passwordHint: "Password must contain at least 6 characters",
        passwordnotification: 'Password is required',
        passwordnotificationminchar: 'Min 6 characters',
        passwordnotificationmaxchar: 'Max 10 characters',
        passwordnotificationreg1: 'Password must contain at least 1 uppercase character',
        passwordnotificationreg2: 'Password must contain at least 1 lowercase character',
        passwordnotificationreg3: 'Password must contain at least 1 number',
        passnotmatch: "Passwords did not match",
        oldnewpassnotsame: "Old password and New password can not be the same",
    },
    confirmDialog: {
        titleGeneric: 'Please Confirm',
        messageGeneric: 'Are you sure?',
        titleDeleteCard: 'Delete Card',
        messageDeleteCard: 'Card will be permanantly deleted',
        titleMoveCard: 'Move Card',
        messageMoveCard: 'Card will be permanantly moved to Tenant',
        titleUpdateProfile: 'Update Profile',
        titleUpdateEmail: 'Update Email',
        messageUpdateEmail: 'You will have to verify your new e-mail',
        titleChangePassword: 'Change Password',
        titleDeleteLabel: 'Delete Label',
        titleSaveLabel: 'Save Label',
        titleGenerateInvitation: 'Generate Invite Code',
        titleDeleteCode: 'Delete Code',
        titleAcceptMember: 'Accept New Member',
        titleRejectMember: 'Reject New Member',
        titleLeaveTenant: 'Leave Tenant',
        titleChangeMemberStatus: 'Change Member Status'
    },
    record: {
        tipPrivate: 'This is your private Link',
        tipClipboard: 'Copy Link to clipboard',
        tipTwitter: 'Share Link on Twitter',
        tipFacebook: 'Share Link on Facebook',
        tipWhatsApp: 'Share Link on WhatsApp',
        tipEmail: 'Share Link by E-Mail',
        tipLinkedIn: 'Share Link on LinkedIn',
        tipQR: 'Share QR Code',
        tinyURL: 'Create a TinyURL link'
    },
    filter: {
      titleSelectLanguage: "Select Language",
      labelTitle: 'Label filter',
      labelSubtitle: 'Select labels which will be used as filters. Labels are used with an AND condition.',
      typeTitle: 'Type filter',
      typeSubtitle: 'Select types which will be used as filters. Types are used with an OR condition.',
      SearchLabel: 'Tags to filter for',
      SearchHint: 'Use non existing tags to do a full text search',
    },
    tutorial: {
        introduction: {
            default: {
                title: "Welcome to Yndooo",
                text: "How nice to see you. In this tutorial we will show you your first steps.",
                imgUrl: "/img/tutorial/01_girl_introduction.svg",
                skipAllBtn: "Skipp All",
                backBtn: "",
                nextBtn: "Next",
            },
            easterPromo: {
                title: "Yndooo wishes you happy easter",
                text: "How nice to see you. In this tutorial we will show you your first steps.",
                imgUrl: "/img/tutorial/01_girl_introduction.svg",
                skipAllBtn: "Skipp All",
                backBtn: "",
                nextBtn: "Next",
            }
        },
        step1: {
            title: "Dashboard",
            text: "On your dashboard, you can access all functions and switch between Private Mode and Team Mode. Private Mode, as the name suggests, is everything that only you can look at.",
            imgUrl: "/img/tutorial/02_create_team.svg",
            backBtn: "Back",
            nextBtn: "Next",
        },
        step2:{
            title: "Create you first card",
            text: "Never lose an important link or website again! Simply save them on your dashboard in Team or Private Mode by clicking Add New.",
            imgUrl: "/img/tutorial/03_add_person_to_team.svg",
            backBtn: "Back",
            nextBtn: "Next",
        },
        step3: {
            title: "Create you first team",
            text: "Creating a team is easy! To do this, go to Go to the left drop down menu and select Create Team! You can then enter the name of your team.",
            imgUrl: "/img/tutorial/04_create_first_card.svg",
            backBtn: "Back",
            nextBtn: "Next",
        },
        step4: {
            title: "Set your preferences",
            text: "Now you can set your personal preferences or complete your profile. If you want to access this overview again later, click on Help. Have fun with Yndooo",
            imgUrl: "/img/tutorial/05_preferences.svg",
            backBtn: "Back",
            nextBtn: "Finish",
        },
    },
};