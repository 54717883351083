<template lang="pug">
  v-container(fluid ma-0)
    v-row(v-scroll="onScroll")
      v-col(v-for="link in linksToShow" :key="link.id" cols="12" md="6" lg="4" xl="3")
        Record(:link="link")
      <!-- Bottom indicator that additional cards are loaded, when reaching the end !-->
      v-col(v-if="loadingMoreCards && isThereMoreCards" v-for="(item, i) in numberOfLoadingCards" :key="`'skeleton-loader-2-'${i}`" cols="12" md="6" lg="4" xl="3")
        v-skeleton-loader(type="card" loading)
      v-col(cols="12" md="6" lg="4" xl="3")
        NewCard
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import Record from './Record'
import NewCard from "@/components/cards/NewCard";
import Link from "@/store/classes/Link";

export default {
  components: {
    NewCard,
    Record,
  },
  data: () => ({
    reachedBottom: false,
  }),
  computed: {
    ...mapGetters([
      'filteredLinks',
      'cardPagination',
      'isThereMoreCards',
      "isLoadingGlobal",
      "loadingCards",
      "loadingMoreCards",
      "cardPagination",
    ]),
    /**
     * If we use two rows instead of one and make them responsive with an v-if the page gets loading problems
     * This is why we use the modified Record component as a work around
     *
     * The Record component throws errors, if the links do not have the required fields, because the components assumes they exist
     * For that reason we have to fake them with an empty component
     * In the future the Record component should be changed, to make this process more performant
     * @return {Link[]|any[]}
     */
    linksToShow() {
      return this.filteredLinks;
    },
    numberOfLoadingCards() {
      // On mobile return 2 cards, on tablet 4 and on desktop 8
      // TODO make this responsive on the actual number of loading cards
      const numRequestedCards = (this.cardPagination.nextRequestCount - this.cardPagination.firstRequestCount);
      return numRequestedCards > 0 ? numRequestedCards : this.$vuetify.breakpoint.sm ? 2 : this.$vuetify.breakpoint.mobile ? 4 : 8;
    }
  },
  methods: {
    ...mapActions([
      "newLink",
      "retrieveNextCards",
      "retrieveCards"
    ]),
    newLinkClicked() {
      this.newLink();
    },
    onScroll() {
      const cardHeight = 210;
      const numOfPrefetchCards = 4;

      this.reachedBottom = document.documentElement.scrollTop + window.innerHeight >
          document.documentElement.offsetHeight - (numOfPrefetchCards * cardHeight);
    },
  },
  watch: {
    async reachedBottom(value) {
      if (value && this.isThereMoreCards) {
        await this.retrieveNextCards();
      }
    },
  },
}
</script>
