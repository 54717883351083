import firebase from "firebase/app";

// Import needed firebase modules
import "firebase/auth";
import "firebase/firestore";
// Import the Performance Monitoring library
import "firebase/performance";
import "firebase/analytics";
import "firebase/functions";

// Remote config
import "firebase/remote-config";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  };

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);