<template>
  <v-dialog
    v-model="dialogVmodel"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card
      v-if="this.currentLink"
      dark
      color="rgba(0, 0, 0, 0.8)"
      class="text-center"
    >      
      <v-card-text
        class="pa-0"
        
      >
        <v-row
          class="d-flex flex-column justify-end"
          style="min-height: 600px;"
          no-gutters
        >
          <v-col>  
            <v-row
              class="flex-column white--text"
              no-gutters
            >
              <v-col>              
                <v-btn 
                  class="float-right" 
                  icon 
                  @click="closeShareLinkDialog"
                >
                  <v-icon
                    medium
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                style="min-height: 25px;"
                class="px-4 text-truncate"
              >
                <a
                  :href="this.currentLink.href" 
                  target="_blank"
                  class="share-link"
                >
                  {{ this.currentLink.href  }}
                </a>
              </v-col>
              <v-col>
                <p
                  class="px-4 my-2 font-weight-bold text-truncate"
                >
                  {{ this.currentLink.title }}
                </p>
              </v-col>
              <v-col>
                <p
                  class="line-clamp px-4 my-2"
                >
                  {{ this.currentLink.description }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="d-flex align-center"
          > 
            <v-row
              no-gutters
            >
              <v-col>
                <img 
                  v-if="qrSrc" 
                  :src="qrSrc"
                  height="160px"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col> 
            <v-row
              class="pa-4 align-self-end"
              no-gutters
              :justify="'space-between'"          
            >
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="copyToClipBoard"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipClipboard') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="share('twitter')"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-twitter</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipTwitter') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="share('facebook')"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-facebook</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipFacebook') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="share('whatsapp')"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-whatsapp</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipWhatsApp') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click.prevent="shareEmail"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-email-outline</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipEmail') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="share('linkedin')"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-linkedin</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tipLinkedIn') }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click.prevent="share('tinyurl')"
                      grey
                      lighten-4
                      v-bind="attrs"
                      v-on="on"
                      >mdi-link-variant</v-icon
                    >
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.record.tinyURL') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-btn
            text
            @click="closeShareLinkDialog"
            class="mx-0 mt-3"
            >
            {{ $vuetify.lang.t('$vuetify.close') }}
          </v-btn>
        </v-row>        
      </v-card-text>
    </v-card>
    <textarea
      v-if="this.currentLink"
      class="copyhref"
      style="display:none"
      v-model="this.currentLink.href"
      ref="href"
    ></textarea>
  </v-dialog>  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { qrcode } from "../main";

const Channels = [
  {
    name: "twitter",
    link: "https://twitter.com/intent/tweet?url=URLPLACEHOLDER",
  },
  {
    name: "facebook",
    link: "https://www.facebook.com/sharer/sharer.php?u=URLPLACEHOLDER",
  },
  {
    name: "whatsapp",
    link: "https://wa.me/?text=URLPLACEHOLDER",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/shareArticle?mini=true&url=URLPLACEHOLDER",
  },
  {
    name: "mailto",
    link: "mailto:",
  },
  {
    name: "tinyurl",
    link: "https://tinyurl.com/create.php?source=create&url=URLPLACEHOLDER&alias=",
  },
];

export default {
  data: () => ({
    qrSrc: null,
    qrOpts: {
      margin: 0,
      width: 160,
      color: {
        dark:"#FFFFFFFF",
        light:"#00000000"
      }
    },
  }),
  computed: {
    ...mapGetters([
      "currentLink",
      "showShareLinkDialog",
    ]),
    dialogVmodel: {
      get() {
        return this.showShareLinkDialog;
      },
      set(val) {
        this.closeShareLinkDialog;
      },
    },
  },
  methods: {
    ...mapActions([
      "closeShareLinkDialog",
    ]),
    copyToClipBoard: function() {
      let element = this.$refs.href;
      element.style.display = "inline";
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      element.style.display = "none";
      this.closeShareLinkDialog();
    },
    share: function(channelName) {
      let url = Channels.filter((channel) => channel.name == channelName)[0]
        .link;
      url = url.replace("URLPLACEHOLDER", encodeURIComponent(this.currentLink.href));
      window.open(url, "_blank");
      this.closeShareLinkDialog();
    },
    shareEmail: function() {      
      window.open(
        "mailto:?subject=" + this.currentLink.title + "&body=" + this.currentLink.href
      );
      this.closeShareLinkDialog();
    },
    createObjectUrl(err, canvas) {
      if (!err) {
        canvas.toBlob((blob) => {
          this.qrSrc = window.URL.createObjectURL(blob);
          //window.open(this.qrSrc);
        });
      } else {
        console.warn("generateQrCode:ERROR", err);
      }
    },
    generateQrCode() {
      if (!this.currentLink.href) {
        return;
      }
      window.URL.revokeObjectURL(this.qrSrc);
      qrcode.toCanvas(this.currentLink.href, this.qrOpts, this.createObjectUrl);
    },
  },
  watch: {
    currentLink: function(val) {
      if (val) {        
        this.generateQrCode()
      }
    },
  },
}
</script>

<style>
  .copyhref {
    width: 1px;
    height: 1px;
    font-size: 1px;
    overflow: hidden;
  }
  a.share-link {
    color: white !important;;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
</style>