<template lang="pug">
  v-container(fluid)
    v-row(align="center" justify="center")
      v-col(cols="12" align="center" :justify="$vuetify.breakpoint.sm? 'left' : 'center'")
        Logo
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="4" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
          v-row(align="center" justify="center" class="pl-12 pr-12").px-5.pt-5.pb-0
            v-col(cols="12")
              v-card(flat height="200" )
                v-card-text
                  div(id="lottie-email-send")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light {{ title ? title : $vuetify.lang.t('$vuetify.register.feedback.title') }}
            v-col(cols="8" class="text-center")
              h2.text-subtitle-2.font-weight-regular {{ description? description : $vuetify.lang.t('$vuetify.register.feedback.description') }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/login')") {{ $vuetify.lang.t('$vuetify.register.feedback.return') }}
            v-col(cols="12")
              h2.text-subtitle-2.font-weight-regular
                | {{ $vuetify.lang.t('$vuetify.register.feedback.resend.question') }}
                v-btn(text color="primary" @click="resendLink")
                  h2.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.register.feedback.resend.link') }}
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form
          v-row(align="center" justify="center")
            v-col(cols="8" class="text-center")
              v-col(cols="12")
                v-card(flat height="200" )
                  v-card-text
                    div(id="lottie-email-send-mobile")
            v-col(cols="12" class="text-center")
              h2.text-h4.font-weight-light {{ title ? title : $vuetify.lang.t('$vuetify.register.feedback.title') }}
            v-col(cols="8" class="text-center mt-0 pt-0")
              h2.text-subtitle-2.font-weight-regular {{ description? description :  $vuetify.lang.t('$vuetify.register.feedback.description') }}
            v-col(cols="12")
              v-btn(color="primary" @click="$router.push('/login')") {{ $vuetify.lang.t('$vuetify.register.feedback.return') }}
            v-col(cols="12")
              h2.text-subtitle-2.font-weight-regular
                | {{ $vuetify.lang.t('$vuetify.register.feedback.resend.question') }}
                v-btn(text color="primary" @click="resendLink")
                  h2.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.register.feedback.resend.link') }}</template>

<script>

import Logo from "../core/Logo";
import {mapActions} from "vuex";
export default {
  name: "EmailSendFeedback",
  components: {Logo},
  data() {
    return {
      loading: false,
      svgContainerMobile: null,
      animItemMobile: null,
      svgContainer: null,
      animItem: null,
    };
  },
  props: {
    title: {
      type: String,
      default: "",
      required: false,
    },
    description: {
      type: String,
      default: "",
      required: false,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    },
    passwordReset: {
      type: Boolean,
      default: false,
      required: false,
    },
    emailConfirmation: {
      type: Boolean,
      default: false,
      required: false,
    },
    restartAnimation: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.xs) {
      this.svgContainerMobile = document.getElementById('lottie-email-send-mobile');
      this.animItemMobile = bodymovin.loadAnimation({
        wrapper: this.svgContainerMobile,
        animType: 'svg',
        loop: false,
        path: '/animations/12135-email-send.json'
      });
    } else {
      this.svgContainer = document.getElementById('lottie-email-send');
      this.animItem = bodymovin.loadAnimation({
        wrapper: this.svgContainer,
        animType: 'svg',
        loop: false,
        path: '/animations/12135-email-send.json'
      });
    }
  },
  methods: {
    resendLink() {
      this.$emit('resend-link');
    }
  },
  watch: {
    restartAnimation: function (newQuestion, oldQuestion) {
      if (this.$vuetify.breakpoint.xs) {
        this.animItemMobile.goToAndPlay(0);
      } else {
        this.animItem.goToAndPlay(0);
      }
    }
  }
}
</script>

<style scoped>
.filter-primary {
  filter: invert(24%) sepia(27%) saturate(7097%) hue-rotate(198deg) brightness(99%) contrast(101%);
}

#lottie-email-send {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#lottie-email-send-mobile {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>