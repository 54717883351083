<template>
  <v-dialog 
    v-model="dialog" 
    :max-width="options.width" 
    :style="{ zIndex: options.zIndex }" 
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar 
        dark 
        :color="options.color" 
        dense 
        flat
      >
        <v-toolbar-title 
          class="white--text"
        >
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text 
        v-show="!!message" 
        class="pt-4 pb-0 px-4"
      >
        {{ message }}
        <v-row
          v-if="isInputEnabled"
          class="pt-3"
        >
          <v-textarea            
            rows="2"
            outlined
            hide-details
            v-model="inputValue"
            :label="options.inputLabel"
          ></v-textarea>
        </v-row>
      </v-card-text>
      <v-card-actions 
        class="pt-4"
        >
        <v-spacer></v-spacer>
        <v-btn 
          color="primary darken-1" 
          text 
          @click.native="agree"
        >
          {{ $vuetify.lang.t('$vuetify.confirm') }}
        </v-btn>
        <v-btn 
          color="grey" 
          text @click.native="cancel"
        >
          {{ $vuetify.lang.t('$vuetify.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,    
    resolve: null,
    reject: null,
    message: null,
    title: null,
    isInputEnabled: false,
    inputValue: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 200,
      inputLabel: null,
    }
  }),
  methods: {
    open(title, message, options, isInputEnabled) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.isInputEnabled = isInputEnabled;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    agree() {
      this.resolve({confirmed:true, input:this.inputValue})
      this.inputValue = null;
      this.dialog = false;
    },
    cancel() {
      this.resolve({confirmed:false})
      this.inputValue = null;
      this.dialog = false;
    }
  }
}
</script>