<template lang="pug">
  v-container(class="fill-height" fluid)
    v-row
      v-col(v-for="plan in sortedPaymentPlans" :key="plan.id" cols="12" md="3")
        Plan(
          :plan="plan"
          :hoverDisabled="hoverDisabled"
          :subscribed="(!freeTierSelectionEnabled && ((plan && activeSubscription && plan.role === activeSubscription.role) || (plan.role === 'free_plan' && !activeSubscription)))"
          :interval="activeSubscription? activeSubscription.priceData.interval: 'month'"
          @plan-selected="planSelected"
          )
</template>

<script>
import Plan from "@/components/subscription/Plan"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PlanList",
  components: { Plan },
  props: {
    freeTierSelectionEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    hoverDisabled: {
      type: Boolean,
      required: false,
      default: false,
      }
    },
  computed: {
    ...mapGetters({
      paymentPlans: "getActivePaymentPlans",
      activeSubscription: "getActiveSubscription",
      customClaimRole: "getCustomClaimRole",
    }),
    sortedPaymentPlans() {
      const roles = ["free_plan", "silver_plan", "gold_plan", "platinum_plan"]
      const plans = [... this.paymentPlans]
      return plans.sort((x, y) => {
        if (roles.indexOf(x.role) < roles.indexOf(y.role)) return -1;
        else if (roles.indexOf(x.role) > roles.indexOf(y.role)) return 1;
        return 0
      });
    },
    activePlan() {
      if(!this.activeSubscription) return { name: "", description: "" }

      const plan = this.paymentPlans.find((item) => {
        return item.role === this.activeSubscription.role
      })

      return plan ? plan : {name: "", description: ""}
    },
    annualSavings() {
      const monthlyPrice = this.activePlan.prices.find((price) => {
        return price.interval === 'month';
      })
      const annualPrice = this.activePlan.prices.find((price) => {
        return price.interval === 'year';
      })
      const savings = ((12 * monthlyPrice.unit_amount) - annualPrice.unit_amount)
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: this.activeSubscription? this.activeSubscription.priceData.currency : 'eur', }).format(( savings/ 100).toFixed(2))
    },
    /**
     * Returns all pricing plans, that are not deactivated in stripe
     */
    activePlans() {
      if(!this.paymentPlans || this.paymentPlans.length === 0) {
        return []
      }

      return this.paymentPlans.filter(item => {
        return item.active;
      })
    }
  },
  methods: {
    ...mapActions([
      'retrivePaymentPlans',
      'retrieveActiveTenantSubscription',
      'goToBillingPortal',
    ]),
    fetch() {
      if (!this.paymentPlans || this.paymentPlans.length <= 0) {
        this.retrivePaymentPlans()
      }
      if (!this.activeSubscription) {
        this.retrieveActiveTenantSubscription()
      }
    },
    formatDate(date) {
      return `${date.toLocaleString('default', {day: '2-digit'})} ${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`;
    },
    switchToAnnualPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    switchToMonthlyPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    cancelPlan() {
      this.loadingCancelSubscription = true
      this.goToBillingPortal()
    },
    planSelected(plan) {
      if (this.activeSubscription && this.activeSubscription.role !== "free_plan" && plan.role === "free_plan") {
        this.cancelPlan()
      }

      this.$emit('plan-selected', plan)
    }
  },
  created() {
    this.fetch()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetch'
  },
}
</script>

<style scoped>

</style>