<template lang="pug">
  v-container(fluid)
    v-row(v-if="!confirmation && !rejection" align="center" justify="center")
      v-col(cols="12" align="center" justify="center" class="mb-4")
        v-col(cols="12" sm="8" md="6" lg="4" xl="4")
          v-btn(text :href="landingPageUrl")
            h2.text-h4.font-weight-bold.primary--text {{ $vuetify.lang.t('$vuetify.productName') }}
      v-col(class="d-none d-sm-block" sm="8" md="6" lg="4" xl="5" align="center" justify="center")
        v-card(raised class="pt-6 pb-6")
          v-form(@submit.prevent="resetPassword" class="ml-12 mr-12")
            v-row(align="center" justify="center")
              v-col(cols="12" class="mb-0 pb-0 text-start")
                p.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.description') }} {{ email }}
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0 text-start")
                p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.password') }}
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                v-text-field(
                  v-model="reset.password"
                  required
                  outlined
                  dense
                  label=""
                  autofocus
                  autocomplete="new-password"
                  :append-icon="reset.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="reset.showPassword ? 'text' : 'password'"
                  :error-messages="passwordErrors"
                  @click:append="reset.showPassword = !reset.showPassword"
                  @input="$v.reset.password.$touch()"
                  @blur="$v.reset.password.$touch()"
                )
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0 text-start")
                p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmation') }}
              v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
                v-text-field(
                  v-model="reset.passwordConfirmation"
                  required
                  outlined
                  dense
                  label=""
                  autocomplete="new-password"
                  :append-icon="reset.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="reset.showPasswordConfirmation ? 'text' : 'password'"
                  :error-messages="passwordConfirmationErrors"
                  @click:append="reset.showPasswordConfirmation = !reset.showPasswordConfirmation"
                  @input="$v.reset.passwordConfirmation.$touch()"
                  @blur="$v.reset.passwordConfirmation.$touch()"
                )
              v-col(cols="12")
                v-btn(type="submit" block color="primary" :loading="loading") {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.reset') }}
              v-col(cols="12")
                router-link(to="/login")  {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.return') }}
      v-col(class="d-flex d-sm-none" align="center" justify="center")
        v-form(@submit.prevent="resetPassword" class="ml-12 mr-12")
          v-row(align="center" justify="center")
            v-col(cols="12" class="text-start")
              h2.text-subtitle-2.font-weight-regular {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.description') }}
            v-col(cols="12" class="mb-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.password') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="reset.password"
                required
                outlined
                dense
                label=""
                autofocus
                autocomplete="new-password"
                :append-icon="reset.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="reset.showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                @click:append="reset.showPassword = !reset.showPassword"
                @input="$v.reset.password.$touch()"
                @blur="$v.reset.password.$touch()"
              )
            v-col(cols="12" class="mb-0 pb-0 text-start")
              p.text-subtitle-2.fontColorLight--text {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmation') }}
            v-col(cols="12" class="mt-0 mb-0 pt-0 pb-0")
              v-text-field(
                v-model="reset.passwordConfirmation"
                required
                outlined
                dense
                label=""
                autocomplete="new-password"
                :append-icon="reset.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                :type="reset.showPasswordConfirmation ? 'text' : 'password'"
                :error-messages="passwordConfirmationErrors"
                @click:append="reset.showPasswordConfirmation = !reset.showPasswordConfirmation"
                @input="$v.reset.passwordConfirmation.$touch()"
                @blur="$v.reset.passwordConfirmation.$touch()"
              )
            v-col(cols="12")
              v-btn(type="submit" block color="primary" :loading="loading") {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.reset') }}
            v-col(cols="12")
              router-link(to="/login")  {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.return') }}
      v-col(cols="12" class="text-center")
        p.text-body-2.fontColorLight--text
          router-link(to="/contact") {{ $vuetify.lang.t('$vuetify.passwordResetConfirmation.contact') }}
    v-row(v-if="confirmation")
      v-col(cols="12")
        ConfirmationFeedback(:description="$vuetify.lang.t('$vuetify.passwordResetConfirmation.confirmation')" :continue-url="continueUrl")
    v-row(v-if="rejection")
      v-col(cols="12")
        RejectionFeedback(:description="$vuetify.lang.t('$vuetify.passwordResetConfirmation.rejection')" :continue-url="continueUrl")
</template>

<script>
import {validationMixin} from "vuelidate";
import {sameAs, maxLength, minLength, required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import ConfirmationFeedback from "@/components/feedback/ConfirmationFeedback";
import FirebaseAuth from "../../../firebase/auth/FirebaseAuth";
import RejectionFeedback from "@/components/feedback/RejectionFeedback";

export default {
  name: "PasswordResetConfirmationForm",
  components: {RejectionFeedback, ConfirmationFeedback},
  mixins: [validationMixin],
  validations: {
    reset: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
      },
      passwordConfirmation: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(50),
        sameAsPassword: sameAs('password')
      },
    },
  },
  props: {
    actionCode: {
      type: String,
      default: "",
      required: true,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    }
  },
  data() {
    return {
      loading: false,
      confirmation: false,
      rejection: false,
      email: "",
      reset: {
        password: '',
        showPassword: false,
        passwordConfirmation: '',
        showPasswordConfirmation: false,
      },
      defaultReset: {
        password: '',
        showPassword: false,
        passwordConfirmation: '',
        showPasswordConfirmation: false,
      }
    };
  },
  computed: {
    ...mapGetters([
      "signedIn",
      "currentUser",
    ]),
    landingPageUrl() {
      return process.env.VUE_APP_ADV_PUBLIC_ID === 'test' ? 'https://yndooo-web-dev.web.app/' : 'https://yndooo.com/'
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.reset.password.$dirty) return errors
      !this.$v.reset.password.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordError.minimum'))
      !this.$v.reset.password.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordError.maximum'))
      !this.$v.reset.password.required && errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordError.required'))
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.reset.passwordConfirmation.$dirty) return errors
      !this.$v.reset.passwordConfirmation.minLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmationError.minimum'))
      !this.$v.reset.passwordConfirmation.maxLength &&
      errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmationError.maximum'))
      !this.$v.reset.passwordConfirmation.required && errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmationError.required'))
      !this.$v.reset.passwordConfirmation.sameAsPassword &&
      errors.push(this.$vuetify.lang.t('$vuetify.passwordResetConfirmation.passwordConfirmationError.sameAsPassword'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      "sendPasswordResetEmail",
      "StoreNotification",
    ]),
    resetForm() {
      this.$v.$reset()
      this.reset = {...this.defaultReset}
    },
    async resetPassword() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      FirebaseAuth.methods.handleResetPassword(this.actionCode, this.reset.password)
          .then(() => {
            this.resetForm();
            this.confirmation = true;
          })
      this.loading = false
    },
  },
  mounted() {
    FirebaseAuth.methods.checkPaswordResetCode(this.actionCode)
        .then((email) => {
          this.email = email;
        })
        .catch(error => {
          this.rejection = true;
          console.log(error);
    })
  }
}
</script>

<style scoped>

</style>