<template lang="pug">
  v-card(shaped)
    v-card-title.text-h4 {{ $t('tenant.subscription.title') }}
    v-card-text
      v-card(shaped flat)
        v-card-text
          v-row
            v-col(cols="12" md="6")
              v-row(justify="center" align="center")
                v-col(cols="12" md="4" class="text-left justify-left")
                  h2.text-h4.font-weight-bold {{ $t('tenant.subscription.plans.' + activePlan.name )}}
                v-col(cols="12" md="8" class="text-left")
                  p.text-h4.font-weight-light {{ `${new Intl.NumberFormat('en-US', { style: 'currency', currency: activeSubscription.priceData.currency, }).format((activeSubscription.priceData.unit_amount / 100).toFixed(2))}`}} / {{ activeSubscription.priceData.interval === 'month' ? $t('tenant.subscription.currentPlan.month') : $t('tenant.subscription.currentPlan.year')  }}
                v-col(cols="12")
                  p.subtitle-1 {{ activeSubscription.priceData.interval === 'month'? $t('tenant.subscription.currentPlan.monthlySubscription') : $t('tenant.subscription.currentPlan.annualSubscription') }} • {{ $t('tenant.subscription.currentPlan.began') }} {{ formatDate(activeSubscription.current_period_start.toDate()) }} • {{ $t('tenant.subscription.currentPlan.ends') }} {{ formatDate(activeSubscription.current_period_end.toDate()) }}
            v-col(cols="12" md="6" class="justify-center align-center text-end")
              v-row(class="fill-height" align-center)
                v-col(cols='6' v-if="!$vuetify.breakpoint.mobile")
                v-col(cols='12' md='6' class="text-center")
                  v-btn(@click="switchToAnnualPlan" :loading="loadingSwitchAnnualPlan" rounded color="primary" class="align-center") {{ $t('tenant.subscription.currentPlan.manage') }}
                v-col(cols='6' v-if="!$vuetify.breakpoint.mobile")
                v-col(cols='12' md='6' class="text-center")
                  v-btn(rounded text class="font-weight-light" @click="cancelPlan" :loading="loadingCancelSubscription") {{ $t('tenant.subscription.currentPlan.invoices') }}
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ActiveSubscription",
  computed: {
    ...mapGetters({
      paymentPlans: "getActivePaymentPlans",
      activeSubscription: "getActiveSubscription",
    }),
    activePlan() {
      if(!this.activeSubscription) return { name: "", description: "" }

      const plan = this.paymentPlans.find((item) => {
        return item.role === this.activeSubscription.role
      })

      return plan ? plan : {name: "", description: ""}
    },
  },
  methods: {
    ...mapActions([
      'retrivePaymentPlans',
      'retrieveActiveTenantSubscription',
      'goToBillingPortal',
    ]),
    fetch() {
      if (!this.paymentPlans || this.paymentPlans.length <= 0) {
        this.retrivePaymentPlans()
      }
      if (!this.activeSubscription) {
        this.retrieveActiveTenantSubscription()
      }
    },
    formatDate(date) {
      return `${date.toLocaleString('default', {day: '2-digit'})} ${date.toLocaleString('default', {month: 'long'})} ${date.getFullYear()}`;
    },
    switchToAnnualPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    switchToMonthlyPlan() {
      this.loadingSwitchAnnualPlan = true
      this.goToBillingPortal()
    },
    cancelPlan() {
      this.loadingCancelSubscription = true
      this.goToBillingPortal()
    }
  },
  created() {
    this.fetch()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetch'
  },
}
</script>

<style scoped>

</style>