<template lang="pug">
  v-dialog(v-model="dialog" fullscreen persistent height="100%" z-index="9999")
    template(v-slot:activator="{ on, attrs }")
      v-list-item-title(v-bind="attrs" v-on="on" class="text-left font-weight-bold")
        v-btn(text block @click="openDialog") {{ $t('tenant.btnCreateTenant') }}
    v-card(fill-height)
      v-toolbar(flat color="transparent")
        v-spacer
        v-btn(icon @click="closeDialog")
          v-tooltip(bottom)
            template(v-slot:activator="{ on, attrs }")
              v-icon(v-bind="attrs" v-on="on") mdi-close
            span {{ $vuetify.lang.t('$vuetify.close') }}
      v-container(fill-height)
        v-row(justify="center" align="start" class="fill-height")
          v-col(cols="12" class="justify-center")
            v-stepper(v-model="step" flat class="elevation-0" :style="{ 'background': 'transparent' }")
              v-stepper-header(v-show="step > 1" class="elevation-0")
                v-stepper-step(step="1") {{ this.$t('tenant.creation.step1.stepperName') }}
                v-stepper-step(step="2") {{ this.$t('tenant.creation.step2.stepperName') }}
                v-stepper-step(step="3") {{ this.$t('tenant.creation.step3.stepperName') }}
              v-stepper-items
                v-stepper-content(step="1" color="transparent")
                  CreateTenantStepperOne(@close="closeDialog" @next="onNext" :force-close="dialog")
                v-stepper-content(step="2")
                  CreateTenantStepperTwo(@close="closeDialog" @next="onNext" :force-close="dialog")
                v-stepper-content(step="3")
                  CreateTenantStepperThree(@close="closeDialog" @next="onNext" :force-close="dialog")
</template>

<script>
import PlanList from "@/components/subscription/PlanList";
import CreateTenantStepperOne from "@/components/tenant/creation/CreateTenantStepperOne";
import CreateTenantStepperTwo from "@/components/tenant/creation/CreateTenantStepperTwo";
import CreateTenantStepperThree from "@/components/tenant/creation/CreateTenantStepperThree";
import {fireAnalytics} from "@/main";

export default {
  name: "CreateTenantDialog",
  components: {CreateTenantStepperThree, CreateTenantStepperTwo, CreateTenantStepperOne, PlanList},
  data() {
    return {
      step: 1,
      numSteps: 3,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    reset() {
      this.step = 1
      this.name = ""
      this.members = ["", "", ""]
      this.dialog = false;
    },
    closeDialog() {
      // After the first step the tenant was successfully created
      if (this.step > 1) {
        this.$router.push('/tenant')
      }
      this.reset()
    },
    openDialog() {
      this.$fireAnalytics.logEvent('tenant_creation_begin');
    },
    onNext() {
      // Go to next step of the creation process
      this.step += 1;

      // Team creation is completed
      if (this.step > this.numSteps) {
        this.closeDialog()
      }
    }
  },
}
</script>

<style scoped>
.creation-dialog {
  padding-top: 0.3vh;
  transform: translateY(-0.5vh);
}
</style>