import FirestoreCodes from "../../../firebase/firestore/FirestoreCodes";

export const CodeTypes = Object.freeze ({
  UNDEFINED: 0,
  INVITE: "invitationCode",
});

export const CodeStatus = Object.freeze ({
  UNDEFINED: 0,
  OPEN: "open",
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PROCESSED: "processed",
  LOCKED: "locked",
});

export const defaultCodeProperties ={
  tenantId: "",
  tenantDisplayName: "",
  tenantAvatarColor: "",
  applicantId: "",
  applicantDisplayName: "",
  inviteeEmail: "",
  isPersonalCopy: false,
}

/**
  * Stores the properties and methods for all types of codes
*/
export default class LinkrCode {
  /**
  * the Code's id
  * @type {string}
  */
  id = "";
  /**
  * the Type of the code
  * @type {CodeTypes}
  */
  type = null;
  /**
  * the code itself
  * @type {string}
  */
  code = "";
  /**
  * the code status
  * @type {CodeStatus}
  */
  status = CodeStatus.UNDEFINED;
  /**
   * the code's creation date
   * @type {Date}
  */
  created = null;
  /**
  * the code's comment
  * @type {string}
  */
  comment = "";
  /**
  * the code's properties
  * @type {defaultCodeProperties}
  */
  properties = defaultCodeProperties;

  /**
   * @constructs LinkrCode
   * @param {CodeTypes} type - the Code's type
   * @param {string} code - the code itself
   * @param {string} [created] - the code's creation date
   * @param {string} [comment] - the code's comment
  */
  constructor(type, code, created, comment){
    this.type = type;
    this.code = code;
    this.created = created ? created : new Date;
    this.comment = comment ? comment : null;
  }
  /**
   * assigns a invitee email to the code
   * @param {string} email invitee E-Mail 
   */
  assignInviteeEmail(email){
    this.properties.inviteeEmail = email;
    this.status = CodeStatus.LOCKED;
    return FirestoreCodes.methods.updateCode(this)
  }
  /**
   * Creates a new Invitation type Code
   * @static
   * @param {string} tenantId - the Id of the inviting tenant
   * @param {string} tenantDisplayName - the displayName of the inviting tenant
   * @param {string} tenantAvatarColor - the Avatar color of the inviting tenant
   * @param {string} [comment] - the code's comment
   * @returns {LinkrCode}
  */
  static createInvitation(tenantId, tenantDisplayName, tenantAvatarColor, comment){
    let aCode = new LinkrCode(CodeTypes.INVITE, null, null, comment);
    aCode.status = CodeStatus.OPEN;
    aCode.properties.tenantId = tenantId;
    aCode.properties.tenantDisplayName = tenantDisplayName;
    aCode.properties.tenantAvatarColor = tenantAvatarColor; 
    aCode.properties.isPersonalCopy = false;
    return new Promise(function (resolve, reject){
      FirestoreCodes.methods.createCode(aCode)
        .then( (response) => {
          resolve(response);
        })
        .catch( (error) => {
          reject(error);
        });
    });
  }
  /**
   * Processes a code entered by a user
   * @static
   * @param {string} codeId - the Id of code
   * @param {string} applicantId - the Id of the user that has entered the code
   * @param {string} applicantDisplayName - the Display name of the user that has entered the code
   * @param {Object[]} applicantTenants A list of tenants that a user belongs to
   * @param {string} applicantEmail - the Email of the user that has entered the code
   * @returns {LinkrCode}
  */
  static processCode(codeId, applicantId, applicantDisplayName, applicantTenants, applicantEmail){
    return new Promise(function (resolve, reject){
      FirestoreCodes.methods.RetrieveCode(codeId)
        .then( (aCode) => {
          if (aCode.properties.inviteeEmail) {
            if (aCode.properties.inviteeEmail != applicantEmail){
              reject('code/wrong-email');
            } else {
              if (aCode.status === CodeStatus.LOCKED) {
                resolve({result: 'accept', code: aCode})
              }
            }            
          } else if (aCode.status === CodeStatus.OPEN) {
            // generate a list of tenant ids that the applicant belongs to
            let applicantTenantsIds = [];
            applicantTenants.forEach( (item) => {
              applicantTenantsIds.push(item.id);
            })
            aCode.status = CodeStatus.PENDING;
            aCode.properties.applicantId = applicantId;
            aCode.properties.applicantDisplayName = applicantDisplayName;
            FirestoreCodes.methods.updateCode(aCode, applicantTenantsIds)
              .then(() => {
                aCode.properties.isPersonalCopy = true;
                FirestoreCodes.methods.createPersonalCodeCopy(aCode)
                .then((aCopy) => {
                  resolve(aCopy)
                })
                .catch( (error) => {
                  reject(error);
                });
              })
              .catch( (error) => {
                reject(error);
              }); 
          } else {
            reject('code/already-used')
          }          
        })
        .catch( (error) => {
          reject(error);
        });    
    });
  }
}
