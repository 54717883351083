<template lang="pug">
  v-tabs(v-model="tab" color="primary" background-color="transparent" show-arrows)
    v-tab {{ $t('$vuetify.profile.tabs.profile') }}
    v-tab {{ $t('$vuetify.profile.tabs.public') }}
    v-tab {{ $t('$vuetify.profile.tabs.account') }}
    v-tab {{ $t('$vuetify.profile.tabs.preferences') }}
    //- v-tab Payments
    v-tabs-items(v-model="tab" :style="{ 'background-color': 'transparent'}")
      v-tab-item(transition="false" reverse-transition="false")
        UserProfile
      v-tab-item(transition="false" reverse-transition="false")
        UserPublic
      v-tab-item(transition="false" reverse-transition="false")
        UserAccount
      v-tab-item(transition="false" reverse-transition="false")
        UserPreferences
      //-
        v-tab-item(:transition="false" :reverse-transition="false")
          UserPaymentOverview
</template>

<script>
import UserAccount from "@/components/user/UserAccount";
import UserProfile from "@/components/user/UserProfile";
import UserPreferences from "@/components/user/UserPreferences";
import PricingTable from "@/components/billing/PricingTable";
import UserPublic from "@/components/user/UserPublic"
//- import UserPaymentOverview from "@/components/user/UserPaymentOverview";
export default {
  name: "UserSettings",
  components: {/* UserPaymentOverview,*/ PricingTable, UserPublic, UserPreferences, UserProfile, UserAccount },
  data: () => ({
    tab: null,
    dialog: null,
    snackbar: false,
  }),
}
</script>