/**
 * Contains all the Tenant properties and methods
 */
import Address from "@/store/classes/Address";
import {createAvatarColor} from "@/store/classes/Colors";

export default class Tenant {
    /**
     * the Tenant's id
     * @type {string}
     */
    id = "";
    /**
     * the Tenant's display name
     * @type {string}
     */
    displayName = "";

    /**
     * the Tenant's company name
     * @type {string}
     */
    companyName = "";

    /**
     *
     * @type {string}
     */
    initials = Tenant.createInitials(this.displayName? this.displayName : this.companyName);

    /**
     *
     * @type {string}
     */
    avatarColor = createAvatarColor();

    /**
     * the Tenant's company address
     * @type {Address}
     */
    address = Address.empty();
    /**
     * the Tenant's company contact name
     * @type {string}
     */
    contactName = "";
    /**
     * the Tenant's company contact position
     * @type {string}
     */
    contactPosition = "";
    /**
     * the Tenant's company contact email
     * @type {string}
     */
    contactEmail = "";
    /**
     * the Tenant's company contact phone
     * @type {string}
     */
    contactPhone = "";
    /**
     * a list of Tenant's members and their statuses
     * @type {string[]}
     */
    members = [];
    /**
     * @constructs Tenant
     * @param {string} displayName - the Tenant's Display name
     * @param {string} companyName - The Tenant's company name.
     * @param {string} avatarColor
     * @param {Address} address Address information
     * @param {string} contactName - The Tenant's company contactName.
     * @param {string} contactPosition - The Tenant's company contactPosition.
     * @param {string} contactEmail - The Tenant's company contact Email
     * @param {string} [contactPhone] - The Tenant's company contactPhone.
     * @param {string[]} [members] - The Tenant's member list
     */
    constructor(displayName, companyName, avatarColor , address, contactName, contactPosition, contactEmail, contactPhone, members) {
        this.displayName = displayName;
        this.companyName = companyName;
        this.avatarColor = avatarColor? avatarColor : createAvatarColor();
        this.initials = Tenant.createInitials(this.displayName? this.displayName : this.companyName);
        this.address = address? address : Address.empty();
        this.contactName = contactName;
        this.contactPosition = contactPosition;
        this.contactEmail = contactEmail;
        if (contactPhone) {
            this.contactPhone = contactPhone;
        }
        if (members) {
            this.members = members;
        }
    };

    /**
     * Creates a copy of this Tenant object
     *
     * @return {Tenant} Returns a new Tenant object
     */
    copy() {
        let tmpTenant = new Tenant(this.displayName, this.companyName, this.avatarColor, this.address, this.contactName, this.contactPosition, this.contactEmail, this.contactPhone, this.members);
        tmpTenant.id = this.id;
        return tmpTenant;
    }

    /**
     * Generates an Empty Tenant object
     * @static
     * @return {Tenant} Returns an empty Tenant object
     */
    static empty() {
        return new Tenant(
            this.displayName,
            this.companyName,
            createAvatarColor(),
            Address.empty(),
            this.contactName,
            this.contactPosition,
            this.contactEmail,
            this.contactPhone,
            [],
            );
    }

    /**
     * Creates the initials of a tenant
     * @param name
     * @return {string}
     */
    static createInitials = function (name) {
        if (!name) return '';

        const segments = name.split(" ").filter(Boolean);
        const letters = []

        for (let i = 0; i < segments.length; i++) {
            letters.push(segments[i].charAt(0))
        }

        return letters.join('')
    }
}