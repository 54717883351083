<template lang="pug">
  v-card(max-width="700" height="100%" class="flexcard")
    v-card-actions
      v-spacer
      v-btn(icon @click="$emit('skip')")
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on") mdi-close
          span {{ $vuetify.lang.t('$vuetify.close') }}
    v-card-actions(class="justify-center align-center pt-4")
      v-img(:src="imgUrl" :lazy-src="imgUrl" alt="Text" aspect-ratio="1.0" max-height="200" min-width="250" max-width="250" class="text-left justify-left")
    v-card-text(pl-4 pr-4)
      v-card(flat)
        v-card-title {{ title }}
        v-card-text(class="text-left") {{ text }}
    v-spacer(vertical)
    v-card-actions(class="pb-6")
      v-btn(v-if="skipAllBtn" color="primary" text @click="$emit('skip')") {{ skipAllBtn }}
      v-btn(v-if="backBtn" color="primary" text @click="$emit('back')") {{ backBtn }}
      v-spacer
      v-btn(color="primary" width="120" @click="$emit('next')") {{ nextBtn }}
</template>

<script>
export default {
  name: "TutorialCardArtTop",
  props: {
    title: {
      type: String,
      required: true,
      default: ""
    },
    text: {
      type: String,
      required: true,
      default: "",
    },
    imgUrl: {
      type: String,
      required: true,
      default: ""
    },
    skipAllBtn: {
      type: String,
      required: true,
      default: ""
    },
    nextBtn: {
      type: String,
      required: true,
      default: ""
    },
    backBtn: {
      type: String,
      required: true,
      default: ""
    }
  },
}
</script>

<style scoped lang="scss">
.flexcard {
  display: flex;
  flex-direction: column;
}
// Add the css below if your card has a toolbar, and if your toolbar's height increases according to the flex display.
.flexcard .v-toolbar {
  flex: 0;
}
</style>