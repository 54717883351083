<template lang="pug">
  v-hover
    template(v-slot:default="{ hover }")
      v-card(shaped outlined :hover="!$vuetify.breakpoint.mobile || !hoverDisabled" :elevation="$vuetify.breakpoint.xs || hoverDisabled ? 1 : elevation(hover)"  :loading="loading" height="100%" class="card-outter")
        v-img(v-if="plan.images.length > 0" :src="plan.images[0]" height="200" contain)
        v-card-title {{ $t('tenant.subscription.plans.' + plan.name ) }}
        v-card-text {{ plan.description }}

        v-divider(class="mx-4")

        v-card-text(v-if="plan.prices.length > 0")
          v-chip-group(
            v-show="(plan.prices && plan.prices[0].active)"
            mandatory
            v-model="selectedPrice"
            :active-class="subscribed? 'secondary' : 'primary'"
            column
            )
            v-chip(
              v-for="(price, i) in plan.prices"
              :disabled="subscribed && i !== selectedPrice"
              :key="price.id"
               ) {{ `${new Intl.NumberFormat('en-US', { style: 'currency', currency: price.currency, }).format((price.unit_amount / 100).toFixed(2))} /${price.interval}`}}
        v-card-text(v-else)
          v-chip-group(mandatory primary)
            v-chip(:color="subscribed? 'secondary': 'primary'") Free

        v-card-actions(class="align-end card-actions")
          v-btn(
            v-if="(!subscribed && plan.prices[0] && plan.prices[0].active) || (!subscribed && !plan.prices[0])"
            color="primary"
            text
            :disabled="disabled"
            @click="subscribe(plan)") Subscribe
          v-btn(
            v-if="(!subscribed && plan.prices[0] && !plan.prices[0].active)"
            color="primary"
            text
            :disabled="disabled"
            @click="onContactSales(plan)"
            href="mailto:sales@yndooo.com"
            ) Contact sales
          v-btn(
            v-if="subscribed"
            color="secondary"
            text
            ) Active
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    subscribed: {
      required: false,
      default: false,
      type: Boolean
    },
    interval: {
      required: false,
      default: '',
      type: String
    },
    plan: {
      required: true,
    },
    hoverDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      selectedPrice: 0,
    }
  },
  computed: {
    ...mapGetters({
      activeSubscription: "getActiveSubscription",
    }),
  },
  methods: {
    ...mapActions([
      'subscribeToPlan',
      'goToBillingPortal',
    ]),
    async subscribe(plan) {
      this.loading = true

      // Check if the plan can be subscribed to
      if (!plan.active) return

      if (plan.role !== "free_plan") {
        // Get the price id and redirect to Stripe
        const price = plan.prices[this.selectedPrice]
        await this.subscribeToPlan({priceId: price.id, value: price.unit_amount, currency: price.currency, package_name: plan.name });
      }

      this.$emit('plan-selected', plan)

      // Cant set this to false here, if we redirect to Stripe.
      // If we set it to false if causes a visual glitch, as the redirect to Stripe takes some time
      if (plan.role === "free_plan" || (!plan.price[0].active && !plan.prices[1].active)) this.loading = false
    },
    onContactSales(plan) {
      this.$emit('plan-selected', plan)
    },
    elevation(hover) {
      if(hover) {
        return 24
      } else if(this.subscribed) {
        return 12
      }
      else {
        return 2
      }
    }
  },
  mounted() {
    if(this.subscribed && this.interval && this.interval === 'year') {
      this.selectedPrice = 1;
    }
  }

}
</script>

<style>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>