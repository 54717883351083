import LinkLabel from '../classes/LinkLabel';
import FirestoreCards from '../../../firebase/firestore/FirestoreCards';
import {fireAnalytics} from "../../main";

const  state = {
  /**
   * A list of all labels in the system
   * @type {LinkLabel[]}
  */
  labels: [],
  /**
   * A list of all default labels in the system
   * @type {LinkLabel[]}
  */
  defaultLabels: [],
  /**
   * A list of all custom labels in the system
   * @type {LinkLabel[]}
  */
  customLabels: [],
};


const getters = {
  /**
   * Retrieves all stored labels
   * @returns {LinkLabel[]}
  */
  allLabels(){
    return state.labels
  },
  /**
   * Retrieves all stored default labels
   * @returns {LinkLabel[]}
  */
   allDefaultLabels(){
    return state.defaultLabels
  },
  /**
   * Retrieves all stored custom labels
   * @returns {LinkLabel[]}
  */
  allCustomLabels(){
    return state.customLabels
  }
};

const actions = {
  /**
   * fetches the default labels from the firestore
  */
  fetchDefaultLabels(context) {
    FirestoreCards.methods.ReadAllabels()
      .then((response) => {
        context.commit('setDefaultLabels', response);
        context.commit('updateAllLabels');
      })
      .catch(error =>{
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });  
  },
  /**
   * fetches the Custom labels from the firestore
   * @param {string} tenantid Tenant ID
  */
  fetchCustomLabels(context, tenantid) {
    FirestoreCards.methods.ReadAllabels(tenantid)
      .then((response) => {
        context.commit('setCustomLabels', response);
        context.commit('updateAllLabels');
      })
      .catch(error =>{
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });  
  },
  /**
   * Creates dublicate versions of Default labels inside a Tenant
   * @param {string} tenantid Tenant ID
   */
  dublicateDefaultLabels(context, tenantid){
    var promises = [];
    state.defaultLabels.forEach( defaultLabel => {
      if (state.customLabels.findIndex(item => item.id === defaultLabel.id) < 0) {
        defaultLabel.isDefault = true;
        state.customLabels.unshift(defaultLabel)
        promises.push(FirestoreCards.methods.updateCustomLabel(tenantid, defaultLabel))
      }
    })
    return Promise.all(promises);
  },
  /**
   * Creates copies of Default labels inside a Tenant
   * @param {string} tenantid Tenant ID
   */
   copyDefaultLabels(context, tenantid){
    var promises = [];
    state.defaultLabels.forEach( defaultLabel => {
      promises.push(FirestoreCards.methods.updateCustomLabel(tenantid, defaultLabel))
    })
    return Promise.all(promises);
  },
  /**
   * Creates dublicate versions of Default labels inside a Tenant
   * @param {string} tenantid Tenant ID
   */
   cleanupDefaultLabelCopies(context, tenantid){
    var promises = [];
    state.customLabels.forEach( customLabel => {
      if (customLabel.isDefault && customLabel.isDeleted) {
        promises.push(FirestoreCards.methods.DeleteCustomLabel(tenantid, customLabel))
        context.commit('deleteCustomLabel', customLabel);
      }
    })
    return Promise.all(promises);
  },
  /**
   * Updates or adds a new custom Label
   * @param {LinkLabel}  label a custom label
  */
  addLabel(context, label) {
    return new Promise(function (resolve, reject){ 
      if (label.id){
        FirestoreCards.methods.updateCustomLabel(context.rootState.user.tenant.id, label)
          .then(() => {
            context.commit('updateCustomLabel', label);
            context.commit('updateAllLabels');
            fireAnalytics.logEvent('label_updated');
            resolve();
          })
          .catch(error =>{
            context.dispatch("StoreError" , {error: error, showNotification: true})
            reject(error);
          });  
      } else {
        FirestoreCards.methods.createCustomLabel(context.rootState.user.tenant.id, label)
          .then((response) => {
            context.commit('addCustomLabel', response);
            context.commit('updateAllLabels');
            fireAnalytics.logEvent('label_created');
            resolve();
          })
          .catch(error =>{
            context.dispatch("StoreError" , {error: error, showNotification: true})
            reject(error);
          });      
      }
    });
  },
  /**
   * Deletes a custom Label
   * @param {LinkLabel}  label a new label
  */
  deleteLabel(context, label) {
    return new Promise(function (resolve, reject){
      FirestoreCards.methods.DeleteCustomLabel(context.rootState.user.tenant.id, label)
      .then(() => {
        context.commit('deleteCustomLabel', label);
        context.commit('updateAllLabels');
        fireAnalytics.logEvent('label_deleted');
        resolve();
      })
      .catch(error =>{
        context.dispatch("StoreError" , {error: error, showNotification: true})
        reject(error);
      }); 
    });
  },
};

const mutations = {
  updateAllLabels(state){
    state.labels = state.defaultLabels.concat(state.customLabels);
    console.log("Label list updated: ", state.labels);
  },
  setDefaultLabels(state, labels){
    state.defaultLabels = labels;
    console.log("Default Label list loaded: ", state.defaultLabels);
  },
  setCustomLabels(state, labels){
    state.customLabels = labels;
    console.log("Custom Label list loaded: ", state.customLabels);
  },
  addCustomLabel(state, label){
    state.customLabels.push(label);
    console.log("Custom Label list updated: ", state.customLabels);
  },
  updateCustomLabel(state, label){
    let labelToUpdate = state.customLabels.find(item => item.id === label.id)
    labelToUpdate.update(label);
    console.log("Custom Label updated: ", label);
  },
  deleteCustomLabel: (state, label) =>  {
    let indexOfElement = state.customLabels.indexOf(label);
    state.customLabels.splice(indexOfElement, 1);
    console.log("Custom Label deleted: ", state.customLabels);
  },
  clearCustomLabels(state){
    state.labels = state.defaultLabels;
    state.customLabels = [];
  }
};

export default{
  state,
  getters,
  actions,
  mutations
};
