import { fireAnalytics} from '../../main'
import { i18n } from '../../plugins/vuetify' 

const getDefaultNotificationProps= () => {
    return {
      absolute: false,
      top: false,
      bottom: true,
      left: false,
      right: false,
      multi_line: false,
      vertical: false,
    };
  };
    
  const  state = {
    errors: {
      items: [],
    },
    notifications: {
      items: [],
        // item example
        /* 
          {
            message: "",
            color: "error", //success, error, info",
            timeout: 0,
            action: null,
          }
        */
      properties:  getDefaultNotificationProps(),
    },
  };

  const getters = {
    getAllErrors: () =>  { return state.errors },
    getAllNotifications: () =>  { return state.notifications.items },
    getNotificationProperties: () =>  { return state.notifications.properties },
  };

  const actions = {
    StoreError({ commit, dispatch }, payload) {
      //TODO remove in production
      dispatch("stopLoadingSpinner");
      console.log("Error: ", payload.error);
      commit("addError", payload.error);      
      fireAnalytics.logEvent('exception', {
        description : payload.error.message,
      });
      if (payload.showNotification){
        let code = payload.customCode ? payload.customCode : payload.error.code;
        let aCustomMessage = payload.error.message;
        // Ignore the code if a custom message was defined
        if (payload.customMessage){
          aCustomMessage = payload.customMessage
          code = null;
        }
        if (code != 'permission-denied') {
          dispatch("StoreNotification" , {
            message: aCustomMessage,
            type: 'error', 
            timeout: payload.timeout,
            code: code,
          });
        }
      }
    },

    StoreNotification({ commit }, payload) {
      let aMessage = payload.message ? payload.message : "Empty Notification";
      let aType = "info";
      let aTimeout = -1;

      //Set custom type
      if (payload.type){
        aType = payload.type
      }

      //Check if translation of the code exists and display standard error message otherwise
      if (payload.code) {
        if (aType == 'error' && i18n.te('$vuetify.notificatons.errors.' + payload.code)){
          aMessage = i18n.t('$vuetify.notificatons.errors.' + payload.code);
        } else {
          aMessage = i18n.t('$vuetify.notificatons.' + payload.code);
        }
      }

      if (payload.timeout){
        aTimeout = payload.timeout
      } else if (aType == "success"){
        aTimeout = 3000;
      }
      console.log("Notification: ", aMessage);
      if (aType != "success") {
        commit("addNotification", {
          message: aMessage,
          type: aType,
          timeout: aTimeout,
          action: payload.action,
        });
      }
    },

    RemoveNotification({ commit }, payload) {
      if (payload.id === "latest"){
        commit("deleteLatestNotification");
      } else if (payload.id === "oldest"){
        commit("deleteOldestNotification");
      } else {
        commit("deleteNotification", payload );
      }
    },

    ChangeNotificationProps({ commit }, payload) {
      let newProps = getDefaultNotificationProps;
      if (payload.absolute) { 
        newProps.absolute = payload.absolute
      }
      if (payload.top) { 
        newProps.top = payload.top
      }
      if (payload.bottom) { 
        newProps.bottom = payload.bottom
      }
      if (payload.left) { 
        newProps.left = payload.left
      }
      if (payload.right) { 
        newProps.right = payload.right
      }
      if (payload.multi_line) { 
        newProps.multi_line = payload.multi_line
      }
      if (payload.vertical) { 
        newProps.top = payload.vertical
      }
      commit("setNotificationProps", newProps);
    },
  }

  const mutations = {
    addError: (state, payload) => {
      state.errors.items.push(payload);
    },
    addNotification: (state, payload) => {      
      state.notifications.items.push({
        message: payload.message,
        color: payload.type, 
        timeout: payload.timeout,
        action: payload.action,
      })
    },
    deleteNotification: (state, notification) =>  {
      let indexOfElement = state.notifications.items.indexOf(notification);
      state.notifications.items.splice(indexOfElement, 1);
    },
    deleteLatestNotification:(state) => {
      state.notifications.items.pop();
    },
    deleteOldestNotification:(state) => {
      state.notifications.items.shift();
    },
    setNotificationProps: (state, payload) => {
      state.notifications.properties.absolute = payload.absolute;
      state.notifications.properties.top = payload.top;
      state.notifications.properties.bottom = payload.bottom;
      state.notifications.properties.left = payload.left;
      state.notifications.properties.right = payload.right;
      state.notifications.properties.multi_line = payload.multi_line;
      state.notifications.properties.vertical = payload.vertical;
    },
  };
  
  export default{
    state,
    getters,
    actions,
    mutations
  };