<template>
  <v-hover v-slot="{ hover }">
    <button class="toggle-favorite" @click="toggle">
      <FavoriteIcon
          class="toggle-favorite__icon"
          :class="hover ? hoverClasses : iconClasses"
          @animationend.native="onIconAnimationEnds"
      />
      <transition name="favorite-particles-transition">
        <div v-if="animating" class="toggle-favorite__particles"></div>
      </transition>
    </button>
  </v-hover>
</template>

<script>
import FavoriteIcon from "./FavoriteIcon";
import {mapActions} from "vuex";
import LinkType from "@/store/classes/LinkType";
import Link from "@/store/classes/Link";
export default {
  name: "FavoriteToggle",
  components: {
    FavoriteIcon
  },
  props: {
    link: {
      required: true,
      type: Link
    }
  },
  data() {
    return {
      animating: false
    };
  },
  computed: {
    hoverClasses() {
      return {
        "toggle-favorite__icon--favorited": true,
        "toggle-favorite__icon--animate": this.animating
      };
    },
    iconClasses() {
      return {
        "toggle-favorite__icon--favorited": this.link.isLiked,
        "toggle-favorite__icon--animate": this.animating
      };
    }
  },
  methods: {
    ...mapActions([
      "likeLink",
    ]),
    toggle() {
      // Only animate on favoriting.
      if (!this.favorited) {
        this.animating = true;
      }

      this.favorited = !this.favorited;

      this.likeLink(this.link)
    },
    onIconAnimationEnds() {
      this.animating = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$particles-animation-duration: 0.8s;
$icon-animation-duration: 0.48s;
$icon-color: #D81B60;
$icon-border-color: #D81B60;

@keyframes favorite-icon-animation {
  0% {
    opacity: 1;
    transform: scale(0.1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes favorite-particles-animation {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

// Particles animation.
.favorite-particles-transition-enter-active {
  background-image: url("/img/particles-sprite.png");
  background-size: 2500% auto;
  background-position: left center;
  background-repeat: no-repeat;

  animation-duration: $particles-animation-duration;
  animation-timing-function: steps(24);
  animation-name: favorite-particles-animation;
}

.toggle-favorite {
  font-size: 32px;
  position: relative;

  &__icon {
    height: 1em;
    width: 1em;

    // Transition mainly for when un-favoriting
    transition: fill-opacity 0.2s, stroke 0.2s;
    fill: $icon-color;
    fill-opacity: 0;
    stroke: $icon-border-color;

    &--favorited {
      fill-opacity: 1;
      stroke: $icon-color;
    }

    // Icon animation
    &--animate {
      opacity: 0;
      transform: scale(0);
      animation-duration: $icon-animation-duration;
      animation-delay: $particles-animation-duration - $icon-animation-duration;
      animation-name: favorite-icon-animation;
    }
  }

  &__particles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3em;
    height: 3em;
  }
}
</style>
