<template lang="pug">
  v-container(fluid)
    v-row(align="center" justify="center")
      v-col(v-if="verified && loaded" cols="12")
        ConfirmationFeedback(:description="$vuetify.lang.t('$vuetify.emailRecovery.confirmation')" :continue-url="continueUrl")
      v-col(v-if="verified && loaded" cols="12")
        p.text-body-2.fontColorLight--text
          router-link(to="/reset")  {{ $vuetify.lang.t('$vuetify.login.emailRecovery.passwordResetQuestion') }}
      v-col(v-else-if="!verified && loaded" cols="12")
        RejectionFeedback(:description="$vuetify.lang.t('$vuetify.emailRecovery.rejection')" :continue-url="continueUrl")
      v-col(v-else class="justify-center align-center text-center")
        v-progress-circular(indeterminate color="primary" :width="3")
</template>

<script>
import FirebaseAuth from "../../../firebase/auth/FirebaseAuth";
import RejectionFeedback from "@/components/feedback/RejectionFeedback";
import ConfirmationFeedback from "@/components/feedback/ConfirmationFeedback";

export default {
  name: "EmailRecovery",
  components: {ConfirmationFeedback, RejectionFeedback},
  props: {
    actionCode: {
      type: String,
      default: "",
      required: true,
    },
    continueUrl: {
      type: String,
      default: "",
      required: false,
    },
    lang: {
      type: String,
      default: "en",
      required: false,
    }
  },
  data() {
    return {
      verified: false,
      loaded: false,
    }
  },
  methods: {
    recoverEmail(){
      FirebaseAuth.methods.handleRecoverEmail(this.actionCode, this.lang)
          .then((resp) => {
            console.log(resp);
            this.response = "Email Sucessfully restored";
          })
          .catch(error => {
            console.log(error);
            this.response = error;
          })
    },
    sendPasswordResetEmail() {
      FirebaseAuth.methods.sendEmailPasswordReset(this.email)
          .then((resp) => {
            console.log(resp);
            this.response = "Password reset Email sent to " + this.email;
          })
          .catch(error => {
            console.log(error);
            this.response = error;
          })
    },
  },
  mounted() {
    FirebaseAuth.methods.checkActionCode(this.actionCode)
        .then(() => {
          this.recoverEmail()
        })
        .then(() => {
          this.verified = true;
          this.loaded = true;
        })
        .catch(error => {
          console.log(error);
          this.verified = false;
          this.loaded = true;
        })
  }
}
</script>

<style scoped>

</style>