<template lang="pug">
  v-container(fluid)
    v-row(align="center")
      v-col(cols="12")
        v-card(
          v-if="currentTenant && isAdmin"
          shaped)
          v-card-text
            v-data-table(
              :headers="headers"
              :items="currentTenant.members"
              :itemsPerPage="itemsPerPage"
              sort-by="name"
              :search="search")
              template(v-slot:top)
                v-row(justify="start" align="center" class="text-start")
                  v-col(cols="12" sm="2" md="1")
                    v-toolbar-title {{ $vuetify.lang.t('$vuetify.tenant.titleMembers') }}
                  v-col(cols="12" sm="6" md="5" class="pl-0 pr-0 justify-start text-start")
                    v-text-field(
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('$vuetify.search')"
                      single-line
                      hide-details
                      rounded
                      outlined
                      dense
                    )
                  v-col(cols="12" sm="4" md="6" class="text-end justify-end")
                    v-dialog( v-model="dialogInviteMember" fullscreen persistent height="100%" z-index="9999")
                      template(v-slot:activator="{ on, attrs }")
                        v-btn(
                          v-if="isAdmin"
                          color="primary"
                          dark
                          rounded
                          :block="$vuetify.breakpoint.mobile"
                          v-bind="attrs"
                          @click="editedItem.description = ''"
                          v-on="on")
                          v-icon mdi-plus
                          | {{ $t('tenant.invite.btnInvite') }}
                      v-card(fill-height)
                        v-toolbar(flat color="transparent")
                          v-spacer
                          v-btn(icon @click="dialogInviteMember = false")
                            v-tooltip(bottom)
                              template(v-slot:activator="{ on, attrs }")
                                v-icon(v-bind="attrs" v-on="on") mdi-close
                              span {{ $vuetify.lang.t('$vuetify.close') }}
                        v-container(fill-height)
                          CreateTenantStepperTwo(@next="dialogInviteMember = false" :skipButton="false")
              template(v-slot:item.actions="{ item }")
                v-icon(
                  small
                  class="mr-2"
                  :disabled="!isAdmin"
                  @click="editItem(item)") mdi-pencil
                v-icon(
                  small
                  class="mr-2"
                  :disabled="!isAdmin"
                  @click="deleteItem(item)") mdi-delete

              template(v-slot:[`item.owner`]="{ item }")
                v-simple-checkbox(
                  v-model="item.owner"
                  :ripple="false"
                  disabled
                  )
              template(v-slot:[`item.admin`]="{ item }")
                v-simple-checkbox(
                  v-model="item.owner? item.owner : item.admin"
                  :ripple="false"
                  :disabled="item.owner || !isAdmin"
                  @input="setMemberStatus(item, 'admin')")

      v-col(cols="12" v-if="currentTenant && isAdmin && allTenantCodes.length > 0")
        CodeGenerate
      v-col(cols="12" v-if="currentTenant && !isAdmin")
        v-card(shaped)
          v-card-title {{ $t("tenant.members.dangerZone") }}
          v-card-subtitle {{ $t("tenant.members.dangerZoneInfo") }}
          v-card-actions
            v-btn(color="error" @click="leaveTenantClicked") {{ $t("tenant.members.leaveTenant") }}
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from 'vuelidate'
import {required, minLength, maxLength, email, between} from 'vuelidate/lib/validators'
import Tenant from "../../store/classes/Tenant.js";
import CodeGenerate from '../../components/CodeGenerate';
import CreateTenantStepperTwo from "./creation/CreateTenantStepperTwo";

export default {
  name: "TenantMembers",
  mixins: [validationMixin],
  validations: {
    currentEditedLocalCopy: {
      displayName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      country: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      zipCode: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      city: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactPosition: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactEmail: {required, email},
      contactPhone: {
        maxLength: maxLength(256),
      },
    },
    currentEditedLabel: {
      label: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      }
    }
  },
  components: {
    CreateTenantStepperTwo,
    CodeGenerate,
  },
  data() {
    return {
      dialogInviteMember: false,

      search: '',
      itemsPerPage: 10,
      editedIndex: -1,
      editedItem: {
        name: '',
        description: '',
      },
      defaultItem: {
        name: '',
        description: '',
      },
      /**
       * A locally stored Tenant class object
       * @type {Tenant}
       */
      currentEditedLocalCopy: Tenant.empty(),
      /**
       * Used to differentiate between creating or editing tenants
       * @type {boolean}
       */
      editing: false,
      /**
       * Used to disable editing Tenant info
       * @type {boolean}
       */
      isEditingDisabled: false,
      customLabelsArray: [],
      currentEditedLabel: null,
      labelSelected: false,
      loading: false,
      valid: false,
      validLabel: false,
      //used to highlight filled(changed) fields
      filledDisplayName: false,
      filledCompanyName: false,
      filledAddress: false,
      filledCountry: false,
      filledZipCode: false,
      filledCity: false,
      filledContactName: false,
      filledContactPosition: false,
      filledContactEmail: false,
      filledContactPhone: false,
      changedTenantFields: false,
      /* Member table headers */
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "displayName",
        },
        {text: this.$t('tenant.roles.owner'), value: "owner"},
        {text: this.$t('tenant.roles.admin'), value: "admin"},
        // {
        //   text: 'Actions',
        //   value: 'actions',
        //   align: 'end',
        //   sortable: false
        // },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentTenant',
      'currentProfile',
      'isOwner',
      'isAdmin',
      'allTenantCodes',
    ]),
    displayNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.displayName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.displayName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.displayName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.displayName.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    companyNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.companyName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.companyName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.companyName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.companyName.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    addressErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.address.$dirty) return errors
      !this.$v.currentEditedLocalCopy.address.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.address.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.address.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    countryErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.country.$dirty) return errors
      !this.$v.currentEditedLocalCopy.country.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.country.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.country.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    zipCodeErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.zipCode.$dirty) return errors
      !this.$v.currentEditedLocalCopy.zipCode.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.zipCode.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.zipCode.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    cityErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.city.$dirty) return errors
      !this.$v.currentEditedLocalCopy.city.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.city.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.city.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    contactNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.contactName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.contactName.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    contactPositionErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactPosition.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactPosition.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.contactPosition.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.contactPosition.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    contactEmailErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactEmail.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactEmail.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      !this.$v.currentEditedLocalCopy.contactEmail.required && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.required'))
      return errors
    },
    contactPhoneErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactPhone.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactPhone.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    labelErrors() {
      const errors = []
      if (!this.$v.currentEditedLabel.label.$dirty) return errors
      !this.$v.currentEditedLabel.label.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLabel.label.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLabel.label.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
  },
  methods: {
    ...mapActions([
      'readProfile',
      'fetchAllTenantInviteCodes',
      'generateTenantInviteCode',
      'removeTenantMember',
      'updateTenantMemberStatus'
    ]),
    setMemberStatus(item, status) {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleChangeMemberStatus'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), {color: 'red'})
          .then((response) => {
            if (response.confirmed) {
              this.updateTenantMemberStatus({
                tenandId: this.currentTenant.id,
                newStatus: item,
              })
                  .catch(() => {
                    if (status === "owner") {
                      item.owner = !item.owner;
                    } else if (status === "admin") {
                      item.admin = !item.admin;
                    }
                  })
            } else {
              if (status === "owner") {
                item.owner = !item.owner;
              } else if (status === "admin") {
                item.admin = !item.admin;
              }
            }
          })
    },
    editItem(item) {
      // this.editedIndex = this.allLabels.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.dialog = true
      console.log("edit member", item)
    },
    deleteItem(item) {
      // const index = this.allLabels.indexOf(item)
      // const selectedLabel = this.allLabels[index]
      // confirm('Are you sure you want to delete this item?') && this.deleteLabel(selectedLabel)
      console.log("remove member", item)
    },
    close() {
      this.dialogInviteMember = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },
    inviteMember() {
      this.generateTenantInviteCode(this.editedItem.description);
      // if (this.editedIndex > -1) {
      //   const copyOfOriginalLabel = this.allLabels[this.editedIndex]
      //   Object.assign(copyOfOriginalLabel, this.editedItem)
      //   this.addLabel(copyOfOriginalLabel)
      // } else {
      //   this.addLabel(new LinkLabel(this.editedItem.label, this.editedItem.description, this.editedItem.color))
      // }
      this.close()
    },
    leaveTenantClicked() {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleLeaveTenant'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageLeaveTenant'), {color: 'red'})
          .then((response) => {
            if (response.confirmed) {
              this.removeTenantMember({userId: this.currentProfile.id, tenantId: this.currentTenant.id})
            }
          })
    },
  },
  watch: {
    isOwner(value) {
      if (value || !this.currentTenant) {
        this.isEditingDisabled = false;
      } else {
        this.isEditingDisabled = true;
      }
    },
    isAdmin(value) {
      if (value) {
        this.fetchAllTenantInviteCodes();
      }
    }
  },
  mounted() {
    this.fetchAllTenantInviteCodes();
    console.log(this.allTenantCodes)
  },
}
</script>

<style scoped>

</style>