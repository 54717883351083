<template lang="pug">
  v-container(fluid)
    v-row(align="center")
      v-col(cols="12")
        v-card(shaped)
          v-card-title Basic
          v-card-text
            v-form(
              class="px-3"
              ref="addnewtenantform"
              v-model="valid")
              v-row
                v-col(v-if="$vuetify.breakpoint.xs" cols="12" class="text-start")
                  v-avatar(size="64" :color="currentTenant.avatarColor? currentTenant.avatarColor : '#ffb84a'" class="justify-space-arround")
                    span(class="white--text headline") {{ currentTenant.initials }}
                v-col(cols="12" md="6")
                  v-text-field(
                    v-model="currentEditedLocalCopy.companyName"
                    :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyName')"
                    :disabled="!isOwner"
                    :filled="filledCompanyName"
                    @change="filledCompanyName = true, changedTenantFields = true"
                    class="mt-5"
                    :error-messages="companyNameErrors"
                    @input="$v.currentEditedLocalCopy.companyName.$touch()"
                    @blur="$v.currentEditedLocalCopy.companyName.$touch()")
                  v-text-field(
                    v-model="currentEditedLocalCopy.displayName"
                    :label="$vuetify.lang.t('$vuetify.tenant.labelDisplayName')"
                    :disabled="!isOwner"
                    :filled="filledDisplayName"
                    @change="filledDisplayName = true, changedTenantFields = true"
                    class="mt-5"
                    :error-messages="displayNameErrors"
                    @input="$v.currentEditedLocalCopy.displayName.$touch()"
                    @blur="$v.currentEditedLocalCopy.displayName.$touch()")
                v-col(v-if="$vuetify.breakpoint.smAndUp" cols="6" class="justify-center align-center text-center")
                  v-avatar(size="124" :color="currentTenant.avatarColor? currentTenant.avatarColor : '#ffb84a'" class="justify-space-around")
                    span(class="white--text headline") {{ currentTenant.initials }}
      v-col(cols="12" md="6")
        v-card(shaped)
          v-card-title Address
          v-card-text
            v-form(
              class="px-3"
              ref="addnewtenantform"
              v-model="valid")
              v-text-field(
                v-model="currentEditedLocalCopy.address.street"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyAdress')"
                :disabled="!isOwner"
                @change="filledStreet = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="streetErrors"
                @input="$v.currentEditedLocalCopy.address.street.$touch()"
                @blur="$v.currentEditedLocalCopy.address.street.$touch()")

              v-text-field(
                v-model="currentEditedLocalCopy.address.city"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyCity')"
                :disabled="!isOwner"
                @change="filledCity = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="cityErrors"
                @input="$v.currentEditedLocalCopy.address.city.$touch()"
                @blur="$v.currentEditedLocalCopy.address.city.$touch()")

              v-text-field(
                v-model="currentEditedLocalCopy.address.zipCode"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyZip')"
                :disabled="!isOwner"
                @change="filledZipCode = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="zipCodeErrors"
                @input="$v.currentEditedLocalCopy.address.zipCode.$touch()"
                @blur="$v.currentEditedLocalCopy.address.zipCode.$touch()")

              v-text-field(
                v-model="currentEditedLocalCopy.address.country"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyCountry')"
                @change="filledCountry = true, changedTenantFields = true"
                :disabled="!isOwner"
                class="mt-5"
                :error-messages="countryErrors"
                @input="$v.currentEditedLocalCopy.address.country.$touch()"
                @blur="$v.currentEditedLocalCopy.address.country.$touch()")
      v-col(cols="12" md="6")
        v-card(shaped)
          v-card-title Contact
          v-card-text
            v-form(class="px-3"
              ref="addnewtenantform"
              v-model="valid")
              v-text-field(
                v-model="currentEditedLocalCopy.contactName"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyContactPerson')"
                :disabled="!isOwner"
                :filled="filledContactName"
                @change="filledContactName = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="contactNameErrors"
                @input="$v.currentEditedLocalCopy.contactName.$touch()"
                @blur="$v.currentEditedLocalCopy.contactName.$touch()")

              v-text-field(
                v-model="currentEditedLocalCopy.contactPosition"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyContactPersonPosition')"
                :disabled="!isOwner"
                :filled="filledContactPosition"
                @change="filledContactPosition = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="contactPositionErrors"
                @input="$v.currentEditedLocalCopy.contactPosition.$touch()"
                @blur="$v.currentEditedLocalCopy.contactPosition.$touch()")
              v-text-field(
                v-model="currentEditedLocalCopy.contactEmail"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyContactPersonEmail')"
                :disabled="!isOwner"
                :filled="filledContactEmail"
                @change="filledContactEmail = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="contactEmailErrors"
                @input="$v.currentEditedLocalCopy.contactEmail.$touch()"
                @blur="$v.currentEditedLocalCopy.contactEmail.$touch()")
              v-text-field(
                v-model="currentEditedLocalCopy.contactPhone"
                :label="$vuetify.lang.t('$vuetify.tenant.labelCompanyContactPersonPhone')"
                :disabled="!isOwner"
                :filled="filledContactPhone"
                @change="filledContactPhone = true, changedTenantFields = true"
                class="mt-5"
                :error-messages="contactPhoneErrors"
                @input="$v.currentEditedLocalCopy.contactPhone.$touch()"
                @blur="$v.currentEditedLocalCopy.contactPhone.$touch()")
      v-col(v-if="isOwner" cols="12" class="text-left")
        v-btn(color="primary" rounded large @click="submit" :disabled="!changedFields") Update Team
      v-col(cols="12")
        TenantDangerZone
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validationMixin} from 'vuelidate'
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import Tenant from "../../store/classes/Tenant.js";
import FileUploadAvatar from "@/components/FileUploadAvatar";
import TenantDangerZone from "@/components/tenant/TenantDangerZone";

export default {
  name: "TenantOverview",
  components: {TenantDangerZone, FileUploadAvatar},
  mixins: [validationMixin],
  validations: {
    currentEditedLocalCopy: {
      displayName: {
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      address: {
        street: {
          minLength: minLength(3),
          maxLength: maxLength(256),
        },
        country: {
          minLength: minLength(3),
          maxLength: maxLength(256),
        },
        zipCode: {
          minLength: minLength(3),
          maxLength: maxLength(256),
        },
        city: {
          minLength: minLength(3),
          maxLength: maxLength(256),
        },
      },
      companyName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactName: {
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactPosition: {
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
      contactEmail: {email},
      contactPhone: {
        maxLength: maxLength(256),
      },
    },
  },
  data() {
    return {
      /**
       * A locally stored Tenant class object
       * @type {Tenant}
       */
      currentEditedLocalCopy: Tenant.empty(),
      /**
       * Used to differentiate between creating or editing tenants
       * @type {boolean}
       */
      editing: false,
      /**
       * Used to disable editing Tenant info
       * @type {boolean}
       */
      isEditingDisabled: false,
      customLabelsArray: [],
      currentEditedLabel: null,
      labelSelected: false,
      loading: false,
      valid: false,
      validLabel: false,
      //used to highlight filled(changed) fields
      filledDisplayName: false,
      filledCompanyName: false,
      filledStreet: false,
      filledCountry: false,
      filledZipCode: false,
      filledCity: false,
      filledContactName: false,
      filledContactPosition: false,
      filledContactEmail: false,
      filledContactPhone: false,
      changedTenantFields: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentTenant',
      'currentProfile',
      'isOwner',
      'isAdmin',
      'allCustomLabels'
    ]),
    changedFields() {
      return this.currentEditedLocalCopy.companyName !== this.currentTenant.companyName ||
          this.currentEditedLocalCopy.displayName !== this.currentTenant.displayName ||
          this.currentEditedLocalCopy.address.street !== this.currentTenant.address.street ||
          this.currentEditedLocalCopy.address.city !== this.currentTenant.address.city ||
          this.currentEditedLocalCopy.address.zipCode !== this.currentTenant.address.zipCode ||
          this.currentEditedLocalCopy.address.country !== this.currentTenant.address.country  ||
          this.currentEditedLocalCopy.contactPhone !== this.currentTenant.contactPhone ||
          this.currentEditedLocalCopy.contactEmail !== this.currentTenant.contactEmail ||
          this.currentEditedLocalCopy.contactName !== this.currentTenant.contactName ||
          this.currentEditedLocalCopy.contactPosition !== this.currentTenant.contactPosition
    },
    displayNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.displayName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.displayName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.displayName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    companyNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.companyName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.companyName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.companyName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      !this.$v.currentEditedLocalCopy.companyName.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      return errors
    },
    streetErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.address.street.$dirty) return errors
      !this.$v.currentEditedLocalCopy.address.street.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.address.street.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    countryErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.address.country.$dirty) return errors
      !this.$v.currentEditedLocalCopy.address.country.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.address.country.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    zipCodeErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.address.zipCode.$dirty) return errors
      !this.$v.currentEditedLocalCopy.address.zipCode.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.address.zipCode.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    cityErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.address.city.$dirty) return errors
      !this.$v.currentEditedLocalCopy.address.city.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.address.city.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    contactNameErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactName.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactName.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.contactName.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    contactPositionErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactPosition.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactPosition.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.currentEditedLocalCopy.contactPosition.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
    contactEmailErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactEmail.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactEmail.email && errors.push(this.$vuetify.lang.t('$vuetify.login.emailError.valid'))
      return errors
    },
    contactPhoneErrors() {
      const errors = []
      if (!this.$v.currentEditedLocalCopy.contactPhone.$dirty) return errors
      !this.$v.currentEditedLocalCopy.contactPhone.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
  },
  methods: {
    ...mapActions([
      'readProfile',
      'createTenant',
      'updateCurrentTenant',
      'addLabel',
      'deleteLabel',
      'updateTenantMemberStatus',
    ]),
    cleanUpTenantFields() {
      this.filledDisplayName = false;
      this.filledCompanyName = false;
      this.filledStreet = false;
      this.filledCountry = false;
      this.filledZipCode = false;
      this.filledCity = false;
      this.filledContactName = false;
      this.filledContactPosition = false;
      this.filledContactEmail = false;
      this.filledContactPhone = false;
      this.changedTenantFields = false;
    },
    submit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }
      this.loading = true;

      if (this.currentEditedLocalCopy.id) {
        this.updateCurrentTenant(this.currentEditedLocalCopy);
        this.cleanUpTenantFields()
      }
      this.loading = false;
    },
    setMemberStatus(item, status) {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleChangeMemberStatus'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageGeneric'), {color: 'red'})
          .then((response) => {
            if (response.confirmed) {
              this.updateTenantMemberStatus({
                tenandId: this.currentEditedLocalCopy.id,
                newStatus: item,
              })
                  .catch(() => {
                    if (status === "owner") {
                      item.owner = !item.owner;
                    } else if (status === "admin") {
                      item.admin = !item.admin;
                    }
                  })
            } else {
              if (status === "owner") {
                item.owner = !item.owner;
              } else if (status === "admin") {
                item.admin = !item.admin;
              }
            }
          })
    },
    setFieldValues(tenant) {
      if (tenant) {
        this.currentEditedLocalCopy = { ...tenant };
        this.isEditingDisabled = !this.isOwner;
        this.editing = true;
        //this.updateCustomLabels();
      } else {
        this.currentEditedLocalCopy = Tenant.empty();
        this.editing = false;
        this.isEditingDisabled = false;
      }
    },
  },
  mounted() {
    this.setFieldValues(this.currentTenant);
  },
  watch: {
    allCustomLabels(value) {
      if (value) {
        //this.updateCustomLabels();
      }
    },
    currentTenant(value) {
      if (value) {
        this.setFieldValues(value);
      }
    },
    isOwner(value) {
      this.isEditingDisabled = !(value || !this.currentTenant);
    }
  },
}
</script>