<template lang="pug">
  v-navigation-drawer(
      app
      :permanent="!$vuetify.breakpoint.mobile"
      :temporary="$vuetify.breakpoint.mobile"
      :mini-variant="mini"
      mini-variant-width="128"
      floating
      :color="!$vuetify.theme.dark? 'white' : 'grey darken-4'"
      v-model="drawer"
      width="290"
      class="text-center pt-8"
      @input="onStateChange")
    v-btn(v-if="$vuetify.breakpoint.xs" to="/" icon :width="$vuetify.breakpoint.mobile? 150 : 300"
           v-ripple="{ class: 'transparent--text', background: 'transparent' }"
           class="text-left justify-left")
    v-img(v-if="$vuetify.theme.dark" src="@/assets/images/logo/logoWhite.svg" alt="images.smartphone.alt"
           aspect-ratio="1.0" contain max-height="35" class="text-left justify-left"
           @load="logoLoaded = true")
    v-img(v-else src="@/assets/images/logo/logoBlack.svg" alt="images.smartphone.alt" aspect-ratio="1.0" contain
           max-height="35" class="text-left justify-left" @load="logoLoaded = true")

    TenantSelection

    v-divider(class="mt-4 mb-4")

    v-list(
        nav
        dense
        rounded)
      v-list-item
        v-list-item-content(class="text-left")
          v-list-item-title(class="title font-weight-thin text-uppercase") {{ $t('$vuetify.menu') }}
      v-list-item-group(
          v-if="signedIn"
          v-model="currentNavItem"
          color="primary"
          mandatory
          class="text-start justify-start"
          active-class="font-weight-bold"
      )
        v-list-item(
            v-for="item in navItems"
            :key="item.title"
            router
            :to="item.route"
            ripple
            class="ma-2")
          v-list-item-icon
            v-icon(right) {{ item.icon }}
          v-list-item-content {{ item.title }}
        v-list-item(
            v-if="$vuetify.breakpoint.mobile"
            router
            to="/profile"
            ripple
            class="ma-2")
          v-list-item-icon
            v-icon(right) mdi-cog
          v-list-item-content Settings
    template(v-slot:append)
      v-list(rounded)
        v-list-item(
            @click="onHelpClicked"
            class="ma-2" ripple)
          v-list-item-icon
            v-icon(size="16" right) mdi-help
          v-list-item-content {{ $vuetify.lang.t('$vuetify.navBar.userDocumentation') }}
        v-list-item(v-if="$vuetify.breakpoint.mobile" ripple class="ma-2" @click="logoutClicked")
          v-list-item-icon
            v-icon mdi-alpha-l
          v-list-item-content Logout
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import CreateTenantDialog from "@/components/tenant/creation/CreateTenantDialog";
import Search from "@/components/search/Search";
import JoinTenantDialog from "@/components/tenant/joining/JoinTenantDialog";
import Tenant from "@/views/tenant/Tenant";
import TenantSelection from "@/components/core/TenantSelection";

export default {
  name: "Drawer",
  components: {TenantSelection, Tenant, JoinTenantDialog, Search, CreateTenantDialog},
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      // for some reason the getter showFilter is not working in this case
      // because of the time limit I used this hack instead 29.12.2020
      search: false,
      mini: false,
      /**
       * Is nav menu visible
       * (name taken from Vue)
       * @type {boolean}
       */
      drawer: false,
      /**
       * The index of the active item in the Nav item group
       * Used to hide nav menu by clicking outside of it
       * @type {number}
       */
      currentNavItem: 0,
      /**
       * The display name of the tenant
       * @type {string}
       */
      tenantDisplayName: "",
      /**
       * The index of the current sort strategy in the dropdown
       * @type {number}
       */
      currentSortItem: 0,
      /**
       * The List of navigation items
       * @typedef navItem
       * @type {Object}
       * @property {string} icon
       * @property {string} title
       * @property {string} route
       */
      allNavItems: [
        {
          icon: 'mdi-view-dashboard-outline',
          title: this.$t('navBar.dashboard'),
          route: '/',
          private: true
        },
        {
          icon: 'mdi-account-group-outline',
          title: this.$t('navBar.editTenant'),
          route: '/tenant',
          private: false
        },
      ],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "signedIn",
      "filter",
      "currentTenant",
      "currentProfile",
      "isPrivateMode",
      "sortStrategies",
      "currentTenant",
      "currentProfile",
    ]),
    userInitials() {
      const name = !this.currentProfile ? '' : this.currentProfile.alias ? this.currentProfile.alias : this.currentProfile.email;

      const segments = name.split(" ").filter(Boolean);
      const letters = []

      for (let i = 0; i < segments.length; i++) {
        letters.push(segments[i].charAt(0))
      }

      const initials = letters.join('');

      return initials? initials : ''
    },
    userAvatarColor() {
      return (this.currentProfile && this.currentProfile.avatarColor)? this.currentProfile.avatarColor : '#ffb84a'
    },
    navItems() {
      if (this.isPrivateMode) {
        return this.allNavItems.filter((item) => {
          return item.private === true
        })
      }

      return this.allNavItems
    },
    /**
     * Returns the number of applied filters
     * @returns {number}
     */
    filterCounter() {
      return this.filter.numberOfAppliedFilters();
    },
    /**
     * Generates a filtered list of all sorting strategies
     * excludes author and likes strategies in private mode
     * @returns {SortStrategy[]}
     */
    filteredSortStrategies() {
      return this.sortStrategies.filter(strategy => {
        return !((this.isPrivateMode && strategy.type === "creator") || (this.isPrivateMode && strategy.type === "likes"))
      })
    },
    filteredTenants() {
      return (this.currentProfile && this.currentProfile.tenants) ? this.currentProfile.tenants.filter((item) => {
        return item.companyName !== this.currentTenant.companyName
      }) : []
    }
  },
  methods: {
    ...mapActions([
      "openFilter",
      "closeFilter",
      "newLink",
      "appLoggedOut",
      "readProfile",
      "SwitchToPrivateMode",
      "SetActiveTenant",
      "searchCards",
      "createTenant",
    ]),
    onStateChange(value) {
      if (value) {
        this.$emit('open') }
      else{
        this.$emit('close')
      }
    },
    onHelpClicked() {
      this.$fireAnalytics.logEvent('help_clicked');
      this.$emit('help-clicked')
    },
    /**
     * Toggles the filter and search panel
     */
    toggleSearch() {
      // for some reason the getter showFilter is not working in this case
      // because of the time limit I used this hack instead 29.12.2020
      this.search = !this.search;

      if (this.search) {
        this.$vuetify.goTo(0, {duration: 1, offset: 1, easing: "linear"});
        this.openFilter()
        return
      }

      this.closeFilter();
    },

    /**
     * Opens the new Link dialog
     */
    openDialog() {
      this.newLink();
    },
    /**
     * Redirects to the Dashboard
     */
    gotoDashboard() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      }
    },
    /**
     * Logs out the current user
     */
    logoutClicked() {
      try {
        this.appLoggedOut();
        if (this.$route.path !== "/login") {
          this.$router.push("login");
        }
      } catch (err) {
        console.log("Logout not possible", err);
      }
    },
    /**
     * Switches to private mode and goes to dashboard
     */
    SwitchToPrivateModeClicked() {
      this.SwitchToPrivateMode();
      this.gotoDashboard();
    },
    /**
     * Switches to selected tenant and goes to dashboard
     * @param {string} tenantId Tenant's ID
     */
    switchToTenantClicked(tenantId) {
      this.SetActiveTenant(tenantId);
      this.gotoDashboard();
    },
    /**
     * Sets the current sorting strategy and reloads the cards
     * @param {SortStrategy} strategy
     */
    SwitchSortStrategy(strategy) {
      this.sortStrategies.forEach(element => {
        if (element === strategy) {
          element.isEnabled ? element.isDescending = !element.isDescending : element.isEnabled = true;
        } else {
          element.isEnabled = false;
        }
      });
      this.searchCards(this.filter.tagFilters);
    }
  },
  watch: {
    currentNavItem() {
      this.drawer = false;
    },
    currentTenant(val) {
      if (val) {
        this.tenantDisplayName = val.displayName;
      } else {
        this.tenantDisplayName = "";
      }
    },
    open(newVal){
      this.drawer = newVal
    }
  },
};
</script>

<style scoped lang="css">
.v-btn--active.no-active::before {
  background-color: transparent !important;
}

#navbar {
}

.linkAsText {
  color: var(--secondary--text);
  text-decoration: none;
}

.v-btn::before {
  display: none;
}

.v-btn--active {
  font-weight: bold;
}
</style>