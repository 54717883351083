import FirestoreCodes from "../../../firebase/firestore/FirestoreCodes";
import FirestoreTenant from "../../../firebase/firestore/FirestoreTenants";
import FirestoreUser from "../../../firebase/firestore/FirestoreUser";
import LinkrCode, { CodeTypes, CodeStatus } from '../classes/LinkrCode';
import {fireAnalytics} from "../../main";

const  state = {
  /**
   * A list of all profile codes
   * @type {LinkrCode[]}
  */
  profileCodes: [],
  /**
   * A list of all tenant codes
   * @type {LinkrCode[]}
  */
  tenantCodes: [],
  /**
   * This code will be applied once the user is signed in
   * @type {String}
   */
  storedCode: null,
};


const getters = {
  /**
   * Retrives all stored profile codes
   * @returns {LinkrCode[]}
  */
  allProfileCodes(){ return state.profileCodes },
  /**
   * Retrives all stored Tenant codes
   * @returns {LinkrCode[]}
  */
  allTenantCodes(){ return state.tenantCodes},
  /**
   * Retrives the stored code
   * @returns {String}
   */
  currentStoredCode(){ return state.storedCode},
};

const actions = {
  /**
   * Fetches all Tenant invite codes from firestore
  */
  fetchAllTenantInviteCodes(context) {
    if (context.rootState.user && context.rootState.user.tenant) {
      FirestoreCodes.methods.getAllInviteCodes(context.rootState.user.tenant.id)
        .then( (response) => {
          context.commit('setInviteCodes', response);        
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
    }
  },
  /**
   * Fetches all Personal code copies from firestore
  */
  fetchAllPersonalCodeCopies(context) {
    FirestoreCodes.methods.getAllPersonalCodeCopies(context.rootState.user.user.uid)
      .then( (response) => {
        context.commit('setPersonalCodeCopies', response);        
      })
      .catch(error => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * Removes a Tenant Code
   * @param {LinkrCode}  code  Code Object
  */
  deleteTenantCode(context, code) {
    FirestoreCodes.methods.deleteCode(code)
      .then( () => {
        context.commit('deleteTenantCode', code);
        context.dispatch("StoreNotification" , {code: "code/deleted", type: "success"})
        fireAnalytics.logEvent('invite_code_deleted', {
          tenant_id: context.rootState.user.tenant.id ? context.rootState.user.tenant.id : 'private',
        });
      })
      .catch( (error) => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * Removes a Tenant Code
   * @param {LinkrCode}  code  Code Object
  */
  deletePersonalCodeCopy(context, code) {
    FirestoreCodes.methods.deleteCode(code)
      .then( () => {
        context.commit('deletePersonalCodeCopy', code);
        context.dispatch("StoreNotification" , {code: "code/deleted", type: "success"})
      })
      .catch( (error) => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * processes a Code entered by a user
   * @param {string}  code  Code string
  */
  processEnteredCode(context, code) {
    let aDisplayName = context.rootState.user.profile.alias ? 
      context.rootState.user.profile.alias : 
      context.rootState.user.profile.username + " " + context.rootState.user.profile.surname;
    LinkrCode.processCode(code, context.rootState.user.user.uid, aDisplayName, context.rootState.user.profile.tenants, context.rootState.user.user.email)
      .then( (response) => {
        if (response.result && response.result == 'accept') {
          response.code.properties.applicantId = context.rootState.user.user.uid;
          response.code.properties.applicantDisplayName = context.rootState.user.profile.alias;
          context.dispatch('acceptTenantInviteCode', response.code)
        } else {
          context.commit('addPersonalCodeCopy', response);
          context.commit('clearStoredCode');
          context.dispatch("StoreNotification" , {code: "code/recognized", type: "success"})
        }
      })
      .catch( (error) => {
        if (error.message) {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        } else {
          context.dispatch("StoreNotification" , {code: error, type: "info"})
        }
      });
  },
  /**
   * Rejects and disables the code
   * @param {LinkrCode} code 
   */
  rejectCode(context, code) {
    code.status = CodeStatus.REJECTED;
    FirestoreCodes.methods.updateCode(code)
      .then(() => {
        context.dispatch("StoreNotification" , {code: "code/rejected", type: "success"})
        fireAnalytics.logEvent('invite_code_rejected');
      })
      .catch(error => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * Generates a new Tenant invitation code
   * @param {string} [comment] - the code's comment
  */
  generateTenantInviteCode(context, comment) {
    return LinkrCode.createInvitation( context.rootState.user.tenant.id, context.rootState.user.tenant.displayName, context.rootState.user.tenant.avatarColor, comment)
      .then( (response) => {
        context.commit('addTenantCode', response);
        context.dispatch("StoreNotification" , {code: "code/created", type: "success"})
        fireAnalytics.logEvent('invite_code_created');
        return response
      })
      .catch(error => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * Accepts a Tenant invitation code
   * @param {LinkrCode}  code  
  */
  acceptTenantInviteCode(context, code) {
    if (code.type === CodeTypes.INVITE){
      FirestoreTenant.methods.addMember(code.properties.tenantId, code.properties.applicantId, code.properties.applicantDisplayName, code.id)
        .then(() => {
          code.status = CodeStatus.ACCEPTED;
          FirestoreCodes.methods.updateCode(code)
            .then(() => {
              FirestoreUser.methods.addTenantToUser(code.properties.applicantId, code.properties.tenantId, code.properties.tenantDisplayName, code.properties.avatarColor)
              .then(() => {
                if (code.properties.applicantId == context.rootState.user.user.uid) {
                  // add tenant to local store
                  const tenant = {id: code.properties.tenantId, displayName: code.properties.tenantDisplayName, joinedAt: new Date, avatarColor: code.properties.avatarColor }
                  context.dispatch('SetActiveTenant', tenant.id);
                } else {
                  // add member to local store
                  context.commit("addTenantMember", { id: code.properties.applicantId, owner: false, admin: false, displayName: code.properties.applicantDisplayName });
                }
                
                context.dispatch("StoreNotification" , {code: "tenant/member-added", type: "success"})
                fireAnalytics.logEvent('invite_code_accepted');
              })
              .catch( (error) => {
                context.dispatch("StoreError" , {error: error, showNotification: true})
              });              
            })
            .catch( (error) => {
              context.dispatch("StoreError" , {error: error, showNotification: true})
            }); 
        })
        .catch(error => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
    } else {
      context.dispatch("StoreNotification" , {code: 'yndooo/not-invite-code', type: "error"})
    }
  },
  /**
   * Send the invite code through email
   * @param {Object}  payload  payload
   * @param {LinkrCode} payload.code Invite code
   * @param {String} payload.to Invitee Email adress
   * @param {string} payload.displayName Invite sender display name
   * @param {String} payload.tenantName Team name that the user is being invited into
   */
  sendTeamInviteMail(context, payload) {
    FirestoreTenant.methods.sendEmailInvite(payload.code.properties.tenantId, payload.to, payload.code.code, payload.displayName, payload.tenantName)
      .then( () => {
        payload.code.assignInviteeEmail(payload.to)
        .then( () => {
          context.dispatch("StoreNotification" , {code: "code/email-sent", type: "success"})
        })
        .catch( (error) => {
          context.dispatch("StoreError" , {error: error, showNotification: true})
        });
      })
      .catch( (error) => {
        context.dispatch("StoreError" , {error: error, showNotification: true})
      });
  },
  /**
   * Stores an invite code so that it could be used once the user is signed in
   * 
   * @param {String} code Invite Code
   */
  storeInviteCode(context, code) {
    context.commit('storeCode', code);
  }
};

const mutations = {
  setInviteCodes(state, codes){
    state.tenantCodes = codes;
    console.log("Tenant Invite codes updated: ", state.tenantCodes);
  },
  setPersonalCodeCopies(state, codes){
    state.profileCodes = codes;
    console.log("Personal codes updated: ", state.profileCodes);
  },
  addTenantCode(state, code){
    state.tenantCodes.push(code);
    console.log("Tenant Invite codes updated: ", state.tenantCodes);
  },
  addPersonalCodeCopy(state, code){
    state.profileCodes.push(code);
    console.log("Personal codes updated: ", state.profileCodes);
  },
  deleteTenantCode: (state, code) =>  {
    let indexOfElement = state.tenantCodes.indexOf(code);
    state.tenantCodes.splice(indexOfElement, 1);
    console.log("Tenant Invite code deleted: ", state.tenantCodes);
  },
  deletePersonalCodeCopy: (state, code) =>  {
    let indexOfElement = state.profileCodes.indexOf(code);
    state.profileCodes.splice(indexOfElement, 1);
    console.log("Tenant Invite code deleted: ", state.profileCodes);
  },
  clearAllCodes(state){
    state.tenantCodes = [];
    state.profileCodes = [];
  },
  storeCode(state, code){
    state.storedCode = code;
    console.log("code stored: ", code);
  },
  clearStoredCode(state){
    state.storedCode = null;
    console.log("code deleted");
  }
};

export default{
  state,
  getters,
  actions,
  mutations
};
