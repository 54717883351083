<template lang="pug">
  v-row(justify="center")
    v-col(cols="12" lg="6")
      v-card(flat color="transparent")
        v-card-actions
          v-row
            v-col(cols="12")
              h4(class="text-h5 text-center") {{ this.$t('tenant.join.step1.title') }}
            v-col(cols="12" class="pb-0")
              v-text-field(
                :label="$t('tenant.join.code')"
                v-model="editedItem.inviteCode"
                :error-messages="inviteCodeErrors"
                autofocus
                outlined
                @input="$v.editedItem.inviteCode.$touch()"
                @keyup.enter.native="joinTenant")
            v-col(cols="12" class="pt-0 pb-0")
              p {{ this.$t('tenant.join.step1.subtitle') }}
            v-col(cols="12" class="pt-0 mt-0")
              v-btn(@click="joinTenant" :loading="loading" color="primary" :disabled="$v.editedItem.inviteCode.$invalid") {{ this.$t('tenant.join.step1.join') }}
</template>

<script>
import {mapActions} from "vuex";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "JoinTenantStepperOne",
  mixins: [validationMixin],
  validations: {
    editedItem: {
      inviteCode: {
        required: required,
        minLength: minLength(3),
        maxLength: maxLength(256),
      },
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editedItem: {
        inviteCode: "",
      },
      defaultItem: {
        inviteCode: "",
      }
    }
  },
  computed: {
    inviteCodeErrors() {
      const errors = []
      if (!this.$v.editedItem.inviteCode.$dirty) return errors
      !this.$v.editedItem.inviteCode.required && errors.push(this.$vuetify.lang.t('$vuetify.rules.fieldnotification'))
      !this.$v.editedItem.inviteCode.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 3))
      !this.$v.editedItem.inviteCode.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
  },
  props: {
    forceClose: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  watch: {
    forceClose: function (newVal, oldVal) {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'processEnteredCode',
    ]),
    reset() {
      this.editedItem = {... this.defaultItem }
    },
    joinTenant() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true

      this.processEnteredCode(this.editedItem.inviteCode);
      this.editedItem = {... this.defaultItem}

      this.loading = false
      this.dialog = false
    },
  }
}
</script>

<style scoped>

</style>