import LinkType from './LinkType'
import LinkLabel from './LinkLabel'
import LinkDateFormatter from './LinkDateFormatter'

/**
  * Generates keywords from a single word by creating an array of letter combinations,
  * by starting with the first letter and sequentially adding all the remaining letters
  * 
  * @param {string} word - the Word that will be split
  * @returns {string[]} combinations of letters
*/
const createKeywords = (word) => {
  const arrWords = [];
  let curword = ""
  word.split('').forEach(letter => {
    curword += letter;
    if (curword.length > 1) {
      arrWords.push(curword);
    }
  });
  return arrWords;
}

/**
  * Contains all the card link properties and methods
*/
export default class Link {  
  /**
   * the Link's id
   * @type {string}
  */
  id = "";
  /**
   * the Link's keywords that are used in search
   * @type {string[]}
  */
  keywords = [];
  /**
   * the Link's address
   * @type {string}
  */
  href = "";
  /**
   * the Link's title
   * @type {string}
  */
  title = "";
  /**
   * the Link's description
   * @type {string}
  */
  description = "";
  /**
   * the Link's type
   * @type {LinkType}
  */
  type = null;
  /**
   * the Link's labels
   * @type {LinkLabel[]}
  */
  labels = [];
  /**
   * the Link's tags
   * @type {string[]}
  */
  tags = [];
  /**
   * the Link's creation date
   * @type {Date}
  */
  created = null;
  /**
   * the Link's author id
   * @type {string}
  */
  author = "";
  /**
   * the Link's modified date
   * @type {Date}
  */
  modifiedDate = null;
  /**
   * the id of user that modified the card
   * @type {string}
  */
  modifiedBy = "";
  /**
   * The Link's author display name.
   * This is used only if the user has left the tenant
   * @type {string}
  */
  displayName = "";
  /**
   * the Link's tenant
   * @type {string}
  */
  tenant = "";
  /**
   * the Link's likes counter
   * @type {number}
  */
  likes = 0;
  /**
   * is this Link liked by the current user
   * @type {boolean}
  */
  isLiked = false;
  /**
   * Locked links can only be modified by tenant owners and admins
   * @type {boolean}
  */
  locked = false;

  /**
    * @constructs Link
    * @param {string} href - the Link's address
    * @param {string} title - The Link's title.
    * @param {string} description - The Link's description.
    * @param {LinkType} type - The Link's type.
    * @param {LinkLabel[]} labels - The Link's labels.
    * @param {Object[]} tags - The Link's tags.
    * @param {Date} created - The Link's creation date.
    * @param {string} author - The Link's author id.
    * @param {string} tenant - The Link's tenant id.
    * @param {number} [likes] - The Link's likes counter
    * @param {boolean} [locked=false] - The Link's locked status
  */
  constructor(href, title, description, type, labels, tags, created, author, tenant, likes, locked){
    this.href = href;
    this.title = title;
    this.description = description;
    this.type = type; //this.initType(type);
    this.labels = labels;
    this.tenant = tenant;
    this.tags = tags ? tags : [];
    this.created = created;
    this.author = author;
    this.isLiked = false;
    if(likes && likes > 0){
      this.likes = likes;
    }
    if(locked){
      this.locked = true;
    }
  }
  /**
   * initializes LinkType from name
   *
   * @param {String}  type  a name of an existing type.
   *
   * @return {LinkType} Returns the LinkType object if it exists, otherwise it returns undefined LinkType object
  */
  initType(type){
    if(!(Object.prototype.toString.call(type) === '[object Object]')){
      return LinkType.fromName(type, store.getters.allTypes);
    }
  }
  /**
   * Changes the link address
   *
   * @memberof Link#
   * 
   * @param {String}  href  a link address.
  */
  setHref(href){
    this.href = href;
  }
  /**
   * increses the like counter
  */
  like(){
    this.likes ++;
    this.isLiked = true;
  }
  /**
   * Checks if Link contains a specific label
   *
   * @param {LinkLabel}  label  a LinkLabel object
   *
   * @return {boolean} Returns true if this Link contains this specific label, false otherwise
  */
  hasLabel(label){
    return this.labels.findIndex(el => el.id === label.id) >= 0
  }
  /**
   * Checks if Link contains a specific type
   *
   * @param {LinkType}  type  a LinkType object
   *
   * @return {boolean} Returns true if this Link contains this specific type, false otherwise
  */
  hasType(type){
    return this.type.name === type.name
  }
  /**
   * Checks if Link contains specific tags
   *
   * @param {string[]}  filterTags  an array of tags
   *
   * @return {boolean} Returns true if this Link contains the specified tags, false otherwise
  */
  testTags(filterTags){
    if(this.tags && this.tags.length >= 0){
      return filterTags.every(i => this.tags.includes(i));
    }
    return true;
  }
  /**
   * Generates a date string based on the difference between the card creation date and today
   * Example: "Today", "Yesterday",  "x days ago"
   *
   *  @return {string} a date string
  */
  formattedDate(){
    return this.modifiedDate ? LinkDateFormatter.format(this.modifiedDate) : LinkDateFormatter.format(this.created);
  }
  /**
   * Checks if Link title or description conains search terms
   *
   * @param {String[]}  searchTerms  an array of serach tems
   *
   * @return {boolean} Returns true if this Link contains all the specified searchterms, false otherwise
  */
  testFreeText(searchTerms){
    if(searchTerms && searchTerms.length > 0){
      return searchTerms.every(i => {
        let tmp = i.toLowerCase();
        return this.title.toLowerCase().includes(tmp) || this.description.toLowerCase().includes(tmp);
      });
    }
    return true;
  }
  /**
   * Returns the current tenant if it is defined, returns Personal otherwise
   *
   * @return {string} 
  */
  getTenant(){
    if(this.tenant) {
      return this.tenant
    }
    return "Personal";
  }
  /**
   * Creates a copy of this Link object
   *
   * @return {Link} Returns a new Link object
  */
  copy(){
    let tmpLink = new Link(this.href, this.title, this.description, this.type, [...this.labels], [...this.tags], this.created, this.author, this.tenant, this.likes, this.locked);
    tmpLink.id = this.id;
    return tmpLink;
  }
  /**
   * Updates the properties of a link
   * 
   * @param {Object} newValues - the new values for the link
   * @param {string} newValues.href - the Link's adress
   * @param {string} newValues.title - The Link's title.
   * @param {string} newValues.description - The Link's description.
   * @param {LinkType} newValues.type - The Link's type.
   * @param {LinkLabel[]} newValues.labels - The Link's labels.
   * @param {Object[]} newValues.tags - The Link's tags.
   * @param {string} newValues.author - The Link's author id.
   * @param {boolean} newValues.locked - The Link's author id.
   * @param {string} newValues.tenant - The Link's tenant id.
  */
  update(newValues){
    this.href = newValues.href;
    this.title = newValues.title;
    this.description = newValues.description;
    this.type = newValues.type; //this.initType(type);
    this.labels = newValues.labels;
    this.tags = newValues.tags;
    this.author = newValues.author;
    this.locked = newValues.locked;
    this.tenant = newValues.tenant;
    this.modifiedDate = newValues.modifiedDate;
    this.modifiedBy = newValues.modifiedBy;
  }
  /**
   * Generates keywords for the link
  */
  generateKeywords(){
    let arrKeywords = [];
    // split title into words
    this.title.split(' ').forEach( titleWord => {
      arrKeywords.push(titleWord.toLowerCase());
    });
    // split description into words
    if (this.description && this.description.length > 0) {
      this.description.split(' ').forEach( descriptionWord => {
        arrKeywords.push(descriptionWord.toLowerCase());
      });
    }
    /* 
    // split all words into letter combinations
    let arrSplitWords = [];
    arrKeywords.forEach(word => {
      if (word.length > 1){
        arrSplitWords = arrSplitWords.concat(createKeywords(word));
      }
    })   
    */  
    //merge all letter combinations into keywords
    this.keywords = arrKeywords;
    // add tags to keywords
    if (this.tags && this.tags.length > 0){
      this.tags.forEach(tag => {
        this.keywords.push(tag.toLowerCase());
      })
    }
  }
  /**
   * Creates a list of label ids
   *
   * @return {object[]} Returns an array of JSON labels, or an empty string
  */
  getlabelIds(){
    if(this.labels && this.labels.length > 0){
      let labelIds = [];
      this.labels.forEach((item, i) => {
        labelIds.push(item.id);
      });
      return labelIds;
    } else {
      return [];
    }
  }
  /**
   * Generates an Empty Link object
   * @static
   * @return {Link} Returns an empty Link object
  */
  static empty(){
      return new Link(this.href, this.title, this.description, LinkType.undefined(), this.labels, this.tags, new Date(), this.author, this.tenant);
  }
}
