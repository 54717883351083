<template lang="pug">
  v-list(color="transparent")
    v-list-item(v-for="(item, index) in items" :key="index")
      v-list-item-content(class="text-start")
        p.text-subtitle-1.font-weight-medium
          svg.svg aria-hidden="true" class="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--checkCircle-svg Icon-color-svg Icon-color--blue-svg" height="16" width="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z" fill-rule="evenodd"></path>
          | {{$vuetify.lang.t(`$vuetify.register.sellingPoints[${index}].title`) }}
        p.padText.text-subtitle-2.font-weight-light.fontColorLight--text {{ $vuetify.lang.t(`$vuetify.register.sellingPoints[${index}].description`)}}
</template>

<script>
export default {
  name: "SellingPointList",
  data() {
    // This is a fix because I cant retrieve the array length of the i18n array
    // TODO figure out why it is not possible to retrieve the length of the vuetify array with:
    // $vuetify.lang.t("$vuetify.register.sellingPoints').length
    return {
      items: [1, 2, 3]
    };
  },
}
</script>

<style scoped>
.svg {
  padding-top: 2px;
  max-height: 1.5em;
  max-width: 25px;
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  fill: #0066cc; /* primary color */
}
.padText {
  padding-top: 2px;
  padding-left: 25px;
}
</style>