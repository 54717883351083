<template>
  <v-card
      height="210px"
      shaped
      target="_blank"
      hover
      v-if="!loading"
  >
    <v-row
        no-gutters
    >
      <v-col
          class="ps-4"
          style="max-width: calc(100% - 60px)"
      >
        <v-row
            no-gutters
        >
          <v-col
              class="d-flex align-end"
              style="min-height: 44px"
          >
            <v-container
                class="pa-0"
            >
              <p
                  class="ma-0 caption text-truncate"
              >
                <a
                    :href="link.href"
                    target="_blank"
                    class="bde-card-link"
                >
                  {{ link.href }}
                </a>
              </p>
              <v-spacer
                  class="pb-2"
              ></v-spacer>
              <v-divider></v-divider>
            </v-container>
          </v-col>
        </v-row>
        <v-row
            no-gutters
        >
          <v-col
              cols="1"
              style="min-height: 120px; max-width: 19px;"
              class="d-flex flex-grow-0 flex-shrink-0"
          >
            <v-container
                class="px-0"
            >
              <v-img
                  v-if="hasFavIcon"
                  height="16px"
                  width="16px"
                  class="mt-3"
                  :src="getDomainFaviconURL(link.href)"
                  @error="hasFavIcon = false"
                  contain
              />
              <v-icon
                  small
                  class="pt-3"
                  v-else
              >
                {{ link.type.icon }}
              </v-icon>
            </v-container>
          </v-col>
          <v-col
              style="max-width: calc(100% - 35px)"
          >
            <v-container
                style="min-height: 120px;"
                class="pa-0"
            >
              <v-row
                  no-gutters
              >
                <v-col>
                  <p
                      class="ma-0 pt-5 body-1 font-weight-medium text-truncate"
                  >
                    <a
                        :href="link.href"
                        target="_blank"
                    >
                      {{ link.title }}
                    </a>
                  </p>
                </v-col>
              </v-row>
              <v-row
                  no-gutters
              >
                <v-col
                >
                  <p
                      class="ma-0 pt-2 text-truncate"
                  >
                    {{ link.description }}
                  </p>
                </v-col>
              </v-row>
              <v-row
                  no-gutters
              >
                <v-col>
                  <p
                      class="ma-0 pt-2 caption text--disabled"
                  >
                    {{ getDisplayDateAndName(link) }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row
            no-gutters
            v-if="link.labels.length > 0"
        >
          <v-col>
            <v-card
                min-height="34px"
                flat
            >
              <v-divider class="pb-2"></v-divider>
              <v-chip-group
                  show-arrows
                  class="bde-sliders-short"
              >
                <v-chip
                    class="py-0 my-0"
                    v-for="label in link.labels"
                    :key="label.id"
                    label
                    :color="label.color"
                    outlined
                    small
                >
                  {{ label.label }}
                </v-chip>
              </v-chip-group>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
          cols="1"
          style="min-width: 60px; max-width: 60px;"
          class="flex-grow-0 flex-shrink-0"
      >
        <v-card-actions
            style="min-height: 200px;"
            class="d-flex flex-column"
        >
          <v-row
              no-gutters
              class="d-flex align-start flex-column"
          >
            <v-col
                class="d-flex align-start justify-center"
            >
              <v-menu
                  bottom
                  left
              >
                <template
                    v-slot:activator="{ on }"
                >
                  <v-btn
                      icon
                      small
                      v-on="on"
                  >
                    <v-icon
                        grey
                        lighten-4
                    >
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                    class="card-context-menu text-left"
                    subheader
                >
                  <v-subheader
                      v-if="link.locked"
                  >
                    {{ $vuetify.lang.t('$vuetify.locked') }}
                  </v-subheader>
                  <v-list-item
                      v-if="link.tenant && ((currentStatus === 'owner') || (currentStatus === 'admin'))"
                      min-height="28px"
                      @click="lockToggleClicked"
                  >
                    <v-list-item-title>
                      {{ link.locked ? $vuetify.lang.t('$vuetify.unlock') : $vuetify.lang.t('$vuetify.lock') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      min-height="28px"
                      @click="editClicked"
                      :disabled="this.link.locked || !(((this.link.author === this.currentUser.uid) || (currentStatus === 'owner') || (currentStatus === 'admin')))"
                  >
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="!link.tenant && currentProfile.activeTenant"
                      min-height="28px"
                      @click="moveClicked"
                      :disabled="this.link.locked || !(((this.link.author === this.currentUser.uid) || (currentStatus === 'owner') || (currentStatus === 'admin')))"
                  >
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.move') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      min-height="28px"
                      @click="deleteClicked"
                      :disabled="this.link.locked || !(((this.link.author === this.currentUser.uid) || (currentStatus === 'owner') || (currentStatus === 'admin')))"
                  >
                    <v-list-item-title>
                      {{ $vuetify.lang.t('$vuetify.delete') }}
                    </v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row
              no-gutters
              class="d-flex align-center flex-column"
          >
            <v-col
                class="d-flex align-center justify-center"
            >
              <div
                  v-if="link.tenant"
              >
                  <FavoriteToggle :link="link"></FavoriteToggle>
              </div>
              <v-img
                  v-else
                  height="100"
                  contain
                  src="@/assets/privatemode.svg"
              />
            </v-col>
          </v-row>
          <v-row
              no-gutters
              class="d-flex align-end flex-column"
          >
            <v-col
                class="d-flex align-end justify-center"
            >
              <v-icon
                  medium
                  @click="showShareDialog"
              >
                mdi-share-variant
              </v-icon>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
  <v-skeleton-loader v-else type="card" :loading="true">
  </v-skeleton-loader>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FavoriteToggle from "@/components/favorite/FavoriteToggle";

export default {
  components: {FavoriteToggle},
  props: {
    link: {
      required: true,
    },
    loading: {
      required: false,
      default: false,
    }
  },
  data: () => ({
    hasFavIcon: false,
  }),
  methods: {
    ...mapActions([
      "likeLink",
      "deleteLink",
      "editLink",
      "shareLink",
      "assignTenant",
      "toggleCardLock",
      "showShareDialog",
    ]),
    deleteClicked: function () {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleDeleteCard'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageDeleteCard'), {color: 'red'})
          .then((response) => {
            if (response.confirmed) {
              this.deleteLink(this.link);
            }
          })
    },
    editClicked: function () {
      this.editLink(this.link);
    },
    moveClicked: function () {
      this.$root.$confirm(this.$vuetify.lang.t('$vuetify.confirmDialog.titleMoveCard'), this.$vuetify.lang.t('$vuetify.confirmDialog.messageMoveCard'), {color: 'blue'})
          .then((response) => {
            if (response.confirmed) {
              this.assignTenant({
                link: this.link,
                tenant: this.currentProfile.activeTenant,
              });
            }
          })
    },
    lockToggleClicked: function () {
      this.toggleCardLock(this.link)
    },
    showShareDialog: function () {
      this.shareLink(this.link)
    },
    getDisplayDateAndName(link) {
      let prefix = link.modifiedBy ? this.$vuetify.lang.t('$vuetify.modified') + " " : "";
      let aDate = link.formattedDate();
      let aDisplayName = ""
      if (!this.isPrivateMode) {
        let aAuthorDisplayName = this.userIdToDisplayName(link.author);
        let aModifierDisplayName = this.userIdToDisplayName(link.modifiedBy);
        aDisplayName = aModifierDisplayName ? aModifierDisplayName :
            aAuthorDisplayName ? aAuthorDisplayName :
                link.displayName ? link.displayName :
                    this.$vuetify.lang.t('$vuetify.undefined');
        aDisplayName = " " + this.$vuetify.lang.t('$vuetify.by') + " " + aDisplayName
      }
      return prefix + aDate + aDisplayName;
    },
    getDomainFaviconURL(linkurl) {
      var domain = linkurl.match(/(\w+):\/\/([^/:]+)(:\d*)?([^# ]*)/);
      domain = RegExp.$2;
      var faviconurl = "https://s2.googleusercontent.com/s2/favicons?domain=" + domain;
      return faviconurl;
    },
  },
  computed: {
    ...mapGetters([
      "currentProfile",
      "currentUser",
      "currentStatus",
      "isPrivateMode",
      "userIdToDisplayName",
    ]),
    likeNumber: function () {
      return this.link.likes ? this.link.likes : "0";
    },
  },
  created() {
    if (this.link.type.name == "www") {
      this.hasFavIcon = true;
    }
  }
};
</script>

<style>
.card-context-menu .v-list-item {
  min-height: 30px;
}

a {
  text-decoration: none;
}

a.bde-card-link {
  color: gray !important;
}

.bde-sliders-short .v-slide-group__prev, .bde-sliders-short .v-slide-group__next {
  flex: 0 1 25px;
  min-width: 25px;
}

/* Like animation of the heart */
@keyframes heart { 0%, 17.5% { font-size: 0; } }

[id='toggle-heart']  {
  will-change: font-size;
  animation: heart 1s cubic-bezier(.17, .89, .32, 1.49);
}
</style>
