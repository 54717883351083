var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentTenant && _vm.isAdmin)?_c('v-card',{attrs:{"shaped":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.currentTenant.members,"itemsPerPage":_vm.itemsPerPage,"sort-by":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"text-start",attrs:{"justify":"start","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2","md":"1"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.tenant.titleMembers')))])],1),_c('v-col',{staticClass:"pl-0 pr-0 justify-start text-start",attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('$vuetify.search'),"single-line":"","hide-details":"","rounded":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-end justify-end",attrs:{"cols":"12","sm":"4","md":"6"}},[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","height":"100%","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdmin)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","rounded":"","block":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){_vm.editedItem.description = ''}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('tenant.invite.btnInvite')))],1):_vm._e()]}}],null,false,1097624525),model:{value:(_vm.dialogInviteMember),callback:function ($$v) {_vm.dialogInviteMember=$$v},expression:"dialogInviteMember"}},[_c('v-card',{attrs:{"fill-height":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogInviteMember = false}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,false,1290636644)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.close')))])])],1)],1),_c('v-container',{attrs:{"fill-height":""}},[_c('CreateTenantStepperTwo',{attrs:{"skipButton":false},on:{"next":function($event){_vm.dialogInviteMember = false}}})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.isAdmin},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.isAdmin},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"disabled":""},model:{value:(item.owner),callback:function ($$v) {_vm.$set(item, "owner", $$v)},expression:"item.owner"}})]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"disabled":item.owner || !_vm.isAdmin},on:{"input":function($event){return _vm.setMemberStatus(item, 'admin')}},model:{value:(item.owner? item.owner : item.admin),callback:function ($$v) {_vm.$set(item.owner? item.owner : item, "admin", $$v)},expression:"item.owner? item.owner : item.admin"}})]}}],null,true)})],1)],1):_vm._e()],1),(_vm.currentTenant && _vm.isAdmin && _vm.allTenantCodes.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('CodeGenerate')],1):_vm._e(),(_vm.currentTenant && !_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"shaped":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("tenant.members.dangerZone")))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t("tenant.members.dangerZoneInfo")))]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.leaveTenantClicked}},[_vm._v(_vm._s(_vm.$t("tenant.members.leaveTenant")))])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }