import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app'
import Home from '../views/Dashboard.vue';
import Profile from '../views/Profile.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue'
import store from '../store/index'
import NotFound from '../views/NotFound.vue'
import Reset from "@/views/Reset";
import Privacy from "@/views/Privacy";
import UserManagement from "@/views/UserManagement"
import Tenant from "../views/tenant/Tenant";
import TenantMembersPage from "@/views/tenant/TenantMembersPage";
import TenantLabelsPage from "@/views/tenant/TenantLabelsPage";
import TenantSubscriptionPage from "@/views/tenant/TenantSubscriptionPage";

Vue.use(VueRouter);

const mainTitle = "Yndooo";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      title: mainTitle
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: mainTitle +" - Login"
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Reset,
    meta: {
      requiresAuth: false,
      title: mainTitle +" - Password Recovery"
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false,
      title: mainTitle +" - Register"
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      requiresAuth: false,
      title: mainTitle +" - Privacy"
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Profile"
    }
  },
  {
    path: '/tenant',
    name: 'Tenant',
    component: Tenant,
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Tenant"
    }
  },
  {
    path: '/tenant/members',
    name: 'TenantMembers',
    component: TenantMembersPage,
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Tenant Members"
    }
  },
  {
    path: '/tenant/labels',
    name: 'TenantLabels',
    component: TenantLabelsPage,
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Tenant Labels"
    }
  },
  {
    path: '/tenant/subscription',
    name: 'TenantLabels',
    component: TenantSubscriptionPage,
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Tenant Billing"
    }
  },
  {
    path: '/usermgmt',
    name: 'User Management',
    component: UserManagement,
    meta: {
      requiresAuth: false,
      title: mainTitle +" - userManagement"
    }
  },
  {
    // catch all 404 - define at the very end
    path: "*",
    component: NotFound,
    name: '404',
    meta: {
      requiresAuth: true,
      title: mainTitle +" - Not Found"
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // console.log(to);
  document.title = to.meta.title;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.signedIn) {
      if (to.name == "Login") {
        // redirect from login page if user is signed in
        next({ name: 'Home', query: to.query });
      } else {
        next({ query: to.query });
      }      
    } else {
      // check if user is stored in local storage
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          store.dispatch("appLoggedIn", user);
          next({ query: to.query });
        } else {
          next({ name: 'Login', query: to.query });
        }
      });
    }
  } else {
    next({ query: to.query }) // does not require auth, make sure to always call next()!
  }
})
export default router;