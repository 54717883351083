export default class SortStrategy{
  /**
  * the Sort strategy type (used as a code in i18n)
  * @type {string}
  */
  type = "";
  /**
  * the field name in Firestore to store by
  * @type {string}
  */
  fieldName = "";
  /**
  * Is this Sorting strategy enabled
  * @type {boolean}
  */
  isEnabled = false;
  /**
  * Is Sorting done in descending order
  * @type {boolean}
  */
  isDescending = false;

  /**
  * @constructs SortStrategy
  * @param {string} type - the Sort strategy type (used as a code in i18n)
  * @param {string} fieldName - the field name in Firestore to store by
  * @param {boolean} isEnabled - Is this Sorting strategy enabled
  * @param {boolean} isDescending - Is Sorting done in descending order
  */
  constructor(type, fieldName, isEnabled, isDescending){
    this.type = type;
    this.fieldName = fieldName;
    this.isEnabled = isEnabled;
    this.isDescending = isDescending;
  }

  /**
  * Sorts provided list according to current strategy.
  */
  sort(linkList){
    if(this.sortStrategy === 'default'){
      return linkList;
    }
    return null;
  }

  /**
   * Generates a new default Sort Strategy object 
   * @static   * 
   * @returns {SortStrategy} 
   */
  static default(){
    return new SortStrategy('created', 'created', true, true);
  }
}
