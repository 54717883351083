import firebase from 'firebase/app'
import { db , fireAnalytics } from '../../src/main'
import Link from '../../src/store/classes/Link'
import LinkLabel from '../../src/store/classes/LinkLabel'
import LinkType from '../../src/store/classes/LinkType'
import types from '../../src/store/modules/types'
import labels from '../../src/store/modules/labels'
import user from '../../src/store/modules/user'
import SortStrategy from '../../src/store/classes/SortStrategy'

const linkConverter = {
  /**
   * Converts A Link class object into JSON object
   *
   * @param {Link}  link  a Link class object
  */
  toFirestore: function(link) {
    return {
      keywords: link.keywords ? link.keywords : [],
      href: link.href,
      title: link.title,
      description: link.description ? link.description : "",
      type: link.type.name,
      labels: link.getlabelIds(),
      tags: link.tags ? link.tags : [],
      created: link.created,
      author: link.author,
      tenant: link.tenant? link.tenant : null,
      likes: link.likes,
      locked: link.locked,
      modifiedDate: link.modifiedDate ? link.modifiedDate : link.created,
      modifiedBy: link.modifiedBy ? link.modifiedBy : null,
    }
  },
  /**
   * Converts Firestore document data into a Link class
   *
   * @param {firebase.firestore.QuerySnapshot}  snapshot  retrieved Document data
   * @param {object}  snapshot  query options
   * 
   * @returns {Link}
  */
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    const linkType = LinkType.fromName(data.type, types.state.types);
    const linkLabels = [];
    const systemLabels = user.state.privateMode ? labels.state.defaultLabels : labels.state.customLabels;
    data.labels.forEach((item) => {
      let aLabel = LinkLabel.fromID(item, systemLabels)
      if (aLabel && !aLabel.isDeleted) {
        linkLabels.push(aLabel);
      }
    });
    const convertedLink = new Link(data.href, data.title, data.description, linkType, linkLabels, data.tags, data.created.toDate(), data.author, data.tenant, data.likes, data.locked)
    if (!data.keywords || data.keywords.length == 0){
      convertedLink.noKeywords = true;
    }
    if (data.displayName) {
      convertedLink.displayName = data.displayName;
    }
    convertedLink.modifiedDate = data.modifiedDate ? data.modifiedDate.toDate() : null;
    convertedLink.modifiedBy = data.modifiedBy ? data.modifiedBy : null;
    return convertedLink;
  }
}
const labelConverter = {
  /**
   * Converts A LinkLabel class object into JSON object
   *
   * @param {LinkLabel}  label  a LinkLabel class object
  */
  toFirestore: function(label) {
    return {
      label: label.label,
      description: label.description ? label.description : "",
      color: label.color,
      isDefault: label.isDefault ? label.isDefault : false,
      isDeleted: label.isDeleted ? label.isDeleted : false,
    }
  },
  /**
   * Converts Firestore document data into a LinkLabel class
   *
   * @param {firebase.firestore.QuerySnapshot}  snapshot  retrieved Document data
   * @param {object}  snapshot  query options
   * 
   * @returns {LinkLabel}
  */
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    const convertedLabel = new LinkLabel(data.label, data.description, data.color, data.isDefault ? data.isDefault : false,  data.isDeleted ? data.isDeleted : false)
    return convertedLabel;
  }
}
/**
 * asynchronosly read documents from firestore
 *
 * @param {firebase.firestore.CollectionReference}  collRef  Firebase collection reference
 * @param {boolean} isCards true if retrieving cards, false if retrieving labels
 * 
 * @returns {Link[]}
*/
async function ReadDocs(collRef, isCards) {
  let snapshot = await collRef.withConverter(isCards ? linkConverter : labelConverter).get();
  let somedocs = [];
  snapshot.forEach(doc => {
    let somedoc = doc.data();
    somedoc.id = doc.id
    somedocs.push(somedoc);    
  });
  return {docs: somedocs, lastDoc: snapshot.docs[snapshot.docs.length-1]};
}
/**
 * asynchronosly deletes a document in firestore
 *
 * @param {firebase.firestore.DocumentReference}  docRef  Firebase document reference
*/
async function deleteDoc(docRef) {
  const res = await docRef.delete();
}
/**
 * Updates a document in the firestore
 *
 * @param {firebase.firestore.CollectionReference}  collRef  Firebase collection reference
 * @param {object} updatedDoc Link class object
 * @param {boolean} isCard true if updating cards, false if updating labels
*/
async function setDoc(collRef, updatedDoc, isCard) {  
  const result = await collRef
    .doc(updatedDoc.id)
    .withConverter(isCard ? linkConverter : labelConverter)
    .set(updatedDoc);
  return result;
}
/**
 * asynchronosly update atomic counter field counter by 1
 * @param {firebase.firestore.DocumentReference}  docRef  Firebase document reference
 * @param {string} fieldName Field name
 * @param {boolean} [isIncrement=false] will increment field if true, subtract if false 
 */
async function updateCounter(docRef,fieldName,isIncrement) {
  const value = isIncrement ? firebase.firestore.FieldValue.increment(1) : firebase.firestore.FieldValue.increment(-1);
  const aResult = await docRef.update({ [fieldName]: value });
  return aResult;
}

export default {  
  methods: {
    /**
     * Creates a Link card document in Firestore
     *
     * @param {Link}  link  a Link class object
     * @param {string}  userid  a user's id
     * @param {string}  [tenant]  a user's tennant id
     * 
     * @returns {Link} returns an updated link with document ID
    */
    createCard(link, userid, tenant) {
      return new Promise(function (resolve, reject){          
        // set document reference
        let docRef = null
        if (tenant) {
          docRef = db.collection("tenants").doc(tenant)
          link.tenant = tenant
        } else {
          docRef = db.collection("profiles").doc(userid)
        }
        docRef
          .collection("cards")
          .withConverter(linkConverter)
          .add(link)
          .then((newDoc) => {
            link.id = newDoc.id;
            fireAnalytics.logEvent('card_created', {
              user_id: userid,
              tenant_id: tenant ? tenant : 'private',
              card_type: link.type.name,
            });
            resolve(link);
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates a Link card document in Firestore
     *
     * @param {Link}  link  a Link class object
     * @param {string}  link.id  a link's document id
     * @param {string}  link.author  a link's creator userid
     * @param {string}  [link.tenant]  a link's tennant id
    */
    updateCard(link) {
    return new Promise(function (resolve, reject){          
      // set document reference
      let collRef = null
      if (link.tenant) {
        collRef = db.collection("tenants").doc(link.tenant).collection("cards")
      } else {
        collRef = db.collection("profiles").doc(link.author).collection("cards")
      }
      try {      
        let aResult = setDoc(collRef, link, true);
        fireAnalytics.logEvent('card_updated', {
          user_id: link.author,
          tenant_id: link.tenant ? link.tenant : 'private',
          card_type: link.type.name,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    })},
    /**
     * Deletes a Link card document from Firestore
     *
     * @param {Link}  link  a Link class object
     * @param {string}  link.id  a link's document id
     * @param {string}  link.author  a link's creator userid
     * @param {string}  [link.tenant]  a link's tennant id
    */
    DeleteCard(link) {
      return new Promise(function (resolve, reject){          
        // set document reference
        let docRef = null
        if (link.tenant) {
          docRef = db.collection("tenants").doc(link.tenant).collection("cards").doc(link.id)
        } else {
          docRef = db.collection("profiles").doc(link.author).collection("cards").doc(link.id)
        }
        docRef.get().then((doc) =>  {
          if (doc.exists) {
            docRef.delete().then( () => {
              fireAnalytics.logEvent('card_deleted', {
                user_id: link.author,
                tenant_id: link.tenant ? link.tenant : 'private',
                card_type: link.type.name,
              });
              resolve();
            }).catch((error) => {
              reject(error);
            });
          } else {
              reject("Document does not exist in Firestore")
          }
        }).catch((error) => {
          reject(error);
        });
    })},
    /**
     * Retrieves all tenant or private cards from firebase
     *
     * @param {string}  id  Firestore collection id
     * @param {Pagination}  cardPagination  Pagination parameters
     * @param {SortStrategy} sortStrategy sort strategy for card retrieval
     * @param {boolean}  [personal=false]  retrieve private cards     * 
    */
    ReadAllCards(id, cardPagination, sortStrategy, personal) {
      return new Promise(function (resolve, reject){     
        // set document and collection references
        let collRef = null
        if (sortStrategy.isDescending) {
          collRef = db.collection(personal ? "profiles" : "tenants").doc(id).collection("cards").orderBy(sortStrategy.fieldName, 'desc').limit(cardPagination.firstRequestCount);
        } else {
          collRef = db.collection(personal ? "profiles" : "tenants").doc(id).collection("cards").orderBy(sortStrategy.fieldName).limit(cardPagination.firstRequestCount);
        }
        try {
          ReadDocs(collRef, true)
            .then( (response) => {
              cardPagination.reset();
              cardPagination.addQuery(collRef, response.docs.length, response.lastDoc);
              resolve(response.docs);
            });          
        } catch (error) {
          reject(error);
        }
      })
    },
    /**
     * Retrieves all tenant or private cards from firebase
     *
     * @param {string}  userId  Firestore collection id
     * @param {Pagination}  cardPagination  Pagination parameters
     * @param {SortStrategy} sortStrategy sort strategy for card retrieval
     * @param {string[]}  [searchTerms]  Firestore collection id
     * @param {string}  [tenantId]  Firestore collection id
     * @param {Boolean}  isPrivateMode  Firestore collection id
    */
    SearchAllCards(userId, cardPagination, sortStrategy, searchTerms, tenantId, isPrivateMode) {
      return new Promise(function (resolve, reject){     
        // set document and collection references
        let docRef = isPrivateMode ? db.collection("profiles").doc(userId) : db.collection("tenants").doc(tenantId);
        let colRef = null;
        if (sortStrategy.isDescending) {
          colRef = docRef.collection("cards")
            .where('keywords', 'array-contains-any', searchTerms)
            .orderBy(sortStrategy.fieldName, 'desc')
            .limit(cardPagination.firstRequestCount);
        } else {
          colRef = docRef.collection("cards")
            .where('keywords', 'array-contains-any', searchTerms)
            .orderBy(sortStrategy.fieldName)
            .limit(cardPagination.firstRequestCount);
        }
        try {
          ReadDocs(colRef, true)
            .then( (response1) => {
              cardPagination.reset();
              cardPagination.addQuery(colRef, response1.docs.length, response1.lastDoc);
              resolve(response1.docs);
            })
        } catch (error) {
          reject(error);
        }
      })
    },
    /**
     * Moves a card from profile to tenant and back
     *
     * @param {Link}  link  a Link class object
     * @param {string} tenantid Tenant document id
     * @param {string} userid user profile id
     * @param {boolean} [toPrivate=false] move direction (card is moved to profile if true)
    */
    MoveCard(link, tenantid, userid, toPrivate) {
      return new Promise(function (resolve, reject){     
        // set document and collection references
        let collRef = null
        let docRef = null
        if (toPrivate) {
          collRef = db.collection("profiles").doc(userid).collection("cards");
          docRef = db.collection("tenants").doc(tenantid).collection("cards").doc(link.id);
          link.tenant = null;
        } else {
          collRef = db.collection("tenants").doc(tenantid).collection("cards");
          docRef = db.collection("profiles").doc(userid).collection("cards").doc(link.id);
          link.tenant = tenantid;
        }
        try {      
          let aResult = setDoc(collRef, link, true);
          deleteDoc(docRef);
          resolve(aResult);
          return(aResult)
        } catch (error) {
          reject(error);
          return error;
        }
      })
    },
    /**
     * Increases the like Counter
     *
     * @param {Link}  link  a Link class object
    */
    IncrementLikeCounter(link) {
      return new Promise(function (resolve, reject){     
        // set document references
        const docRef = link.tenant 
              ? db.collection("tenants").doc(link.tenant).collection("cards").doc(link.id)
              : db.collection("profiles").doc(link.author).collection("cards").doc(link.id);
        try {      
          let aResult = updateCounter(docRef,'likes', true);
          resolve(aResult);
          return(aResult)
        } catch (error) {
          reject(error);
          return error;
        }
      })
    },
    /**
     * Locks or Unlocks the card in Firestore
     *
     * @param {Link}  link  a Link class object
     * @param {boolean}  isLocked  the new lock value
    */
    SetCardLock(link, isLocked) {
      return new Promise(function (resolve, reject){     
        // set document references
        const docRef = link.tenant 
              ? db.collection("tenants").doc(link.tenant).collection("cards").doc(link.id)
              : db.collection("profiles").doc(link.author).collection("cards").doc(link.id);
        docRef
          .update({
            locked: isLocked,
          })
          .then(() =>  {
            resolve(isLocked);
          })
          .catch((error) => {
            reject(error);
            return error;
          });
      })
    },
    /**
     * Retrieves all labels from firebase
     *
     * @param {string}  [tenantid]  Firestore collection id, retrieves default labels if null
    */
    ReadAllabels(tenantid) {
      return new Promise(function (resolve, reject){     
        // set document and collection references
        let collRef = null
        if (tenantid) {
          collRef = db.collection("tenants").doc(tenantid).collection("customLabels");
        } else {
          collRef = db.collection("defaultLabels");
        }
        try {
          ReadDocs(collRef, false)
            .then( (response) => {
              resolve(response.docs);
            })          
        } catch (error) {
          reject(error);
          return error;
        }
      })
    },
    /**
     * Sets a Label counter initial value in a tenant
     * (used for older tenant that do not have a label counter)
     * @param {string} tenantid Firestore collection id
     * @param {number} count a new custom label count
     */
    SetLabelCount(tenantid,count) {
      return new Promise(function (resolve, reject){ 
        const tenantRef = db.collection("tenants").doc(tenantid)
        tenantRef
          .update({
            customLabelCount: count
          })
          .then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          }); 
      });
    },
    /**
     * Creates a Custom Label document in Firestore
     *
     * @param {string}  tenantid  Firestore collection id
     * @param {LinkLabel}  label  a Link class object
     * 
     * @returns {LinkLabel} returns an updated link with document ID
    */
    createCustomLabel(tenantid, label) {
      return new Promise(function (resolve, reject){
        // set document reference
        const tenantRef = db.collection("tenants").doc(tenantid);
        tenantRef.collection("customLabels")
          .withConverter(labelConverter)
          .add(label)
          .then((newDoc) => {
            label.id = newDoc.id;
            updateCounter(tenantRef,'customLabelCount', true)
            .then(() => {
              resolve(label);
            }).catch((error) => {
              reject(error);
            });            
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Updates a custom label document in Firestore
     *
     * @param {string}  tenantid  Firestore collection id
     * @param {LinkLabel}  label  a Link class object
    */
    updateCustomLabel(tenantid, label) {
      return new Promise(function (resolve, reject){          
        // set document reference
        const collRef = db.collection("tenants").doc(tenantid).collection("customLabels");
        try {      
          setDoc(collRef, label, false);
          resolve();
        } catch (error) {
          reject(error);
        }
    })},
    /**
     * Deletes a custom Label document from Firestore
     *
     * @param {string}  tenantid  Firestore collection id
     * @param {LinkLabel}  label  a Link class object
    */
    DeleteCustomLabel(tenantid, label) {
      return new Promise(function (resolve, reject){
        // set document reference
        const tenantRef = db.collection("tenants").doc(tenantid);
        const docRef = tenantRef.collection("customLabels").doc(label.id);    
        deleteDoc(docRef)
          .then(()=>{
            updateCounter(tenantRef,'customLabelCount', false)
            .then(() => {
              resolve();
            }).catch((error) => {
              reject(error);
            });
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * store user display name in the card (in case they leave the tenant)
     *
     * @param {string}  tenantId  Firestore collection id
     * @param {string}  userId  Firestore collection id
     * @param {string}  displayName  a Link class object
    */
    StoreAuthorDisplayName(tenantId, userId, displayName) {
      return new Promise(function (resolve, reject){
        const authorCards = db.collection("tenants").doc(tenantId).collection("cards");
        authorCards
          .where('author', '==', userId)
          .get()
          .then(snapshots => {
            if (snapshots.size > 0) {
              snapshots.forEach(orderItem => {
                authorCards.doc(orderItem.id).update({ displayName: displayName })
              })
              resolve();
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    })},
    /**
     * Loads next documents in a page
     *
     * @param {firebase.firestore.Query}  query  The query that is used to retrieve the documents
     * @param {firebase.firestore.DocumentSnapshot}  lastDoc  the last document from the previous page
     * @param {number}  readLimit  a Link class object
    */
    LoadNextPage(query, lastDoc, readLimit) {
      return new Promise(function (resolve, reject){
        let collRef = query.startAfter(lastDoc).limit(readLimit)        
        try {
          ReadDocs(collRef, true)
            .then( (response) => {
              resolve(response);
            });          
        } catch (error) {
          reject(error);
        }
    })},
  } 
};