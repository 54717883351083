<template>
  <v-banner 
    v-model="filterVmodel"
    transition="slide-y-transition"
    style="width: 100%"
  >
    <v-card
    shaped
  >
    <v-card-actions class="pt-6">
      <v-row>
        <v-col cols="12">
          <Search v-if="$vuetify.breakpoint.xs" :autofocus="autofocusSearch"></Search>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $vuetify.lang.t("$vuetify.filter.labelTitle") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $vuetify.lang.t("$vuetify.filter.labelSubtitle") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-card 
      class="d-flex flex-wrap" 
      flat
    >
      <v-btn
        v-for="label in labelsArray" 
        :key="label.label.id" 
        class="mx-4 my-3"  
        :color="label.label.color" 
        dark 
        @click="labelClicked(label)" 
        :outlined="!label.selected" 
        x-small
      >
        {{ label.label.label }}
      </v-btn>
    </v-card>
    <v-divider></v-divider>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $vuetify.lang.t("$vuetify.filter.typeTitle") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $vuetify.lang.t("$vuetify.filter.typeSubtitle") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-card 
      class="d-flex flex-wrap " 
      flat
    >
      <v-btn 
        v-for="type in typesArray" 
        :key="type.type.name" 
        class="ma-2"  
        :outlined="!type.selected" 
        x-small  
        @click="typeClicked(type)" 
        color="primary"
        >
          <v-icon 
            class="mr-2" 
            :color="(type.selected)?'':'primary'"
            x-small
          >
            {{type.type.icon}}
          </v-icon>
        {{type.type.label}}
      </v-btn>
    </v-card>
  </v-card>


    <!-- <template v-slot:actions="{ dismiss }"> -->
    <v-spacer></v-spacer>
      <v-btn text color="primary" @click="resetFilterClicked">{{ $t('$vuetify.filter.clear') }}</v-btn>
    <!--</template>-->
  </v-banner>
</template>

<script>
import { mapActions, mapGetters} from 'vuex';
import Search from "@/components/search/Search";

export default {
  name: "FilterDialog",
  components: {Search},
  data() {
    return {
      autofocusSearch: true,
      filterVisible: true,
      labelsArray: [],
      typesArray: [],
      selectedTags: [],
      menu: false,
      //used to stop running a search during sign in
      ignoreFirstUpdate: true,
    }
  },
  computed: {
    ...mapGetters([
      'allLabels',
      'allCustomLabels',
      'allDefaultLabels',
      'allTypes',
      'showFilter',
      'filter',
      'isPrivateMode'
    ]),
    filterVmodel: {
      get(){
        return this.showFilter;
      },
      set(){
        this.closeFilter;
      }
    },
    selectedCounter(){
      return this.labelsArray.filter(item => item.selected).length + this.typesArray.filter(item => item.selected).length
    }
  },
  methods: {
    ...mapActions([
      'addLabelFilter',
      'removeLabelFilter',
      'addTypeFilter',
      'removeTypeFilter',
      'closeFilter',
      'searchCards',
      'resetFilter',
      'setTagsFilters',
    ]),
    labelClicked(label) {
      label.selected = !label.selected;
      if(label.selected){
        this.addLabelFilter(label.label);
      }
      else{
        this.removeLabelFilter(label.label)
      }
    },
    typeClicked(type) {
      type.selected = !type.selected;
      if(type.selected){
        this.addTypeFilter(type.type);
      }
      else{
        this.removeTypeFilter(type.type)
      }
    },
    updateSearchTags(val){
      if (!this.ignoreFirstUpdate) {
        this.searchCards(val);
      } else {
        this.ignoreFirstUpdate = false;
      }
      this.setTagsFilters(val);
      console.log(val);
    },
    loadFilters() {
     // this.resetFilter();
      this.labelsArray.length = 0;
      if (this.isPrivateMode) {
        this.allDefaultLabels.forEach((item, i) => {
          this.labelsArray.push({label: item, selected: this.filter.labelFilters.findIndex(label => item.id === label.id) >= 0 ? true : false});
        });
      } else {
        this.allCustomLabels.forEach((item, i) => {
          this.labelsArray.push({label: item, selected: this.filter.labelFilters.findIndex(label => item.id === label.id) >= 0 ? true : false});
        });
      }
      this.labelsArray.sort();
      this.typesArray.length = 0;
      this.allTypes.forEach((item, i) => {
        this.typesArray.push({type: item, selected: this.filter.typeFilters.findIndex(type => item.name === type.name) >= 0 ? true : false});
      });
    },
    resetFilterClicked(){
      this.labelsArray.forEach((item, i) => {
        item.selected = false;
      });
      this.typesArray.forEach((item, i) => {
        item.selected = false;
      });
      this.selectedTags=[];
      this.resetFilter();
    }
  },
  watch: {
    allCustomLabels(value) {
      if (value) {
        this.loadFilters();
      }  
    },
  },
  mounted () {
    this.loadFilters();
  }
}
</script>

<style>
.v-banner__text{
  width: 100%;
  margin-right: 16px;
}
</style>
