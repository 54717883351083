var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"shaped":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("$vuetify.codes.invitations"))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allTenantCodes,"item-key":"id","flat":""},scopedSlots:_vm._u([{key:"item.properties.inviteeEmail",fn:function(ref){
var item = ref.item;
return [(item.properties.inviteeEmail)?_c('span',[_vm._v(_vm._s(item.properties.inviteeEmail))]):_c('span')]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-input',{staticClass:"font-weight-regular body-2  pt-4 pb-3",attrs:{"dense":"","readonly":"","hide-details":"true"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.code),expression:"item.code"}],ref:item.code,attrs:{"type":"text","readonly":"","size":"25"},domProps:{"value":(item.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "code", $event.target.value)}}})])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.codes.' + item.type))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status === 'pending'? ((_vm.$t('tenant.members.inviteAccepted')) + " " + (item.properties.applicantDisplayName)) : item.status)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created.toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":"","id":("deleteInviteCode-" + (item.id))},on:{"click":function($event){return _vm.deleteCodeClicked(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.codes.tipDelete'))+" ")])]):_vm._e(),(item.status !== 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.sendEmail(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-email")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.codes.tipMail'))+" ")])]):_vm._e(),(item.status === 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.AcceptClicked(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.codes.tipAccept'))+" ")])]):_vm._e(),(item.status === 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"grey":"","lighten-4":""},on:{"click":function($event){return _vm.RejectClicked(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.codes.tipReject'))+" ")])]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }