<template lang="pug">
  v-app(
      id="app"
      v-resize="onResize"
      :style="{ background: $vuetify.theme.themes[theme].background }"
  )
    Navbar(v-if="showNavbar" @drawer="drawer = true")
    Drawer(v-if="showNavbar" @help-clicked="tutorialCompleted = false" :open="drawer" @close="drawer = false" @open="drawer=true")
    LinkDialog
    ShareDialog
    Tutorial(v-if="showTutorial" :show="showTutorial" @completed="onTutorialCompleted")
    // TutorialTenant(v-if="showTutorialTenant" :show="showTutorialTenant" @completed="tutorialTenantCompleted = true")
    ConfirmDialog(ref="confirm")
    Notifications
    v-main
      router-view(class="text-start")
    Footer(v-if="['Login', 'Reset', 'User Management'].includes($route.name)")
</template>

<script>
import Navbar from './components/core/Navbar';
import LinkDialog from './components/LinkDialog';
import Notifications from './components/Notifications';
import ConfirmDialog from "./components/ConfirmDialog";
import ShareDialog from "./components/ShareDialog";
import { mapActions, mapGetters} from 'vuex';
import Footer from "@/components/core/Footer";
import firebase from 'firebase/app'
import Tutorial from "@/components/tutorial/Tutorial";
import Drawer from "@/components/core/Drawer";
import TutorialTenant from "@/components/tutorial/tenantCreation/TutorialTenant";
import { version } from "../package.json";

export default {
  components: {
    TutorialTenant,
    Drawer,
    Tutorial,
    Footer,
    Navbar,
    LinkDialog,
    Notifications,
    ConfirmDialog,
    ShareDialog,
  },
  name: 'App',
  metaInfo: {
    changed(metaInfo) {
      firebase.analytics().setCurrentScreen(metaInfo.title);
      firebase.analytics().logEvent("page_view");
      firebase.analytics().logEvent("screen_view", {
        app_name: isPWA() ? "pwa" : "web",
        screen_name: metaInfo.title,
        app_version: version
      });
    }
  },
  data() {
    return {
      tutorialCompleted: true,
      tutorialTenantCompleted: false,
      drawer: false,
    }
  },
  computed: {
    ...mapGetters([
      "cardPagination",
      "currentUser",
      "currentStoredCode",
      "currentProfile",
    ]),
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    showNavbar() {
      return !['Login', 'Register', 'Reset', 'User Management'].includes(this.$route.name)
    },
    showTutorial() {
      return Boolean((this.currentUser && this.currentProfile) && (!this.currentProfile.hideTutorial | !this.tutorialCompleted))
    },
    showTutorialTenant() {
      return Boolean( (this.$route.path.startsWith('/tenant') && !this.tutorialTenantCompleted))
    },
  },
  methods: {
    ...mapActions([
      'fetchTypes',
      'fetchSortingStrategies',
      'updateHideTutorial',
      'processEnteredCode',
    ]),
    onResize(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': this.cardPagination.setRequestCount(16,8);
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1 user-scalable=no");
          break;
        case 'sm':
          this.cardPagination.setRequestCount(16,8);
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1");
          break;
        case 'md':
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1");
          this.cardPagination.setRequestCount(40,20);
          break;
        case 'lg': this.cardPagination.setRequestCount(60,30);
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1");
          break;
        case 'xl': this.cardPagination.setRequestCount(100,50);
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1");
          break;
        default:
          this.cardPagination.setRequestCount(40,20);
          break;
      }
    },
    async onTutorialCompleted() {
      this.tutorialCompleted = true

      if (!this.currentProfile.hideTutorial) {
        await this.updateHideTutorial({ hideTutorial: true })
        this.$fireAnalytics.logEvent('tutorial_complete');
      }
    },
    async onTutorialSkipped() {
      this.tutorialCompleted = true

      if (!this.currentProfile.hideTutorial) {
        await this.updateHideTutorial({ hideTutorial: true })
        this.$fireAnalytics.logEvent('tutorial_skipped');
      }
    }
  },
  mounted(){
    this.$vuetify.lang.current = this.$route.query.lang ? this.$route.query.lang : localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.$i18n.locale = this.$vuetify.lang.current;
    firebase.auth().languageCode = this.$vuetify.lang.current;
    this.$root.$confirm = this.$refs.confirm.open;
    this.$vuetify.theme.dark = this.$route.query.theme === 'dark' ? true : localStorage.getItem("theme") === 'dark';

    this.fetchTypes();
    this.fetchSortingStrategies();
  },
  watch: {
    currentProfile(value) {
      if (value && this.currentStoredCode) {
        console.log("processing code: ", this.currentStoredCode);
        this.processEnteredCode(this.currentStoredCode);
      }
    },
  },
}

</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(''),
  url('/fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('/fonts/roboto-v20-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

</style>