<template lang="pug">
  v-row(justify="center")
    v-col(cols="12" lg="6")
      v-card(flat color="transparent")
        v-card-actions
          v-row
            v-col(cols="12")
              h4(class="text-h5 text-center") {{ this.$t('tenant.creation.step1.title') }}
            v-col(cols="12" class="pb-0")
              v-text-field(
                :label="$t('tenant.creation.step1.name')"
                v-model="name"
                :error-messages="nameErrors"
                outlined
                autofocus
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                @keyup.enter.native="createTenant")
            v-col(cols="12" class="pt-0 pb-0")
              p {{ this.$t('tenant.creation.step1.subtitle') }}
            v-col(cols="12" class="pt-0 mt-0")
              v-btn(@click="createTenant" :loading="loading" color="primary" :disabled="$v.name.$invalid") {{ this.$t('tenant.creation.step1.create') }}
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import Tenant from "@/store/classes/Tenant";

export default {
  name: "CreateTenantStepperOne",
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(256),
    },
  },
  props: {
    forceClose: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  watch: {
    forceClose: function (newVal, oldVal) {
      this.reset()
    }
  },
  data() {
    return {
      loading: false,
      name: "",
    }
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.minLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.mincharacters', 1))
      !this.$v.name.maxLength && errors.push(this.$vuetify.lang.t('$vuetify.rules.maxcharacters', 256))
      return errors
    },
  },
  methods: {
    ...mapActions({
      createTenantAction: "createTenant",
    }),
    createTenant() {
      this.$v.name.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true

      // The tenant needs a display name to be handled correctly
      const tenant = Tenant.empty()
      tenant.displayName = this.name;
      tenant.companyName = this.name;
      this.createTenantAction(tenant)

      // Analytics
      this.$fireAnalytics.logEvent('tenant_creation_step1_completed');

      this.loading = false
      this.$emit('next')
      this.reset()
    },
    reset() {
      this.name = ""
    },
    closeDialog() {
      this.reset()
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>