<template lang="pug">
  v-data-table(
    :headers="headers"
    :items="desserts"
    :items-per-page="5"
    class="elevation-1")
</template>

<script>
export default {
name: "PricingTable",
  data () {
    return {
      headers: [
        {
          text: 'Feature',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: 'Bronze', value: 'calories'},
        {text: 'Silver', value: 'fat'},
        {text: 'Gold', value: 'carbs'},
        {text: 'Platinum', value: 'protein'},
      ],
      desserts: [
        {
          name: 'Feature 0',
          calories: '✓',
          fat: '✓',
          carbs: '✓',
          protein: '✓',
          iron: '✓',
        },
        {
          name: 'Feature 1',
          calories: 'x',
          fat: '✓',
          carbs: '✓',
          protein: '✓',
          iron: '✓',
        },
        {
          name: 'Feature 2',
          calories: 'x',
          fat: 'x',
          carbs: '✓',
          protein: '✓',
          iron: '✓',
        },
        {
          name: 'Feature 2',
          calories: 'x',
          fat: 'x',
          carbs: 'x',
          protein: '✓',
          iron: '✓',
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>